import React from 'react';
import { TextNew } from 'src/components/shared/TextNew';
import styles from './ListHeader.module.sass';

const ListHeader = () => {
    return (
        <TextNew className={styles.root} textSize={'XL'} tagName={'h2'} textWeight={700}>
            <span className={styles.title}>Breakdown</span>
            <span className={styles.units}>
                tCO<sub>2</sub>e
            </span>
        </TextNew>
    );
};

export { ListHeader };
