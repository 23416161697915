type formatNumberOptions = {
    fixedWidth?: boolean;
    minimum?: number;
};

const defaultOptions = {
    fixedWidth: false,
};

function formatFixedWidth(value: string, width: number) {
    const index = value.indexOf('.');
    if (index < 0) {
        return `${value}.${''.padEnd(width, '0')}`;
    }
    const trailing = value.substring(index + 1);
    return `${value.substring(0, index)}.${trailing.padEnd(width, '0').substring(0, width)}`;
}

const formatNumber = (value: number, options: formatNumberOptions = defaultOptions) => {
    const v = Math.max(options.minimum ?? Number.MIN_VALUE, value);
    let text = v.toLocaleString('en-AU', {
        maximumFractionDigits: 3,
    });
    text = options.fixedWidth ? formatFixedWidth(text, 3) : text;
    if (options.minimum && value < options.minimum) {
        text = `< ${text}`;
    }
    return text;
};

export default formatNumber;
