import React, { ReactNode } from 'react';
import { NavigationPage } from '../NavigationPage';
import { Grid } from 'src/components/shared/Grid';
import { NumericInputForm } from 'src/components/shared/NumericInputForm';
import { ReactComponent as Economy } from './Economy.svg';
import { ReactComponent as Business } from './BusinessClass.svg';
import { TravelType, DomesticAirTravelType } from 'src/models/travel';
import { useDispatch, useSelector } from 'react-redux';
import { updateTravel } from 'src/store/actions/travel/updateTravel';
import { RootState } from 'src/store';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { useTimeframe } from 'src/store/utils/useTimeframe';
import styles from './DomesticAir.module.sass';

type DomesticAirInputProps = {
    title: string;
    subtype: DomesticAirTravelType;
    icon: ReactNode;
};

const DomesticAirInput: React.FC<DomesticAirInputProps> = (props: DomesticAirInputProps) => {
    const { title, subtype, icon } = props;
    const dispatch = useDispatch();
    const duration = useSelector<RootState, number>(
        state => state.calculation.travel[TravelType.DomesticAir][subtype].duration,
    );
    return (
        <NumericInputForm
            title={title}
            icon={icon}
            value={duration}
            units="hours"
            onValueChange={value =>
                dispatch(
                    updateTravel({
                        type: TravelType.DomesticAir,
                        subtype,
                        duration: value,
                    }),
                )
            }
        />
    );
};

const DomesticAir: React.FC = () => {
    const { timeframeText } = useTimeframe();
    return (
        <>
            <NavigationPage
                title="Travel - Air, Domestic"
                description={`Over the past ${timeframeText}, how many hours has your household travelled domestically by air?`}
                tooltip="To account for your whole household, make sure you multiply the flight time by the number of household members on each flight.">
                <div className={styles.root}>
                    <Grid columns={{ xs: 2 }}>
                        <DomesticAirInput title="Economy" subtype={DomesticAirTravelType.Economy} icon={<Economy />} />
                        <DomesticAirInput
                            title="Business"
                            subtype={DomesticAirTravelType.Business}
                            icon={<Business />}
                        />
                    </Grid>
                </div>
            </NavigationPage>
            <DidYouKnow
                text={[
                    "The carbon footprint of a passenger flying economy class from Sydney to Melbourne is <strong>230kg CO₂e</strong>! That's equivalent to driving <strong>15km</strong> to and from work everyday for <strong>6 weeks</strong>.",
                    'Emissions are highest during take-off and landing. To reduce your carbon footprint, try to fly direct whenever possible.',
                    'Flying business class almost <strong>doubles</strong> your per-passenger carbon footprint!',
                ]}
            />
        </>
    );
};

export { DomesticAir };
