import React from 'react';
import { TextNew } from 'src/components/shared/TextNew';
import { ButtonText } from 'src/components/shared/ButtonText';
import { Modal } from '../shared/Modal';
import { ModalControls } from '../shared/ModalControls';
import { useUI } from '../UI';
import { event } from 'src/utils/eventTracking';
import styles from './modal.module.sass';

type NewCalculationProps = {
    onClose: () => void;
};

const NewCalculation = (props: NewCalculationProps) => {
    const { onClose } = props;
    const { reset } = useUI();
    return (
        <Modal onClose={onClose} title="Warning">
            {props => (
                <div className={styles.root}>
                    <TextNew className={styles.modalContent} tagName={'p'} textSize={'M'} textWeight={400}>
                        Opening a new calculation will clear any unsaved data in your current session. Are you sure you
                        wish to open?
                    </TextNew>

                    <ModalControls>
                        <ButtonText className={styles.button} variant="outlined" onClick={props.close} text="Cancel" />
                        <ButtonText
                            className={styles.button}
                            variant="primary"
                            text="OK"
                            onClick={() => {
                                event('new', 'new-calculation');
                                reset();
                                props.close();
                            }}
                        />
                    </ModalControls>
                </div>
            )}
        </Modal>
    );
};

export { NewCalculation };
