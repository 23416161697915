import { store } from 'src/store';
import { serializeCategoriesState } from 'src/store/serializers/categorySerializers';

const flushCategoriesState = () => {
    const {
        calculation: { categories },
    } = store.getState();
    try {
        localStorage.setItem('cpos:categories', serializeCategoriesState(categories));
    } catch (err) {
        // TODO: update state with error
    }
};

export { flushCategoriesState };
