import React, { ReactNode, useReducer, useMemo } from 'react';
import { SaveCalculation } from './modals/SaveCalculation';
import { NewCalculation } from './modals/NewCalculation';
import { ChangeTimeframeModal } from './modals/ChangeTimeframeModal';
import { useDispatch } from 'react-redux';
import { ActionType as RootActionType } from 'src/store/actions/root';
import { ActionType as NavigationActionType } from 'src/store/actions/navigation';
import { TimeframeType } from 'src/models';
import { IncompleteResultsModal } from './modals/IncompleteResultsModal';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { useConfiguration } from 'src/ConfigurationProvider';
import { OffsetAsGuestModal } from './modals/OffsetAsGuestModal';
import { event } from 'src/utils/eventTracking';

export enum ModalType {
    NEW,
    SAVE,
    CHANGE_TIMEFRAME,
    INCOMPLETE_RESULTS_WARNING,
    OFFSET_AS_GUEST,
}

enum ActionType {
    SHOW_MODAL,
    HIDE,
    RESET,
    TOGGLE_SIDEBAR,
    CLOSE_SIDEBAR,
}

type ShowModalActionPayload =
    | { type: ModalType.NEW }
    | { type: ModalType.SAVE }
    | { type: ModalType.CHANGE_TIMEFRAME; timeframe: TimeframeType }
    | { type: ModalType.INCOMPLETE_RESULTS_WARNING }
    | { type: ModalType.OFFSET_AS_GUEST; onGuest: () => void; onLogin: () => void };

type Action =
    | { type: ActionType.SHOW_MODAL; payload: ShowModalActionPayload }
    | { type: ActionType.HIDE }
    | { type: ActionType.RESET }
    | { type: ActionType.TOGGLE_SIDEBAR }
    | { type: ActionType.CLOSE_SIDEBAR };

type State = {
    modal?: ShowModalActionPayload;
    isSidebarOpen: boolean;
};

const initalState = {
    modal: undefined,
    isSidebarOpen: false,
};

const reducer = (state: State = initalState, action: Action): State => {
    switch (action.type) {
        case ActionType.SHOW_MODAL:
            return {
                ...state,
                modal: action.payload,
            };
        case ActionType.HIDE:
            return {
                ...state,
                modal: undefined,
            };
        case ActionType.HIDE:
            return initalState;
        case ActionType.TOGGLE_SIDEBAR:
            return {
                ...state,
                isSidebarOpen: !state.isSidebarOpen,
            };
        case ActionType.CLOSE_SIDEBAR:
            return {
                ...state,
                isSidebarOpen: false,
            };
    }
    return state;
};

export type UIContextType = {
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
    closeSidebar: () => void;
    login: (total: number | undefined, calculationId: string | undefined) => void;
    logout: () => void;
    offset: (total: number | undefined, calculationId: string | undefined) => void;
    showModal: (modal: ShowModalActionPayload) => void;
    reset: () => void;
};

const UIContext = React.createContext<UIContextType | null>(null);

function useUI(): UIContextType {
    const context = React.useContext(UIContext);
    if (context === null) {
        throw Error('The UIContext has not yet been initialized.');
    }
    return context;
}

function withUI(Component: any) {
    return function WrappedComponent(props: any) {
        return <UIContext.Consumer>{ui => <Component {...props} ui={ui} />}</UIContext.Consumer>;
    };
}

function createQueryStringParameters(total: number | undefined, calculationId: string | undefined) {
    const parameters = Array<string>();
    if (total) {
        parameters.push(`emission_total=${total}`);
    }
    if (calculationId) {
        parameters.push(`calculation_id=${calculationId}`);
    }
    return parameters;
}

type UIProviderProps = {
    children: ReactNode;
};

function UIProvider(props: UIProviderProps) {
    const reduxDispatch = useDispatch();
    const [state, dispatch] = useReducer(reducer, initalState);
    const { logoutUrl } = useConfiguration();
    const api = useMemo<UIContextType>(() => {
        return {
            isSidebarOpen: state.isSidebarOpen,
            toggleSidebar: () =>
                dispatch({
                    type: ActionType.TOGGLE_SIDEBAR,
                }),
            closeSidebar: () =>
                dispatch({
                    type: ActionType.CLOSE_SIDEBAR,
                }),
            login: (total: number | undefined, calculationId: string | undefined) => {
                if (process.env.REACT_APP_CPOS_LOGIN_PAGE_URL) {
                    const parameters = props ? createQueryStringParameters(total, calculationId) : [];
                    window.location.href = `${process.env.REACT_APP_CPOS_LOGIN_PAGE_URL}?${parameters.join('&')}`;
                }
            },
            logout: () => {
                if (logoutUrl) {
                    window.location.href = logoutUrl;
                }
            },
            offset: (total: number | undefined, calculationId: string | undefined) => {
                if (process.env.REACT_APP_CPOS_OFFSET_PAGE_URL) {
                    event('offset', 'proceed-to-offset');
                    const parameters = createQueryStringParameters(total, calculationId);
                    window.location.href = `${process.env.REACT_APP_CPOS_OFFSET_PAGE_URL}?${parameters.join('&')}`;
                }
            },
            showModal: (payload: ShowModalActionPayload) => {
                dispatch({
                    type: ActionType.SHOW_MODAL,
                    payload,
                });
            },
            reset: () => {
                reduxDispatch({ type: RootActionType.NEW });
                reduxDispatch({
                    type: NavigationActionType.NAVIGATE_TO,
                    payload: {
                        key: NavigationKey.Root,
                        index: 0,
                    },
                });
                reduxDispatch({
                    type: NavigationActionType.NAVIGATE_TO,
                    payload: {
                        key: NavigationKey.Main,
                        index: 1,
                    },
                });
                reduxDispatch({
                    type: NavigationActionType.NAVIGATE_TO,
                    payload: {
                        key: NavigationKey.GettingStarted,
                        index: 0,
                    },
                });
                dispatch({
                    type: ActionType.RESET,
                });
            },
        };
    }, [state.isSidebarOpen, dispatch, reduxDispatch]);
    return (
        <UIContext.Provider value={api}>
            <>
                {props.children}
                {state.modal && state.modal.type === ModalType.NEW && (
                    <NewCalculation
                        onClose={() =>
                            dispatch({
                                type: ActionType.HIDE,
                            })
                        }
                    />
                )}
                {state.modal && state.modal.type === ModalType.SAVE && (
                    <SaveCalculation
                        onClose={() =>
                            dispatch({
                                type: ActionType.HIDE,
                            })
                        }
                    />
                )}
                {state.modal && state.modal.type === ModalType.CHANGE_TIMEFRAME && (
                    <ChangeTimeframeModal
                        timeframe={state.modal.timeframe}
                        onClose={() =>
                            dispatch({
                                type: ActionType.HIDE,
                            })
                        }
                    />
                )}
                {state.modal && state.modal.type === ModalType.INCOMPLETE_RESULTS_WARNING && (
                    <IncompleteResultsModal
                        onClose={() =>
                            dispatch({
                                type: ActionType.HIDE,
                            })
                        }
                        onOk={() => {
                            dispatch({
                                type: ActionType.HIDE,
                            });
                            reduxDispatch({
                                type: NavigationActionType.NAVIGATE_TO,
                                payload: {
                                    key: NavigationKey.Main,
                                    index: 3,
                                },
                            });
                        }}
                    />
                )}
                {state.modal && state.modal.type === ModalType.OFFSET_AS_GUEST && (
                    <OffsetAsGuestModal
                        onClose={() =>
                            dispatch({
                                type: ActionType.HIDE,
                            })
                        }
                        onGuest={state.modal.onGuest}
                        onLogin={state.modal.onLogin}
                    />
                )}
            </>
        </UIContext.Provider>
    );
}

export { UIProvider, useUI, withUI };
