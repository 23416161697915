import { Middleware } from 'redux';
import { RootState } from 'src/store';
import { Actions, ActionType } from 'src/store/actions/navigation';
import { flushUIState } from '../storage/flushUIState';

const navigationMiddleware: Middleware<{}, RootState> = store => next => (action: Actions) => {
    const result = next(action);
    switch (action.type) {
        case ActionType.NAVIGATE_TO:
        case ActionType.RESET:
        case ActionType.SET_COMPLETED:
            flushUIState();
            break;
    }
    return result;
};

export { navigationMiddleware };
