import React from 'react';
import { NavigationPage } from '../NavigationPage';
import { Grid } from 'src/components/shared/Grid';
import { NumericInputForm } from 'src/components/shared/NumericInputForm';
import { ReactComponent as CoachIcon } from './Coach.svg';
import { TravelType, CoachTravelType } from 'src/models/travel';
import { useDispatch, useSelector } from 'react-redux';
import { updateTravel } from 'src/store/actions/travel/updateTravel';
import { RootState } from 'src/store';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { useTimeframe } from 'src/store/utils/useTimeframe';
import styles from './Coach.module.sass';

const Coach = () => {
    const { timeframeText } = useTimeframe();
    const dispatch = useDispatch();
    const duration = useSelector<RootState, number>(
        state => state.calculation.travel[TravelType.Coach][CoachTravelType.Coach].duration,
    );
    return (
        <>
            <NavigationPage
                title="Travel - Coach"
                description={`Over the past ${timeframeText}, how many hours has your household travelled by coach?`}
                tooltip="To account for your whole household, make sure you multiply the travel time by the number of household members on each trip. Transport around your local area by public bus should be accounted for in the Transport section.">
                <div className={styles.root}>
                    <Grid columns={{}}>
                        <NumericInputForm
                            title="Coach"
                            icon={<CoachIcon className={styles.icon} />}
                            value={duration}
                            units="hours"
                            disabled={false}
                            onValueChange={value =>
                                dispatch(
                                    updateTravel({
                                        type: TravelType.Coach,
                                        subtype: CoachTravelType.Coach,
                                        duration: value,
                                    }),
                                )
                            }
                        />
                    </Grid>
                </div>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'Depending on how full they are, coaches produce between 5 and 10 times fewer emissions per passenger than cars or aircraft.',
                    'Lower your travel footprint by packing light - the more a coach weights, the more carbon emissions it produces!',
                ]}
            />
        </>
    );
};

export { Coach };
