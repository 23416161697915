import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { NavigationRoot } from 'src/components/shared/Navigation';
import { EnergyType } from 'src/models/energy';
import { Energy } from './Energy';
import { ElectricityRegion } from './ElectricityRegion';
import { ElectricityConsumption } from './ElectricityConsumption';
import { NaturalGasRegion } from './NaturalGasRegion';
import { NaturalGasConsumption } from './NaturalGasConsumption';
import { LPGConsumption } from './LPGConsumption';
import { FirewoodConsumption } from './FirewoodConsumption';
import { flushEnergyState } from 'src/store/storage/flushEnergyState';
import { NavigationKey } from 'src/store/reducers/navigationReducer';

const EnergyRoot: React.FC = () => {
    const selected = useSelector<RootState, Array<EnergyType>>(state => state.calculation.energy.selected);
    return (
        <NavigationRoot navigationKey={NavigationKey.Energy} onNavigate={flushEnergyState}>
            <Energy />
            {selected.includes(EnergyType.Electricity) && (
                <NavigationRoot navigationKey={NavigationKey.EnergyElectricity} onNavigate={flushEnergyState}>
                    <ElectricityRegion />
                    <ElectricityConsumption />
                </NavigationRoot>
            )}
            {selected.includes(EnergyType.NaturalGas) && (
                <NavigationRoot navigationKey={NavigationKey.EnergyNaturalGas} onNavigate={flushEnergyState}>
                    <NaturalGasRegion />
                    <NaturalGasConsumption />
                </NavigationRoot>
            )}
            {selected.includes(EnergyType.LPG) && <LPGConsumption />}
            {selected.includes(EnergyType.Firewood) && <FirewoodConsumption />}
        </NavigationRoot>
    );
};

export { EnergyRoot };
