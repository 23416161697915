import React, { ReactNode, useEffect, useState } from 'react';
import { useConfiguration } from './ConfigurationProvider';
import { Throbber } from './components/shared/Throbber';
import styles from './AuthenticationProvider.module.sass';

type AuthenticationContextType = {
    isAuthenticated: boolean;
};

const AuthenticationContext = React.createContext<AuthenticationContextType | null>(null);

function useAuthentication(): AuthenticationContextType {
    const context = React.useContext(AuthenticationContext);
    if (context === null) {
        throw Error('The AuthenticationContext has not yet been initialized.');
    }
    return context;
}

type AuthenticationProviderProps = {
    children: ReactNode;
};

function AuthenticationProvider(props: AuthenticationProviderProps) {
    const { children } = props;
    const {
        wpApiSettings: { root, nonce },
    } = useConfiguration();
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    useEffect(() => {
        const load = async () => {
            try {
                const response = await fetch(`${root}wp/v2/users/me`, {
                    headers: {
                        'X-WP-Nonce': nonce,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                });
                if (response.status === 200) {
                    setIsAuthenticated(true);
                }
            } catch (e) {
                console.log(e);
            } finally {
                setIsLoading(false);
            }
        };
        load();
    }, []);

    if (isLoading) {
        return (
            <div className={styles.root}>
                <Throbber className={styles.throbber} variant="primary" />
            </div>
        );
    }
    return <AuthenticationContext.Provider value={{ isAuthenticated }}>{children}</AuthenticationContext.Provider>;
}

export { AuthenticationProvider, useAuthentication };
