import { BinSize, CollectionFrequency, PercentageFull } from 'src/models/waste';
import { ActionType, Actions } from '.';
import { ReduxThunkAction, WasteState } from 'src/store';
import strip from 'src/utils/strip';
import { ConfigurationType } from 'src/configuration';
import { Conversion } from 'src/libs/conversion';
import { TimeframeType } from 'src/models';

function calculateEmissions(waste: WasteState, timeframe: TimeframeType, configuration: ConfigurationType): number {
    let factor = configuration.emissions.waste[waste.binSize];

    switch (waste.collectionFrequency) {
        case CollectionFrequency.Fortnightly:
            factor = factor / 2;
            break;
        case CollectionFrequency.Monthly:
            factor = factor / (52 / 12);
            break;
    }

    switch (waste.percentageFull) {
        case PercentageFull.Empty:
            factor = 0;
            break;
        case PercentageFull.Quarter:
            factor = factor * 0.25;
            break;
        case PercentageFull.Half:
            factor = factor * 0.5;
            break;
        case PercentageFull.ThreeQuarter:
            factor = factor * 0.75;
            break;
        case PercentageFull.Full:
            factor = factor * 1;
            break;
    }

    return factor * Conversion.yearly[timeframe];
}

type UpdateWasteProps = {
    binSize?: BinSize;
    collectionFrequency?: CollectionFrequency;
    percentageFull?: PercentageFull;
};

const updateWaste = (props: UpdateWasteProps): ReduxThunkAction<Actions> => {
    return (dispatch, getState, configuration) => {
        dispatch({
            type: ActionType.UPDATE,
            payload: strip(props),
        });

        const {
            calculation: {
                metadata: { timeframe },
                waste,
            },
        } = getState();

        dispatch({
            type: ActionType.UPDATE_EMISSIONS,
            payload: {
                emissions: calculateEmissions(waste, timeframe, configuration),
            },
        });
    };
};

export { updateWaste };
