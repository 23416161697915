import React from 'react';
import classNames from 'classnames';
import { TextNew } from '../shared/TextNew';
import { UIElementProps } from '..';
import styles from './ShareFooter.module.sass';

import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
} from 'react-share';

const URL = window.location.href;

const QUOTE =
    "I've found this amazing tool from Carbon Positive Australia that allows you to calculate your personal carbon footprint for free. Check it out here!";

const ShareFooter = (props: UIElementProps) => {
    const { className } = props;
    const quote = QUOTE;
    return (
        <section className={classNames(className, styles.root)}>
            <TextNew className={styles.label} textSize={'XS'} textStyle={'smallCaps'}>
                Share
            </TextNew>
            <FacebookShareButton className={styles.button} url={URL} quote={quote}>
                <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton className={styles.button} url={URL} title={quote}>
                <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton className={styles.button} url={URL}>
                <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
        </section>
    );
};

export { ShareFooter };
