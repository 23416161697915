import { TimeframeType } from 'src/models';

export enum ActionType {
    UPDATE = 'METADATA:UPDATE',
}

export type UpdateAction = {
    type: ActionType.UPDATE;
    payload: {
        id: string | undefined;
        label: string | undefined;
        timeframe: TimeframeType | null;
        savedAt: Date | undefined;
    };
};

export type Actions = UpdateAction;
