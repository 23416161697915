import React, { MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './TextNew.module.sass';

interface TextProps extends React.HTMLAttributes<HTMLElement> {
    tagName?: keyof JSX.IntrinsicElements;
    id?: string;
    className?: string;
    textStyle?: 'smallCaps' | 'title' | 'subtitle' | 'link' | 'default';
    textSize?: 'XXXS' | 'XXS' | 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL' | 'XXXL';
    textWeight?: 300 | 400 | 700;
    children: ReactNode;
    onClick?: MouseEventHandler;
    ref?: React.Ref<any>;
}

const TextNew: React.FC<TextProps> = (props: TextProps) => {
    const {
        tagName,
        id,
        className,
        textStyle = 'default',
        textSize = 'M',
        textWeight = 400,
        children,
        onClick
    } = props;

    const style = {
        fontWeight: textWeight,
    };

    const Component = tagName || 'span';
    return (
        <Component
            {...{ style }}
            id={id}
            onClick={onClick}
            className={classNames(className, styles[`style--${textStyle}`], styles[`size--${textSize}`])}>
            {children}
        </Component>
    );
};

export { TextNew };