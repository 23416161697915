import { Middleware } from 'redux';
import { RootState } from 'src/store';
import { ActionType as RootActionType } from 'src/store/actions/root';
import { clearState } from 'src/store/storage/clearState';
import { flushUIState } from 'src/store/storage/flushUIState';
import { flushMetadataState } from 'src/store/storage/flushMetadataState';

const rootMiddleware: Middleware<{}, RootState> = store => next => action => {
    const result = next(action);
    if (action.type === RootActionType.NEW) {
        clearState();
        flushUIState();
        flushMetadataState();
    }
    return result;
};

export { rootMiddleware };
