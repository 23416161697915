import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { ReactComponent as Information } from 'src/components/svg/Information.svg';
import { ColorVariant } from 'src/styles/ColorVariant';
import { Tooltip } from 'src/components/shared/Tooltip';
import styles from './Form.module.sass';

type Size = 'small' | 'large';

type FormProps = {
    className?: string;
    colorVariant?: ColorVariant;
    badge?: ReactNode;
    size?: Size;
    children: ReactNode | ReactNode[];
    tooltip?: string;
};

const Form: React.FC<FormProps> = (props: FormProps) => {
    const { className, colorVariant = undefined, size = 'small', badge, children, tooltip } = props;

    return (
        <div className={classNames(className, styles.root)} data-size={size} data-color-variant={colorVariant}>
            {tooltip && (
                <Tooltip text={tooltip} className={styles.tooltipContainer}>
                    <Information className={styles.tooltip} tabIndex={-1} />
                </Tooltip>
            )}
            {badge && badge}
            <div
                className={classNames(styles.content, {
                    [styles.padded]: badge,
                })}>
                {children}
            </div>
        </div>
    );
};

export { Form };
