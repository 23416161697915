import { layoutGenerator } from "react-break";

export const layout = layoutGenerator({
  default: 0,
  xs: 420,
  sm: 600,
  md: 800,
  lg: 1100,
  xl: 1300,
});

export const AtLeastDefault = layout.isAtLeast('default');
export const AtMostDefault = layout.isAtMost('default');

export const AtLeastXS = layout.isAtLeast('xs');
export const AtLeastSM = layout.isAtLeast('sm');
export const AtLeastMD = layout.isAtLeast('md');
export const AtLeastLG = layout.isAtLeast('lg');
export const AtLeastXL = layout.isAtLeast('xl');

export const AtMostXS = layout.isAtMost('xs');
export const AtMostSM = layout.isAtMost('sm');
export const AtMostMD = layout.isAtMost('md');
export const AtMostLG = layout.isAtMost('lg');
export const AtMostXL = layout.isAtMost('xl');
