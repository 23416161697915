import { CategoryType } from 'src/models';

export enum ActionType {
    SET_SELECTED = 'CATEGORIES:SET_SELECTED',
    APPEND_SELECTED = 'CATEGORIES:APPEND_SELECTED',
    SET_COMPLETED = 'CATEGORIES:SET_COMPLETED',
    RESET_COMPLETED = 'CATEGORIES:RESET_COMPLETED',
}

export type SetCategoryAction = {
    type: ActionType.SET_SELECTED;
    payload: {
        type: CategoryType;
        selected: boolean;
    };
};

export type AppendCategoryAction = {
    type: ActionType.APPEND_SELECTED;
    payload: {
        type: CategoryType;
    };
};

export type SetCategoryCompletedAction = {
    type: ActionType.SET_COMPLETED;
    payload: {
        type: CategoryType;
    };
};

export type ResetCategoryCompletedAction = {
    type: ActionType.RESET_COMPLETED;
};

export type Actions =
    | SetCategoryAction
    | AppendCategoryAction
    | SetCategoryCompletedAction
    | ResetCategoryCompletedAction;
