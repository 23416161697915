import { CategoryType, TimeframeType } from 'src/models';
import { WaterRegion } from 'src/models/water';
import { WaterState } from 'src/store';
import { WaterListItemType } from '.';
import { Conversion } from './conversion';
import { configuration } from 'src/configuration';
import round from 'src/utils/round';

function getWaterAverage(region: WaterRegion | undefined, timeframe: TimeframeType): number {
    const {
        averages: { water },
    } = configuration;
    return round(water[region ?? WaterRegion.Australia] * Conversion.yearly[timeframe]);
}

function getWaterTitle(item: WaterListItemType): string {
    const region = item.region === WaterRegion.Australia ? 'Aust Avg' : item.region?.toUpperCase();
    return `Water (${region})`;
}

function getWaterSubtitle(item: WaterListItemType, timeframe: TimeframeType): string {
    return `${item.consumption.toLocaleString('en-AU', { maximumFractionDigits: 2 })} kL`;
}

function getWaterTotal(state: WaterState) {
    return state.emissions;
}

function getWaterList(state: WaterState): Array<WaterListItemType> {
    return [{ ...state, key: 'water', category: CategoryType.Water }];
}

export { getWaterList, getWaterTotal, getWaterTitle, getWaterSubtitle, getWaterAverage };
