import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Row.module.sass';

type RowProps = {
    className?: string;
    children: ReactNode;
    verticalAlignment?: "center" | "top";
    horizontalAlignment?: "center" | "left";
}

const Row: React.FC<RowProps> = (props: RowProps) => {
    const {
        className,
        children,
        verticalAlignment = "top",
        horizontalAlignment = "center",
    } = props;
    return (
        <div
            className={classNames(className, styles.root, {
                [styles.hCenter]: horizontalAlignment === "center",
                [styles.vCenter]: verticalAlignment === "center",
            })}>
            {children}
        </div>
    );
};

export { Row };