import getList from 'src/store/selectors/getList';
import { useSelector } from 'react-redux';
import { RootState, CalculationState } from 'src/store';
import { getInsights, Insight } from 'src/libs/insights';

const useKeyInsights = (): Array<Insight> => {
    const {
        categories: { selected },
        metadata: { timeframe },
    } = useSelector<RootState, CalculationState>(state => state.calculation);
    const emissions = useSelector(getList);
    return getInsights(selected, emissions, timeframe);
};

export { useKeyInsights };
