import React, { ReactNode } from 'react';
import {
    FuelType,
    TransportType,
    MeasurementType,
    CalculationType,
    VehicleEmissions,
    VehicleSize,
} from 'src/models/transport';
import { Form, FormBadge, FormControl } from 'src/components/shared/Forms';
import { Grid } from 'src/components/shared/Grid';
import { TextNew } from 'src/components/shared/TextNew';
import { Dropdown } from 'src/components/shared/Dropdown';
import { NumericInput } from 'src/components/shared/NumericInput';
import { ColorVariant } from 'src/styles/ColorVariant';
import { VehicleSubtype, VehicleSizes } from 'src/libs/transport';
import styles from './VehicleForm.module.sass';

const DefaultVehicleSizeExample = {
    [VehicleSize.Small]: null,
    [VehicleSize.SmallHybrid]: null,
    [VehicleSize.Medium]: null,
    [VehicleSize.MediumHybrid]: null,
    [VehicleSize.Large]: null,
    [VehicleSize.LargeHybrid]: null,
    [VehicleSize.ExtraLarge]: null,
    [VehicleSize.ExtraLargeHybrid]: null,
    [VehicleSize.PeopleMover]: null,
    [VehicleSize.PeopleMoverHybrid]: null,
};
const VehicleExamples = {
    [TransportType.Car]: {
        ...DefaultVehicleSizeExample,
        [VehicleSize.Small]: 'Toyota Corolla, Hyundai i30',
        [VehicleSize.SmallHybrid]: 'Toyota Corolla SX, Hyundai Ioniq',
        [VehicleSize.Medium]: 'Mazda 6, Toyota Camry',
        [VehicleSize.MediumHybrid]: 'Toyota Camry Ascent, Mercedes-Benz C300e',
        [VehicleSize.Large]: 'Holdon Commodore, Mercedes-Benz CLS',
        [VehicleSize.LargeHybrid]: 'Lexus ES300h Sport, Mercedes-Benz E Class',
    },
    [TransportType.SUV]: {
        ...DefaultVehicleSizeExample,
        [VehicleSize.Small]: 'Subaru XV, Hyundai Kona',
        [VehicleSize.SmallHybrid]: 'Subaru XV, Hyundai Kona',
        [VehicleSize.Medium]: 'Toyota RAV4, Mazda CX5',
        [VehicleSize.MediumHybrid]: 'Toyota RAV4, Mitsubishi Outlander PHEV',
        [VehicleSize.Large]: 'Toyota Hilux, Kia Sorrento',
        [VehicleSize.LargeHybrid]: 'BMW X5, Volvo XC90',
        [VehicleSize.ExtraLarge]: 'Nissan Patrol, Toyota Landcruiser',
        [VehicleSize.ExtraLargeHybrid]: 'Nissan Patrol, Toyota Landcruiser',
    },
    [TransportType.Van]: {
        ...DefaultVehicleSizeExample,
        [VehicleSize.PeopleMover]: 'Kia Carnival, Toyota Tarago',
        [VehicleSize.PeopleMoverHybrid]: 'Kia Carnival, Toyota Tarago',
    },
    [TransportType.Motorcycle]: {
        ...DefaultVehicleSizeExample,
    },
    [TransportType.PublicTransport]: {
        ...DefaultVehicleSizeExample,
    },
};

type VehicleUpdateHandler<T = any> = (value: T) => void;

type VehicleFormProps = {
    type: VehicleSubtype;
    title: string;
    tooltip?: string;
    icon: ReactNode;
    colorVariant: ColorVariant;
    vehicle: VehicleEmissions;
    disabled: boolean;
    weekly: boolean;
    onFuelUpdate: VehicleUpdateHandler<FuelType>;
    onMeasurementUpdate: VehicleUpdateHandler<MeasurementType>;
    onCalculationUpdate: VehicleUpdateHandler<CalculationType>;
    onSizeUpdate: VehicleUpdateHandler<VehicleSize>;
    onFuelEconomyUpdate: VehicleUpdateHandler<number>;
    onConsumptionUpdate: VehicleUpdateHandler<number>;
    onDistanceUpdate: VehicleUpdateHandler<number>;
    onFuelAverageClick?: () => void;
    onDistanceAverageClick?: () => void;
};

const ElectricVehicle = () => {
    return (
        <div className={styles.electricOverview}>
            <TextNew className={styles.heading} tagName={'div'} textStyle={'smallCaps'} textSize={'S'} textWeight={700}>
            About electric vehicles
            </TextNew>
            <TextNew tagName={'p'} textSize={'XS'} textWeight={400}>Electric vehicles are not powered by liquid fuels and as such do not directly produce carbon emissions. To account for the emissions from charging your electric vehicle, make sure you complete the electricity section of the calculator.</TextNew>
            <TextNew tagName={'p'} textSize={'XS'} textWeight={400}>If you charged your vehicle at home, the electricity consumption will be included in your total kWh consumption (found on your electricity bill). </TextNew>
            <TextNew tagName={'p'} textSize={'XS'} textWeight={400}>If you charged your vehicle outside the home, you will need to consider whether this was from a renewable source (e.g. a solar charging station) or non-renewable (e.g. at a friend's house who uses grid electricity). If you charged your car from a non-renewable energy source, you should include the kWh consumption in the electricity section of the calculator.</TextNew>
        </div>
    )
}

const VehicleForm = (props: VehicleFormProps) => {
    const {
        type,
        title,
        tooltip,
        icon,
        colorVariant,
        disabled,
        vehicle,
        weekly,
        onFuelUpdate,
        onMeasurementUpdate,
        onCalculationUpdate,
        onSizeUpdate,
        onFuelEconomyUpdate,
        onConsumptionUpdate,
        onDistanceUpdate,
        onFuelAverageClick,
        onDistanceAverageClick,
    } = props;
    return (
        <Form
            size="large"
            tooltip={tooltip}
            colorVariant={colorVariant}
            badge={
                <FormBadge className={styles.badge} colorVariant={colorVariant} title={title} icon={icon}>
                    <Dropdown
                        className={styles.fuel}
                        disabled={disabled}
                        options={[
                            { label: 'Petrol', value: FuelType.Petrol },
                            { label: 'Diesel', value: FuelType.Diesel },
                            { label: 'LPG', value: FuelType.LPG },
                            { label: 'Ethanol 10%', value: FuelType.E10 },
                            { label: 'Biodiesel', value: FuelType.Biodiesel },
                            { label: 'Electric', value: FuelType.Electric },
                        ]}
                        defaultValue={vehicle.fuel}
                        onValueChange={value => onFuelUpdate(value as FuelType)}
                    />
                </FormBadge>
            }>
            {vehicle.fuel === FuelType.Electric 
                ? <ElectricVehicle />
                : <Grid columns={{ xs: 1, sm: 2, md: 2 }}>
                    <FormControl
                        label="Calculate By"
                        tooltipTitle="What does this mean?"
                        tooltip="Calculating by fuel consumed will result in a more accurate output. Alternatively, you can calculate by distance travelled. This method estimates fuel consumption based on your fuel economy or vehicle size.">
                        <Dropdown
                            className={styles.input}
                            disabled={disabled}
                            options={[
                                { label: 'Fuel consumed', value: CalculationType.Fuel },
                                {
                                    label: 'Distance travelled',
                                    value: CalculationType.Distance,
                                },
                            ]}
                            defaultValue={vehicle.calculation}
                            onValueChange={value => onCalculationUpdate(value as CalculationType)}
                        />
                    </FormControl>
                    {vehicle.calculation === CalculationType.Fuel && (
                        <FormControl
                            label="Fuel Consumed"
                            footer={
                                onFuelAverageClick && (
                                    <TextNew
                                        className={styles.button}
                                        tagName={'button'}
                                        textSize={'S'}
                                        textWeight={700}
                                        textStyle={'smallCaps'}
                                        onClick={onFuelAverageClick}>
                                        Use Australian Average
                                    </TextNew>
                                )
                            }>
                            <NumericInput
                                className={styles.input}
                                disabled={disabled}
                                tabIndex={disabled ? -1 : undefined}
                                value={vehicle.consumption}
                                units={weekly ? 'L/week' : 'L'}
                                onValueChange={onConsumptionUpdate}
                            />
                        </FormControl>
                    )}
                    {vehicle.calculation === CalculationType.Distance && (
                        <FormControl
                            label="Distance"
                            footer={
                                onDistanceAverageClick && (
                                    <TextNew
                                        className={styles.button}
                                        tagName={'button'}
                                        textSize={'S'}
                                        textWeight={700}
                                        textStyle={'smallCaps'}
                                        onClick={onDistanceAverageClick}>
                                        Use Australian Average
                                    </TextNew>
                                )
                            }>
                            <NumericInput
                                className={styles.input}
                                disabled={disabled}
                                tabIndex={disabled ? -1 : undefined}
                                value={vehicle.distance}
                                units={weekly ? 'km/week' : 'km'}
                                onValueChange={onDistanceUpdate}
                            />
                        </FormControl>
                    )}
                    <FormControl
                        label="Measure By"
                        tooltipTitle="What does this mean?"
                        tooltip="Measuring by fuel economy will result in a more accurate output. Fuel economy is expressed in litres per 100 km (l/100km) and in newer vehicles is often displayed on the dashboard. Alternatively, you can measure by size, which applies an average fuel economy based on the size of your vehicle."
                        footer={<></>}>
                        <Dropdown
                            className={styles.input}
                            disabled={disabled}
                            options={[
                                {
                                    label: 'Fuel economy',
                                    value: MeasurementType.FuelEconomy,
                                },
                                {
                                    label: type === TransportType.Van ? 'Engine type' : 'Car size',
                                    value: MeasurementType.VehicleSize,
                                },
                            ]}
                            defaultValue={vehicle.measurement}
                            onValueChange={value => onMeasurementUpdate(value as MeasurementType)}
                        />
                    </FormControl>
                    {vehicle.measurement === MeasurementType.VehicleSize && (
                        <FormControl
                            label={type === TransportType.Van ? 'Engine type' : 'Car size'}
                            footer={
                                <TextNew
                                    className={styles.examplesLabel}
                                    textSize={'XS'}
                                    textWeight={400}
                                    textStyle={'smallCaps'}>
                                    <span className={styles.prefix}>e.g.</span> {VehicleExamples[type][vehicle.size]}
                                </TextNew>
                            }>
                            <Dropdown
                                className={styles.input}
                                disabled={disabled}
                                options={VehicleSizes[type]}
                                defaultValue={vehicle.size}
                                onValueChange={value => onSizeUpdate(value as VehicleSize)}
                            />
                        </FormControl>
                    )}
                    {vehicle.measurement === MeasurementType.FuelEconomy && (
                        <FormControl label="Fuel Economy">
                            <NumericInput
                                className={styles.input}
                                disabled={disabled}
                                tabIndex={disabled ? -1 : undefined}
                                value={vehicle.fuelEconomy}
                                units="L/100km"
                                onValueChange={onFuelEconomyUpdate}
                            />
                        </FormControl>
                    )}
                </Grid>
            }
        </Form>
    );
};

export { VehicleForm };
