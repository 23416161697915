import { store } from 'src/store';
import { serializeGroceriesState } from 'src/store/serializers/groceriesSerializers';

const flushGroceriesState = () => {
    const {
        calculation: { groceries },
    } = store.getState();
    try {
        localStorage.setItem('cpos:groceries', serializeGroceriesState(groceries));
    } catch (err) {
        // TODO: update state with error
    }
};

export { flushGroceriesState };
