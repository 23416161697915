import React, { ReactNode, useMemo } from 'react';
import { ActionType } from 'src/store/actions/navigation';
import { NavigationContext, NavigationContextType } from 'src/components/shared/Navigation/Navigation';
import { useNavigation, useNavigationState } from '.';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { CSSTransition } from 'react-transition-group';
import styles from './NavigationRoot.module.sass';

type Action = () => void;

type NavigationRootProps = {
    navigationKey: NavigationKey;
    onNavigate?: Action;
    onBack?: Action;
    onNext?: Action;
    children: Array<ReactNode> | ReactNode;
};

function getVisibleChildren(children: Array<ReactNode> | ReactNode): Array<ReactNode> {
    if (Array.isArray(children)) {
        return children.filter(child => !!child);
    }
    return !!children ? [children] : [];
}

// const NavigationRoot = (props: NavigationRootProps) => {
//     const { navigationKey, onNavigate, onBack, onNext, children } = props;
//     const { index, dispatch } = useNavigationState(navigationKey);
//     const navigation = useNavigation();
//     const value = useMemo<NavigationContextType>(() => {
//         return {
//             back: () => {
//                 if (index === 0) {
//                     onBack && onBack();
//                     onNavigate && onNavigate();
//                     navigation.back();
//                     return;
//                 }
//                 dispatch({
//                     type: ActionType.NAVIGATE_TO,
//                     payload: {
//                         key: navigationKey,
//                         index: index - 1,
//                     },
//                 });
//                 onBack && onBack();
//                 onNavigate && onNavigate();
//             },
//             next: () => {
//                 const length = getVisibleChildren(children).length;
//                 if (index === length - 1) {
//                     onNext && onNext();
//                     onNavigate && onNavigate();
//                     navigation.next();
//                     return;
//                 }
//                 dispatch({
//                     type: ActionType.NAVIGATE_TO,
//                     payload: {
//                         key: navigationKey,
//                         index: index + 1,
//                     },
//                 });
//                 onNext && onNext();
//                 onNavigate && onNavigate();
//             },
//         };
//     }, [children, index]);
//     const components = getVisibleChildren(children);
//     return (
//         <NavigationContext.Provider value={value}>
//             {index < components.length && components[index]}
//         </NavigationContext.Provider>
//         // <TransitionGroup component={null}>
//         //     <CSSTransition
//         //         key={index}
//         //         classNames={{
//         //             ...styles,
//         //         }}
//         //         timeout={2500}>
//         //         <NavigationContext.Provider value={value}>
//         //             <div className={styles.container}>
//         //                 {index}
//         //                 <button onClick={value.back}>Back</button>
//         //                 <button onClick={value.next}>Next</button>
//         //             </div>
//         //         </NavigationContext.Provider>
//         //     </CSSTransition>
//         // </TransitionGroup>
//     );
// };

const NavigationRoot = (props: NavigationRootProps) => {
    const { navigationKey, onNavigate, onBack, onNext, children } = props;
    const { index, dispatch } = useNavigationState(navigationKey);
    const navigation = useNavigation();
    const value = useMemo<NavigationContextType>(() => {
        return {
            back: () => {
                if (index === 0) {
                    onBack && onBack();
                    onNavigate && onNavigate();
                    navigation.back();
                    return;
                }
                dispatch({
                    type: ActionType.NAVIGATE_TO,
                    payload: {
                        key: navigationKey,
                        index: index - 1,
                    },
                });
                onBack && onBack();
                onNavigate && onNavigate();
            },
            next: () => {
                const length = getVisibleChildren(children).length;
                if (index === length - 1) {
                    onNext && onNext();
                    onNavigate && onNavigate();
                    navigation.next();
                    return;
                }
                dispatch({
                    type: ActionType.NAVIGATE_TO,
                    payload: {
                        key: navigationKey,
                        index: index + 1,
                    },
                });
                onNext && onNext();
                onNavigate && onNavigate();
            },
        };
    }, [children, index]);
    const components = getVisibleChildren(children);
    return (
        // <NavigationContext.Provider value={value}>
        //     <div className={styles.root}>
        //         {components.map((component, i) => (
        //             <Transition key={i} appear={false} in={i === index} timeout={200} unmountOnExit={true}>
        //                 {(state: string) => (
        //                     <div className={classNames(styles.container, styles[state])}>{component}</div>
        //                 )}
        //             </Transition>
        //         ))}
        //     </div>
        // </NavigationContext.Provider>
        // <NavigationContext.Provider value={value}>
        //     {components.map((component, i) => (
        //         <Transition key={i} appear={false} in={i === index} timeout={200} unmountOnExit={true}>
        //             {(state: string) => <div className={classNames(styles.container, styles[state])}>{component}</div>}
        //         </Transition>
        //     ))}
        // </NavigationContext.Provider>

        // <NavigationContext.Provider value={value}>
        //     {components.map((component, i) => (
        //         <CSSTransition
        //             key={i}
        //             appear={false}
        //             in={i === index}
        //             timeout={{
        //                 enter: 3250,
        //                 exit: 3250,
        //             }}
        //             mountOnEnter={true}
        //             unmountOnExit={true}
        //             classNames={{
        //                 enter: styles.enter,
        //                 enterActive: styles.enterActive,
        //                 exit: styles.exit,
        //                 exitActive: styles.exitActive,
        //             }}>
        //             {component}
        //         </CSSTransition>
        //     ))}
        // </NavigationContext.Provider>

        <NavigationContext.Provider value={value}>
            {index < components.length && components[index]}
        </NavigationContext.Provider>
    );
};

export { NavigationRoot };
