import React from 'react';
import { NavigationRoot } from 'src/components/shared/Navigation';
import { Waste } from './Waste';
import { flushWasteState } from 'src/store/storage/flushWasteState';
import { NavigationKey } from 'src/store/reducers/navigationReducer';

const WasteRoot: React.FC = () => {
    return (
        <NavigationRoot navigationKey={NavigationKey.Waste} onNavigate={flushWasteState}>
            <Waste />
        </NavigationRoot>
    );
};

export { WasteRoot };
