import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { NavigationRoot, useNavigationState } from 'src/components/shared/Navigation';
import { TransportRoot } from 'src/components/pages/transport/TransportRoot';
import { TravelRoot } from 'src/components/pages/travel/TravelRoot';
import { WaterRoot } from 'src/components/pages/water/WaterRoot';
import { EnergyRoot } from 'src/components/pages/energy/EnergyRoot';
import { WasteRoot } from 'src/components/pages/waste/WasteRoot';
import { GroceriesRoot } from './groceries/GroceriesRoot';
import { CategoryType } from 'src/models';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { ActionType } from 'src/store/actions/categories';
import { flushCategoriesState } from 'src/store/storage/flushCategoriesState';

const CategoryRoot: React.FC = () => {
    const dispatch = useDispatch();
    const selected = useSelector<RootState, Array<CategoryType>>(state => state.calculation.categories.selected);
    const children = useMemo(() => {
        return selected.map(category => {
            switch (category) {
                case CategoryType.Transport:
                    return <TransportRoot key={CategoryType.Transport} />;
                case CategoryType.Energy:
                    return <EnergyRoot key={CategoryType.Energy} />;
                case CategoryType.Water:
                    return <WaterRoot key={CategoryType.Water} />;
                case CategoryType.Travel:
                    return <TravelRoot key={CategoryType.Travel} />;
                case CategoryType.Groceries:
                    return <GroceriesRoot key={CategoryType.Groceries} />;
                case CategoryType.Waste:
                    return <WasteRoot key={CategoryType.Waste} />;
            }
        });
    }, [selected]);
    const { index } = useNavigationState(NavigationKey.Categories);
    return (
        <NavigationRoot
            navigationKey={NavigationKey.Categories}
            onNext={() => {
                dispatch({
                    type: ActionType.SET_COMPLETED,
                    payload: {
                        type: selected[index],
                    },
                });
                flushCategoriesState();
            }}>
            {children}
        </NavigationRoot>
    );
};

export { CategoryRoot };
