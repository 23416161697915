import { combineReducers } from 'redux';
import { uiReducer, UIState } from './uiReducer';
import { calculationReducer, CalculationState } from './calculationReducer';
import { Actions, ActionType } from '../actions/root';

export type RootState = {
    ui: UIState;
    calculation: CalculationState;
};

const combinedReducer = combineReducers<RootState, any>({
    ui: uiReducer,
    calculation: calculationReducer,
});

const rootReducer = (state: RootState | undefined, action: Actions): RootState => {
    switch (action.type) {
        case ActionType.NEW:
            return combinedReducer(undefined, action);
        case ActionType.LOAD:
            return {
                ui: action.payload.ui,
                calculation: action.payload.calculation,
            };
    }
    return combinedReducer(state, action);
};

export { rootReducer };
