import React from 'react';
import classNames from 'classnames';
import { Calculation } from 'src/api';
import { UIElementProps } from 'src/components';
import { TextNew } from 'src/components/shared/TextNew';
import { TimeframeType } from 'src/models';
import NumberFormat from 'react-number-format';
import styles from './List.module.sass';
import round from 'src/utils/round';
import { useDispatch } from 'react-redux';
import { useNavigationState } from 'src/components/shared/Navigation';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { loadCalculation } from 'src/store/actions/root/loadCalculation';

function getTimeframe(timeframe: TimeframeType): string {
    switch (timeframe) {
        case TimeframeType.Month:
            return '1 month';
        case TimeframeType.Quarter:
            return '3 months';
        case TimeframeType.Year:
            return '1 year';
    }
}

function getPerMonthImpact(total: number, timeframe: TimeframeType): number {
    switch (timeframe) {
        case TimeframeType.Month:
            return total;
        case TimeframeType.Quarter:
            return total / 4;
        case TimeframeType.Year:
            return total / 12;
    }
}

function formatDate(date: Date) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
}

type ListProps = UIElementProps & {
    calculations: Calculation[];
    selected?: Calculation;
    onChange: (calculation: Calculation) => void;
};

const List = (props: ListProps) => {
    const dispatch = useDispatch();
    const { navigate } = useNavigationState(NavigationKey.Root);
    const { className, calculations, selected, onChange } = props;
    return (
        <div className={classNames(className, styles.root)}>
            <div className={styles.header}>
                <TextNew className={styles.title} tagName={'h2'} textStyle={'default'} textSize={'L'} textWeight={700}>
                    Calculation History
                </TextNew>
            </div>
            <table className={styles.table} cellSpacing={0} cellPadding={0}>
                <thead>
                    <tr>
                        <TextNew tagName={'th'} textStyle={'default'} textSize={'S'} textWeight={700}>
                            Date
                        </TextNew>
                        <TextNew tagName={'th'} textStyle={'default'} textSize={'S'} textWeight={700}>
                            Timeframe
                        </TextNew>
                        <TextNew tagName={'th'} textStyle={'default'} textSize={'S'} textWeight={700}>
                            Status
                        </TextNew>
                        <TextNew tagName={'th'} textStyle={'default'} textSize={'S'} textWeight={700}>
                            Description
                        </TextNew>
                        <TextNew tagName={'th'} textStyle={'default'} textSize={'S'} textWeight={700}>
                            tCO<sub>2</sub>e (Total)
                        </TextNew>
                        <TextNew tagName={'th'} textStyle={'default'} textSize={'S'} textWeight={700}>
                            tCO<sub>2</sub>e (Monthly Avg.)
                        </TextNew>
                    </tr>
                </thead>
                <tbody>
                    {calculations.map(calculation => {
                        return (
                            <tr
                                key={calculation.id}
                                className={classNames({ [styles.selected]: calculation.id === selected?.id })}
                                onClick={() => onChange(calculation)}>
                                <td>
                                    <TextNew
                                        tagName={'button'}
                                        textSize={'S'}
                                        textWeight={300}
                                        onClick={() => {
                                            dispatch(loadCalculation({ calculation }));
                                            navigate(0);
                                        }}>
                                        {formatDate(calculation.started_at)}
                                    </TextNew>
                                </td>
                                <TextNew tagName={'td'} textSize={'S'} textWeight={300}>
                                    {getTimeframe(calculation.timeframe)}
                                </TextNew>
                                <TextNew tagName={'td'} textSize={'S'} textWeight={300}>
                                    {calculation.complete ? 'Complete' : 'Saved'}
                                </TextNew>
                                <TextNew tagName={'td'} textSize={'S'} textWeight={300}>
                                    {calculation.label}
                                </TextNew>
                                <TextNew tagName={'td'} textSize={'S'} textWeight={300}>
                                    <NumberFormat
                                        value={round(calculation.emission_total / 1000)}
                                        displayType={'text'}
                                        decimalScale={3}
                                        thousandSeparator={true}
                                    />
                                </TextNew>
                                <TextNew tagName={'td'} textSize={'S'} textWeight={300}>
                                    <NumberFormat
                                        value={round(
                                            getPerMonthImpact(calculation.emission_total, calculation.timeframe) / 1000,
                                        )}
                                        displayType={'text'}
                                        decimalScale={3}
                                        thousandSeparator={true}
                                    />
                                </TextNew>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export { List };
