import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Form, FormControl } from 'src/components/shared/Forms';
import { NumericInput, UnitsAlignment } from 'src/components/shared/NumericInput';
import { ColorVariant } from 'src/styles/ColorVariant';
import styles from './NumericInputForm.module.sass';

type NumericInputFormProps = {
    className?: string;
    colorVariant?: ColorVariant;
    title: string | React.ReactNode;
    tooltip?: string;
    icon: ReactNode;
    footer?: ReactNode;
    minValue?: number;
    maxValue?: number;
    decimalScale?: number;
    value: number;
    disabled?: boolean;
    units?: string;
    unitsAlignment?: UnitsAlignment;
    onValueChange: (value: number) => void;
};

const NumericInputForm: React.FC<NumericInputFormProps> = (props: NumericInputFormProps) => {
    const {
        className,
        colorVariant = undefined,
        title,
        tooltip,
        icon,
        footer,
        minValue,
        maxValue,
        decimalScale,
        value,
        disabled,
        units,
        unitsAlignment,
        onValueChange,
    } = props;
    return (
        <Form className={classNames(className, styles.root)} colorVariant={colorVariant} tooltip={tooltip}>
            <span className={styles.icon}>{icon}</span>
            <FormControl label={title} labelTextVariant={'label'} footer={footer}>
                <NumericInput
                    minValue={minValue}
                    maxValue={maxValue}
                    decimalScale={decimalScale}
                    value={value}
                    units={units}
                    unitsAlignment={unitsAlignment}
                    disabled={disabled}
                    onValueChange={v => onValueChange(v)}
                />
            </FormControl>
        </Form>
    );
};

export { NumericInputForm };
