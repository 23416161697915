import { MetadataState } from 'src/store';
import stringify from './stringify';

const deserializeMetadataState = (json: string): MetadataState => {
    return JSON.parse(json);
};

const serializeMetadataState = (state: MetadataState): string => {
    return stringify(state);
};

export { serializeMetadataState, deserializeMetadataState };
