import React from 'react';
import { CalculationType, MotorcycleEmissions } from 'src/models/transport';
import { ReactComponent as Badge } from '../transport/Motorcycle.svg';
import { Form, FormBadge, FormControl } from 'src/components/shared/Forms';
import { Grid } from 'src/components/shared/Grid';
import { Dropdown } from 'src/components/shared/Dropdown';
import { NumericInput } from 'src/components/shared/NumericInput';
import { ColorVariant } from 'src/styles/ColorVariant';
import { TextNew } from 'src/components/shared/TextNew';

import styles from './MotorcycleForm.module.sass';

type MotorcycleUpdateHandler<T = any> = (value: T) => void;

type MotorcycleFormProps = {
    title: string;
    tooltip?: string;
    colorVariant: ColorVariant;
    motorcycle: MotorcycleEmissions;
    disabled: boolean;
    weekly: boolean;
    onCalculationUpdate: MotorcycleUpdateHandler<CalculationType>;
    onConsumptionUpdate: MotorcycleUpdateHandler<number>;
    onDistanceUpdate: MotorcycleUpdateHandler<number>;
    onFuelAverageClick?: () => void;
    onDistanceAverageClick?: () => void;
};

const MotorcycleForm = (props: MotorcycleFormProps) => {
    const {
        title,
        tooltip,
        colorVariant,
        motorcycle,
        disabled,
        weekly,
        onCalculationUpdate,
        onConsumptionUpdate,
        onDistanceUpdate,
        onFuelAverageClick,
        onDistanceAverageClick,
    } = props;
    return (
        <Form
            size="large"
            tooltip={tooltip}
            colorVariant={colorVariant}
            badge={<FormBadge className={styles.badge} colorVariant={colorVariant} title={title} icon={<Badge />} />}>
            <Grid columns={{ xs: 1, sm: 2, md: 2 }}>
                <FormControl
                    label="Calculate By"
                    tooltipTitle="How does this work?"
                    tooltip="Calculating by fuel consumed will result in a more accurate output. Alternatively, you can calculate by distance travelled. This method estimates fuel consumption based on average fuel economy.">
                    <Dropdown
                        className={styles.input}
                        disabled={disabled}
                        options={[
                            { label: 'Fuel Consumed', value: CalculationType.Fuel },
                            {
                                label: 'Distance Travelled',
                                value: CalculationType.Distance,
                            },
                        ]}
                        defaultValue={motorcycle.calculation}
                        onValueChange={value => onCalculationUpdate(value as CalculationType)}
                    />
                </FormControl>
                {motorcycle.calculation === CalculationType.Fuel && (
                    <FormControl
                        label="Fuel Consumed"
                        footer={
                            onFuelAverageClick && (
                                <TextNew
                                    className={styles.button}
                                    tagName={'button'}
                                    textSize={'S'}
                                    textWeight={700}
                                    textStyle={'smallCaps'}
                                    onClick={onFuelAverageClick}>
                                    Use Australian Average
                                </TextNew>
                            )
                        }>
                        <NumericInput
                            className={styles.input}
                            disabled={disabled}
                            value={motorcycle.consumption}
                            units={weekly ? 'L/week' : 'L'}
                            onValueChange={onConsumptionUpdate}
                        />
                    </FormControl>
                )}
                {motorcycle.calculation === CalculationType.Distance && (
                    <FormControl
                        label="Distance"
                        footer={
                            onDistanceAverageClick && (
                                <TextNew
                                    className={styles.button}
                                    tagName={'button'}
                                    textSize={'S'}
                                    textWeight={700}
                                    textStyle={'smallCaps'}
                                    onClick={onDistanceAverageClick}>
                                    Use Australian Average
                                </TextNew>
                            )
                        }>
                        <NumericInput
                            className={styles.input}
                            disabled={disabled}
                            value={motorcycle.distance}
                            units={weekly ? 'km/week' : 'km'}
                            onValueChange={onDistanceUpdate}
                        />
                    </FormControl>
                )}
            </Grid>
        </Form>
    );
};

export { MotorcycleForm };
