import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import getEnergyTotal from 'src/store/selectors/getEnergyTotal';
import getEnergyList from 'src/store/selectors/getEnergyList';
import { getEnergyTitle, getEnergySubtitle } from 'src/libs/energy';
import getTransportTotal from 'src/store/selectors/getTransportTotal';
import getTransportList from 'src/store/selectors/getTransportList';
import { getTransportTitle, getTransportSubtitle } from 'src/libs/transport';
import getWaterTotal from 'src/store/selectors/getWaterTotal';
import getWaterList from 'src/store/selectors/getWaterList';
import getTravelTotal from 'src/store/selectors/getTravelTotal';
import getTravelList from 'src/store/selectors/getTravelList';
import { getTravelTitle, getTravelSubtitle } from 'src/libs/travel';
import getGroceriesTotal from 'src/store/selectors/getGroceriesTotal';
import getGroceriesList from 'src/store/selectors/getGroceriesList';
import { getGroceriesTitle, getGroceriesSubtitle } from 'src/libs/groceries';
import getWasteTotal from 'src/store/selectors/getWasteTotal';
import getWasteList from 'src/store/selectors/getWasteList';
import { getWaterTitle, getWaterSubtitle } from 'src/libs/water';
import { CategoryType, TimeframeType } from 'src/models';
import { RootState } from 'src/store';
import { ListItem } from './ListItem';
import { Category } from './Category';
import { getWasteTitle } from 'src/libs/waste';
import { ListHeader } from './ListHeader';
import styles from './List.module.sass';
import { TransportListItemType } from 'src/libs';
import { TransportType, FuelType } from 'src/models/transport';

const TravelList = (props: { showCategoryLegend: boolean; highlight: Array<string> }) => {
    const { showCategoryLegend, highlight } = props;
    const total = useSelector(getTravelTotal);
    const items = useSelector(getTravelList);
    if (items.length === 0 || total === 0) {
        return null;
    }
    return (
        <Category title="Travel" total={total} showCategoryLegend={showCategoryLegend} categoryIndex={1}>
            {items
                .filter(item => item.emissions !== 0)
                .map(item => (
                    <ListItem
                        key={item.key}
                        title={getTravelTitle(item)}
                        subtitle={getTravelSubtitle(item)}
                        total={item.emissions}
                        insight={highlight.indexOf(item.key)}
                    />
                ))}
        </Category>
    );
};

const EnergyList = (props: { showCategoryLegend: boolean; highlight: Array<string> }) => {
    const { showCategoryLegend, highlight } = props;
    const total = useSelector(getEnergyTotal);
    const items = useSelector(getEnergyList);
    if (items.length === 0 || total === 0) {
        return null;
    }
    return (
        <Category title="Energy" total={total} showCategoryLegend={showCategoryLegend} categoryIndex={2}>
            {items
                .filter(item => item.emissions !== 0)
                .map(item => (
                    <ListItem
                        key={item.key}
                        title={getEnergyTitle(item)}
                        subtitle={getEnergySubtitle(item)}
                        total={item.emissions}
                        insight={highlight.indexOf(item.key)}
                    />
                ))}
        </Category>
    );
};

const WaterList = (props: { showCategoryLegend: boolean; highlight: Array<string> }) => {
    const { showCategoryLegend, highlight } = props;
    const timeframe = useSelector<RootState, TimeframeType>(state => state.calculation.metadata.timeframe);
    const total = useSelector(getWaterTotal);
    const items = useSelector(getWaterList);
    if (items.length === 0 || total === 0) {
        return null;
    }
    return (
        <Category title="Water" total={total} showCategoryLegend={showCategoryLegend} categoryIndex={3}>
            <ListItem
                key={items[0].key}
                title={getWaterTitle(items[0])}
                subtitle={getWaterSubtitle(items[0], timeframe)}
                total={items[0].emissions}
                insight={highlight.indexOf(items[0].key)}
            />
        </Category>
    );
};

const TransportList = (props: { showCategoryLegend: boolean; highlight: Array<string> }) => {
    const { showCategoryLegend, highlight } = props;
    const total = useSelector(getTransportTotal);
    const items = useSelector(getTransportList);

    if (items.length === 0 || total === 0) {
        return null;
    }

    const transportFilter = (item: TransportListItemType) => {
        if (item.type === TransportType.Car && item.fuel === FuelType.Electric) {
            return item.fuel === FuelType.Electric || item.emissions !== 0;
        }
        return item.emissions !== 0;
    };

    return (
        <Category title="Transport" total={total} showCategoryLegend={showCategoryLegend} categoryIndex={4}>
            {items.filter(transportFilter).map(item => (
                <ListItem
                    key={item.key}
                    title={getTransportTitle(item)}
                    subtitle={getTransportSubtitle(item)}
                    total={item.emissions}
                    insight={highlight.indexOf(item.key)}
                />
            ))}
        </Category>
    );
};

const GroceriesList = (props: { showCategoryLegend: boolean; highlight: Array<string> }) => {
    const { showCategoryLegend, highlight } = props;
    const total = useSelector(getGroceriesTotal);
    const items = useSelector(getGroceriesList);
    if (items.length === 0 || total === 0) {
        return null;
    }
    return (
        <Category title="Food &amp; Drink" total={total} showCategoryLegend={showCategoryLegend} categoryIndex={5}>
            {items
                .filter(item => item.emissions !== 0)
                .map(item => (
                    <ListItem
                        key={item.key}
                        title={getGroceriesTitle(item)}
                        subtitle={getGroceriesSubtitle(item)}
                        total={item.emissions}
                        insight={highlight.indexOf(item.key)}
                    />
                ))}
        </Category>
    );
};

const WasteList = (props: { showCategoryLegend: boolean; highlight: Array<string> }) => {
    const { showCategoryLegend, highlight } = props;
    const total = useSelector(getWasteTotal);
    const items = useSelector(getWasteList);
    if (items.length === 0 || total === 0) {
        return null;
    }
    return (
        <Category title="Waste" total={total} showCategoryLegend={showCategoryLegend} categoryIndex={6}>
            <ListItem
                key={items[0].key}
                title={getWasteTitle(items[0])}
                total={items[0].emissions}
                insight={highlight.indexOf(items[0].key)}
            />
        </Category>
    );
};

type ListProps = {
    className: string;
    showCategoryLegend: boolean;
    highlight?: Array<string>;
};

const List = (props: ListProps) => {
    const { className, showCategoryLegend, highlight = [] } = props;
    const selected = useSelector<RootState, Array<CategoryType>>(state => state.calculation.categories.selected);
    return (
        <div className={classNames(className, styles.root)}>
            <ListHeader />
            <dl>
                {selected.includes(CategoryType.Travel) && (
                    <TravelList showCategoryLegend={showCategoryLegend} highlight={highlight} />
                )}
                {selected.includes(CategoryType.Energy) && (
                    <EnergyList showCategoryLegend={showCategoryLegend} highlight={highlight} />
                )}
                {selected.includes(CategoryType.Water) && (
                    <WaterList showCategoryLegend={showCategoryLegend} highlight={highlight} />
                )}
                {selected.includes(CategoryType.Transport) && (
                    <TransportList showCategoryLegend={showCategoryLegend} highlight={highlight} />
                )}
                {selected.includes(CategoryType.Groceries) && (
                    <GroceriesList showCategoryLegend={showCategoryLegend} highlight={highlight} />
                )}
                {selected.includes(CategoryType.Waste) && (
                    <WasteList showCategoryLegend={showCategoryLegend} highlight={highlight} />
                )}
            </dl>
        </div>
    );
};

export { List };
