import { AnyAction } from '@reduxjs/toolkit';
import { TimeframeType } from 'src/models';
import { ActionType } from 'src/store/actions/metadata';
import { ActionType as RootActionType } from 'src/store/actions/root';
import strip from 'src/utils/strip';

export type MetadataState = {
    id?: string;
    label?: string;
    timeframe: TimeframeType | null;
    startedAt: Date;
    savedAt: Date | undefined;
    unitPrice?: number;
};

const initialState = {
    id: undefined,
    timeframe: null,
    startedAt: new Date(),
    savedAt: undefined,
};

const metadataReducer = (state: MetadataState = initialState, action: AnyAction) => {
    switch (action.type) {
        case RootActionType.NEW:
            return {
                ...state,
                id: null,
                label: null,
                timeframe: null,
                startedAt: new Date(),
            };
        case ActionType.UPDATE:
            return {
                ...state,
                ...strip(action.payload),
            };
    }
    return state;
};

export { metadataReducer };
