import React from 'react';
import { ButtonIcon } from 'src/components/shared/ButtonIcon';
import { Container } from 'src/components/shared/Container';
import { useNavigationState } from 'src/components/shared/Navigation';
import { Scrollable } from 'src/components/shared/Scrollable';
import { TextNew } from 'src/components/shared/TextNew';
import { ReactComponent as Close } from 'src/components/svg/Close.svg';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { NavigationPage } from '../NavigationPage';
import { Category } from './Category';
import { SubCategory } from './SubCategory';
import styles from './index.module.sass';

const Information = () => {
    const { navigate } = useNavigationState(NavigationKey.Root);
    return (
        <Container className={styles.root} padding="none">
            <ButtonIcon className={styles.closeButton} label="Close" icon={<Close />} onClick={() => navigate(0)} />
            <Scrollable className={styles.scrollContainer}>
                <TextNew className={styles.title} tagName={'h1'} textSize={'XXL'} textWeight={700}>
                    How did we calculate this?
                </TextNew>
                <div className={styles.links}>
                    <TextNew className={styles.linkLabel} textSize={'S'} textWeight={300}>
                        Take me to
                    </TextNew>
                    <div className={styles.tags}>
                        <a className={styles.tag} href="#how-did-we-calculate-transport">
                            <TextNew textSize={'S'} textWeight={700}>
                                Transport
                            </TextNew>
                        </a>
                        <a className={styles.tag} href="#how-did-we-calculate-energy">
                            <TextNew textSize={'S'} textWeight={700}>
                                Energy
                            </TextNew>
                        </a>
                        <a className={styles.tag} href="#how-did-we-calculate-water">
                            <TextNew textSize={'S'} textWeight={700}>
                                Water
                            </TextNew>
                        </a>
                        <a className={styles.tag} href="#how-did-we-calculate-travel">
                            <TextNew textSize={'S'} textWeight={700}>
                                Travel
                            </TextNew>
                        </a>
                        <a className={styles.tag} href="#how-did-we-calculate-groceries">
                            <TextNew textSize={'S'} textWeight={700}>
                                Food &amp; Drink
                            </TextNew>
                        </a>
                        <a className={styles.tag} href="#how-did-we-calculate-waste">
                            <TextNew textSize={'S'} textWeight={700}>
                                Waste
                            </TextNew>
                        </a>
                    </div>
                </div>
                <div className={styles.content}>
                    <Category id="how-did-we-calculate-transport" title="Transport">
                        <SubCategory title="Vehicles">
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                Calculations are based on emissions factors from the{' '}
                                <a
                                    href="https://www.dcceew.gov.au/climate-change/publications/national-greenhouse-accounts-factors-2024"
                                    target="_blank"
                                    rel="noreferrer">
                                    2024 Australian National Greenhouse Accounts Factors
                                </a>
                                . Average distances have been sourced from the{' '}
                                <a
                                    href="https://www.abs.gov.au/statistics/industry/tourism-and-transport/survey-motor-vehicle-use-australia/latest-release"
                                    target="_blank"
                                    rel="noreferrer">
                                    Australian Bureau of Statistics Survey of Motor Vehicle Use 2020
                                </a>
                                . Average fuel economy has been calculated using data from the Australian Government's{' '}
                                <a href="https://www.greenvehicleguide.gov.au/" target="_blank" rel="noreferrer">
                                    Green Vehicle Guide
                                </a>
                                .
                            </TextNew>
                        </SubCategory>
                        <SubCategory title="Public Transport">
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                Calculations for taxi/rideshare travel are based on emissions factors from the{' '}
                                <a
                                    href="https://www.dcceew.gov.au/climate-change/publications/national-greenhouse-accounts-factors-2024"
                                    target="_blank"
                                    rel="noreferrer">
                                    2024 Australian National Greenhouse Accounts Factors
                                </a>{' '}
                                and data from the{' '}
                                <a
                                    href="https://www.abs.gov.au/statistics/industry/tourism-and-transport/survey-motor-vehicle-use-australia/latest-release"
                                    target="_blank"
                                    rel="noreferrer">
                                    Australian Bureau of Statistics Survey of Motor Vehicle Use 2020
                                </a>{' '}
                                and the{' '}
                                <a href="https://www.greenvehicleguide.gov.au/" target="_blank" rel="noreferrer">
                                    Australian Government's Green Vehicle Guide
                                </a>
                                .
                            </TextNew>
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                Calculations for bus, tram, train and ferry calculations are based on the{' '}
                                <a
                                    href="https://www.dcceew.gov.au/climate-change/publications/national-greenhouse-gas-inventory-quarterly-update-june-2022"
                                    target="_blank"
                                    rel="noreferrer">
                                    Australian Government's National Greenhouse Gas Inventory 2022
                                </a>
                                , the{' '}
                                <a
                                    href="https://s3.ap-southeast-2.amazonaws.com/hdp.au.prod.app.com-participate.files/6615/2948/1938/Transport_Strategy_Refresh__Zero_Net_Emissions_Strategy_-_Greenhouse_Gas_Emissions_and_Air_Quality.pdf"
                                    target="_blank"
                                    rel="noreferrer">
                                    City of Melbourne's Transport Strategy Refresh
                                </a>
                                , and the{' '}
                                <a
                                    href="https://www.gov.uk/government/publications/greenhouse-gas-reporting-conversion-factors-2024"
                                    target="_blank"
                                    rel="noreferrer">
                                    UK Government's GHG Conversion Factors for Company Reporting 2024
                                </a>
                                .
                            </TextNew>
                        </SubCategory>
                    </Category>
                    <Category id="how-did-we-calculate-energy" title="Energy">
                        <SubCategory>
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                Calculations are based on emissions factors from the{' '}
                                <a
                                    href="https://www.dcceew.gov.au/climate-change/publications/national-greenhouse-accounts-factors-2024"
                                    target="_blank"
                                    rel="noreferrer">
                                    2024 Australian National Greenhouse Accounts Factors
                                </a>
                                . Averages consumption values have been calculated based on data from the{' '}
                                <a
                                    href="https://www.abs.gov.au/statistics/people/population/household-and-family-projections-australia/2021-2046"
                                    target="_blank"
                                    rel="noreferrer">
                                    Australian Bureau of Statistics Household and Family Projections 2021 to 2046
                                </a>{' '}
                                and the{' '}
                                <a
                                    href="https://www.energy.gov.au/publications/australian-energy-update-2024"
                                    target="_blank"
                                    rel="noreferrer">
                                    Department of the Environment and Energy's Australian Energy Update 2024
                                </a>
                                .
                            </TextNew>
                        </SubCategory>
                        <SubCategory title="Solar panels">
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                Solar panels can significantly reduce the amount of electricity a household imports from
                                the grid. However, due to the inherent timing mismatch between peak electricity{' '}
                                <strong>consumption</strong> (mornings and evenings) and peak solar electricity{' '}
                                <strong>generation</strong> (daylight hours), most households still require some amount
                                of grid electricity to supplement their energy needs.
                            </TextNew>
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                The electricity that a household imports from the grid (regardless of whether it is
                                solar energy or not) has an associated emissions intensity. Emissions can be calculated
                                by applying the grid emissions factor to the imported kWh value.
                            </TextNew>
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                The energy a household consumes behind the meter (i.e. from home solar generation)
                                cannot be calculated from the electricity bill alone, however is reflected in the
                                reduced kWh import from the grid.
                            </TextNew>
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                In many cases, some of the energy produced by a household's solar system is exported
                                back to the grid. This low carbon energy has already been accounted for with the forfeit
                                of your STCs, registered with the Clean Energy Regulator's REC Registry, at the point of
                                purchase of your solar system. As such , this exported energy should not be applied as a
                                "credit" to your household's total carbon footprint as it would be a case of double
                                counting.
                            </TextNew>
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                In the case that you <strong>do not</strong> forfeit your STCs to your solar provider,
                                please contact us and we can assist you to calculate the emissions from your electricity
                                use.
                            </TextNew>
                        </SubCategory>
                    </Category>
                    <Category id="how-did-we-calculate-water" title="Water">
                        <SubCategory>
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                Calculations are based on emissions factors from the{' '}
                                <a
                                    href="https://www.dcceew.gov.au/climate-change/publications/national-greenhouse-accounts-factors-2024"
                                    target="_blank"
                                    rel="noreferrer">
                                    2024 Australian National Greenhouse Accounts Factors
                                </a>
                                . Average water consumption has been calculated based on data from the{' '}
                                <a href="http://www.bom.gov.au/water/npr/" target="_blank" rel="noreferrer">
                                    Bureau of Meteorology's National Performance Report 2022-23: Urban Water Utilities
                                </a>
                                .
                            </TextNew>
                        </SubCategory>
                    </Category>
                    <Category id="how-did-we-calculate-travel" title="Travel">
                        {/* <SubCategory>
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                Calculations for air and train travel are based on emissions factors from the{' '}
                                <a
                                    href="https://www.gov.uk/government/publications/greenhouse-gas-reporting-conversion-factors-2020"
                                    target="_blank"
                                    rel="noreferrer">
                                    UK Government's GHG Conversion Factors for Company Reporting 2020
                                </a>
                                . Average travel speed is based on data from the{' '}
                                <a
                                    href="https://www.eesi.org/papers/view/fact-sheet-high-speed-rail-development-worldwide#:~:text=Several%20sections%20of%20the%20national,(120%E2%80%93160%20mph)."
                                    target="_blank"
                                    rel="noreferrer">
                                    Environmental and Energy Study Institute
                                </a>{' '}
                                and is taken to be 835 km/hr and 250 km/hr for air and train travel, respectively.
                            </TextNew>
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                Calculations for cruise ships are based on emissions factors from the research paper '
                                <a
                                    href="https://www.researchgate.net/publication/46496377_Carbon_emissions_from_international_cruise_ship_passengers'_travel_to_and_from_New_Zealand"
                                    target="_blank"
                                    rel="noreferrer">
                                    Carbon emissions from international cruise ship passengers
                                </a>
                                ' travel to and from New Zealand' by Howitt et. al. Average cruising speed is taken to
                                be 20 knots. Average time spent cruising is taken to be 12 hours/day.
                            </TextNew>
                        </SubCategory> */}
                        <SubCategory title="Road Trips">
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                Calculations for road trips are based on emissions factors from the{' '}
                                <a
                                    href="https://www.dcceew.gov.au/climate-change/publications/national-greenhouse-accounts-factors-2024"
                                    target="_blank"
                                    rel="noreferrer">
                                    2024 Australian National Greenhouse Accounts Factors
                                </a>
                                . Average fuel economy has been calculated based on data from the{' '}
                                <a href="https://www.greenvehicleguide.gov.au/" target="_blank" rel="noreferrer">
                                    Australian Government's Green Vehicle Guide
                                </a>
                                .
                            </TextNew>
                        </SubCategory>
                        <SubCategory title="Coach, Air and Train">
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                Calculations for coach, air and train travel are based on emissions factors from the{' '}
                                <a
                                    href="https://www.gov.uk/government/publications/greenhouse-gas-reporting-conversion-factors-2024"
                                    target="_blank"
                                    rel="noreferrer">
                                    UK Government's GHG Conversion Factors for Company Reporting 2024
                                </a>
                                . Average travel speed is taken to be 80km/hr, 835km/hr and 250km/hr for coach, air and
                                train travel, respectively.
                            </TextNew>
                        </SubCategory>
                        <SubCategory title="Cruise Ship">
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                Calculations for cruise ships are based on emissions factors from the research paper '
                                <a
                                    href="https://www.researchgate.net/publication/46496377_Carbon_emissions_from_international_cruise_ship_passengers'_travel_to_and_from_New_Zealand"
                                    target="_blank"
                                    rel="noreferrer">
                                    Carbon emissions from international cruise ship passengers' travel to and from New
                                    Zealand
                                </a>
                                ' by Howitt et. al. Average cruising speed is taken to be 20 knots. Average time spent
                                cruising is taken to be 12 hours/day.
                            </TextNew>
                        </SubCategory>
                    </Category>
                    <Category id="how-did-we-calculate-groceries" title="Food &amp; Drink">
                        <SubCategory>
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                Calculations are based on emissions factors from{' '}
                                <a
                                    href="https://apps.epa.vic.gov.au/AGC/resources/docs/agc_assumptions_for_calculations-march_2011-v2.pdf"
                                    target="_blank"
                                    rel="noreferrer">
                                    Life Cycle Strategies' Food, Grocery &amp; Services Footprint Calculator
                                </a>
                                . Average food spend has been calculated based on data from the{' '}
                                <a
                                    href="https://www.abs.gov.au/statistics/economy/finance/household-expenditure-survey-australia-summary-results/latest-release#data-download"
                                    target="_blank"
                                    rel="noreferrer">
                                    Australian Bureau of Statistics Household Expenditure Survey 2015-16
                                </a>
                                . Figures have been adjusted for inflation using the{' '}
                                <a
                                    href="https://www.rba.gov.au/calculator/annualDecimal.html"
                                    target="_blank"
                                    rel="noreferrer">
                                    Reserve Bank of Australia's Inflation Calculator
                                </a>
                                .
                            </TextNew>
                        </SubCategory>
                    </Category>
                    <Category id="how-did-we-calculate-waste" title="Waste">
                        <SubCategory>
                            <TextNew tagName={'p'} textSize={'S'} textWeight={300}>
                                Calculations are based on emissions factors from the{' '}
                                <a
                                    href="https://www.dcceew.gov.au/climate-change/publications/national-greenhouse-accounts-factors-2024"
                                    target="_blank"
                                    rel="noreferrer">
                                    2024 Australian National Greenhouse Accounts Factors
                                </a>
                                .
                            </TextNew>
                        </SubCategory>
                    </Category>
                </div>
            </Scrollable>
        </Container>
    );
};

export { Information };
