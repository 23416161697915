import { CategoriesState } from 'src/store';
import stringify from './stringify';

const deserializeCategoriesState = (json: string): CategoriesState => {
    return JSON.parse(json);
};

const serializeCategoriesState = (state: CategoriesState): string => {
    return stringify(state);
};

export { serializeCategoriesState, deserializeCategoriesState };
