import { EnergyType } from 'src/models/energy';
import { ActionType, Actions } from '.';
import { EnergyState, ReduxThunkAction } from 'src/store';
import { ConfigurationType } from 'src/configuration';
import { CategoryType } from 'src/models';

function calculateEmissions(type: EnergyType, state: EnergyState, configuration: ConfigurationType): number {
    const {
        emissions: { [CategoryType.Energy]: factors },
    } = configuration;
    switch (type) {
        case EnergyType.Electricity:
            return (
                factors[EnergyType.Electricity][state[EnergyType.Electricity].region!] *
                state[EnergyType.Electricity].consumption
            );
        case EnergyType.NaturalGas:
            return (
                factors[EnergyType.NaturalGas][state[EnergyType.NaturalGas].region!] *
                state[EnergyType.NaturalGas].consumption
            );
        case EnergyType.LPG:
            return factors[EnergyType.LPG] * state[EnergyType.LPG].consumption;
        case EnergyType.Firewood:
            return factors[EnergyType.Firewood] * state[EnergyType.Firewood].consumption;
    }
}

type UpdateConsumptionProps = {
    type: EnergyType;
    consumption: number;
    isAverage?: boolean;
};

const updateConsumption = (props: UpdateConsumptionProps): ReduxThunkAction<Actions> => {
    const { type, consumption, isAverage = false } = props;
    return (dispatch, getState, configuration) => {
        dispatch({
            type: ActionType.UPDATE_CONSUMPTION,
            payload: {
                type,
                consumption,
                isAverage,
            },
        });
        const {
            calculation: { energy },
        } = getState();
        dispatch({
            type: ActionType.UPDATE_EMISSIONS,
            payload: {
                type,
                emissions: calculateEmissions(type, energy, configuration),
            },
        });
    };
};

export { updateConsumption };
