import React, { ReactNode } from 'react';
import { NavigationPage } from '../NavigationPage';
import { Grid } from 'src/components/shared/Grid';
import { NumericInputForm } from 'src/components/shared/NumericInputForm';
import { ReactComponent as Economy } from './Economy.svg';
import { ReactComponent as PremiumEconomy } from './PremiumEconomy.svg';
import { ReactComponent as Business } from './BusinessClass.svg';
import { ReactComponent as Sleeper } from './Sleeper.svg';
import { TravelType, TrainTravelType } from 'src/models/travel';
import { useDispatch, useSelector } from 'react-redux';
import { updateTravel } from 'src/store/actions/travel/updateTravel';
import { RootState } from 'src/store';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { useTimeframe } from 'src/store/utils/useTimeframe';
import styles from './Train.module.sass';

type TrainInputProps = {
    title: string;
    subtype: TrainTravelType;
    icon: ReactNode;
};

const TrainInput: React.FC<TrainInputProps> = (props: TrainInputProps) => {
    const { title, subtype, icon } = props;
    const dispatch = useDispatch();
    const duration = useSelector<RootState, number>(
        state => state.calculation.travel[TravelType.Train][subtype].duration,
    );
    return (
        <NumericInputForm
            title={title}
            icon={icon}
            value={duration}
            units="hours"
            onValueChange={value =>
                dispatch(
                    updateTravel({
                        type: TravelType.Train,
                        subtype,
                        duration: value,
                    }),
                )
            }
        />
    );
};

const Train = () => {
    const { timeframeText } = useTimeframe();
    return (
        <>
            <NavigationPage
                title="Travel - Train"
                description={`Over the past ${timeframeText}, how many hours has your household travelled long-distances by train?`}
                tooltip="To account for your whole household, make sure you multiply the travel time by the number of household members on each journey. Transport around your local area by public train should be accounted for in the Transport section.">
                <div className={styles.root}>
                    <Grid columns={{ xs: 2, sm: 2, md: 4 }}>
                        <TrainInput
                            title="Economy"
                            subtype={TrainTravelType.Economy}
                            icon={<Economy className={styles.icon} />}
                        />
                        <TrainInput
                            title="Premium Economy"
                            subtype={TrainTravelType.PremiumEconomy}
                            icon={<PremiumEconomy className={styles.icon} />}
                        />
                        <TrainInput
                            title="Business"
                            subtype={TrainTravelType.Business}
                            icon={<Business className={styles.icon} />}
                        />
                        <TrainInput
                            title="Sleeper"
                            subtype={TrainTravelType.Sleeper}
                            icon={<Sleeper className={styles.icon} />}
                        />
                    </Grid>
                </div>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'Taking the train instead of flying can reduce your travel footprint by up to <strong>90%</strong>!',
                    'Taking the Eurostar emits around <strong>6 grams</strong> of CO₂e per passenger per kilometre, compared to </strong>156</strong> grams for a short-haul flight!',
                    'Of all transport methods, trains almost always have the lowest carbon footprint per passenger kilometre for medium to long distances.',
                ]}
            />
        </>
    );
};

export { Train };
