import {
    PublicTransportType,
    TransportType,
    FuelType,
    VehicleSize,
    MeasurementType,
    CalculationType,
} from 'src/models/transport';

export enum ActionType {
    RESET = 'TRANSPORT:RESET',
    SET_SELECTED = 'TRANSPORT:SET_SELECTED',
    SET_VEHICLE_COUNT = 'TRANSPORT:SET_VEHICLE_COUNT',
    UPDATE_VEHICLE = 'TRANSPORT:UPDATE_VEHICLE',
    UPDATE_VEHICLE_EMISSIONS = 'TRANSPORT:UPDATE_VEHICLE_EMISSIONS',
    UPDATE_MOTORCYCLE = 'TRANSPORT:UPDATE_MOTORCYCLE',
    UPDATE_MOTORCYCLE_EMISSIONS = 'TRANSPORT:UPDATE_MOTORCYCLE_EMISSIONS',
    UPDATE_PUBLIC_TRANSPORT = 'TRANSPORT:UPDATE_PUBLIC_TRANSPORT',
    UPDATE_PUBLIC_TRANSPORT_EMISSIONS = 'TRANSPORT:UPDATE_PUBLIC_TRANSPORT_EMISSIONS',
}

export type ResetAction = {
    type: ActionType.RESET;
};

export type SetSelectedAction = {
    type: ActionType.SET_SELECTED;
    payload: {
        type: TransportType;
        selected: boolean;
    };
};

export type SetVehicleCountAction = {
    type: ActionType.SET_VEHICLE_COUNT;
    payload: {
        type: TransportType;
        count: number;
    };
};

export type UpdateVehicleAction = {
    type: ActionType.UPDATE_VEHICLE;
    payload: {
        type: TransportType.Car | TransportType.SUV | TransportType.Van;
        index: number;
        fuel?: FuelType;
        measurement?: MeasurementType;
        size?: VehicleSize;
        fuelEconomy?: number;
        calculation?: CalculationType;
        consumption?: number;
        distance?: number;
    };
};

export type UpdateVehicleEmissionsAction = {
    type: ActionType.UPDATE_VEHICLE_EMISSIONS;
    payload: {
        type: TransportType.Car | TransportType.SUV | TransportType.Van;
        index: number;
        emissions: number;
    };
};

export type UpdateMotorcycleAction = {
    type: ActionType.UPDATE_MOTORCYCLE;
    payload: {
        index: number;
        calculation?: CalculationType;
        consumption?: number;
        distance?: number;
    };
};

export type UpdateMotorcycleEmissionsAction = {
    type: ActionType.UPDATE_MOTORCYCLE_EMISSIONS;
    payload: {
        index: number;
        emissions: number;
    };
};

export type UpdatePublicTransportAction = {
    type: ActionType.UPDATE_PUBLIC_TRANSPORT;
    payload: {
        type: PublicTransportType;
        distance: number;
    };
};

export type UpdatePublicTransportEmissionsAction = {
    type: ActionType.UPDATE_PUBLIC_TRANSPORT_EMISSIONS;
    payload: {
        type: PublicTransportType;
        emissions: number;
    };
};

export type Actions =
    | ResetAction
    | SetSelectedAction
    | SetVehicleCountAction
    | UpdateVehicleAction
    | UpdateVehicleEmissionsAction
    | UpdateMotorcycleAction
    | UpdateMotorcycleEmissionsAction
    | UpdatePublicTransportAction
    | UpdatePublicTransportEmissionsAction;
