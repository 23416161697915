import React from 'react';
import classNames from 'classnames';
import { TextNew } from 'src/components/shared/TextNew';
import { ReactComponent as Information } from 'src/components/svg/Information.svg';
import { Placement } from '@popperjs/core';
import Tippy from '@tippyjs/react/headless';
import styles from './Tooltip.module.sass';
import { useSpring, motion } from 'framer-motion';


type TooltipProps = {
    text: string | React.ReactElement<any>;
    className?: string;
    iconElement?: React.ReactElement<any>;
    placement?: Placement;
    children: React.ReactElement<any>;
};

const springConfig = { damping: 20, stiffness: 300 };
const initialScale = 0.5;


const Tooltip: React.FC<TooltipProps> = (props: TooltipProps) => {

    const { className, text, placement = 'top', children, iconElement = <Information /> } = props;
    const opacity = useSpring(0, springConfig);
    const scale = useSpring(initialScale, springConfig);

    function onMount() {
        scale.set(1);
        opacity.set(1);
    }

    function onHide({ unmount }:any): void {
        const cleanup = scale.onChange(value => {
            if (value <= initialScale) {
                cleanup();
                unmount();
            }
        });

        scale.set(initialScale);
        opacity.set(0);
    }

    return (
        <Tippy
            trigger="click"
            placement={placement}
            render={attrs => (
                <motion.div style={{ scale, opacity }} className={styles.tooltip} {...attrs}>
                    {iconElement}
                    <TextNew className={styles.text} textSize={'S'}>
                        {text}
                    </TextNew>
                </motion.div>
            )}
            animation={true}
            onMount={onMount}
            onHide={onHide}>
            <div className={classNames(className, styles.tooltipIcon)}>{children}</div>
        </Tippy>
    );
};

export { Tooltip };
