import React from 'react';
import { NavigationPage } from '../NavigationPage';
import { RegionSelector } from './RegionSelector';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store';
import { ActionType } from 'src/store/actions/energy';
import { EnergyType, EnergyRegion } from 'src/models/energy';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';

const ElectricityRegion: React.FC = () => {
    const dispatch = useDispatch();
    const region = useSelector<RootState, EnergyRegion | undefined>(
        state => state.calculation.energy[EnergyType.Electricity].region,
    );
    return (
        <>
            <NavigationPage
                title="Energy - Electricity"
                description="Which region governs your electricity supply?"
                enableBack={true}
                enableNext={region !== undefined}>
                <RegionSelector
                    region={region}
                    onRegionChange={value =>
                        dispatch({
                            type: ActionType.UPDATE_REGION,
                            payload: {
                                type: EnergyType.Electricity,
                                region: value,
                            },
                        })
                    }
                />
            </NavigationPage>
            <DidYouKnow
                text={[
                    'Coal accounts for <strong>62%</strong> of electricity generation in Australia, helping make it one of the most polluting electricity grids in the world.',
                    "Electricity emissions contribute <strong>a third</strong> of Australia's total greenhouse gas emissions.",
                    "Around <Strong>24%</strong> of Australia's total electricity generation comes from renewables. Of this, wind is most popular (<strong>35%</strong>) followed by solar (<strong>32%</strong>).",
                ]}
            />
        </>
    );
};

export { ElectricityRegion };
