import React from 'react';
import { CategoryProgress } from 'src/components/category-progress';
import { CalculationTotal } from 'src/components/calculation-total';
import styles from './Footer.module.sass';

const Footer: React.FC = () => {
    return (
        <section className={styles.root}>
            <CategoryProgress />
            <CalculationTotal />
        </section>
    );
};

export { Footer };
