import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransportType, VehicleEmissions, DefaultVehicleEmissions } from 'src/models/transport';
import { RootState } from 'src/store';
import { ReactComponent as Car } from '../transport/Car.svg';
import { ReactComponent as SUV } from '../transport/SUV.svg';
import { ReactComponent as Van } from '../transport/Van.svg';
import { VehicleSubtype } from 'src/libs/transport';
import { TravelType } from 'src/models/travel';
import { VehicleForm } from '../shared/VehicleForm';
import { updateRoadTripVehicle } from 'src/store/actions/travel/updateRoadTripVehicle';

function getColorVariant(type: VehicleSubtype) {
    switch (type) {
        case TransportType.Car:
            return 1;
        case TransportType.SUV:
            return 2;
        case TransportType.Van:
            return 3;
    }
}

function getIcon(type: VehicleSubtype) {
    switch (type) {
        case TransportType.Car:
            return <Car />;
        case TransportType.SUV:
            return <SUV />;
        case TransportType.Van:
            return <Van />;
    }
}

function getTitle(type: VehicleSubtype, index: number) {
    switch (type) {
        case TransportType.Car:
            return `Car ${index + 1}`;
        case TransportType.SUV:
            return `SUV ${index + 1}`;
        case TransportType.Van:
            return `People Mover ${index + 1}`;
    }
}

type RoadTripVehicleProps = {
    type: VehicleSubtype;
    index: number;
};

const RoadTripVehicle = (props: RoadTripVehicleProps) => {
    const { type, index } = props;
    const dispatch = useDispatch();
    const vehicle = useSelector<RootState, VehicleEmissions>(
        state => state.calculation.travel[TravelType.RoadTrip][type][index] || DefaultVehicleEmissions,
    );
    return (
        <VehicleForm
            type={type}
            title={getTitle(type, index)}
            icon={getIcon(type)}
            colorVariant={getColorVariant(type)}
            vehicle={vehicle}
            disabled={false}
            weekly={false}
            onFuelUpdate={fuel => dispatch(updateRoadTripVehicle({ type, index, fuel }))}
            onMeasurementUpdate={measurement => dispatch(updateRoadTripVehicle({ type, index, measurement }))}
            onCalculationUpdate={calculation => dispatch(updateRoadTripVehicle({ type, index, calculation }))}
            onSizeUpdate={size => dispatch(updateRoadTripVehicle({ type, index, size }))}
            onFuelEconomyUpdate={fuelEconomy => dispatch(updateRoadTripVehicle({ type, index, fuelEconomy }))}
            onConsumptionUpdate={consumption => dispatch(updateRoadTripVehicle({ type, index, consumption }))}
            onDistanceUpdate={distance => dispatch(updateRoadTripVehicle({ type, index, distance }))}
        />
    );
};

export { RoadTripVehicle };
