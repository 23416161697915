import React from 'react';
import styles from './Separator.module.sass';

type Size = "small" | "large"

type SeparatorProps = {
    size: Size
}

const Separator: React.FC<SeparatorProps> = (props: SeparatorProps) => {
    return (
        <section className={styles.root} data-size={props.size}>
            <div />
            <div />
            <div />
            <div />
        </section>
    );
};

export { Separator };