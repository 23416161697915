import { Actions, ActionType } from '../actions/travel';
import {
    DomesticAirTravelType,
    InternationalAirTravelType,
    TrainTravelType,
    CruiseShipTravelType,
    CoachTravelType,
    TravelEmissions,
    TravelType,
} from 'src/models/travel';
import {
    DefaultMotorcycleEmissions,
    DefaultVehicleEmissions,
    MotorcycleEmissions,
    TransportType,
    VehicleEmissions,
    VehicleSize,
} from 'src/models/transport';
import strip from 'src/utils/strip';

export type DomesticTravelState = {
    [DomesticAirTravelType.Economy]: TravelEmissions;
    [DomesticAirTravelType.Business]: TravelEmissions;
};

export type InternationalTravelState = {
    [InternationalAirTravelType.Economy]: TravelEmissions;
    [InternationalAirTravelType.PremiumEconomy]: TravelEmissions;
    [InternationalAirTravelType.Business]: TravelEmissions;
    [InternationalAirTravelType.First]: TravelEmissions;
};

export type TrainTravelState = {
    [TrainTravelType.Economy]: TravelEmissions;
    [TrainTravelType.PremiumEconomy]: TravelEmissions;
    [TrainTravelType.Business]: TravelEmissions;
    [TrainTravelType.Sleeper]: TravelEmissions;
};

export type CruiseShipTravelState = {
    [CruiseShipTravelType.Cabin]: TravelEmissions;
};

export type CoachTravelState = {
    [CoachTravelType.Coach]: TravelEmissions;
};

export type RoadTripTravelState = {
    vehicleCount: {
        [TransportType.Car]: number;
        [TransportType.SUV]: number;
        [TransportType.Van]: number;
        [TransportType.Motorcycle]: number;
    };
    [TransportType.Car]: Array<VehicleEmissions>;
    [TransportType.SUV]: Array<VehicleEmissions>;
    [TransportType.Van]: Array<VehicleEmissions>;
    [TransportType.Motorcycle]: Array<MotorcycleEmissions>;
};

export type TravelState = {
    selected: Array<TravelType>;
    [TravelType.DomesticAir]: DomesticTravelState;
    [TravelType.InternationalAir]: InternationalTravelState;
    [TravelType.Train]: TrainTravelState;
    [TravelType.CruiseShip]: CruiseShipTravelState;
    [TravelType.Coach]: CoachTravelState;
    [TravelType.RoadTrip]: RoadTripTravelState;
};

const defaultEmissions = {
    duration: 0,
    emissions: 0,
};

const initialState = {
    selected: [],
    [TravelType.DomesticAir]: {
        [DomesticAirTravelType.Economy]: defaultEmissions,
        [DomesticAirTravelType.Business]: defaultEmissions,
    },
    [TravelType.InternationalAir]: {
        [InternationalAirTravelType.Economy]: defaultEmissions,
        [InternationalAirTravelType.PremiumEconomy]: defaultEmissions,
        [InternationalAirTravelType.Business]: defaultEmissions,
        [InternationalAirTravelType.First]: defaultEmissions,
    },
    [TravelType.Train]: {
        [TrainTravelType.Economy]: defaultEmissions,
        [TrainTravelType.PremiumEconomy]: defaultEmissions,
        [TrainTravelType.Business]: defaultEmissions,
        [TrainTravelType.Sleeper]: defaultEmissions,
    },
    [TravelType.CruiseShip]: {
        [CruiseShipTravelType.Cabin]: defaultEmissions,
    },
    [TravelType.Coach]: {
        [CoachTravelType.Coach]: defaultEmissions,
    },
    [TravelType.RoadTrip]: {
        vehicleCount: {
            [TransportType.Car]: 0,
            [TransportType.SUV]: 0,
            [TransportType.Van]: 0,
            [TransportType.Motorcycle]: 0,
        },
        [TransportType.Car]: new Array<VehicleEmissions>(),
        [TransportType.SUV]: new Array<VehicleEmissions>(),
        [TransportType.Van]: new Array<VehicleEmissions>(),
        [TransportType.Motorcycle]: new Array<MotorcycleEmissions>(),
    },
};

const travelReducer = (state: TravelState = initialState, action: Actions) => {
    switch (action.type) {
        case ActionType.RESET:
            return initialState;
        case ActionType.SET_SELECTED:
            if (action.payload.selected && state.selected.includes(action.payload.type) === false) {
                return {
                    ...state,
                    selected: [...state.selected, action.payload.type],
                };
            }
            if (action.payload.selected === false && state.selected.includes(action.payload.type)) {
                return {
                    ...state,
                    selected: state.selected.filter(v => v !== action.payload.type),
                };
            }
            break;
        case ActionType.UPDATE:
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    [action.payload.subtype]: {
                        ...(state[action.payload.type] as any)[action.payload.subtype],
                        duration: action.payload.duration,
                    },
                },
            };
        case ActionType.UPDATE_EMISSIONS:
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    [action.payload.subtype]: {
                        ...(state[action.payload.type] as any)[action.payload.subtype],
                        emissions: action.payload.emissions,
                    },
                },
            };
        case ActionType.UPDATE_ROADTRIP_VEHICLE_COUNT:
            return {
                ...state,
                [TravelType.RoadTrip]: {
                    ...state[TravelType.RoadTrip],
                    vehicleCount: {
                        ...state[TravelType.RoadTrip].vehicleCount,
                        [action.payload.type]: action.payload.count,
                    },
                },
            };
        case ActionType.UPDATE_ROADTRIP:
        case ActionType.UPDATE_ROADTRIP_EMISSIONS:
            let payload = undefined;
            switch (action.payload.type) {
                case TransportType.Car:
                case TransportType.SUV:
                case TransportType.Van:
                    const defaultVehicleEmissions = {
                        ...DefaultVehicleEmissions,
                        size: action.payload.type === TransportType.Van ? VehicleSize.PeopleMover : VehicleSize.Small,
                    };
                    payload = {
                        ...defaultVehicleEmissions,
                        ...(state[TravelType.RoadTrip][action.payload.type][action.payload.index] || {}),
                    };
                    break;
                case TransportType.Motorcycle:
                    payload = {
                        ...DefaultMotorcycleEmissions,
                        ...(state[TravelType.RoadTrip][action.payload.type][action.payload.index] || {}),
                    };
                    break;
            }
            const defaultArray = new Array(action.payload.index)
                .fill(payload)
                .map((vehcile, index) => state[TravelType.RoadTrip][action.payload.type][index] || vehcile);
            switch (action.type) {
                case ActionType.UPDATE_ROADTRIP:
                    return {
                        ...state,
                        [TravelType.RoadTrip]: {
                            ...state[TravelType.RoadTrip],
                            [action.payload.type]: Object.assign(
                                defaultArray,
                                state[TravelType.RoadTrip][action.payload.type],
                                {
                                    [action.payload.index]: {
                                        ...payload,
                                        ...strip(action.payload, ['type', 'index']),
                                    },
                                },
                            ),
                        },
                    };
                case ActionType.UPDATE_ROADTRIP_EMISSIONS:
                    return {
                        ...state,
                        [TravelType.RoadTrip]: {
                            ...state[TravelType.RoadTrip],
                            [action.payload.type]: Object.assign(
                                defaultArray,
                                state[TravelType.RoadTrip][action.payload.type],
                                {
                                    [action.payload.index]: {
                                        ...state[TravelType.RoadTrip][action.payload.type][action.payload.index],
                                        emissions: action.payload.emissions,
                                    },
                                },
                            ),
                        },
                    };
            }
    }
    return state;
};

export { travelReducer };
