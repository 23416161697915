import { WaterState } from 'src/store';
import stringify from './stringify';

const deserializeWaterState = (json: string): WaterState => {
    return JSON.parse(json, (key, value) => {
        switch (key) {
            case 'isUpdating':
                return false;
        }
        return value;
    });
};

const serializeWaterState = (state: WaterState): string => {
    return stringify(state);
};

export { serializeWaterState, deserializeWaterState };
