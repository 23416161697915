import React from 'react';
import classNames from 'classnames';
import { TextNew } from 'src/components/shared/TextNew';

import { Insight } from 'src/libs/insights';
import styles from './KeyInsightsList.module.sass';

type KeyInsightProps = {
    title: string;
    description: string;
};

const KeyInsight = (props: KeyInsightProps) => {
    const { title, description } = props;
    return (
        <li className={styles.insight}>
            <TextNew className={styles.insightTitle} tagName={'h3'} textSize={'S'} textWeight={700}>
                <div className={styles.indicator} />
                {title}
            </TextNew>
            <TextNew className={styles.insightDescription} tagName={'p'} textSize={'S'} textWeight={300}>
                <span dangerouslySetInnerHTML={{ __html: description }} />
            </TextNew>
        </li>
    );
};

type KeyInsightsListProps = {
    className: string;
    insights: Array<Insight>;
};

const KeyInsightsList = (props: KeyInsightsListProps) => {
    const { className, insights } = props;
    return (
        <div className={classNames(className, styles.root)}>
            <TextNew className={styles.title} tagName={'h2'} textSize={'XL'} textWeight={700}>
                Key Insights
            </TextNew>
            <ol className={styles.list}>
                {insights.map((insight, index) => {
                    return <KeyInsight key={index} title={insight.title} description={insight.description} />;
                })}
            </ol>
        </div>
    );
};

export { KeyInsightsList };
