import React from 'react';
import { Page } from '../Page';
import { ReactComponent as Purchase } from './Purchase.svg';
import { ReactComponent as Calculate } from './Calculate.svg';
import { OptionButton } from './OptionButton';
import { useNavigation } from 'src/components/shared/Navigation';
import { Grid } from 'src/components/shared/Grid';
import { flushMetadataState } from 'src/store/storage/flushMetadataState';
import { event } from 'src/utils/eventTracking';
import styles from './Welcome.module.sass';

const Welcome: React.FC = () => {
    const navigation = useNavigation();
    return (
        <Page
            title="Welcome"
            description="Calculate your household's carbon footprint, then offset by donating to native restoration projects"
            subtitle="Choose an option to begin">
            <div className={styles.root}>
                <Grid limit={true} columns={{ default: 2, xs: 2, sm: 2, md: 2 }}>
                    <OptionButton
                        title="Calculate now"
                        description="Enter data about your lifestyle to determine your carbon footprint."
                        icon={<Calculate />}
                        onClick={() => {
                            event('new', 'new-calculation');
                            flushMetadataState();
                            navigation.next();
                        }}
                    />
                    <OptionButton
                        title="Offset now"
                        description="Skip the calculator and use pre-filled averages to offset your emissions."
                        icon={<Purchase />}
                        onClick={() => {
                            window.location.href =
                                'https://carbonpositiveaustralia.org.au/product-category/quick-offsets/';
                        }}
                    />
                </Grid>
                {/* <div className={styles.description}>
                    <TextNew tagName={'p'} textSize={'S'}>
                        Offsetting carbon allows us to positively compensate for the unavoidable carbon emissions we all
                        generate in everyday life. When you buy an offset/carbon credit, you fund projects that avoid,
                        reduce or remove greenhouse gas emissions elsewhere in the world. Use our calculator to
                        determine your household impact for a given period of time, then offset that impact by donating
                        to Carbon Positive’s range of restoration projects.
                    </TextNew>
                </div> */}
            </div>
        </Page>
    );
};

export { Welcome };
