import { Actions, ActionType } from '../actions/waste';
import { WasteEmissions, BinSize, CollectionFrequency, PercentageFull } from 'src/models/waste';

export type WasteState = WasteEmissions;

const initialState = {
    binSize: BinSize.Medium,
    collectionFrequency: CollectionFrequency.Weekly,
    percentageFull: PercentageFull.Half,
    emissions: 0,
};

const wasteReducer = (state: WasteState = initialState, action: Actions) => {
    switch (action.type) {
        case ActionType.RESET:
            return initialState;
        case ActionType.UPDATE:
            return {
                ...state,
                ...action.payload,
            };
        case ActionType.UPDATE_EMISSIONS:
            return {
                ...state,
                emissions: action.payload.emissions,
            };
    }
    return state;
};

export { wasteReducer };
