import React, { ReactNode } from 'react';
import { Page } from './Page';
import { NavigationButtons } from './NavigationButtons';
import { useNavigation } from 'src/components/shared/Navigation/Navigation';
import { AtMostDefault, AtLeastXS } from '../../utils/breakpoints';
import styles from './NavigationPage.module.sass';

type NavigationPageProps = {
    title: string;
    description: string;
    subtitle?: string;
    tooltip?: string;
    children: ReactNode;
    enableNext?: boolean;
    enableBack?: boolean;
    onBackClick?: () => boolean;
    onNextClick?: () => boolean;
};

const NavigationPage: React.FC<NavigationPageProps> = (props: NavigationPageProps) => {
    const {
        title,
        description,
        subtitle,
        tooltip,
        children,
        enableNext = true,
        enableBack = true,
        onBackClick,
        onNextClick,
    } = props;
    const { back, next } = useNavigation();
    const navigationButtons = (
        <NavigationButtons
            className={styles.buttons}
            enableBack={enableBack}
            enableNext={enableNext}
            onBackClick={() => {
                if (onBackClick && onBackClick() === false) {
                    return;
                }
                back();
            }}
            onNextClick={() => {
                if (onNextClick && onNextClick() === false) {
                    return;
                }
                next();
            }}
        />
    );
    return (
        <>
            <AtMostDefault>
                <Page title={title} description={description} subtitle={subtitle} tooltip={tooltip}>
                    {children}
                    {navigationButtons}
                </Page>
            </AtMostDefault>
            <AtLeastXS>
                <Page title={title} description={description} subtitle={subtitle} tooltip={tooltip}>
                    {children}
                    {navigationButtons}
                </Page>
            </AtLeastXS>
        </>
    );
};

export { NavigationPage };
