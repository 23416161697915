import { EmissionsElement } from './';

export enum BinSize {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
}

export enum CollectionFrequency {
    Weekly = 'weekly',
    Fortnightly = 'fortnightly',
    Monthly = 'monthly',
}

export enum PercentageFull {
    Empty = 'empty',
    Quarter = 'quarter',
    Half = 'half',
    ThreeQuarter = 'three-quarter',
    Full = 'full',
}

export type WasteEmissions = EmissionsElement & {
    binSize: BinSize;
    collectionFrequency: CollectionFrequency;
    percentageFull: PercentageFull;
};
