import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Container.module.sass';
import { useSpring, motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';

type ContainerProps = {
    className?: string;
    children: ReactNode;
    padding?: 'none' | 'visible';
};

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
    const { className, children, padding = 'visible' } = props;
    return (
        <div
            className={classNames(className, styles.root, {
                [styles.padding]: padding === 'visible',
            })}>
            {children}
        </div>
    );
};

export { Container };
