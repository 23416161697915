import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { ActionType, NavigateToAction } from 'src/store/actions/navigation';
import { NavigationKey } from 'src/store/reducers/navigationReducer';

type Action = () => void;

export type NavigationContextType = {
    back: Action;
    next: Action;
};

const NavigationContext = React.createContext<NavigationContextType | null>(null);

const emptyNavigationContext = {
    back: () => {},
    next: () => {},
};

function useNavigation(): NavigationContextType {
    const context = React.useContext(NavigationContext) || emptyNavigationContext;
    if (context === null) {
        throw Error('The NavigationContext has not yet been initialized.');
    }
    return context;
}

type useNavigationStateReturnType = {
    index: number;
    dispatch: Dispatch<SetStateAction<NavigateToAction>>;
    navigate: (index: number) => void;
    reset: () => void;
};

function useNavigationState(key: NavigationKey): useNavigationStateReturnType {
    const dispatch = useDispatch();
    const index = useSelector<RootState, number>(state => state.ui.navigation[key] || 0);
    const navigate = useMemo(() => {
        return (i: number) => {
            dispatch({
                type: ActionType.NAVIGATE_TO,
                payload: {
                    key,
                    index: i,
                },
            });
        };
    }, []);
    const reset = useMemo(() => {
        return () => {
            dispatch({
                type: ActionType.RESET,
                payload: {
                    keys: [key],
                },
            });
        };
    }, []);
    return {
        index,
        dispatch,
        navigate,
        reset,
    };
}

export { NavigationContext, useNavigation, useNavigationState };
