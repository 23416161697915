import React from 'react';
import { NavigationRoot } from 'src/components/shared/Navigation';
import { Regions } from './Regions';
import { Consumption } from './Consumption';
import { flushWaterState } from 'src/store/storage/flushWaterState';
import { NavigationKey } from 'src/store/reducers/navigationReducer';

const WaterRoot: React.FC = () => {
    return (
        <NavigationRoot navigationKey={NavigationKey.Water} onNavigate={flushWaterState}>
            <Regions />
            <Consumption />
        </NavigationRoot>
    );
};

export { WaterRoot };
