import { EmissionsElement } from '.';

export enum TransportType {
    Car = 'car',
    SUV = 'suv',
    Van = 'van',
    Motorcycle = 'motorcycle',
    PublicTransport = 'public-transport',
}

export enum FuelType {
    Petrol = 'petrol',
    Diesel = 'diesel',
    LPG = 'lpg',
    E10 = 'e10',
    Biodiesel = 'biodiesel',
    Electric = 'electric',
}

export enum VehicleSize {
    Small = 'small',
    SmallHybrid = 'small-hybrid',
    Medium = 'medium',
    MediumHybrid = 'medium-hybrid',
    Large = 'large',
    LargeHybrid = 'large-hybrid',
    ExtraLarge = 'extra-large',
    ExtraLargeHybrid = 'extra-large-hybrid',
    PeopleMover = 'people-mover',
    PeopleMoverHybrid = 'people-mover-hybrid',
}

export enum MeasurementType {
    VehicleSize = 'vehicle-size',
    FuelEconomy = 'fuel-economy',
}

export enum CalculationType {
    Fuel = 'fuel',
    Distance = 'distance',
}

export type VehicleMeasurement =
    | { measurement: MeasurementType.VehicleSize; size: VehicleSize }
    | { measurement: MeasurementType.FuelEconomy; fuelEconomy: number };

export type VehicleCalculation =
    | { calculation: CalculationType.Fuel; consumption: number }
    | { calculation: CalculationType.Distance; distance: number };

export type VehicleEmissions = EmissionsElement &
    VehicleMeasurement &
    VehicleCalculation & {
        fuel: FuelType;
        isAverage: boolean;
    };

export type MotorcycleEmissions = EmissionsElement & VehicleCalculation & { isAverage: boolean };

export enum PublicTransportType {
    Taxi = 'taxi',
    Bus = 'bus',
    Tram = 'tram',
    Train = 'train',
    Ferry = 'ferry',
}

export type PublicTransportEmissions = EmissionsElement & {
    distance: number;
};

const DefaultVehicleEmissions = {
    fuel: FuelType.Petrol,
    measurement: MeasurementType.FuelEconomy,
    size: VehicleSize.Small,
    fuelEconomy: 0,
    calculation: CalculationType.Fuel,
    consumption: 0,
    distance: 0,
    emissions: 0,
    isAverage: false,
};

const DefaultMotorcycleEmissions = {
    fuel: FuelType.Petrol,
    calculation: CalculationType.Fuel,
    consumption: 0,
    distance: 0,
    emissions: 0,
    isAverage: false,
};

const DefaultPublicTransportEmissions = {
    distance: 0,
    emissions: 0,
};

export { DefaultVehicleEmissions, DefaultMotorcycleEmissions, DefaultPublicTransportEmissions };
