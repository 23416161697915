import React, { CSSProperties, useMemo } from 'react';
import classNames from 'classnames';
import { TextNew } from 'src/components/shared/TextNew';
import { UIElementProps } from 'src/components';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';
import { useSelector } from 'react-redux';
import getCategoryTotals from 'src/store/selectors/getCategoryTotals';
import styles from './BreakdownChart.module.sass';

const COLORS = ['#A04130', '#E5C385', '#17846F', '#CC6B3C', '#859959', '#5CBBBD'];

const useData = () => {
    const [transport, energy, water, travel, groceries, waste] = useSelector(getCategoryTotals);
    return useMemo(() => {
        const data = [
            { name: 'Travel', value: travel },
            { name: 'Energy', value: energy },
            { name: 'Water', value: water },
            { name: 'Transport', value: transport },
            { name: 'Food & Drink', value: groceries },
            { name: 'Waste', value: waste },
        ];
        return data
            .map((d, index) => {
                return {
                    ...d,
                    fill: COLORS[index],
                };
            })
            .filter(d => d.value > 0);
    }, [transport, energy, water, travel, groceries, waste]);
};

const BreakdownChartLegend = () => {
    const data = useData();
    console.log(data);
    return (
        <ul className={styles.legend}>
            {data.map(point => {
                var style = { '--marker-color': point.fill } as React.CSSProperties;
                return (
                    <li className={styles.legendItem} {...{ style }}>
                        <TextNew textStyle={'smallCaps'} textWeight={700}>{point.name}</TextNew>
                    </li>
                );
            })}
        </ul>
    );
}
const BreakdownChart = (props: UIElementProps) => {
    const className = { props };
    const data = useData();
    return (
        <>
            <ResponsiveContainer className={classNames(className, styles.container)}>
                <PieChart>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius="60%"
                        outerRadius="80%"
                        fill="#ff0000"
                        paddingAngle={0}
                        dataKey="value"
                        labelLine={false}
                        // label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                        //     const RADIAN = Math.PI / 180;
                        //     const radius = 25 + innerRadius + (outerRadius - innerRadius);
                        //     const x = cx + radius * Math.cos(-midAngle * RADIAN);
                        //     const y = cy + radius * Math.sin(-midAngle * RADIAN);
                        //     return (
                        //         <text
                        //             x={x}
                        //             y={y}
                        //             fill="#315B55"
                        //             fontWeight="bold"
                        //             textAnchor={x > cx ? 'start' : 'end'}
                        //             dominantBaseline="central">
                        //             {data[index].name}
                        //         </text>
                        //     );
                        // }}
                    />
                </PieChart>
            </ResponsiveContainer>
            <BreakdownChartLegend />
        </>
    );
};

export { BreakdownChart };
