import React from 'react';
import { Container } from '../../shared/Container';
import { Overview } from './Overview';
import { Detail } from './Detail';
import SummaryImage from 'src/assets/summary.png';
import { Grid } from 'src/components/shared/Grid';
import { ButtonText } from 'src/components/shared/ButtonText';
import { ReactComponent as LeftArrow } from 'src/components/svg/LeftArrow.svg';
import { useNavigation } from 'src/components/shared/Navigation';
import styles from './Summary.module.sass';
import { NavigationButtons } from '../../pages/NavigationButtons';
import { AtLeastXS, AtMostDefault } from '../../../utils/breakpoints';
import getTotal from 'src/store/selectors/getTotal';
import { useDispatch, useSelector } from 'react-redux';
import { TextNew } from 'src/components/shared/TextNew';
import { event } from 'src/utils/eventTracking';
import NumberFormat from 'react-number-format';
import { RootState } from 'src/store';
import { useEffect } from 'react';
import { ActionType as NavigationActionType } from 'src/store/actions/navigation';

const Image: React.FC = () => {
    return (
        <div className={styles.summaryImage}>
            <img alt="Illustration of a tennis court" src={SummaryImage} />
        </div>
    );
};

type TotalProps = {
    className?: string;
};

const Total: React.FC<TotalProps> = (props: TotalProps) => {
    const total = useSelector(getTotal);
    const m2 = ((total / 1000) * 10000) / 133;
    const courts = Math.ceil(m2 / 261);
    return (
        <TextNew className={styles.text} textSize={'XS'} tagName="p">
            To offset your emissions, around{' '}
            <strong>
                <NumberFormat value={m2} displayType={'text'} decimalScale={0} thousandSeparator={true} /> m<sup>2</sup>
            </strong>{' '}
            of natural habitat must be restored.{' '}
            {m2 >= 250 && (
                <span>
                    That's roughly the same size as{' '}
                    <strong>
                        {courts > 0 ? courts : 'less than one'} tennis court{courts > 1 && 's'}!
                    </strong>
                </span>
            )}
        </TextNew>
    );
};

const usePageTracking = () => {
    const dispatch = useDispatch();
    const completed = useSelector<RootState, boolean>(state => state.ui.navigation.completed) || false;
    useEffect(() => {
        if (completed === false) {
            event('summary', 'summary-page-visited');
            dispatch({
                type: NavigationActionType.SET_COMPLETED,
            });
        }
    }, []);
};

const Summary: React.FC = () => {
    const { back } = useNavigation();
    usePageTracking();
    return (
        <>
            <Container className={styles.root} padding="none">
                <Grid className={styles.grid} columns={{ md: 2 }}>
                    <AtLeastXS>
                        <ButtonText
                            className={styles.backButton}
                            variant="outlined"
                            text="Back"
                            icon={<LeftArrow />}
                            onClick={back}
                        />
                    </AtLeastXS>
                    <div className={styles.aside}>
                        <Image />
                        <Total />
                    </div>
                    <Overview className={styles.overview} />
                </Grid>
                <Detail className={styles.detail} />
            </Container>
            <AtMostDefault>
                <NavigationButtons className={styles.buttons} enableBack={true} enableNext={false} onBackClick={back} />
            </AtMostDefault>
        </>
    );
};

export { Summary };
function flushNavigationState() {
    throw new Error('Function not implemented.');
}
