import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { useNavigationState } from '../shared/Navigation';
import { ReactComponent as Chevron } from 'src/components/svg/ChevronDown.svg';
import { TextNew } from 'src/components/shared/TextNew';
import styles from './DropdownMenu.module.sass';
import { useUI } from '../UI';

type DropdownMenuProps = {
    header: ReactNode;
};

const DropdownMenu = (props: DropdownMenuProps) => {
    const { header } = props;
    const headerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [visible, setVisibility] = useState(false);
    const { navigate } = useNavigationState(NavigationKey.Root);
    const { logout } = useUI();

    useEffect(() => {
        document.addEventListener('mousedown', handleDocumentClick);
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);

    function handleDocumentClick(event: Event) {
        if (isWithinComponent(event.target as Node) === false) {
            setVisibility(false);
        }
    }

    function isWithinComponent(target: Node) {
        function isWithin(ref: React.RefObject<HTMLElement>) {
            return ref.current && ref.current.contains(target);
        }
        return isWithin(headerRef) || isWithin(containerRef);
    }

    return (
        <>
            <div data-visible={visible} ref={headerRef} className={styles.header} onClick={() => setVisibility(true)}>
                {header}
                <Chevron className={styles.arrow} />
            </div>
            {visible && (
                <div ref={containerRef} className={styles.menu}>
                    <ul>
                        <li
                            onClick={() => {
                                navigate(1);
                                document.body.removeAttribute('data-scroll-lock');
                                setVisibility(false);
                            }}>
                            <TextNew textStyle="smallCaps" textWeight={700}>
                                View Calculation History
                            </TextNew>
                        </li>
                        <li
                            onClick={() => {
                                logout();
                                setVisibility(false);
                            }}>
                            <TextNew textStyle="smallCaps" textWeight={700}>
                                Logout
                            </TextNew>
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
};

export { DropdownMenu };
