import React from 'react';
import { Grid } from 'src/components/shared/Grid';
import { NavigationPage } from '../NavigationPage';
import { ReactComponent as Month } from './Month.svg';
import { ReactComponent as Quarter } from './Quarter.svg';
import { ReactComponent as Year } from './Year.svg';
import { RootState } from 'src/store';
import { useSelector, useDispatch } from 'react-redux';
import { ActionType } from 'src/store/actions/metadata';
import { TimeframeType } from 'src/models';
import { CheckboxControl } from 'src/components/shared/CheckboxControl';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { ModalType, useUI } from 'src/components/UI';
import styles from './Timeframe.module.sass';
import getList from 'src/store/selectors/getList';

const Timeframe = () => {
    const dispatch = useDispatch();
    const timeframe = useSelector<RootState, TimeframeType | null>(state => state.calculation.metadata.timeframe);
    const items = useSelector(getList);
    const { showModal } = useUI();
    const updateTimeframe = (value: TimeframeType) => {
        if (value !== timeframe) {
            if (!timeframe || items.length === 0) {
                dispatch({
                    type: ActionType.UPDATE,
                    payload: {
                        timeframe: value,
                    },
                });
                return;
            }
            showModal({ type: ModalType.CHANGE_TIMEFRAME, timeframe: value });
        }
    };
    return (
        <>
            <NavigationPage
                title="Getting Started"
                description="...and over which timeframe?"
                subtitle="Select an option"
                tooltip="Your carbon footprint is calculated retrospectively based on your resoure consumption for a specific period. Calculate your footprint regularly to compare your progress over time. If you would like to calculate your emissions from a particular flight, holiday or event, use our Quick Offsets, or contact us for a more detailed calculation."
                enableNext={!!timeframe}>
                <div className={styles.root}>
                    <Grid columns={{ default: 3, xs: 3, sm: 3, md: 3 }}>
                        <CheckboxControl
                            title="Past Month"
                            icon={<Month className={styles.icon} />}
                            size="small"
                            checked={timeframe === TimeframeType.Month}
                            onChecked={value => updateTimeframe(TimeframeType.Month)}
                        />
                        <CheckboxControl
                            title="Past 3 Months"
                            icon={<Quarter className={styles.icon} />}
                            size="small"
                            checked={timeframe === TimeframeType.Quarter}
                            onChecked={value => updateTimeframe(TimeframeType.Quarter)}
                        />
                        <CheckboxControl
                            title="Past Year"
                            icon={<Year className={styles.icon} />}
                            size="small"
                            checked={timeframe === TimeframeType.Year}
                            onChecked={value => updateTimeframe(TimeframeType.Year)}
                        />
                    </Grid>
                </div>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'We have free tools available to help you calculate the carbon footprint of a specific event (e.g. a wedding, music festival, or overseas holiday). Visit the Carbon Positive Australia website to find out more.',
                ]}
            />
        </>
    );
};

export { Timeframe };
