import React, { ReactNode, Children } from 'react';
import classNames from 'classnames';
import styles from './Grid.module.sass';

type GridPropsColumns = {
    default?: number,
    xs?: number,
    sm?: number,
    md?: number,
    lg?: number,
    xl?: number,
};

type GridProps = {
    className?: string;
    limit?: boolean;
    children: ReactNode;
    columns: GridPropsColumns;
};

const Grid: React.FC<GridProps> = (props: GridProps) => {
    const {
        className,
        children,
        columns = {
            default: 1,
            xs: 1,
            sm: 2,
            md: 3,
        },
    } = props;

    // if (columns['sm']) columns['sm'] = Math.min(columns['sm'], Children.count(children));
    // if (columns['md']) columns['md'] = Math.min(columns['md'], Children.count(children));
    // if (columns['lg']) columns['lg'] = Math.min(columns['lg'], Children.count(children));
    // if (columns['xl']) columns['xl'] = Math.min(columns['xl'], Children.count(children));
    // console.log(Children.count(children));

    let limit = props.limit ?? false;
    props.columns.default = props.columns.default || 1;
    
    let style = limit
        ? {maxWidth: Children.count(children) * 175 + Math.floor(Children.count(children) / 2) * 24}
        : {};

    return (
        <div
            style={style}
            className={classNames(className, styles.root, styles.grid, {
                [styles[`col--default-${columns['default']}`]]: columns['default'],
                [styles[`col--xs-${columns['xs']}`]]: columns['xs'],
                [styles[`col--sm-${columns['sm']}`]]: columns['sm'],
                [styles[`col--md-${columns['md']}`]]: columns['md'],
                [styles[`col--lg-${columns['lg']}`]]: columns['lg'],
                [styles[`col--xl-${columns['xl']}`]]: columns['xl'],
            })}>
            {children}
        </div>
    );
};

export { Grid };