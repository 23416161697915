import { CategoryType } from 'src/models';
import { GroceryType } from 'src/models/groceries';
import { EmissionsListItemType } from 'src/libs';
import { EnergyType } from 'src/models/energy';
import { TravelType } from 'src/models/travel';
import { PublicTransportType, TransportType } from 'src/models/transport';

function getOptionATitle(item: EmissionsListItemType, total: number): string {
    const percentage = Math.round((item.emissions / total) * 100);
    switch (item.category) {
        case CategoryType.Transport:
            switch (item.type) {
                case TransportType.Car:
                    return `Local travel in Car ${item.index + 1} accounts for ${percentage}% of your total`;
                case TransportType.SUV:
                    return `Local travel in SUV ${item.index + 1} accounts for ${percentage}% of your total`;
                case TransportType.Van:
                    return `Local travel in People Mover ${item.index + 1} accounts for ${percentage}% of your total`;
                case TransportType.Motorcycle:
                    return `Bike/Scooter ${item.index + 1} accounts for ${percentage}% of your total`;
                case TransportType.PublicTransport:
                    switch (item.subtype) {
                        case PublicTransportType.Taxi:
                            return `Travel by taxi/rideshare accounts for ${percentage}% of your total`;
                        case PublicTransportType.Bus:
                            return `Local bus travel accounts for ${percentage}% of your total`;
                        case PublicTransportType.Tram:
                            return `Travel by tram accounts for ${percentage}% of your total`;
                        case PublicTransportType.Train:
                            return `Local train travel accounts for ${percentage}% of your total`;
                        case PublicTransportType.Ferry:
                            return `Travel by ferry accounts for ${percentage}% of your total`;
                    }
            }
            break;
        case CategoryType.Groceries:
            switch (item.type) {
                case GroceryType.Meat:
                    return `Meat consumption accounts for ${percentage}% of your total`;
                case GroceryType.EggsAndDairy:
                    return `Consumption of eggs & dairy accounts for ${percentage}% of your total`;
                case GroceryType.Seafood:
                    return `Consumption of seafood accounts for ${percentage}% of your total`;
                case GroceryType.FruitAndNuts:
                    return `Consumption of fruit & nuts accounts for ${percentage}% of your total`;
                case GroceryType.BreadAndCereals:
                    return `Consumption of breads & cereals accounts for ${percentage}% of your total`;
                case GroceryType.Vegetables:
                    return `Consumption of vegetables accounts for ${percentage}% of your total`;
                case GroceryType.ProcessedFood:
                    return `Consumption of processed foods accounts for ${percentage}% of your total`;
                case GroceryType.NonAlcoholicDrinks:
                    return `Consumption of non-alcoholic drinks accounts for ${percentage}% of your total`;
                case GroceryType.AlcoholicDrinks:
                    return `Consumption of alcoholic drinks accounts for ${percentage}% of your total`;
                case GroceryType.DiningOutOrTakeaway:
                    return `Consumption of dining out & takeaway accounts for ${percentage}% of your total`;
            }
            return `Food and drink consumption accounts for ${percentage}% of your total`;
        case CategoryType.Energy:
            switch (item.type) {
                case EnergyType.Electricity:
                    return `Electicity consumption accounts for ${percentage}% of your total`;
                case EnergyType.NaturalGas:
                    return `Natural gas consumption accounts for ${percentage}% of your total`;
                case EnergyType.LPG:
                    return `LPG consumption accounts for ${percentage}% of your total`;
                case EnergyType.Firewood:
                    return `Firewood consumption accounts for ${percentage}% of your total`;
            }
            break;
        case CategoryType.Travel:
            switch (item.type) {
                case TravelType.DomesticAir:
                    return `Domestic air travel accounts for ${percentage}% of your total`;
                case TravelType.InternationalAir:
                    return `International air travel accounts for ${percentage}% of your total`;
                case TravelType.Train:
                    return `Long distance train travel accounts for ${percentage}% of your total`;
                case TravelType.CruiseShip:
                    return `Cruise ship travel accounts for ${percentage}% of your total`;
                case TravelType.Coach:
                    return `Coach travel accounts for ${percentage}% of your total`;
                case TravelType.RoadTrip: {
                    switch (item.subtype) {
                        case TransportType.Car:
                            return `Road trips in Car ${item.index + 1} account for ${percentage}% of your total`;
                        case TransportType.SUV:
                            return `Road trips in SUV ${item.index + 1} account for ${percentage}% of your total`;
                        case TransportType.Van:
                            return `Road trips in People Mover ${
                                item.index + 1
                            } account for ${percentage}% of your total`;
                        case TransportType.Motorcycle:
                            return `Road trips on Motorcycle ${
                                item.index + 1
                            } account for ${percentage}% of your total`;
                    }
                }
            }
            break;
        case CategoryType.Water:
            return `Water consumption accounts for ${percentage}% of your total`;
        case CategoryType.Waste:
            return `General waste accounts for ${percentage}% of your total`;
    }
}

export { getOptionATitle };
