import {
    DomesticAirTravelType,
    InternationalAirTravelType,
    TravelType,
    TrainTravelType,
    CruiseShipTravelType,
    CoachTravelType,
    RoadTripTransportType,
} from 'src/models/travel';
import { CategoryType } from 'src/models';
import { TravelState } from 'src/store';
import { TravelListItemType } from '.';
import { CalculationType, TransportType, VehicleCalculation } from 'src/models/transport';
import capitalize from 'src/utils/capitalize';

function getTravelTitle(item: TravelListItemType): string {
    switch (item.type) {
        case TravelType.DomesticAir:
            return `Domestic Air - ${capitalize(item.subtype)}`;
        case TravelType.InternationalAir:
            return `International Air - ${capitalize(item.subtype)}`;
        case TravelType.Train:
            return `Train - ${capitalize(item.subtype)}`;
        case TravelType.CruiseShip:
            return `Cruise Ship - ${capitalize(item.subtype)}`;
        case TravelType.Coach:
            return `Coach`;
        case TravelType.RoadTrip:
            switch (item.subtype) {
                case TransportType.Car:
                    return `Road trip - Car ${item.index + 1}`;
                case TransportType.SUV:
                    return `Road trip - SUV ${item.index + 1}`;
                case TransportType.Van:
                    return `Road trip - People Mover ${item.index + 1}`;
                case TransportType.Motorcycle:
                    return `Road trip - Motorcycle ${item.index + 1}`;
            }
    }
}

function getVehicleSubtitle(vehicle: VehicleCalculation): string {
    switch (vehicle.calculation) {
        case CalculationType.Distance:
            return `${vehicle.distance.toLocaleString('en-AU', { maximumFractionDigits: 2 })} km`;
        case CalculationType.Fuel:
            return `${vehicle.consumption.toLocaleString('en-AU', { maximumFractionDigits: 2 })} L`;
    }
}

function getTravelSubtitle(item: TravelListItemType): string {
    switch (item.type) {
        case TravelType.DomesticAir:
        case TravelType.InternationalAir:
        case TravelType.Train:
            return `${item.duration.toLocaleString('en-AU', { maximumFractionDigits: 2 })} hours`;
        case TravelType.CruiseShip:
            return `${item.duration.toLocaleString('en-AU', { maximumFractionDigits: 2 })} days`;
        case TravelType.Coach:
            return `${item.duration.toLocaleString('en-AU', { maximumFractionDigits: 2 })} hours`;
        case TravelType.RoadTrip:
            return getVehicleSubtitle(item);
    }
}

function getTravelTotal(state: TravelState) {
    const { selected, [TravelType.RoadTrip]: roadtrip } = state;
    const { vehicleCount } = roadtrip;

    return Array.from(selected.values()).reduce((a, b) => {
        switch (b) {
            case TravelType.Coach:
            case TravelType.CruiseShip:
            case TravelType.DomesticAir:
            case TravelType.InternationalAir:
            case TravelType.Train:
                return (
                    a +
                    Object.values(state[b]).reduce((a1, b1) => {
                        return a1 + b1.emissions;
                    }, 0)
                );
            case TravelType.RoadTrip:
                return (
                    a +
                    Object.entries(vehicleCount).reduce((p, [key, value]) => {
                        const type = key as RoadTripTransportType;
                        return (
                            p +
                            roadtrip[type].slice(0, value).reduce((a1, b1) => {
                                return a1 + b1.emissions;
                            }, 0)
                        );
                    }, 0)
                );
        }
    }, 0);
}

function getTravelList(state: TravelState): Array<TravelListItemType> {
    return Array.from(state.selected).flatMap<TravelListItemType>(item => {
        switch (item) {
            case TravelType.DomesticAir:
                return Object.entries(DomesticAirTravelType).map(([key, value]) => {
                    return {
                        ...state[TravelType.DomesticAir][value],
                        key: `travel:domestic:${key}`,
                        category: CategoryType.Travel,
                        type: TravelType.DomesticAir,
                        subtype: value,
                    };
                });
            case TravelType.InternationalAir:
                return Object.entries(InternationalAirTravelType).map(([key, value]) => {
                    return {
                        ...state[TravelType.InternationalAir][value],
                        key: `travel:international:${key}`,
                        category: CategoryType.Travel,
                        type: TravelType.InternationalAir,
                        subtype: value,
                    };
                });
            case TravelType.Train:
                return Object.entries(TrainTravelType).map(([key, value]) => {
                    return {
                        ...state[TravelType.Train][value],
                        key: `travel:train:${key}`,
                        category: CategoryType.Travel,
                        type: TravelType.Train,
                        subtype: value,
                    };
                });
            case TravelType.CruiseShip:
                return [
                    {
                        ...state[TravelType.CruiseShip][CruiseShipTravelType.Cabin],
                        key: `travel:cruise:cabin`,
                        category: CategoryType.Travel,
                        type: TravelType.CruiseShip,
                        subtype: CruiseShipTravelType.Cabin,
                    },
                ];
            case TravelType.Coach:
                return [
                    {
                        ...state[TravelType.Coach][CoachTravelType.Coach],
                        key: `travel:coach:coach`,
                        category: CategoryType.Travel,
                        type: TravelType.Coach,
                        subtype: CoachTravelType.Coach,
                    },
                ];
            case TravelType.RoadTrip:
                const { vehicleCount } = state[TravelType.RoadTrip];
                return [
                    TransportType.Car,
                    TransportType.SUV,
                    TransportType.Van,
                    TransportType.Motorcycle,
                ].flatMap<TravelListItemType>(key => {
                    switch (key) {
                        case TransportType.Car:
                            return state[TravelType.RoadTrip][TransportType.Car]
                                .slice(0, vehicleCount[TransportType.Car])
                                .map((vehicle, index) => {
                                    return {
                                        ...vehicle,
                                        key: `travel:road-trip:${TransportType.Car}:${index}`,
                                        category: CategoryType.Travel,
                                        type: TravelType.RoadTrip,
                                        subtype: TransportType.Car,
                                        index,
                                    };
                                });
                        case TransportType.SUV:
                            return state[TravelType.RoadTrip][TransportType.SUV]
                                .slice(0, vehicleCount[TransportType.SUV])
                                .map((vehicle, index) => {
                                    return {
                                        ...vehicle,
                                        key: `travel:road-trip:${TransportType.SUV}:${index}`,
                                        category: CategoryType.Travel,
                                        type: TravelType.RoadTrip,
                                        subtype: TransportType.SUV,
                                        index,
                                    };
                                });
                        case TransportType.Van:
                            return state[TravelType.RoadTrip][TransportType.Van]
                                .slice(0, vehicleCount[TransportType.Van])
                                .map((vehicle, index) => {
                                    return {
                                        ...vehicle,
                                        key: `travel:road-trip:${TransportType.Van}:${index}`,
                                        category: CategoryType.Travel,
                                        type: TravelType.RoadTrip,
                                        subtype: TransportType.Van,
                                        index,
                                    };
                                });
                        case TransportType.Motorcycle:
                            return state[TravelType.RoadTrip][TransportType.Motorcycle]
                                .slice(0, vehicleCount[TransportType.Motorcycle])
                                .map((motorcycle, index) => {
                                    return {
                                        ...motorcycle,
                                        key: `travel:road-trip:${TransportType.Motorcycle}:${index}`,
                                        category: CategoryType.Travel,
                                        type: TravelType.RoadTrip,
                                        subtype: TransportType.Motorcycle,
                                        index,
                                    };
                                });
                    }
                    return [];
                });
        }
    });
}

export { getTravelList, getTravelTotal, getTravelTitle, getTravelSubtitle };
