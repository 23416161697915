import React from 'react';
import { TextNew } from 'src/components/shared/TextNew';
import { List } from './List';
import { Total } from './Total';
import { Footer } from './Footer';
import styles from './index.module.sass';
import { Row } from '../shared/Row';

const YourImpact: React.FC = () => {
    return (
        <div className={styles.root}>
            <Row className={styles.headingContainer} verticalAlignment="center" horizontalAlignment="left">
                <TextNew tagName={'h3'} className={styles.heading} textSize={'XL'} textWeight={700}>
                    Your Footprint
                </TextNew>
                <TextNew tagName={'span'} className={styles.units} textWeight={700}>
                    tCO<sub>2</sub>e
                </TextNew>
            </Row>
            <List className={styles.list} />
            <Total className={styles.total} />
            <Footer />
        </div>
    );
};

export { YourImpact };
