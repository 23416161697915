export default function stringify(state: any) {
    function replacer(key: string, value: any) {
        if (value instanceof Map) {
            return Array.from(value.entries());
        }
        if (value instanceof Set) {
            return Array.from(value.values());
        }
        return value;
    }
    return JSON.stringify(state, replacer);
}
