import { TravelState } from 'src/store';
import stringify from './stringify';

const deserializeTravelState = (json: string): TravelState => {
    return JSON.parse(json, (key, value) => {
        switch (key) {
            case 'isUpdating':
                return false;
        }
        return value;
    });
};

const serializeTravelState = (state: TravelState): string => {
    return stringify(state);
};

export { serializeTravelState, deserializeTravelState };
