import React from 'react';
import { List } from './List';
import { KeyInsightsList } from './KeyInsightsList';
import { Footnote } from './Footnote';
import { Grid } from '../../shared/Grid';
import { useKeyInsights } from './useKeyInsights';
import styles from './KeyInsights.module.sass';

const KeyInsights: React.FC = () => {
    const insights = useKeyInsights();
    const highlight = insights.filter(insight => insight.key).map(insight => insight.key!);
    return (
        <div className={styles.root}>
            <Grid className={styles.grid} columns={{ default: 1, sm: 1, md: 2 }}>
                <List className={styles.col1} showCategoryLegend={false} highlight={highlight} />
                <div className={styles.col2}>
                    <KeyInsightsList className={styles.insights} insights={insights} />
                    <Footnote className={styles.footnote} />
                </div>
            </Grid>
        </div>
    );
};

export { KeyInsights };
