import React from 'react';
import { NavigationPage } from '../NavigationPage';
import { ReactComponent as Car } from './Car.svg';
import { ReactComponent as SUV } from './SUV.svg';
import { ReactComponent as Van } from './Van.svg';
import { ReactComponent as Motorcycle } from './Motorcycle.svg';
import { ReactComponent as Bus } from './Bus.svg';
import { RootState } from 'src/store';
import { useSelector, useDispatch } from 'react-redux';
import { TransportType } from 'src/models/transport';
import { ActionType } from 'src/store/actions/transport';
import { Grid } from 'src/components/shared/Grid';
import { CheckboxControl } from 'src/components/shared/CheckboxControl';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { useTimeframe } from 'src/store/utils/useTimeframe';
import styles from './Transport.module.sass';

const Transport: React.FC = () => {
    const dispatch = useDispatch();
    const { timeframeText } = useTimeframe();
    const selected = useSelector<RootState, Array<TransportType>>(state => state.calculation.transport.selected);
    return (
        <>
            <NavigationPage
                title="Transport"
                description={`Over the past ${timeframeText}, which modes of transport has your household used to travel around your local area?`}
                subtitle="Select all that apply"
                tooltip='This section relates to typical commuting around your local area only. You can account for long-distance or "out of the ordinary" trips in the Travel section.'
                enableBack={true}
                enableNext={selected.length > 0}>
                <div className={styles.root}>
                    <Grid columns={{ default: 2, xs: 2, sm: 3, md: 3 }}>
                        <CheckboxControl
                            title="Car"
                            subtitle="Small hatchbacks, sedans &amp; station wagons"
                            icon={<Car className={styles.icon} />}
                            checked={selected.includes(TransportType.Car)}
                            onChecked={checked =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: TransportType.Car,
                                        selected: checked,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            title="SUV"
                            subtitle="Sports utility vehicles &amp; 4WDs"
                            icon={<SUV className={styles.icon} />}
                            checked={selected.includes(TransportType.SUV)}
                            onChecked={checked =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: TransportType.SUV,
                                        selected: checked,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            title="People Mover"
                            subtitle="Family vans"
                            icon={<Van className={styles.icon} />}
                            checked={selected.includes(TransportType.Van)}
                            onChecked={checked =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: TransportType.Van,
                                        selected: checked,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            title="Motorcycle"
                            subtitle="Motorcycles &amp; scooters"
                            icon={<Motorcycle className={styles.icon} />}
                            checked={selected.includes(TransportType.Motorcycle)}
                            onChecked={checked =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: TransportType.Motorcycle,
                                        selected: checked,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            title="Public Transport"
                            subtitle="Taxis, trains, buses, trams, &amp; ferries"
                            icon={<Bus className={styles.icon} />}
                            checked={selected.includes(TransportType.PublicTransport)}
                            onChecked={checked =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: TransportType.PublicTransport,
                                        selected: checked,
                                    },
                                })
                            }
                        />
                    </Grid>
                </div>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'Road transport accounts for around <strong>12%</strong> of global greenhouse gas emissions.',
                    "There are over <strong>13 million passenger</strong> vehicles in Australia. If they were parked end-to-end the traffic jam would stretch almost <strong>55,000 kilometres</strong> - that's 13 times the distance from Sydney to Perth!",
                    'In Australia, more than <strong>65%</strong> of the workforce commute by private car. Only <strong>10%</strong> of commuters rely on public transport.',
                ]}
            />
        </>
    );
};

export { Transport };
