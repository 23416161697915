import { CategoryType } from 'src/models';
import { NavigationKey } from 'src/store/reducers/navigationReducer';

function getCorrespondingNavigationKeys(type: CategoryType): Array<NavigationKey> {
    switch (type) {
        case CategoryType.Transport:
            return [NavigationKey.Transport, NavigationKey.TransportVehicles];
        case CategoryType.Energy:
            return [NavigationKey.Energy, NavigationKey.EnergyElectricity, NavigationKey.EnergyNaturalGas];
        case CategoryType.Water:
            return [NavigationKey.Water];
        case CategoryType.Travel:
            return [NavigationKey.Travel];
        case CategoryType.Groceries:
            return [NavigationKey.Groceries];
        case CategoryType.Waste:
            return [NavigationKey.Waste];
    }
}

export { getCorrespondingNavigationKeys };
