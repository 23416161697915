import { store } from 'src/store';
import { serializeEnergyState } from 'src/store/serializers/energySerializers';

const flushEnergyState = () => {
    const {
        calculation: { energy },
    } = store.getState();
    try {
        localStorage.setItem('cpos:energy', serializeEnergyState(energy));
    } catch (err) {
        // TODO: update state with error
    }
};

export { flushEnergyState };
