import { ThunkAction } from 'redux-thunk';
import { TransportType, CalculationType, MotorcycleEmissions, FuelType } from 'src/models/transport';
import { ActionType, Actions } from '.';
import { ReduxThunkAction } from 'src/store';
import strip from 'src/utils/strip';
import { TravelType } from 'src/models/travel';
import { ConfigurationType } from 'src/configuration';

function calculateEmissions(motorcycle: MotorcycleEmissions, configuration: ConfigurationType): number {
    const factor = configuration.emissions.transport.vehicles[FuelType.Petrol];
    switch (motorcycle.calculation) {
        case CalculationType.Fuel:
            return factor * motorcycle.consumption;
        case CalculationType.Distance:
            const fuelEconomy = configuration.averages.transport.economy[TransportType.Motorcycle];
            return (motorcycle.distance / 100) * fuelEconomy * factor;
    }
}

type UpdateRoadTripMotorcycleProps = {
    index: number;
    calculation?: CalculationType;
    consumption?: number;
    distance?: number;
    isAverage?: boolean;
};

const updateRoadTripMotorcycle = (props: UpdateRoadTripMotorcycleProps): ReduxThunkAction<Actions> => {
    const { index } = props;
    return (dispatch, getState, configuration) => {
        dispatch({
            type: ActionType.UPDATE_ROADTRIP,
            payload: {
                type: TransportType.Motorcycle,
                ...strip(props),
            },
        });

        const {
            calculation: {
                travel: {
                    [TravelType.RoadTrip]: {
                        [TransportType.Motorcycle]: { [index]: motorcycle },
                    },
                },
            },
        } = getState();

        dispatch({
            type: ActionType.UPDATE_ROADTRIP_EMISSIONS,
            payload: {
                type: TransportType.Motorcycle,
                index,
                emissions: calculateEmissions(motorcycle, configuration),
            },
        });
    };
};

export { updateRoadTripMotorcycle };
