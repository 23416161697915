import React, { useRef } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { CategoryType, TimeframeType } from 'src/models';
import { Category } from './Category';
import { useScrollbarVisibility } from 'src/utils/useScrollbarVisibility';
import getEnergyTotal from 'src/store/selectors/getEnergyTotal';
import getEnergyList from 'src/store/selectors/getEnergyList';
import { getEnergyTitle, getEnergySubtitle } from 'src/libs/energy';
import getTransportTotal from 'src/store/selectors/getTransportTotal';
import getTransportList from 'src/store/selectors/getTransportList';
import { getTransportTitle, getTransportSubtitle } from 'src/libs/transport';
import getWaterTotal from 'src/store/selectors/getWaterTotal';
import getWaterList from 'src/store/selectors/getWaterList';
import getTravelTotal from 'src/store/selectors/getTravelTotal';
import getTravelList from 'src/store/selectors/getTravelList';
import { getTravelTitle, getTravelSubtitle } from 'src/libs/travel';
import getGroceriesTotal from 'src/store/selectors/getGroceriesTotal';
import getGroceriesList from 'src/store/selectors/getGroceriesList';
import getWasteTotal from 'src/store/selectors/getWasteTotal';
import getWasteList from 'src/store/selectors/getWasteList';
import { ListItem } from './ListItem';
import { getWaterTitle, getWaterSubtitle } from 'src/libs/water';
import { getGroceriesTitle, getGroceriesSubtitle } from 'src/libs/groceries';
import { getWasteTitle } from 'src/libs/waste';
import styles from './List.module.sass';
import { FuelType, TransportType } from 'src/models/transport';
import { TransportListItemType } from 'src/libs';

const TravelList: React.FC = () => {
    const total = useSelector(getTravelTotal);
    const items = useSelector(getTravelList);
    if (items.length === 0) {
        return null;
    }
    return (
        <Category type={CategoryType.Travel} title="Travel" total={total}>
            {items
                .filter(item => item.emissions !== 0)
                .map(item => (
                    <ListItem
                        key={item.key}
                        title={getTravelTitle(item)}
                        subtitle={getTravelSubtitle(item)}
                        total={item.emissions}
                    />
                ))}
        </Category>
    );
};

const EnergyList: React.FC = () => {
    const total = useSelector(getEnergyTotal);
    const items = useSelector(getEnergyList);
    if (items.length === 0) {
        return null;
    }
    return (
        <Category type={CategoryType.Energy} title="Energy" total={total}>
            {items
                .filter(item => item.emissions !== 0)
                .map(item => (
                    <ListItem
                        key={item.key}
                        title={getEnergyTitle(item)}
                        subtitle={getEnergySubtitle(item)}
                        total={item.emissions}
                    />
                ))}
        </Category>
    );
};

const WaterList: React.FC = () => {
    const timeframe = useSelector<RootState, TimeframeType>(state => state.calculation.metadata.timeframe);
    const total = useSelector(getWaterTotal);
    const items = useSelector(getWaterList);
    if (items.length === 0 || total === 0) {
        return null;
    }
    return (
        <Category type={CategoryType.Water} title="Water" total={total}>
            <ListItem
                key={items[0].key}
                title={getWaterTitle(items[0])}
                subtitle={getWaterSubtitle(items[0], timeframe)}
                total={items[0].emissions}
            />
        </Category>
    );
};

const TransportList: React.FC = () => {
    const total = useSelector(getTransportTotal);
    const items = useSelector(getTransportList);
    if (items.length === 0) {
        return null;
    }

    const transportFilter = (item: TransportListItemType) => {
        if (item.type === TransportType.Car && item.fuel === FuelType.Electric) {
            return item.fuel === FuelType.Electric || item.emissions !== 0;
        }
        return item.emissions !== 0;
    };

    return (
        <Category type={CategoryType.Transport} title="Transport" total={total}>
            {items.filter(transportFilter).map(item => (
                <ListItem
                    key={item.key}
                    title={getTransportTitle(item)}
                    subtitle={getTransportSubtitle(item)}
                    total={item.emissions}
                />
            ))}
        </Category>
    );
};

const GroceriesList: React.FC = () => {
    const total = useSelector(getGroceriesTotal);
    const items = useSelector(getGroceriesList);
    if (items.length === 0) {
        return null;
    }
    return (
        <Category type={CategoryType.Groceries} title="Food &amp; Drink" total={total}>
            {items
                .filter(item => item.emissions !== 0)
                .map(item => (
                    <ListItem
                        key={item.key}
                        title={getGroceriesTitle(item)}
                        subtitle={getGroceriesSubtitle(item)}
                        total={item.emissions}
                    />
                ))}
        </Category>
    );
};

const WasteList: React.FC = () => {
    const total = useSelector(getWasteTotal);
    const items = useSelector(getWasteList);
    if (items.length === 0 || total === 0) {
        return null;
    }
    return (
        <Category type={CategoryType.Waste} title="Waste" total={total}>
            <ListItem key={items[0].key} title={getWasteTitle(items[0])} total={items[0].emissions} />
        </Category>
    );
};

type ListProps = {
    className: string;
};

const List: React.FC<ListProps> = (props: ListProps) => {
    const { className } = props;
    const ref = useRef<HTMLOListElement>(null);
    const selected = useSelector<RootState, Array<CategoryType>>(state => state.calculation.categories.selected);
    const { y } = useScrollbarVisibility(ref, [selected]);
    return (
        <ol
            ref={ref}
            className={classNames(className, styles.root, {
                [styles.scrollable]: y,
            })}>
            {selected.includes(CategoryType.Travel) && <TravelList />}
            {selected.includes(CategoryType.Energy) && <EnergyList />}
            {selected.includes(CategoryType.Water) && <WaterList />}
            {selected.includes(CategoryType.Transport) && <TransportList />}
            {selected.includes(CategoryType.Groceries) && <GroceriesList />}
            {selected.includes(CategoryType.Waste) && <WasteList />}
        </ol>
    );
};

export { List };
