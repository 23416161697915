import { Actions, ActionType } from '../actions/energy';
import { EnergyType, EnergyEmissions, RegionalEnergyEmissions } from 'src/models/energy';

export type EnergyState = {
    selected: Array<EnergyType>;
    [EnergyType.Electricity]: RegionalEnergyEmissions;
    [EnergyType.NaturalGas]: RegionalEnergyEmissions;
    [EnergyType.LPG]: EnergyEmissions;
    [EnergyType.Firewood]: EnergyEmissions;
};

const initialState = {
    selected: [],
    [EnergyType.Electricity]: {
        region: undefined,
        consumption: 0,
        isAverage: false,
        emissions: 0,
    },
    [EnergyType.NaturalGas]: {
        region: undefined,
        consumption: 0,
        isAverage: false,
        emissions: 0,
    },
    [EnergyType.LPG]: {
        consumption: 0,
        emissions: 0,
    },
    [EnergyType.Firewood]: {
        consumption: 0,
        emissions: 0,
    },
};

const energyReducer = (state: EnergyState = initialState, action: Actions) => {
    switch (action.type) {
        case ActionType.RESET:
            return initialState;
        case ActionType.SET_SELECTED:
            if (action.payload.selected && state.selected.includes(action.payload.type) === false) {
                return {
                    ...state,
                    selected: [...state.selected, action.payload.type],
                };
            }
            if (action.payload.selected === false && state.selected.includes(action.payload.type)) {
                return {
                    ...state,
                    selected: state.selected.filter(v => v !== action.payload.type),
                };
            }
            break;
        case ActionType.UPDATE_REGION:
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    region: action.payload.region,
                },
            };
        case ActionType.UPDATE_CONSUMPTION:
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    consumption: action.payload.consumption,
                    isAverage: action.payload.isAverage,
                },
            };
        case ActionType.UPDATE_EMISSIONS:
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    emissions: action.payload.emissions,
                },
            };
    }
    return state;
};

export { energyReducer };
