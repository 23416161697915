import React from 'react';
import classNames from 'classnames';
import styles from './TextInput.module.sass';

type TextInputProps = {
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    value?: string;
    onValueChange?: (value: string) => void;
};

const TextInput = (props: TextInputProps) => {
    const { className, placeholder, disabled = false, value, onValueChange } = props;
    return (
        <input
            className={classNames(className, styles.root)}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={event => {
                onValueChange && onValueChange(event.currentTarget.value);
            }}
        />
    );
};

export { TextInput };
