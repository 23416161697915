import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { TimeframeType } from 'src/models';
import getTimeframeText from 'src/utils/getTimeframeText';

type UseTimeframeReturn = {
    timeframe: TimeframeType;
    timeframeText: string;
};

const useTimeframe = (): UseTimeframeReturn => {
    const timeframe = useSelector<RootState, TimeframeType>(state => state.calculation.metadata.timeframe);
    return { timeframe, timeframeText: getTimeframeText(timeframe) };
};

export { useTimeframe };
