const event = (action: string, label: string) => {
    const w = window as any;
    if (w.gtag) {
        w.gtag('event', action, {
            event_category: 'carbon-calculator',
            event_label: label,
        });
    }
};

export { event };
