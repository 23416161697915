import { EmissionsElement } from '.';

export enum WaterRegion {
    NSW = 'nsw',
    ACT = 'act',
    VIC = 'vic',
    QLD = 'qld',
    SA = 'sa',
    TAS = 'tas',
    WA = 'wa',
    NT = 'nt',
    Australia = 'australia',
}

export type WaterEmissions = EmissionsElement & {
    region?: WaterRegion;
    consumption: number;
    isAverage: boolean;
};
