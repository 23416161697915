import { CategoryType, TimeframeType } from 'src/models';
import { GroceryType } from 'src/models/groceries';
import { EmissionsListItemType } from 'src/libs';
import { EnergyRegion, EnergyType } from 'src/models/energy';
import { TravelType } from 'src/models/travel';
import { CalculationType, PublicTransportType, TransportType } from 'src/models/transport';
import { Conversion } from '../conversion';
import capitalize from 'src/utils/capitalize';
import round from 'src/utils/round';

function formatNumber(value: number, round: boolean): string {
    return value.toLocaleString('en-AU', { maximumFractionDigits: round ? 0 : 2 });
}

function getOptionBorCTitle(item: EmissionsListItemType, timeframe: TimeframeType): string {
    switch (item.category) {
        case CategoryType.Transport:
            switch (item.type) {
                case TransportType.Car:
                case TransportType.SUV:
                case TransportType.Van:
                    const friendlyName =
                        item.type === TransportType.Car
                            ? 'Car'
                            : item.type === TransportType.SUV
                            ? 'SUV'
                            : 'People Mover';
                    switch (item.calculation) {
                        case CalculationType.Distance:
                            const distance = item.distance * Conversion.weekly[timeframe];
                            return `${friendlyName} ${item.index + 1} travelled ${formatNumber(distance, false)} km`;
                        case CalculationType.Fuel:
                            const fuel = item.consumption * Conversion.weekly[timeframe];
                            return `${friendlyName} ${item.index + 1} used ${formatNumber(
                                fuel,
                                false,
                            )} L of ${capitalize(item.fuel)}`;
                    }
                    break;
                case TransportType.Motorcycle:
                    switch (item.calculation) {
                        case CalculationType.Distance:
                            const distance = item.distance * Conversion.weekly[timeframe];
                            return `Motorcycle ${item.index + 1} travelled ${formatNumber(distance, false)} km`;
                        case CalculationType.Fuel:
                            const fuel = item.consumption * Conversion.weekly[timeframe];
                            return `Motorcycle ${item.index + 1} used ${formatNumber(fuel, false)} L of fuel`;
                    }
                    break;
                case TransportType.PublicTransport:
                    switch (item.subtype) {
                        case PublicTransportType.Bus:
                            return `Your household travelled ${item.distance} km by taxi/rideshare`;
                        case PublicTransportType.Ferry:
                            return `Your household travelled ${item.distance} km by ferry`;
                        case PublicTransportType.Taxi:
                            return `Your household travelled ${item.distance} km by taxi`;
                        case PublicTransportType.Train:
                            return `Your household travelled ${item.distance} km by train`;
                        case PublicTransportType.Tram:
                            return `Your household travelled ${item.distance} km by tram`;
                    }
                    break;
            }
            break;
        case CategoryType.Groceries:
            const amount = formatNumber(round(item.amount * Conversion.weekly[timeframe]), true);
            switch (item.type) {
                case undefined:
                    return `Your household consumed $${amount} worth of food & drink`;
                case GroceryType.Meat:
                    return `Your household consumed $${amount} worth of meat`;
                case GroceryType.EggsAndDairy:
                    return `Your household consumed $${amount} worth of eggs & dairy`;
                case GroceryType.Seafood:
                    return `Your household consumed $${amount} worth of seafood`;
                case GroceryType.FruitAndNuts:
                    return `Your household consumed $${amount} worth of fruit & nuts`;
                case GroceryType.BreadAndCereals:
                    return `Your household consumed $${amount} worth of bread & cereals`;
                case GroceryType.Vegetables:
                    return `Your household consumed $${amount} worth of vegetables`;
                case GroceryType.ProcessedFood:
                    return `Your household consumed $${amount} worth of processed foods`;
                case GroceryType.NonAlcoholicDrinks:
                    return `Your household consumed $${amount} worth of non-alcholic drinks`;
                case GroceryType.AlcoholicDrinks:
                    return `Your household consumed $${amount} worth of alcoholic drinks`;
                case GroceryType.DiningOutOrTakeaway:
                    return `Your household consumed $${amount} worth of dining out & takeaway`;
            }
        case CategoryType.Energy:
            switch (item.type) {
                case EnergyType.Electricity:
                    return `Your household used ${formatNumber(item.consumption, false)} kWh of electricity`;
                case EnergyType.NaturalGas:
                    const units = item.region === EnergyRegion.WA ? 'kwh' : 'MJ';
                    return `Your household used ${formatNumber(item.consumption, false)} ${units} of natural gas`;
                case EnergyType.LPG:
                    return `Your household used ${formatNumber(item.consumption, false)} kg of LPG`;
                case EnergyType.LPG:
                    return `Your household used ${formatNumber(item.consumption, false)} kg of firewood`;
            }
            break;
        case CategoryType.Travel:
            switch (item.type) {
                case TravelType.DomesticAir:
                    return `Your household flew a total of ${formatNumber(item.duration, false)} hours domestically`;
                case TravelType.InternationalAir:
                    return `Your household flew a total of ${formatNumber(item.duration, false)} hours internationally`;
                case TravelType.Train:
                    return `Your household travelled a total of ${formatNumber(
                        item.duration,
                        false,
                    )} hours by long-distance train`;
                case TravelType.CruiseShip:
                    return `Your household cruised for a total of ${formatNumber(item.duration, false)} days`;
                case TravelType.Coach:
                    return `Your household travelled a total of ${formatNumber(item.duration, false)} hours by coach`;
                case TravelType.RoadTrip: {
                    switch (item.subtype) {
                        case TransportType.Car:
                        case TransportType.SUV:
                        case TransportType.Van:
                            const friendlyName =
                                item.subtype === TransportType.Car
                                    ? 'Car'
                                    : item.subtype === TransportType.SUV
                                    ? 'SUV'
                                    : 'People Mover';
                            switch (item.calculation) {
                                case CalculationType.Distance:
                                    return `Your household travelled ${formatNumber(
                                        item.distance,
                                        false,
                                    )} km in ${friendlyName} ${item.index + 1} when road tripping`;
                                case CalculationType.Fuel:
                                    return `Road trips in ${friendlyName} ${item.index + 1} used ${formatNumber(
                                        item.consumption,
                                        false,
                                    )} L of ${capitalize(item.fuel)}`;
                            }
                            break;
                        case TransportType.Motorcycle:
                            switch (item.calculation) {
                                case CalculationType.Distance:
                                    return `Your household travelled ${formatNumber(
                                        item.distance,
                                        false,
                                    )} km on Motorcycle ${item.index + 1} when road tripping`;
                                case CalculationType.Fuel:
                                    return `Road trips on Motorcycle ${item.index + 1} used ${formatNumber(
                                        item.consumption,
                                        false,
                                    )} L of fuel`;
                            }
                            break;
                    }
                }
            }
            break;
        case CategoryType.Water:
            return `Your household used ${formatNumber(item.consumption, false)} kL of water`;
        case CategoryType.Waste:
            return `Your household waste produced ${formatNumber(round(item.emissions / 1000), false)} tCO2e`;
    }
    return '';
}

export { getOptionBorCTitle };
