import React from 'react';
import { TextNew } from 'src/components/shared/TextNew';
import formatNumber from 'src/utils/formatNumber';
import styles from './ListItem.module.sass';

type ListItemProps = {
    title: string;
    subtitle?: string;
    total: number;
};

const ListItem: React.FC<ListItemProps> = (props: ListItemProps) => {
    const { title, subtitle, total } = props;
    return (
        <li className={styles.root}>
            <div className={styles.column}>
                <TextNew className={styles.title} textSize={'XS'}>
                    {title}
                </TextNew>
                {subtitle && (
                    <TextNew className={styles.subtitle} textSize={'XXS'}>
                        {subtitle}
                    </TextNew>
                )}
            </div>
            <TextNew className={styles.total} textSize={'XS'} textWeight={300}>
                {formatNumber(total / 1000, { minimum: total > 0 ? 0.01 : 0, fixedWidth: true })}
            </TextNew>
        </li>
    );
};

export { ListItem };
