import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Header } from './Header';
import { Copyright } from './Copyright';
import { Separator } from './Separator';
import { useUI } from '../UI';
import { AtLeastXS } from '../../utils/breakpoints';
import { useNavigationState } from '../shared/Navigation';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { YourImpact } from '../your-impact';
import { Footer } from './Footer';
import { AnimatePresence } from 'framer-motion';
import { Backdrop } from '../shared/Backdrop';
import styles from './Layout.module.sass';

type LayoutProps = {
    children: ReactNode;
};

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
    const { children } = props;
    const { isSidebarOpen, closeSidebar } = useUI();
    const { index: rootNavigationIndex } = useNavigationState(NavigationKey.Root);
    const { index: mainNavigationIndex } = useNavigationState(NavigationKey.Main);

    // TODO: the Footer should be moved inside the CategoryRoot component such that
    // we only display it when the CategoryRoot is being displayed
    const inCategoryLayout = rootNavigationIndex === 0 && mainNavigationIndex === 2;

    return (
        <>
            <div className={styles.container}>
                <Separator size="large" />
                <Header />
                <div className={styles.body}>{children}</div>
                {inCategoryLayout && <Footer />}
                <AtLeastXS>
                    <Separator size="small" />
                </AtLeastXS>
                <Copyright />
            </div>
            {inCategoryLayout && (
                <>
                    <div
                        data-visible={isSidebarOpen}
                        className={classNames(styles.sidebar, {
                            open: isSidebarOpen,
                            closed: isSidebarOpen === false,
                        })}>
                        <YourImpact />
                    </div>
                    <AnimatePresence>{isSidebarOpen && <Backdrop onClick={() => closeSidebar()} />}</AnimatePresence>
                </>
            )}
        </>
    );
};

export { Layout };
