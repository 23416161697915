import React from 'react';
import classNames from 'classnames';
import { TextNew } from 'src/components/shared/TextNew';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import getTotal from 'src/store/selectors/getTotal';
import { OffsetButton } from './OffsetButton';
import round from 'src/utils/round';
import styles from './Footnote.module.sass';
import formatNumber from 'src/utils/formatNumber';
import { useConfiguration } from 'src/ConfigurationProvider';
import { CalculationState, RootState } from 'src/store';

type FootnoteProps = {
    className: string;
};

const Footnote = (props: FootnoteProps) => {
    const { className } = props;
    const { unitPrice: currentUnitPrice } = useConfiguration();
    let {
        metadata: { unitPrice: previousUnitPrice },
    } = useSelector<RootState, CalculationState>(state => state.calculation);
    const total = useSelector(getTotal);

    const unitPrice = previousUnitPrice ?? currentUnitPrice;

    return (
        <div className={classNames(className, styles.root)}>
            <TextNew className={styles.title} textSize={'L'} tagName="h2" textWeight={700}>
                What you can do about it
            </TextNew>
            <TextNew className={styles.subtitle} textSize={'M'} textWeight={700}>
                Offset your carbon footprint
            </TextNew>
            <TextNew className={styles.content} textSize={'M'} textWeight={400}>
                Trees remove carbon dioxide from the atmosphere. You can offset your footprint by donating to Carbon
                Positive Australia's range of native restoration projects.
            </TextNew>

            <div className={styles.stats}>
                <div className={styles.statsFootprint}>
                    <TextNew className={styles.content} textSize={'XS'} textStyle={'smallCaps'} textWeight={400}>
                        Your Footprint
                    </TextNew>
                    <div>
                        <TextNew textSize={'L'} textWeight={700}>
                            {formatNumber(total / 1000, { fixedWidth: true })}
                        </TextNew>
                        <TextNew textSize={'L'} textWeight={300}>
                            tCO<sub>2</sub>e
                        </TextNew>
                    </div>
                </div>
                <div className={styles.statsOffset}>
                    <TextNew className={styles.content} textSize={'XS'} textStyle={'smallCaps'} textWeight={400}>
                        Offset Amount
                    </TextNew>
                    <NumberFormat
                        value={(total / 1000) * unitPrice}
                        displayType={'text'}
                        decimalScale={2}
                        thousandSeparator={true}
                        renderText={value => (
                            <div>
                                <TextNew textSize={'L'} textWeight={700}>
                                    $
                                </TextNew>
                                <TextNew textSize={'L'} textWeight={300}>
                                    {value}
                                </TextNew>
                            </div>
                        )}
                    />
                </div>
                <div className={styles.statsAction}>
                    <OffsetButton total={round(total)} />
                </div>
            </div>
        </div>
    );
};

export { Footnote };
