import { CategoryType, TimeframeType } from 'src/models';
import { GroceryType } from 'src/models/groceries';
import { EmissionsListItemType } from 'src/libs';
import { EnergyType } from 'src/models/energy';
import { TravelType } from 'src/models/travel';
import { PublicTransportType, TransportType } from 'src/models/transport';

function getDescription(item: EmissionsListItemType): string {
    switch (item.category) {
        case CategoryType.Transport:
            switch (item.type) {
                case TransportType.Car:
                    return 'To reduce your transport emissions, consider alternative options such as walking, cycling or public transport. When choosing your next vehicle, make sure you compare the relative fuel economy of your choices - this can lower your carbon footprint AND save money in the long term!';
                case TransportType.SUV:
                    return 'To reduce your transport emissions, consider alternative options such as walking, cycling or public transport. When choosing your next vehicle, make sure you compare the relative fuel economy of your choices - this can lower your carbon footprint AND save money in the long term!';
                case TransportType.Van:
                    return 'To reduce your transport emissions, consider alternative options such as walking, cycling or public transport. When choosing your next vehicle, make sure you compare the relative fuel economy of your choices - this can lower your carbon footprint AND save money in the long term!';
                case TransportType.Motorcycle:
                    return 'To reduce your transport emissions, consider alternative options such as walking, cycling or public transport. When choosing your next vehicle, make sure you compare the relative fuel economy of your choices - this can lower your carbon footprint AND save money in the long term!';
                case TransportType.PublicTransport:
                    switch (item.subtype) {
                        case PublicTransportType.Taxi:
                            return 'To reduce your transport emissions, consider alternative options such as walking, cycling or public transport. If you do travel by taxi/rideshare, try to split the trip with friends to reduce your individual emissions.';
                        case PublicTransportType.Bus:
                            return 'Using public transport is a great way to lower your carbon footprint. To further reduce your footprint, consider alternative options such as walking or cycling where possible. Electric bikes are great for distances outside of your cycling range!';
                        case PublicTransportType.Tram:
                            return 'Using public transport is a great way to lower your carbon footprint. To further reduce your transport emissions, consider alternative options such as walking or cycling where possible. Electric bikes are great for distances outside of your cycling range!';
                        case PublicTransportType.Train:
                            return 'Using public transport is a great way to lower your carbon footprint. To further reduce your transport emissions, consider alternative options such as walking or cycling where possible. Electric bikes are great for distances outside of your cycling range!';
                        case PublicTransportType.Ferry:
                            return 'Using public transport is a great way to lower your carbon footprint. To further reduce your transport emissions, consider alternative options such as walking or cycling where possible. Electric bikes are great for distances outside of your cycling range!';
                    }
            }
            break;
        case CategoryType.Groceries:
            switch (item.type) {
                case undefined:
                    return "No matter your diet, you can reduce your carbon footprint by buying local, seasonal produce whenever possible. Consider doing smaller, more frequent food shops to minimise waste - this can lower your carbon footprint AND save you money. Find out if there's a community garden near you, or try growing your own fruit and vegetables at home!";
                case GroceryType.Meat:
                    return 'Due to the resources required to farm and process meat, animal-based foods tend to have a higher carbon footprint than plant-based foods. To reduce your footprint, consider increasing the amount of plant-based protein in your diet, or sticking to meats like chicken and pork. Whenever possible, buy locally produced meat to reduce emissions from transportation.';
                case GroceryType.EggsAndDairy:
                    return 'Animal-based foods like eggs & dairy tend to have a higher carbon footprint than plant-based foods due to the resources required to produce them. To reduce your footprint, consider switching to plant-based milks like soy, oat, almond or coconut. Whenever possible, buy local to reduce emissions from transportation.';
                case GroceryType.Seafood:
                    return 'Seafood (along with other meats) tends to have a higher carbon footprint than plant-based foods due to the resources required to catch, farm and process. To reduce your footprint, consider increasing the amount of plant-based protein in your diet. Whenever possible, buy local seafood to reduce emissions from transportation.';
                case GroceryType.FruitAndNuts:
                    return "These products often travel long distances to get to your shopping basket. To reduce your footprint buy local, in-season produce whenever possible. Find out if there's a community garden near you, or even plant some fruit trees at home! Home-grown produce not only tastes great, but spending time in the garden can be great for your mental health.";
                case GroceryType.BreadAndCereals:
                    return 'These products often travel long distances to get to your shopping basket. Australia has introduced mandatory country of origin labelling, making it easier to determine where your food comes from. To reduce your footprint check the label and buy local whenever possible.';
                case GroceryType.Vegetables:
                    return "These products often travel long distances to get to your shopping basket. To reduce your footprint buy local, in-season produce whenever possible. Find out if there's a community garden near you, or try growing your own at home! Home-grown produce not only tastes great, but spending time in the garden can be great for your mental health.";
                case GroceryType.ProcessedFood:
                    return 'These products often travel long distances to get to your shopping basket. Australia has introduced mandatory country of origin labelling, making it easier to determine where your food comes from. To reduce your footprint check the label and buy local whenever possible.';
                case GroceryType.NonAlcoholicDrinks:
                    return 'These products often travel long distances to get to your shopping basket. Australia has introduced mandatory country of origin labelling, making it easier to determine where your food comes from. To reduce your footprint check the label and buy local whenever possible.';
                case GroceryType.AlcoholicDrinks:
                    return 'These products often travel long distances to get to your shopping basket. Australia has introduced mandatory country of origin labelling, making it easier to determine where your food comes from. To reduce your footprint check the label and buy local whenever possible.';
                case GroceryType.DiningOutOrTakeaway:
                    return 'A lot of resources are required to supply a restaurant, café or pub meal... think refrigeration, lighting, gas and transport. To reduce your footprint, consider replicating your favourite meals at home, or choosing establishments that source local, in-season produce where possible.';
            }
            break;
        case CategoryType.Energy:
            switch (item.type) {
                case EnergyType.Electricity:
                    return "There are lots of small behaviour changes you can make to reduce your household's electricity consumption like turning the lights off when you leave a room, using a standby power board, using the 'eco' setting on your dishwasher and washing machine, and setting your air conditioner between 24 and 27 degrees. If you own your own home, consider installing solar panels - this can lower your carbon footprint AND save money in the long term!";
                case EnergyType.NaturalGas:
                    return "There are lots of small behaviour changes you can make to reduce your household's natural gas consumption. In the kitchen, try using a smaller pan if possible, and put a lid on so the food cooks faster. If you have a gas hot water system, conserve hot water by taking shorter showers and installing low-flow shower heads. Set your water heater to a maximum of 50 degrees Celsius.";
                case EnergyType.LPG:
                    return "There are lots of small behaviour changes you can make to reduce your household's LPG consumption. If your hot water system runs on LPG, conserve hot water by taking shorter showers and installing low-flow shower heads. Set your water heater to a maximum of 50 degrees Celsius. When using LPG for outdoor cooking, make sure you switch the gas off at the bottle (not just the BBQ) after every use.";
                case EnergyType.Firewood:
                    return 'To reduce your heating needs, open your curtains during the day to naturally warm your home. Ensure your roof is well-insulated and that there are no cold draughts entering through the doors or flooring. Layer up with clothes, then decide if you still need to light the fire!';
            }
            break;
        case CategoryType.Travel:
            switch (item.type) {
                case TravelType.DomesticAir:
                    return "Reducing your air travel footprint doesn't mean giving up your holidays. Instead, consider taking trips closer to home - using road transport where possible. If you do fly, choose economy class to reduce your share of emissions and take direct flights whenever possible. Before you book a business flight, consider whether a video call would suffice.";
                case TravelType.InternationalAir:
                    return "Reducing your air travel footprint doesn't mean giving up your holidays. Instead, consider taking trips closer to home - using road transport where possible. If you do fly, choose economy class to reduce your share of emissions and take direct flights whenever possible. Before you book a business flight, consider whether a video call would suffice.";
                case TravelType.Train:
                    return 'While travelling by train results in a far smaller carbon footprint than flying, there are still ways to reduce your impact. Travel economy class to reduce your share of emissions, and take the direct route whenever possible. Before you book a business trip, consider whether a video call would suffice.';
                case TravelType.CruiseShip:
                    return "Reducing your travel footprint doesn't mean giving up your holidays. Instead, consider taking trips closer to home - using road transport where possible. It takes a lot of energy to fuel a cruise ship. You can lower your cruise footprint by choosing an operator with newer, more fuel efficient vessels - some operators have even switched to hybrid vessels!";
                case TravelType.Coach:
                    return 'While travelling by coach results in a far smaller carbon footprint than driving or flying, there are still ways to reduce your impact. Try to book the most direct route possible, and limit the size of your luggage by packing only what you need - the more a coach weighs, the more carbon emissions it produces.';
                case TravelType.RoadTrip:
                    return 'To reduce your travel emissions, consider alternative options such as travelling by coach or train. Pack as light as possible - the less your vehicle weighs, the less fuel it will consume, and the lower your emissions will be!';
            }
            break;
        case CategoryType.Water:
            return "A significant amount of energy is required to treat, supply and use water. There are lots of small actions you can take to reduce your household's water consumption. For example, take shorter showers, make sure the dishwasher is full before turning it on, water your garden during the cooler parts of the day, and put a layer of mulch around your plants to trap moisture and reduce evaporation.";
        case CategoryType.Waste:
            return "Organic materials break down in landfill and release methane, a potent greenhouse gas. To reduce the carbon footprint of your household's waste, compost and recycle where possible. Also consider doing smaller, more frequent food shops to minimise waste - this can lower your carbon footprint AND save you money.";
    }
}

export { getDescription };
