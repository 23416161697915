import React from 'react';
import { NavigationPage } from '../NavigationPage';
import { Grid } from 'src/components/shared/Grid';
import { NumericInputForm } from 'src/components/shared/NumericInputForm';
import { ReactComponent as Cabin } from './Cruise.svg';
import { TravelType, CruiseShipTravelType } from 'src/models/travel';
import { useDispatch, useSelector } from 'react-redux';
import { updateTravel } from 'src/store/actions/travel/updateTravel';
import { RootState } from 'src/store';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { useTimeframe } from 'src/store/utils/useTimeframe';
import styles from './CruiseShip.module.sass';

const CruiseShip = () => {
    const { timeframeText } = useTimeframe();
    const dispatch = useDispatch();
    const duration = useSelector<RootState, number>(
        state => state.calculation.travel[TravelType.CruiseShip][CruiseShipTravelType.Cabin].duration,
    );
    return (
        <>
            <NavigationPage
                title="Travel - Cruise Ship"
                description={`Over the past ${timeframeText}, how many days has your household travelled by cruise ship?`}
                tooltip="To account for your whole household, make sure you multiply the number of days by the number of household members on each cruise. ">
                <div className={styles.root}>
                    <Grid columns={{}}>
                        <NumericInputForm
                            title="Cabin"
                            icon={<Cabin className={styles.icon} />}
                            value={duration}
                            units="days"
                            onValueChange={value =>
                                dispatch(
                                    updateTravel({
                                        type: TravelType.CruiseShip,
                                        subtype: CruiseShipTravelType.Cabin,
                                        duration: value,
                                    }),
                                )
                            }
                        />
                    </Grid>
                </div>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'The average cruise results in almost <strong>1 tonne/<strong> of CO₂e per passenger. This is equivalent to a return air trip from London to Tokyo in economy class!',
                    'You can lower your cruise footprint by choosing an operator with newer, more fuel efficient vessels - some operators have even switched to hybrid vessels! ',
                ]}
            />
        </>
    );
};

export { CruiseShip };
