import React from 'react';
import { TextNew } from 'src/components/shared/TextNew';
import { ButtonText } from 'src/components/shared/ButtonText';
import { Modal } from '../shared/Modal';
import { ModalControls } from '../shared/ModalControls';
import styles from './modal.module.sass';

type OffsetAsGuestModalProps = {
    onClose: () => void;
    onGuest: () => void;
    onLogin: () => void;
};

const OffsetAsGuestModal = (props: OffsetAsGuestModalProps) => {
    const { onClose, onGuest, onLogin } = props;
    return (
        <Modal onClose={onClose} title="Before you proceed...">
            {props => (
                <div className={styles.root}>
                    <TextNew className={styles.modalContent} tagName={'p'} textSize={'M'} textWeight={400}>
                        Would you like to create or sign into a Carbon Positive Australia account to save your
                        calculation?
                    </TextNew>
                    <TextNew className={styles.modalContent} tagName={'p'} textSize={'M'} textWeight={400}>
                        Registered accounts can browse a history of previous calculations as well as load them for
                        future use.
                    </TextNew>
                    <ModalControls>
                        <ButtonText
                            className={styles.button}
                            variant="outlined"
                            text="Proceed as guest"
                            onClick={() => {
                                onGuest();
                                props.close();
                            }}
                        />
                        <ButtonText
                            className={styles.button}
                            variant="primary"
                            text="Create account/login"
                            onClick={() => {
                                onLogin();
                                props.close();
                            }}
                        />
                    </ModalControls>
                </div>
            )}
        </Modal>
    );
};

export { OffsetAsGuestModal };
