import React from 'react';
import { TextNew } from 'src/components/shared/TextNew';
import { ButtonText } from 'src/components/shared/ButtonText';
import { Modal } from '../shared/Modal';
import { ModalControls } from '../shared/ModalControls';
import { useUI } from '../UI';
import styles from './modal.module.sass';

type Action = () => void;

type IncompleteResultsModalProps = {
    onOk: Action;
    onClose?: () => void;
};


const IncompleteResultsModal = (props: IncompleteResultsModalProps) => {
    const { onOk, onClose } = props;
    return (
        <Modal onClose={onClose} title="Uh-Oh">
            {props => (
                <div className={styles.root}>
                    <TextNew className={styles.modalContent} tagName={'p'} textSize={'M'} textWeight={400}>
                        It looks like you haven't completed all fields in your calculation. If you continue to your
                        results now, your calculated footprint won't be complete.
                    </TextNew>
                    <ModalControls>
                        <ButtonText className={styles.button} variant="outlined" onClick={props.close} text="Cancel" />
                        <ButtonText
                            variant="primary"
                            onClick={() => {
                                props.close();
                                onOk();
                            }}
                            text="Continue Anyway"
                        />
                    </ModalControls>
                </div>
            )}
        </Modal>
    );
};

export { IncompleteResultsModal };
