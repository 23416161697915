import { GroceryType, CalculationMethod, DietType } from 'src/models/groceries';

export enum ActionType {
    RESET = 'GROCERIES:RESET',
    SET_CALCULATION_METHOD = 'GROCERIES:SET_CALCULATION_METHOD',
    UPDATE = 'GROCERIES:UPDATE',
    UPDATE_EMISSIONS = 'GROCERIES:UPDATE_EMISSIONS',
    UPDATE_CATEGORY = 'GROCERIES:UPDATE_CATEGORY',
    UPDATE_CATEGORY_EMISSIONS = 'GROCERIES:UPDATE_CATEGORY_EMISSIONS',
}

export type ResetAction = {
    type: ActionType.RESET;
};

export type SetCalculationMethodAction = {
    type: ActionType.SET_CALCULATION_METHOD;
    payload: {
        method: CalculationMethod;
    };
};

export type UpdateCategoryAction = {
    type: ActionType.UPDATE_CATEGORY;
    payload: {
        type: GroceryType;
        amount: number;
        isAverage: boolean;
    };
};

export type UpdateCategoryEmissionAction = {
    type: ActionType.UPDATE_CATEGORY_EMISSIONS;
    payload: {
        type: GroceryType;
        emissions: number;
    };
};

export type UpdateAction = {
    type: ActionType.UPDATE;
    payload: {
        diet: DietType;
        amount: number;
    };
};

export type UpdateEmissionsAction = {
    type: ActionType.UPDATE_EMISSIONS;
    payload: {
        emissions: number;
    };
};

export type Actions =
    | ResetAction
    | SetCalculationMethodAction
    | UpdateAction
    | UpdateEmissionsAction
    | UpdateCategoryAction
    | UpdateCategoryEmissionAction;
