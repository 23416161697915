import { FuelType, VehicleSize, MeasurementType, CalculationType, VehicleEmissions } from 'src/models/transport';
import { ActionType, Actions } from '.';
import { ReduxThunkAction } from 'src/store';
import strip from 'src/utils/strip';
import { VehicleSubtype } from 'src/libs/transport';
import { TimeframeType } from 'src/models';
import { ConfigurationType } from 'src/configuration';
import { Conversion } from 'src/libs/conversion';

function calculateEmissions(
    type: VehicleSubtype,
    vehicle: VehicleEmissions,
    timeframe: TimeframeType,
    configuration: ConfigurationType,
): number {
    const factor = configuration.emissions.transport.vehicles[vehicle.fuel];
    switch (vehicle.calculation) {
        case CalculationType.Fuel:
            if (vehicle.fuel === FuelType.Electric) {
                return 0;
            }
            return factor * vehicle.consumption * Conversion.weekly[timeframe];
        case CalculationType.Distance:
            switch (vehicle.measurement) {
                case MeasurementType.FuelEconomy:
                    return (vehicle.distance / 100) * vehicle.fuelEconomy * factor * Conversion.weekly[timeframe];
                case MeasurementType.VehicleSize:
                    const {
                        averages: {
                            transport: {
                                economy: { [type]: fuelEconomy },
                            },
                        },
                    } = configuration;
                    const { size, fuel } = vehicle;
                    const economy = (fuelEconomy as any)[size][fuel];
                    return (vehicle.distance / 100) * economy * factor * Conversion.weekly[timeframe];
            }
    }
}

type UpdateVehicleProps = {
    type: VehicleSubtype;
    index: number;
    fuel?: FuelType;
    measurement?: MeasurementType;
    calculation?: CalculationType;
    size?: VehicleSize;
    fuelEconomy?: number;
    consumption?: number;
    distance?: number;
    isAverage?: boolean;
};

const updateVehicle = (props: UpdateVehicleProps): ReduxThunkAction<Actions> => {
    const { type, index } = props;
    return (dispatch, getState, configuration) => {
        dispatch({
            type: ActionType.UPDATE_VEHICLE,
            payload: strip(props),
        });

        const {
            calculation: {
                metadata: { timeframe },
                transport: {
                    [type]: { [index]: vehicle },
                },
            },
        } = getState();

        dispatch({
            type: ActionType.UPDATE_VEHICLE_EMISSIONS,
            payload: {
                type,
                index,
                emissions: calculateEmissions(type, vehicle, timeframe, configuration),
            },
        });
    };
};

export { updateVehicle };
