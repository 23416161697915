import { FuelType, VehicleSize, MeasurementType, CalculationType, VehicleEmissions } from 'src/models/transport';
import { ActionType, Actions } from '.';
import { ReduxThunkAction } from 'src/store';
import strip from 'src/utils/strip';
import { VehicleSubtype } from 'src/libs/transport';
import { TravelType } from 'src/models/travel';
import { ConfigurationType } from 'src/configuration';

function calculateEmissions(type: VehicleSubtype, vehicle: VehicleEmissions, configuration: ConfigurationType): number {
    const factor = configuration.emissions.transport.vehicles[vehicle.fuel];
    switch (vehicle.calculation) {
        case CalculationType.Fuel:
            return factor * vehicle.consumption;
        case CalculationType.Distance:
            switch (vehicle.measurement) {
                case MeasurementType.FuelEconomy:
                    return (vehicle.distance / 100) * vehicle.fuelEconomy * factor;
                case MeasurementType.VehicleSize:
                    const {
                        averages: {
                            transport: {
                                economy: { [type]: fuelEconomy },
                            },
                        },
                    } = configuration;
                    const { size, fuel } = vehicle;
                    const economy = (fuelEconomy as any)[size][fuel];
                    return (vehicle.distance / 100) * economy * factor;
            }
    }
}

type UpdateRoadTripVehicleProps = {
    type: VehicleSubtype;
    index: number;
    fuel?: FuelType;
    measurement?: MeasurementType;
    calculation?: CalculationType;
    size?: VehicleSize;
    fuelEconomy?: number;
    consumption?: number;
    distance?: number;
};

const updateRoadTripVehicle = (props: UpdateRoadTripVehicleProps): ReduxThunkAction<Actions> => {
    const { type, index } = props;
    return (dispatch, getState, configuration) => {
        dispatch({
            type: ActionType.UPDATE_ROADTRIP,
            payload: strip(props),
        });

        const {
            calculation: {
                travel: {
                    [TravelType.RoadTrip]: {
                        [type]: { [index]: vehicle },
                    },
                },
            },
        } = getState();

        dispatch({
            type: ActionType.UPDATE_ROADTRIP_EMISSIONS,
            payload: {
                type,
                index,
                emissions: calculateEmissions(type, vehicle, configuration),
            },
        });
    };
};

export { updateRoadTripVehicle };
