import { Actions, ActionType } from '../actions/transport';
import {
    PublicTransportType,
    TransportType,
    PublicTransportEmissions,
    VehicleEmissions,
    MotorcycleEmissions,
    DefaultVehicleEmissions,
    DefaultMotorcycleEmissions,
    DefaultPublicTransportEmissions,
    VehicleSize,
} from 'src/models/transport';
import strip from 'src/utils/strip';

export type PublicTransportState = {
    [PublicTransportType.Bus]: PublicTransportEmissions;
    [PublicTransportType.Ferry]: PublicTransportEmissions;
    [PublicTransportType.Taxi]: PublicTransportEmissions;
    [PublicTransportType.Train]: PublicTransportEmissions;
    [PublicTransportType.Tram]: PublicTransportEmissions;
};

export type TransportState = {
    selected: Array<TransportType>;
    vehicleCount: {
        [TransportType.Car]: number;
        [TransportType.SUV]: number;
        [TransportType.Van]: number;
        [TransportType.Motorcycle]: number;
    };
    [TransportType.Car]: Array<VehicleEmissions>;
    [TransportType.SUV]: Array<VehicleEmissions>;
    [TransportType.Van]: Array<VehicleEmissions>;
    [TransportType.Motorcycle]: Array<MotorcycleEmissions>;
    [TransportType.PublicTransport]: PublicTransportState;
};

const initialState = {
    selected: [],
    vehicleCount: {
        [TransportType.Car]: 0,
        [TransportType.SUV]: 0,
        [TransportType.Van]: 0,
        [TransportType.Motorcycle]: 0,
    },
    [TransportType.Car]: new Array<VehicleEmissions>(),
    [TransportType.SUV]: new Array<VehicleEmissions>(),
    [TransportType.Van]: new Array<VehicleEmissions>(),
    [TransportType.Motorcycle]: new Array<MotorcycleEmissions>(),
    [TransportType.PublicTransport]: {
        [PublicTransportType.Bus]: DefaultPublicTransportEmissions,
        [PublicTransportType.Ferry]: DefaultPublicTransportEmissions,
        [PublicTransportType.Taxi]: DefaultPublicTransportEmissions,
        [PublicTransportType.Train]: DefaultPublicTransportEmissions,
        [PublicTransportType.Tram]: DefaultPublicTransportEmissions,
    },
};

const transportReducer = (state: TransportState = initialState, action: Actions) => {
    switch (action.type) {
        case ActionType.RESET:
            return initialState;
        case ActionType.SET_SELECTED:
            if (action.payload.selected && state.selected.includes(action.payload.type) === false) {
                return {
                    ...state,
                    selected: [...state.selected, action.payload.type],
                };
            }
            if (action.payload.selected === false && state.selected.includes(action.payload.type)) {
                return {
                    ...state,
                    selected: state.selected.filter(v => v !== action.payload.type),
                };
            }
            break;
        case ActionType.SET_VEHICLE_COUNT:
            return {
                ...state,
                vehicleCount: {
                    ...state.vehicleCount,
                    [action.payload.type]: action.payload.count,
                },
            };
        case ActionType.UPDATE_VEHICLE:
        case ActionType.UPDATE_VEHICLE_EMISSIONS:
            const defaultVehicle = {
                ...DefaultVehicleEmissions,
                size: action.payload.type === TransportType.Van ? VehicleSize.PeopleMover : VehicleSize.Small,
            };
            const defaultVehicleArray = new Array(action.payload.index)
                .fill(defaultVehicle)
                .map((vehcile, index) => state[action.payload.type][index] || vehcile);

            switch (action.type) {
                case ActionType.UPDATE_VEHICLE:
                    return {
                        ...state,
                        [action.payload.type]: Object.assign(defaultVehicleArray, state[action.payload.type], {
                            [action.payload.index]: {
                                ...(state[action.payload.type][action.payload.index] || defaultVehicle),
                                ...strip(action.payload, ['type', 'index']),
                            },
                        }),
                    };
                case ActionType.UPDATE_VEHICLE_EMISSIONS:
                    return {
                        ...state,
                        [action.payload.type]: Object.assign(defaultVehicleArray, state[action.payload.type], {
                            [action.payload.index]: {
                                ...(state[action.payload.type][action.payload.index] || defaultVehicle),
                                emissions: action.payload.emissions,
                            },
                        }),
                    };
            }
            break;
        case ActionType.UPDATE_MOTORCYCLE:
        case ActionType.UPDATE_MOTORCYCLE_EMISSIONS:
            const defaultMotorcycleArray = new Array(action.payload.index)
                .fill(DefaultMotorcycleEmissions)
                .map((vehcile, index) => state[TransportType.Motorcycle][index] || vehcile);
            switch (action.type) {
                case ActionType.UPDATE_MOTORCYCLE:
                    return {
                        ...state,
                        [TransportType.Motorcycle]: Object.assign(
                            defaultMotorcycleArray,
                            state[TransportType.Motorcycle],
                            {
                                [action.payload.index]: {
                                    ...(state[TransportType.Motorcycle][action.payload.index] ||
                                        DefaultMotorcycleEmissions),
                                    ...strip(action.payload, ['type', 'index']),
                                },
                            },
                        ),
                    };
                case ActionType.UPDATE_MOTORCYCLE_EMISSIONS:
                    return {
                        ...state,
                        [TransportType.Motorcycle]: Object.assign(
                            defaultMotorcycleArray,
                            state[TransportType.Motorcycle],
                            {
                                [action.payload.index]: {
                                    ...(state[TransportType.Motorcycle][action.payload.index] ||
                                        DefaultMotorcycleEmissions),
                                    emissions: action.payload.emissions,
                                },
                            },
                        ),
                    };
            }
        case ActionType.UPDATE_PUBLIC_TRANSPORT:
            return {
                ...state,
                [TransportType.PublicTransport]: {
                    ...state[TransportType.PublicTransport],
                    [action.payload.type]: {
                        ...state[TransportType.PublicTransport][action.payload.type],
                        distance: action.payload.distance,
                    },
                },
            };
        case ActionType.UPDATE_PUBLIC_TRANSPORT_EMISSIONS:
            return {
                ...state,
                [TransportType.PublicTransport]: {
                    ...state[TransportType.PublicTransport],
                    [action.payload.type]: {
                        ...state[TransportType.PublicTransport][action.payload.type],
                        emissions: action.payload.emissions,
                    },
                },
            };
    }
    return state;
};

export { transportReducer };
