import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Throbber } from './components/shared/Throbber';
import { get } from 'src/api/calculation';
import { useNavigationState } from 'src/components/shared/Navigation';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { loadCalculation } from './store/actions/root/loadCalculation';
import { useConfiguration } from './ConfigurationProvider';
import styles from './Loader.module.sass';

type LoaderProps = {
    calculationId: string;
};

const Loader = (props: LoaderProps) => {
    const { calculationId } = props;
    const dispatch = useDispatch();
    const { wpApiSettings } = useConfiguration();
    const [error, setError] = useState();
    const { navigate: navigateRoot } = useNavigationState(NavigationKey.Root);
    const { navigate: navigateMain } = useNavigationState(NavigationKey.Main);
    useEffect(() => {
        const load = async () => {
            try {
                const calculation = await get(calculationId, wpApiSettings);
                dispatch(loadCalculation({ calculation }));
                if (calculation.complete) {
                    navigateRoot(0);
                    navigateMain(3);
                }
                window.location.href = '/carbon-footprint-calculator';
            } catch (e) {
                console.log('ERROR', e);
                setError(e);
            }
        };
        load();
    }, [calculationId]);
    return (
        <div className={styles.root}>
            <Throbber className={styles.throbber} variant={error ? 'error' : 'primary'}></Throbber>
        </div>
    );
};

export { Loader };
