import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransportType, VehicleEmissions, DefaultVehicleEmissions } from 'src/models/transport';
import { RootState } from 'src/store';
import { updateVehicle } from 'src/store/actions/transport/updateVehicle';
import { ReactComponent as Car } from './Car.svg';
import { ReactComponent as SUV } from './SUV.svg';
import { ReactComponent as Van } from './Van.svg';
import { VehicleSubtype, getTransportDistanceAverage, getTransportFuelConsumptionAverage } from 'src/libs/transport';
import { TimeframeType } from 'src/models';
import { VehicleForm } from '../shared/VehicleForm';
import round from 'src/utils/round';

function getColorVariant(type: VehicleSubtype) {
    switch (type) {
        case TransportType.Car:
            return 1;
        case TransportType.SUV:
            return 2;
        case TransportType.Van:
            return 3;
    }
}

function getIcon(type: VehicleSubtype) {
    switch (type) {
        case TransportType.Car:
            return <Car />;
        case TransportType.SUV:
            return <SUV />;
        case TransportType.Van:
            return <Van />;
    }
}

function getTitle(type: VehicleSubtype, index: number) {
    switch (type) {
        case TransportType.Car:
            return `Car ${index + 1}`;
        case TransportType.SUV:
            return `SUV ${index + 1}`;
        case TransportType.Van:
            return `People Mover ${index + 1}`;
    }
}

type VehicleProps = {
    type: VehicleSubtype;
    index: number;
    disabled?: boolean;
};

const Vehicle = (props: VehicleProps) => {
    const { type, index, disabled = false } = props;
    const dispatch = useDispatch();
    const vehicle = useSelector<RootState, VehicleEmissions>(
        state => state.calculation.transport[type][index] || DefaultVehicleEmissions,
    );
    return (
        <VehicleForm
            type={type}
            title={getTitle(type, index)}
            icon={getIcon(type)}
            colorVariant={getColorVariant(type)}
            vehicle={vehicle}
            disabled={disabled}
            weekly={true}
            onFuelUpdate={fuel => {
                if (vehicle.isAverage) {
                    const average = getTransportFuelConsumptionAverage({
                        type,
                        fuel: fuel,
                        timeframe: TimeframeType.Year,
                    });
                    dispatch(updateVehicle({ type, index, consumption: round(average / 52) }));
                }
                dispatch(updateVehicle({ type, index, fuel }));
            }}
            onMeasurementUpdate={measurement => dispatch(updateVehicle({ type, index, measurement }))}
            onCalculationUpdate={calculation => dispatch(updateVehicle({ type, index, calculation }))}
            onSizeUpdate={size => dispatch(updateVehicle({ type, index, size }))}
            onFuelEconomyUpdate={fuelEconomy => dispatch(updateVehicle({ type, index, fuelEconomy }))}
            onConsumptionUpdate={consumption => dispatch(updateVehicle({ type, index, consumption, isAverage: false }))}
            onDistanceUpdate={distance => dispatch(updateVehicle({ type, index, distance, isAverage: false }))}
            onFuelAverageClick={() => {
                const average = getTransportFuelConsumptionAverage({
                    type,
                    fuel: vehicle.fuel,
                    timeframe: TimeframeType.Year,
                });
                dispatch(
                    updateVehicle({
                        type,
                        index,
                        consumption: round(average / 52),
                        isAverage: true,
                    }),
                );
            }}
            onDistanceAverageClick={() => {
                const average = getTransportDistanceAverage({
                    type,
                    timeframe: TimeframeType.Year,
                });
                dispatch(updateVehicle({ type, index, distance: round(average / 52), isAverage: true }));
            }}
        />
    );
};

export { Vehicle };
