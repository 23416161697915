import { store } from 'src/store';
import { serializeMetadataState } from 'src/store/serializers/metadataSerializers';

const flushMetadataState = () => {
    const {
        calculation: { metadata },
    } = store.getState();
    try {
        localStorage.setItem('cpos:metadata', serializeMetadataState(metadata));
    } catch (err) {
        // TODO: update state with error
    }
};

export { flushMetadataState };
