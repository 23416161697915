import React from 'react';
import { NavigationPage } from '../NavigationPage';
import { Form, FormBadge, FormControl } from 'src/components/shared/Forms';
import { Grid } from 'src/components/shared/Grid';
import { ReactComponent as Badge } from './Water.svg';
import { TextNew } from 'src/components/shared/TextNew';
import { NumericInput } from 'src/components/shared/NumericInput';
import { RootState } from 'src/store';
import { WaterEmissions } from 'src/models/water';
import { useDispatch, useSelector } from 'react-redux';
import { updateConsumption } from 'src/store/actions/water/updateConsumption';
import { getWaterAverage } from 'src/libs/water';
import { useTimeframe } from 'src/store/utils/useTimeframe';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import styles from './Consumption.module.sass';

const Consumption: React.FC = () => {
    const { timeframe, timeframeText } = useTimeframe();
    const { region, consumption } = useSelector<RootState, WaterEmissions>(state => state.calculation.water);
    const dispatch = useDispatch();
    return (
        <>
        <NavigationPage
            title="Water"
            description={`Over the past ${timeframeText}, how much water has your household consumed?`}>
            <Form
                size="large"
                colorVariant={6}
                badge={
                    <FormBadge
                        colorVariant={6}
                        title="Water"
                        subtitle={region ?? 'Australian Average'}
                        icon={<Badge />}
                    />
                }>
                <Grid columns={{ default: 1, sm: 1, md: 1 }} limit={false}>
                    <FormControl
                        className={styles.formControl}
                        label="kL Consumed"
                        tooltipTitle="Where can I find this?"
                        tooltip="Water consumption is measured in kilolitres (kL). You can find this figure on your water bill or via your online account. Alternatively, you can select to use the average household water consumption for your state."
                        footer={
                             <TextNew
                                className={styles.button}
                                tagName={'button'}
                                textSize={'S'}
                                textWeight={700}
                                textStyle={'smallCaps'}
                                onClick={() => {
                                    dispatch(
                                        updateConsumption({
                                            consumption: getWaterAverage(region, timeframe),
                                            isAverage: true,
                                        }),
                                    )
                                }}>
                                Use average for {region ?? 'Australian Average'}
                            </TextNew>
                        }>
                        <NumericInput
                            className={styles.input}
                            value={consumption}
                            units="kL"
                            onValueChange={consumption =>
                                dispatch(updateConsumption({ consumption, isAverage: false }))
                            }
                        />
                    </FormControl>
                </Grid>
            </Form>
        </NavigationPage>
        <DidYouKnow
            text={[
                `A significant amount of energy is required to treat, supply and use water. An easy way to reduce your consumption is to install a low-flow shower head and limit showers to less than 4 minutes`
            ]}
            />
        </>
    );
};

export { Consumption };
