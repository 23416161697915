import React, { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState, TransportState } from 'src/store';
import { TransportType } from 'src/models/transport';
import { NavigationPage } from '../NavigationPage';
import { Vehicle } from './Vehicle';
import { Motorcycle } from './Motorcycle';
import { Carousel } from 'src/components/shared/Carousel';
import { useNavigation, useNavigationState } from 'src/components/shared/Navigation';
import { NavigationContext, NavigationContextType } from 'src/components/shared/Navigation/Navigation';
import { flushTransportState } from 'src/store/storage/flushTransportState';
import { ActionType } from 'src/store/actions/navigation';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { useTimeframe } from 'src/store/utils/useTimeframe';

const useVehicleFacts = (index: number) => {
    const { vehicleCount, selected } = useSelector<RootState, TransportState>(state => state.calculation.transport);
    return useMemo<Array<string>>(() => {
        function getNormalizedType() {
            const array = Object.entries(vehicleCount).flatMap(([key, value]) => {
                const type = key as TransportType;
                return Array.from({ length: selected.includes(type) ? value : 0 }, () => type);
            });
            return array[array.length - 1];
        }
        switch (getNormalizedType()) {
            case TransportType.Car:
                return [
                    'Hybrid vehicles combine the power of petrol engines with the efficiency of electric motors - charging the battery while you drive. Over time, they can save you money AND lower your carbon footprint!',
                    "The world's fastest accelerating car on the commercial market is electric!",
                    'Electric vehicles represent less than 1% of new vehicle sales in Australia. Compare this to around 5% in China and 4% in Europe.',
                ];
            case TransportType.SUV:
                return [
                    'The average fuel economy of an Australian passenger vehicle is <strong>11.1 litres per 100 kilometres</strong> (l/100km). The average for a medium-sized hybrid is just 3.4 l/100km.',
                    'Less than <strong>10%</strong> of Australian households get by without a car, while most (<strong>54%</strong>) have at least two cars.',
                    'Of all Australian capitals, Sydney has the lowest proportion of commuters driving to work (<strong>53.7%</strong>) and the highest proportion of commuters using public transport.',
                ];
            case TransportType.Van:
                return [
                    'The transport sector is the <strong>second largest</strong> source of emissions in the Australian economy, and emissions are growing fast!',
                    'If you filled a balloon with the equivalent of <strong>1 tonne</strong> of carbon dioxide, it would be roughly the same size as a two-storey house!',
                    'The average Light Commercial Vehicle in Australia travels over <strong>15,000</strong> kilometres per year.',
                ];
            case TransportType.Motorcycle:
                return [
                    'Even though Brisbane has <strong>2 million</strong> fewer people than Melbourne, it has almost <strong>1,700</strong> more motorcycle commuters!',
                    "The average fuel economy of an Australian motorbike is <strong>6.1</strong> litres per 100 kilometres (l/100km). That's almost half the fuel economy of an average car.",
                    'There are almost <strong>900,000</strong> motorcycles in Australia.',
                ];
        }
        return [];
    }, [index, vehicleCount, selected]);
};

const useVehicleComponents = () => {
    const { vehicleCount, selected } = useSelector<RootState, TransportState>(state => state.calculation.transport);
    return useMemo<Array<ReactNode>>(() => {
        return Object.entries(vehicleCount).flatMap(([key, value]) => {
            const type = key as TransportType;
            if (selected.includes(type) === false) {
                return [];
            }
            return Array.from({ length: value }, (_, index) => {
                switch (type) {
                    case TransportType.Car:
                    case TransportType.SUV:
                    case TransportType.Van:
                        return <Vehicle key={`${key}_${index}`} type={type} index={index} />;
                    case TransportType.Motorcycle:
                        return <Motorcycle key={`${key}_${index}`} index={index} />;
                }
            });
        });
    }, [selected, vehicleCount]);
};

const Vehicles = () => {
    const { index, dispatch } = useNavigationState(NavigationKey.TransportVehicles);
    const { timeframeText } = useTimeframe();
    const facts = useVehicleFacts(index);
    const children = useVehicleComponents();
    const navigation = useNavigation();
    const { navigate } = useNavigationState(NavigationKey.TransportVehicles);
    const actions = useMemo<NavigationContextType>(() => {
        return {
            back: () => {
                if (index === 0) {
                    navigation.back();
                    flushTransportState();
                    return;
                }
                dispatch({
                    type: ActionType.NAVIGATE_TO,
                    payload: {
                        key: NavigationKey.TransportVehicles,
                        index: index - 1,
                    },
                });
                flushTransportState();
            },
            next: () => {
                if (index === children.length - 1) {
                    navigation.next();
                    flushTransportState();
                    return;
                }
                dispatch({
                    type: ActionType.NAVIGATE_TO,
                    payload: {
                        key: NavigationKey.TransportVehicles,
                        index: index + 1,
                    },
                });
                flushTransportState();
            },
        };
    }, [dispatch, navigation, index]);
    return (
        <>
            <NavigationContext.Provider value={actions}>
                <NavigationPage
                    title="Transport - Vehicles"
                    description={`Tell us more about your vehicle and its typical weekly use.`}>
                    <Carousel index={index} onClick={navigate}>
                        {children}
                    </Carousel>
                </NavigationPage>
            </NavigationContext.Provider>
            <DidYouKnow text={facts} />
        </>
    );
};

export { Vehicles };
