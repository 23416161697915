import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import styles from './Carousel.module.sass';
import { DisabledTabbingContainer } from './DisabledTabbingContainer';

type Carousel = {
    index: number;
    onClick: (index: number) => void;
    children: Array<ReactNode>;
};

const spring = {
    type: 'spring',
    stiffness: 500,
    damping: 30,
};

const Carousel = (props: Carousel) => {
    const { index, onClick, children } = props;
    return (
        <div className={styles.root}>
            <div className={styles.scroller}>
                {index > 0 && (
                    <DisabledTabbingContainer className={styles.left} onClick={() => onClick(index - 1)}>
                        {children[index - 1]}
                    </DisabledTabbingContainer>
                )}
                <div className={styles.middle}>{children[index]}</div>
                {index < children.length - 1 && (
                    <DisabledTabbingContainer className={styles.right} onClick={() => onClick(index + 1)}>
                        {children[index + 1]}
                    </DisabledTabbingContainer>
                )}
            </div>
            {children.length > 1 && (
                <motion.div className={styles.thumbs}>
                    {Array.from({ length: children.length }).map((_, i) => {
                        return (
                            <motion.div
                                key={i}
                                transition={spring}
                                animate={{ scale: i === index ? 1.75 : 1 }}
                                className={classNames(styles.thumb, { [styles.active]: i === index })}
                                onClick={() => onClick(i)}
                            />
                        );
                    })}
                </motion.div>
            )}
        </div>
    );
};

export { Carousel };
