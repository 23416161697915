import { Action } from 'redux';
import { NavigationKey } from 'src/store/reducers/navigationReducer';

export enum ActionType {
    NAVIGATE_TO = 'UI:NAVIGATE_TO',
    RESET = 'UI:RESET',
    SET_COMPLETED = 'UI:SET_COMPLETED',
}

export interface NavigateToAction extends Action<ActionType> {
    type: ActionType.NAVIGATE_TO;
    payload: {
        key: NavigationKey;
        index: number;
    };
}

export interface ResetAction extends Action<ActionType> {
    type: ActionType.RESET;
    payload: {
        keys: Array<NavigationKey>;
    };
}

export interface SetCompletedAction extends Action<ActionType> {
    type: ActionType.SET_COMPLETED;
}

export type Actions = NavigateToAction | ResetAction | SetCompletedAction;
