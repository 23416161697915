import React from 'react';
import classNames from 'classnames';
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { ReactComponent as Chevron } from 'src/components/svg/ChevronDown.svg';
import styles from './Dropdown.module.sass';

type DropdownOptionType = {
    label: string;
    value: string;
};

type DropdownProps = {
    className: string;
    disabled?: boolean;
    options: Array<DropdownOptionType>;
    defaultValue?: string;
    onValueChange?: (value: string) => void;
};

type DropdownArrowProps = {
    isOpen: boolean;
};

const DropdownArrow = (props: DropdownArrowProps) => {
    return <Chevron data-active={props.isOpen} />;
};

const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
    const { className, disabled, options, defaultValue, onValueChange } = props;
    return (
        <ReactDropdown
            className={classNames(className, styles.root)}
            controlClassName={styles.control}
            placeholderClassName={styles.placeholder}
            arrowClassName={styles.arrow}
            arrowClosed={<DropdownArrow isOpen={false} />}
            arrowOpen={<DropdownArrow isOpen={true} />}
            menuClassName={styles.menu}
            options={options}
            disabled={disabled}
            value={defaultValue}
            onChange={option => onValueChange && onValueChange(option.value)}
        />
    );
};

export { Dropdown };
