import React, { ReactNode, useEffect, useRef } from 'react';
import { UIElementProps } from '..';

function disable(element: HTMLDivElement) {
    element.querySelectorAll<HTMLInputElement>('input').forEach(child => (child.tabIndex = -1));
    element.querySelectorAll<HTMLButtonElement>('button').forEach(child => (child.tabIndex = -1));
}

function enable(element: HTMLDivElement) {
    element.querySelectorAll<HTMLInputElement>('input').forEach(child => (child.tabIndex = 0));
    element.querySelectorAll<HTMLButtonElement>('button').forEach(child => (child.tabIndex = 0));
}

type DisabledTabbingContainerProps = UIElementProps & {
    children: ReactNode;
    onClick: () => void;
};

const DisabledTabbingContainer = (props: DisabledTabbingContainerProps) => {
    const { className, children, onClick } = props;
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (ref.current) {
            disable(ref.current);
        }
        return () => {
            if (ref.current) {
                enable(ref.current);
            }
        };
    }, [children]);
    return (
        <div ref={ref} className={className} onClick={onClick}>
            {children}
        </div>
    );
};

export { DisabledTabbingContainer };
