import React from 'react';
import { NavigationRoot } from 'src/components/shared/Navigation';
import { Categories } from './Categories';
import { Timeframe } from './Timeframe';
import { flushCategoriesState } from 'src/store/storage/flushCategoriesState';
import { flushMetadataState } from 'src/store/storage/flushMetadataState';
import { NavigationKey } from 'src/store/reducers/navigationReducer';

const GettingStartedRoot: React.FC = () => {
    return (
        <NavigationRoot
            navigationKey={NavigationKey.GettingStarted}
            onNavigate={() => {
                flushMetadataState();
                flushCategoriesState();
            }}>
            <Categories />
            <Timeframe />
        </NavigationRoot>
    );
};

export { GettingStartedRoot };
