import React, { ReactNode } from 'react';
import { Grid } from 'src/components/shared/Grid';
import { TextNew } from 'src/components/shared/TextNew';
import { NavigationPage } from '../NavigationPage';
import { ReactComponent as Meat } from './Meat.svg';
import { ReactComponent as Eggs } from './Eggs.svg';
import { ReactComponent as Seafood } from './Seafood.svg';
import { ReactComponent as FruitAndNuts } from './FruitAndNuts.svg';
import { ReactComponent as BreadAndCereals } from './BreadAndCereals.svg';
import { ReactComponent as Vegetables } from './Vegetables.svg';
import { ReactComponent as ProcessedFoods } from './ProcessedFoods.svg';
import { ReactComponent as AlcoholicDrinks } from './AlcoholicDrinks.svg';
import { ReactComponent as NonAlcoholicDrinks } from './NonAlcoholicDrinks.svg';
import { ReactComponent as DiningOutOrTakeaway } from './DiningOutOrTakeaway.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { NumericInputForm } from 'src/components/shared/NumericInputForm';
import { updateCategory } from 'src/store/actions/groceries/updateCategory';
import { GroceryType, GroceryEmissions } from 'src/models/groceries';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { useTimeframe } from 'src/store/utils/useTimeframe';
import { configuration } from 'src/configuration';
import { CategoryType } from 'src/models';
import styles from './Categories.module.sass';

type CategoryInputProps = {
    className?: string;
    title: string;
    tooltip: string;
    type: GroceryType;
    icon: ReactNode;
};

const CategoryInput: React.FC<CategoryInputProps> = (props: CategoryInputProps) => {
    const { className, title, tooltip, type, icon } = props;
    const dispatch = useDispatch();
    const groceries = useSelector<RootState, GroceryEmissions>(state => state.calculation.groceries.categories[type]);
    return (
        <NumericInputForm
            className={className}
            title={title}
            tooltip={tooltip}
            icon={icon}
            value={groceries.amount}
            units="$/week"
            unitsAlignment="right"
            onValueChange={amount => dispatch(updateCategory({ type, amount, isAverage: false }))}
            footer={
                <TextNew
                    className={styles.button}
                    tagName={'button'}
                    textSize={'S'}
                    textWeight={700}
                    textStyle={'smallCaps'}
                    onClick={() => {
                        const average = configuration.averages[CategoryType.Groceries].category[type];
                        dispatch(updateCategory({ type, amount: average, isAverage: true }));
                    }}>
                    Use Australian Average
                </TextNew>
            }
        />
    );
};

const Categories: React.FC = () => {
    const { timeframeText } = useTimeframe();
    return (
        <>
            <NavigationPage
                title="Food &amp; Drink - By Category"
                description={`How much did your household typically spend on the following categories each week?`}>
                <Grid columns={{ xs: 2, sm: 3, md: 4, lg: 5 }}>
                    <CategoryInput
                        type={GroceryType.Meat}
                        title="Meat"
                        tooltip="Includes raw and processed meats."
                        icon={<Meat className={styles.icon} />}
                    />
                    <CategoryInput
                        type={GroceryType.EggsAndDairy}
                        title="Eggs &amp; Dairy"
                        tooltip="Includes eggs, milk, cheese, yoghurt, ice-cream and other dairy."
                        icon={<Eggs className={styles.icon} />}
                    />
                    <CategoryInput
                        type={GroceryType.Seafood}
                        title="Seafood"
                        tooltip="Includes fresh, frozen, canned and bottled seafood."
                        icon={<Seafood className={styles.icon} />}
                    />
                    <CategoryInput
                        type={GroceryType.FruitAndNuts}
                        title="Fruit &amp; Nuts"
                        tooltip="Includes all fresh fruit and nuts. Excludes canned, frozen and dried fruits."
                        icon={<FruitAndNuts className={styles.icon} />}
                    />
                    <CategoryInput
                        type={GroceryType.BreadAndCereals}
                        title="Breads &amp; Cereals"
                        tooltip="Includes bread, flour, rice, pasta, cereals, legumes and other raw grains."
                        icon={<BreadAndCereals className={styles.icon} />}
                    />
                    <CategoryInput
                        type={GroceryType.Vegetables}
                        title="Vegetables"
                        tooltip="Includes all fresh vegetables. Excludes canned, frozen and dried vegetables."
                        icon={<Vegetables className={styles.icon} />}
                    />
                    <CategoryInput
                        type={GroceryType.ProcessedFood}
                        title="Processed Food"
                        tooltip="Includes all processed foods such as sugar, confectionary, sauces, canned and bottled produce, packaged meals, and frozen fruits and vegetables."
                        icon={<ProcessedFoods className={styles.icon} />}
                    />
                    <CategoryInput
                        type={GroceryType.NonAlcoholicDrinks}
                        title="Non-Alcoholic Drinks"
                        tooltip="Includes store purchases of soft drinks, water, cordial, fruit juice, tea, and coffee. Drinks purchased for on-venue consumption (e.g. at a café or restaurant) should be included in 'Restaurants &amp; Takeaway'."
                        icon={<NonAlcoholicDrinks className={styles.icon} />}
                    />
                    <CategoryInput
                        type={GroceryType.AlcoholicDrinks}
                        title="Alcoholic Drinks"
                        tooltip="Includes bottle shop purchases only. Alcoholic drinks purchased for on-venue consumption (e.g. at a pub or restaurant) should be included in 'Dining Out &amp; Takeaway'."
                        icon={<AlcoholicDrinks className={styles.icon} />}
                    />
                    <CategoryInput
                        type={GroceryType.DiningOutOrTakeaway}
                        title="Dining Out &amp; Takeaway"
                        tooltip="Includes food and drink purchases at restaurants, cafes, pubs and clubs for on-premises consumption, as well as takeaway meals."
                        icon={<DiningOutOrTakeaway className={styles.icon} />}
                    />
                </Grid>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'The average Australian eats more than <strong>25kg</strong> of beef each year. For every kilogram of beef produced, almost <strong>300</strong> kilograms of CO₂e is released.',
                    'Australian scientists have discovered a seaweed species that has the potential to reduce methane emissions from cows by up to <strong>99.9</strong>% when added to feed!',
                    'The average Australian diet results in <strong>14.5 kilograms</strong> of CO₂e per person per day. Reducing consumption of lamb and beef to <strong>50 grams</strong> per day could reduce agricultural emissions by <strong>22%</strong>!',
                ]}
            />
        </>
    );
};

export { Categories };
