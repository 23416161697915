import { Calculation } from './';
import { serialize, deserialize } from './serializers/wordpressSerializer';
import { wpApiSettings } from '../ConfigurationProvider';

const create = async (calculation: Calculation, wpApiSettings: wpApiSettings): Promise<string> => {
    const response = await fetch(`${wpApiSettings.root}wp/v2/calculations`, {
        headers: {
            'X-WP-Nonce': wpApiSettings.nonce,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: serialize(calculation),
    });
    const json = await response.json();
    return json.last_inserted_id;
};

const update = async (calculation: Calculation, wpApiSettings: wpApiSettings): Promise<void> => {
    await fetch(`${wpApiSettings.root}wp/v2/calculations/${calculation.id}`, {
        headers: {
            'X-WP-Nonce': wpApiSettings.nonce,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: serialize(calculation),
    });
};

const get = async (id: string, wpApiSettings: wpApiSettings): Promise<Calculation> => {
    const response = await fetch(`${wpApiSettings.root}wp/v2/calculations/${id}`, {
        headers: {
            'X-WP-Nonce': wpApiSettings.nonce,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    const json = await response.json();
    return json.data.map(deserialize).shift();
};

const getAll = async (limit: number = 10, wpApiSettings: wpApiSettings): Promise<Array<Calculation>> => {
    const response = await fetch(`${wpApiSettings.root}wp/v2/calculations?limit=${limit}`, {
        headers: {
            'X-WP-Nonce': wpApiSettings.nonce,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });
    const json = await response.json();
    return json.data.map(deserialize);
};

export { create, update, get, getAll };
