import { EmissionsElement } from '.';
import { TransportType } from './transport';

export enum TravelType {
    DomesticAir = 'domestic-air',
    InternationalAir = 'international-air',
    Train = 'train',
    CruiseShip = 'cruise-ship',
    Coach = 'coach',
    RoadTrip = 'road-trip',
}

export enum DomesticAirTravelType {
    Economy = 'economy',
    Business = 'business',
}

export enum InternationalAirTravelType {
    Economy = 'economy',
    PremiumEconomy = 'premium-economy',
    Business = 'business',
    First = 'first',
}

export enum TrainTravelType {
    Economy = 'economy',
    PremiumEconomy = 'premium-economy',
    Business = 'business',
    Sleeper = 'sleeper',
}

export enum CruiseShipTravelType {
    Cabin = 'cabin',
}

export enum CoachTravelType {
    Coach = 'coach',
}

export type RoadTripTransportType =
    | TransportType.Car
    | TransportType.SUV
    | TransportType.Van
    | TransportType.Motorcycle;

export type TravelEmissions = EmissionsElement & {
    duration: number;
};
