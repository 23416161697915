import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AtMostMD, AtLeastLG } from '../../utils/breakpoints';
import classNames from 'classnames';
import { CategoryButton } from './CategoryButton';
import { CategoryType } from 'src/models';
import { CategoriesState, RootState } from 'src/store';
import { ActionType as CategoryActionType } from 'src/store/actions/categories';
import { ActionType as NavigationActionType } from 'src/store/actions/navigation';
import { useNavigationState } from 'src/components/shared/Navigation';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { flushCategoriesState } from 'src/store/storage/flushCategoriesState';
import { ResultsButton } from './ResultsButton';
import { Popup } from './Popup';
import { CategoryLabels } from './CategoryLabels';
import { ButtonText } from '../shared/ButtonText';
import styles from './index.module.sass';

const CategoryProgress: React.FC = () => {
    //const [adding, setAdding] = useState(false);
    const dispatch = useDispatch();
    const { selected, completed } = useSelector<RootState, CategoriesState>(state => state.calculation.categories);
    const { index } = useNavigationState(NavigationKey.Categories);
    return (
        <div className={styles.root}>
            <AtMostMD className={styles.breakContainer}>
                <Popup
                    buttonStart={
                        <ButtonText
                            className={classNames(styles.button, styles.buttonPopup, styles.buttonStart)}
                            text="Category Selection"
                            onClick={() => {
                                document.body.removeAttribute('data-scroll-lock');
                                dispatch({
                                    type: NavigationActionType.NAVIGATE_TO,
                                    payload: {
                                        key: NavigationKey.Main,
                                        index: 1,
                                    },
                                });
                                dispatch({
                                    type: NavigationActionType.NAVIGATE_TO,
                                    payload: {
                                        key: NavigationKey.GettingStarted,
                                        index: 0,
                                    },
                                });
                            }}
                        />
                    }
                    buttonResults={
                        <ResultsButton
                            variant={'popup'}
                            className={classNames(styles.button, styles.buttonPopup, styles.buttonResults)}
                            completed={selected.length === completed.length}
                        />
                    }></Popup>
            </AtMostMD>
            <AtLeastLG className={styles.breakContainer}>
                <ButtonText
                    className={classNames(styles.button, styles.buttonDefault, styles.buttonStart)}
                    text="Category Selection"
                    onClick={() => {
                        document.body.removeAttribute('data-scroll-lock');
                        dispatch({
                            type: NavigationActionType.NAVIGATE_TO,
                            payload: {
                                key: NavigationKey.Main,
                                index: 1,
                            },
                        });
                        dispatch({
                            type: NavigationActionType.NAVIGATE_TO,
                            payload: {
                                key: NavigationKey.GettingStarted,
                                index: 0,
                            },
                        });
                    }}
                />
                {selected.map((type, i) => (
                    <CategoryButton
                        variant={'default'}
                        key={type}
                        className={styles.button}
                        type={type}
                        navigationIndex={i}
                        label={CategoryLabels[type]}
                        selected={i === index}
                        completed={completed.includes(type)}
                    />
                ))}
                <ResultsButton
                    variant={'default'}
                    className={classNames(styles.button, styles.buttonDefault, styles.buttonResults)}
                    completed={selected.length === completed.length}
                />
            </AtLeastLG>
        </div>
    );
};

export { CategoryProgress };
