import { BinSize, CollectionFrequency, PercentageFull } from 'src/models/waste';

export enum ActionType {
    RESET = 'WASTE:RESET',
    UPDATE = 'WASTE:UPDATE',
    UPDATE_EMISSIONS = 'WASTE:UPDATE_EMISSIONS',
}

export type ResetAction = {
    type: ActionType.RESET;
};

export type UpdateAction = {
    type: ActionType.UPDATE;
    payload: {
        binSize?: BinSize;
        collectionFrequency?: CollectionFrequency;
        percentageFull?: PercentageFull;
    };
};

export type UpdateEmissionsAction = {
    type: ActionType.UPDATE_EMISSIONS;
    payload: {
        emissions: number;
    };
};

export type Actions = ResetAction | UpdateAction | UpdateEmissionsAction;
