import React from 'react';
import { Provider } from 'react-redux';
import { Root } from 'src/components/Root';
import { useAuthentication } from './AuthenticationProvider';
import { Loader } from './Loader';
import { store } from './store';
import { getCookie, setCookie } from './utils/cookies';
import { useQueryParameter } from './utils/useQueryParameter';

const App = () => {
    const { isAuthenticated } = useAuthentication();
    const calculationId = useQueryParameter('calculation_id') || getCookie('calculation_id');
    if (isAuthenticated && calculationId && calculationId.length > 0) {
        setCookie('calculation_id', '');
        return (
            <Provider store={store}>
                <Loader calculationId={calculationId} />
            </Provider>
        );
    }
    return (
        <Provider store={store}>
            <Root />
        </Provider>
    );
};

export { App };
