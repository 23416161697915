import { store } from 'src/store';
import { serializeTransportState } from 'src/store/serializers/transportSerializers';

const flushTransportState = () => {
    const {
        calculation: { transport },
    } = store.getState();
    try {
        localStorage.setItem('cpos:transport', serializeTransportState(transport));
    } catch (err) {
        // TODO: update state with error
    }
};

export { flushTransportState };
