import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavigationPage } from '../NavigationPage';
import { Grid } from 'src/components/shared/Grid';
import { CheckboxControl } from 'src/components/shared/CheckboxControl';
import { ReactComponent as AirplaneInternational } from './AirplaneInternational.svg';
import { ReactComponent as AirplaneDomestic } from './AirplaneDomestic.svg';
import { ReactComponent as Train } from './Train.svg';
import { ReactComponent as Cruise } from './Cruise.svg';
import { ReactComponent as Coach } from './Coach.svg';
import { ReactComponent as RoadTrip } from './RoadTrip.svg';
import { TravelType } from 'src/models/travel';
import { RootState } from 'src/store';
import { ActionType } from 'src/store/actions/travel';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { useTimeframe } from 'src/store/utils/useTimeframe';
import styles from './Travel.module.sass';

const Travel: React.FC = () => {
    const dispatch = useDispatch();
    const { timeframeText } = useTimeframe();
    const selected = useSelector<RootState, Array<TravelType>>(state => state.calculation.travel.selected);
    return (
        <>
            <NavigationPage
                title="Travel"
                description={`Over the past ${timeframeText}, how has your household travelled long-distances?`}
                subtitle="Select all that apply"
                enableBack={true}
                enableNext={selected.length > 0}>
                <div className={styles.root}>
                    <Grid columns={{ default: 2, xs: 2, sm: 3, md: 3 }}>
                        <CheckboxControl
                            title="Road Trip"
                            icon={<RoadTrip className={styles.icon} />}
                            checked={selected.includes(TravelType.RoadTrip)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: TravelType.RoadTrip,
                                        selected: value,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            title="Coach"
                            icon={<Coach className={styles.icon} />}
                            checked={selected.includes(TravelType.Coach)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: TravelType.Coach,
                                        selected: value,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            title="Train"
                            icon={<Train className={styles.icon} />}
                            checked={selected.includes(TravelType.Train)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: TravelType.Train,
                                        selected: value,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            title="Air (Domestic)"
                            icon={<AirplaneDomestic className={styles.icon} />}
                            checked={selected.includes(TravelType.DomesticAir)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: TravelType.DomesticAir,
                                        selected: value,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            title="Air (International)"
                            icon={<AirplaneInternational className={styles.icon} />}
                            checked={selected.includes(TravelType.InternationalAir)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: TravelType.InternationalAir,
                                        selected: value,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            title="Cruise Ship"
                            icon={<Cruise className={styles.icon} />}
                            checked={selected.includes(TravelType.CruiseShip)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: TravelType.CruiseShip,
                                        selected: value,
                                    },
                                })
                            }
                        />
                    </Grid>
                </div>
            </NavigationPage>
            <DidYouKnow
                text={[
                    "Transport represents more than <strong>18%</strong> of Australia's total greenhouse gas emissions.",
                    'In 2016, the aviation industry agreed on a global scheme to address emissions from international air travel. Airlines must monitor and report their emissions and purchase carbon offsets for any growth in emissions above 2020 levels.',
                ]}
            />
        </>
    );
};

export { Travel };
