import React from 'react';
import classNames from 'classnames';
import { ReactComponent as Logo } from '../svg/Logo.svg';
import { TextNew } from 'src/components/shared/TextNew';
import { ButtonText } from '../shared/ButtonText';
import { Navigation } from './Navigation';
import { ReactComponent as New } from '../svg/New.svg';
import { ReactComponent as Save } from '../svg/Save.svg';
import { ReactComponent as Account } from '../svg/Account.svg';
import { ReactComponent as RightArrow } from '../svg/RightArrow.svg';
import { AtMostLG, AtLeastXL } from '../../utils/breakpoints';
import { ModalType, useUI } from '../UI';
import { DropdownMenu } from './DropdownMenu';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { CategoryType } from 'src/models';
import { useAuthentication } from 'src/AuthenticationProvider';
import { useNavigationState } from '../shared/Navigation';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { event } from 'src/utils/eventTracking';
import styles from './Header.module.sass';

const scrollCheck = (evt: any) => (document.body.dataset.hasScrolled = `${window.scrollY > 0}`);
window.addEventListener('scroll', scrollCheck, {
    passive: true,
});

const Header: React.FC = () => {
    const { showModal, reset, login, logout } = useUI();
    const { navigate } = useNavigationState(NavigationKey.Root);
    const { isAuthenticated } = useAuthentication();
    const selected = useSelector<RootState, Array<CategoryType>>(state => state.calculation.categories.selected);
    const newCalculationHandler = () => {
        if (selected.length > 0) {
            showModal({ type: ModalType.NEW });
        } else {
            event('new', 'new-calculation');
            reset();
        }
    };
    return (
        <section className={styles.root}>
            <a
                title="Click to visit our main site."
                className={styles.logo}
                href="https://carbonpositiveaustralia.org.au/"
                rel="noopener noreferrer"
                target="_blank">
                <Logo />
            </a>
            <TextNew tagName="h1" textSize={'S'} textStyle="smallCaps" textWeight={700} className={styles.title}>
                Carbon Footprint Calculator
                <TextNew tagName="small" textSize={'XS'} textWeight={400} className={styles.subtitle}>
                    Your footprint. Australia's Trees. Our Future.
                </TextNew>
            </TextNew>

            <AtLeastXL>
                <Navigation>
                    <li className={styles.menuItem}>
                        <ButtonText
                            className={styles.button}
                            variant="primary"
                            text={
                                <TextNew tagName="span" textSize={'M'} textStyle={'smallCaps'} textWeight={700}>
                                    New Calculation
                                </TextNew>
                            }
                            icon={<New />}
                            onClick={newCalculationHandler}
                        />
                    </li>
                    {isAuthenticated && (
                        <li className={styles.menuItem}>
                            <ButtonText
                                className={styles.button}
                                variant="primary"
                                text={
                                    <TextNew tagName="span" textSize={'M'} textStyle={'smallCaps'} textWeight={700}>
                                        Save Calculation
                                    </TextNew>
                                }
                                icon={<Save />}
                                onClick={() => showModal({ type: ModalType.SAVE })}
                            />
                        </li>
                    )}
                    {isAuthenticated === false && (
                        <li className={styles.menuItem}>
                            <ButtonText
                                className={styles.button}
                                variant="primary"
                                text={
                                    <TextNew tagName="span" textSize={'M'} textStyle={'smallCaps'} textWeight={700}>
                                        Create Account / Login
                                    </TextNew>
                                }
                                icon={<Account />}
                                onClick={() => login(undefined, undefined)}
                            />
                        </li>
                    )}
                    {isAuthenticated && (
                        <li className={classNames(styles.menuItem, styles.menuItemHasDropdown)}>
                            <DropdownMenu
                                header={
                                    <>
                                        <Account />
                                        <TextNew tagName="span" textSize={'M'} textStyle={'smallCaps'} textWeight={700}>
                                            Account
                                        </TextNew>
                                    </>
                                }
                            />
                        </li>
                    )}
                </Navigation>
            </AtLeastXL>

            <AtMostLG>
                <Navigation context="sidebar">
                    <li className={styles.menuItem}>
                        <ButtonText
                            iconAlignment={'right'}
                            className={styles.button}
                            variant="primary"
                            text="New"
                            icon={<RightArrow className={styles.buttonArrowLeft} />}
                            onClick={newCalculationHandler}
                        />
                    </li>
                    {isAuthenticated && (
                        <li className={styles.menuItem}>
                            <ButtonText
                                iconAlignment={'right'}
                                className={styles.button}
                                variant="primary"
                                text="Save"
                                icon={<RightArrow className={styles.buttonArrowLeft} />}
                                onClick={() => showModal({ type: ModalType.SAVE })}
                            />
                        </li>
                    )}
                    {isAuthenticated === false && (
                        <li className={styles.menuItem}>
                            <ButtonText
                                iconAlignment={'right'}
                                className={styles.button}
                                variant="primary"
                                text="Create Account / Login"
                                icon={<RightArrow className={styles.buttonArrowLeft} />}
                                onClick={() => {
                                    login(undefined, undefined);
                                    document.body.removeAttribute('data-scroll-lock');
                                }}
                            />
                        </li>
                    )}
                    {isAuthenticated && (
                        <li className={styles.menuItem}>
                            <ButtonText
                                className={styles.button}
                                variant="primary"
                                text="View Calculation History"
                                onClick={() => {
                                    navigate(1);
                                    document.body.removeAttribute('data-scroll-lock');
                                }}
                            />
                        </li>
                    )}
                    {isAuthenticated && (
                        <li className={styles.menuItem}>
                            <ButtonText className={styles.button} variant="primary" text="Logout" onClick={logout} />
                        </li>
                    )}
                </Navigation>
            </AtMostLG>
        </section>
    );
};

export { Header };
