import { TimeframeType } from 'src/models';

const Conversion = {
    weekly: {
        [TimeframeType.Month]: 4.35,
        [TimeframeType.Quarter]: 13.0,
        [TimeframeType.Year]: 52.0,
    },
    yearly: {
        [TimeframeType.Month]: 0.0833,
        [TimeframeType.Quarter]: 0.25,
        [TimeframeType.Year]: 1,
    },
};

export { Conversion };
