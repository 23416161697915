import React, { ReactNode } from 'react';
import { TextNew } from './TextNew';
import { Action } from 'src/types';
import styles from './Modal.module.sass';
import { Backdrop } from '../shared/Backdrop';
import { motion, AnimatePresence } from 'framer-motion';

type ModalRenderProps = {
    close: Action;
};

type ModalProps = {
    title: string;
    onClose?: Action;
    children: (props: ModalRenderProps) => ReactNode;
};

const spring = {
    type: 'spring',
    bounce: 0.4,
    duration: 0.5,
};

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
    const { children, onClose } = props;
    const [isOpen, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(true);
    }, []);

    const closeHandler = () => {
        setOpen(false);
        document.body.removeAttribute('data-scroll-lock');
    }

    return (
        <AnimatePresence onExitComplete={onClose}>
            {isOpen && (
                <div className={styles.root}>
                    <Backdrop key={'backdrop'} onClick={() => setOpen(false)} />
                    <motion.div
                        key={'modal'}
                        initial={{ opacity: 0, scale: 0.85 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.95 }}
                        transition={spring}
                        className={styles.modal}>
                        <TextNew className={styles.title} tagName={'h2'} textSize={'L'} textWeight={700}>
                            {props.title}
                        </TextNew>
                        <div className={styles.content}>{children({ close: closeHandler })}</div>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
};

export { Modal };
