import React, { useState } from 'react';
import classNames from 'classnames';
import { TextNew } from '../../shared/TextNew';
import { RadioButton } from 'src/components/shared/RadioButton';
import { KeyInsights } from './KeyInsights';
import { ImpactBreakdown } from './ImpactBreakdown';
import styles from './Detail.module.sass';

type DetailProps = {
    className: string;
};

const Detail: React.FC<DetailProps> = (props: DetailProps) => {
    const { className } = props;
    const [tabIndex, setTabIndex] = useState(0);
    return (
        <div className={classNames(className, styles.root)}>
            <div className={styles.header}>
                <TextNew className={styles.title} textSize={'XL'} tagName="h2" textWeight={700}>
                    Your impact in detail
                </TextNew>
                <div className={styles.buttons}>
                    <RadioButton
                        className={styles.button}
                        label="Key insights"
                        checked={tabIndex === 0}
                        onChange={() => {
                            setTabIndex(0);
                        }}
                    />
                    <RadioButton
                        className={styles.button}
                        label="Impact breakdown"
                        checked={tabIndex === 1}
                        onChange={() => {
                            setTabIndex(1);
                        }}
                    />
                </div>
            </div>
            <div className={styles.content}>
                {tabIndex === 0 && <KeyInsights />}
                {tabIndex === 1 && <ImpactBreakdown />}
            </div>
        </div>
    );
};

export { Detail };
