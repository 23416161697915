import React from 'react';
import { NavigationPage } from '../NavigationPage';
import { Form, FormBadge, FormControl } from 'src/components/shared/Forms';
import { ReactComponent as FoodByOverallSpend } from './FoodByOverallSpend.svg';
import { Grid } from 'src/components/shared/Grid';
import { Dropdown } from 'src/components/shared/Dropdown';
import { NumericInput } from 'src/components/shared/NumericInput';
import { RootState } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';
import { updateDiet } from 'src/store/actions/groceries/updateDiet';
import { DietType, DietryEmissions } from 'src/models/groceries';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { useTimeframe } from 'src/store/utils/useTimeframe';
import styles from './Diet.module.sass';

const Diet: React.FC = () => {
    const { diet, amount } = useSelector<RootState, DietryEmissions>(state => state.calculation.groceries.overall);
    const { timeframeText } = useTimeframe();
    const dispatch = useDispatch();
    return (
        <>
            <NavigationPage
                title="Food &amp; Drink - By Total Spend"
                description={`How much did your household typically spend on food and drinks each week?`}>
                <Form
                    size={'large'}
                    className={styles.root}
                    badge={
                        <FormBadge className={styles.formBadge} title="Total Spend" icon={<FoodByOverallSpend />} />
                    }>
                    <Grid columns={{ default: 1, md: 2 }} limit={false}>
                        <FormControl label="Diet Type">
                            <Dropdown
                                className={styles.input}
                                options={[
                                    { label: 'Omnivore', value: DietType.Omnivore },
                                    { label: 'Pescatarian', value: DietType.Pescatarian },
                                    { label: 'Vegetarian', value: DietType.Vegetarian },
                                    { label: 'Vegan', value: DietType.Vegan },
                                ]}
                                defaultValue={diet}
                                onValueChange={value => dispatch(updateDiet({ diet: value as DietType }))}
                            />
                        </FormControl>
                        <FormControl
                            label="Weekly Spend"
                            tooltipTitle="What does this include?"
                            tooltip="Includes grocery shopping, dining out, and takeaway purchases. Both alcoholic and non-alcoholic drinks should be included.">
                            <NumericInput
                                className={styles.input}
                                value={amount}
                                units="$/week"
                                unitsAlignment="right"
                                onValueChange={amount => dispatch(updateDiet({ amount }))}
                            />
                        </FormControl>
                    </Grid>
                </Form>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'The average Australian diet results in <strong>14.5 kilograms</strong> of CO₂e per person per day. Reducing consumption of lamb and beef to <strong>50 grams</strong> per day could reduce agricultural emissions by <strong>22%</strong>!',
                    'After Argentinians, Australians have the second most carbon-intensive diet in the world!',
                    '11% of food-related emissions come from transport. Eating only local foods for a year woud avert the carbon emissions associated with driving 1,600 kilometres in a passenger vehicle.',
                ]}
            />
        </>
    );
};

export { Diet };
