import { CategoryType, TimeframeType } from 'src/models';
import { EmissionsListItemType } from 'src/libs';
import { getOptionATitle } from './getOptionATitle';
import { getDescription } from './getDescription';
import { getOptionBorCTitle } from './getOptionBorCTitle';
import { getAverageComparisonSubtitle } from './getAverageComparisonSubtitle';

export type Insight = {
    key?: string;
    title: string;
    description: string;
};

const OptionD = {
    title: 'Calculate your total carbon footprint',
    description:
        'Next time you calculate your carbon footprint, try including additional sections for a more accurate calculation. Where possible, use your own data rather than the Australian averages.',
};

const OptionE = {
    title: 'Reduce, reuse & recycle',
    description:
        'Personal and household items such as clothing, furniture, appliances and toiletries also contribute to your carbon footprint. Before you make a new purchase, consider whether you could borrow from a family member, friend or neighbour. If not, could you find it second-hand? Buy local to reduce transportation emissions and support companies that implement sustainable and ethical practices.',
};

function createOptionA(item: EmissionsListItemType, total: number): Insight {
    return {
        key: item.key,
        title: getOptionATitle(item, total),
        description: getDescription(item),
    };
}

function createOptionBorC(item: EmissionsListItemType, timeframe: TimeframeType): Insight {
    const subtitle = getAverageComparisonSubtitle(item, timeframe);
    const description = getDescription(item);
    return {
        key: item.key,
        title: getOptionBorCTitle(item, timeframe),
        description: subtitle ? subtitle.concat('. ', description) : description,
    };
}

function getInsights(
    categories: Array<CategoryType>,
    items: Array<EmissionsListItemType>,
    timeframe: TimeframeType,
): Array<Insight> {
    items = items.filter(a => a.emissions > 0).sort((a, b) => b.emissions - a.emissions);

    if (items.length <= 0) {
        return [OptionD, OptionE];
    }

    if (categories.length === 1) {
        const optionB = createOptionBorC(items[0], timeframe);
        return [optionB, OptionD, OptionE];
    }

    const optionA = createOptionA(
        items[0],
        items.reduce((a, b) => b.emissions + a, 0),
    );

    const optionC = items.length === 1 ? undefined : createOptionBorC(items[1], timeframe);

    if (categories.length >= 2 && categories.length <= 5) {
        return [optionA, optionC ?? OptionE, OptionD];
    }

    return [optionA, optionC ?? OptionD, OptionE];
}

export { getInsights };
