import { WasteState } from 'src/store';
import stringify from './stringify';

const deserializeWasteState = (json: string): WasteState => {
    return JSON.parse(json, (key, value) => {
        switch (key) {
            case 'isUpdating':
                return false;
        }
        return value;
    });
};

const serializeWasteState = (state: WasteState): string => {
    return stringify(state);
};

export { serializeWasteState, deserializeWasteState };
