import { TransportState } from 'src/store';
import stringify from './stringify';

const deserializeTransportState = (json: string): TransportState => {
    return JSON.parse(json, (key, value) => {
        switch (key) {
            case 'isUpdating':
                return false;
        }
        return value;
    });
};

const serializeTransportState = (state: TransportState): string => {
    return stringify(state);
};

export { serializeTransportState, deserializeTransportState };
