import { CategoryType } from 'src/models';
import { BinSize } from 'src/models/waste';
import { WasteState } from 'src/store';
import { WasteListItemType } from '.';

function getWasteTitle(item: WasteListItemType): string {
    switch (item.binSize) {
        case BinSize.Small:
            return 'Small Bin';
        case BinSize.Medium:
            return 'Medium Bin';
        case BinSize.Large:
            return 'Large Bin';
    }
}

function getWasteTotal(state: WasteState) {
    return state.emissions;
}

function getWasteList(state: WasteState): Array<WasteListItemType> {
    return [{ ...state, key: 'waste', category: CategoryType.Waste }];
}

export { getWasteList, getWasteTotal, getWasteTitle };
