import React from 'react';
import { useSelector } from 'react-redux';
import getTotal from 'src/store/selectors/getTotal';
import { TextNew } from 'src/components/shared/TextNew';
import { Tooltip } from '../shared/Tooltip';
import { ReactComponent as Information } from 'src/components/svg/Information.svg';
import { ReactComponent as SidebarOpen } from 'src/components/svg/SidebarOpen.svg';
import { useUI } from '../UI';
import formatNumber from 'src/utils/formatNumber';
import styles from './index.module.sass';

const CalculationTotal: React.FC = props => {
    const { toggleSidebar } = useUI();
    const total = useSelector(getTotal);
    return (
        <section className={styles.root}>
            <TextNew
                className={styles.label}
                textSize={'XS'}
                textStyle={'smallCaps'}
                textWeight={700}
                tagName={'strong'}>
                Total Footprint
            </TextNew>

            <Tooltip text="Your carbon footprint is made up of more than just carbon dioxide and includes other greenhouse gases such as methane and nitrous oxide. Carbon dioxide equivalent (CO₂e) is a metric measure used to convert emissions from other greenhouse gases to the amount of carbon dioxide with the equivalent global warming potential. For example, the global warming potential of methane is 28. This means that emitting 1 metric tonne of methane is equivalent to emitting 28 tonnes of carbon dioxide.">
                <Information className={styles.buttonTooltip} />
            </Tooltip>

            <div className={styles.value} onClick={toggleSidebar}>
                <TextNew className={styles.valueTotal} textSize={'XXL'} textStyle={'title'} textWeight={700}>
                    {formatNumber(total / 1000, { fixedWidth: true, minimum: total !== 0 ? 0.01 : 0 })}
                </TextNew>
                <TextNew className={styles.valueUnits} textSize={'M'}>
                    {' '}
                    tCO<sub>2</sub>e
                </TextNew>
                {props.children}
            </div>
            <button className={styles.buttonSidebar} onClick={toggleSidebar}>
                <SidebarOpen />
            </button>
        </section>
    );
};

export { CalculationTotal };
