import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { TextNew } from 'src/components/shared/TextNew';
import { ColorVariant } from 'src/styles/ColorVariant';
import styles from './FormBadge.module.sass';

type FormBadgeProps = {
    className?: string;
    colorVariant?: ColorVariant;
    icon: ReactNode;
    title: string;
    subtitle?: string;
    children?: ReactNode;
};

const FormBadge: React.FC<FormBadgeProps> = (props: FormBadgeProps) => {
    const { className, colorVariant = undefined, icon, title, subtitle, children } = props;
    return (
        <div className={classNames(className, styles.root)} data-color-variant={colorVariant}>
            {icon}
            <TextNew className={styles.title} tagName={'h3'} textWeight={700}>
                {title}
            </TextNew>
            {subtitle && (
                <TextNew tagName={'span'} textStyle={'smallCaps'} textSize={'S'} textWeight={300}>
                    {subtitle}
                </TextNew>
            )}
            {children && children}
        </div>
    );
};

export { FormBadge };
