import React, { MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Button.module.sass';

export type ButtonVariant = 'primary' | 'outlined';

export type ButtonProps = {
    className?: string;
    variant?: ButtonVariant;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    children: ReactNode;
};

const Button = (props: ButtonProps) => {
    const { className, variant = 'primary', disabled, onClick, children } = props;
    const cls = classNames(className, styles.root, styles[variant]);
    return (
        <button disabled={disabled} className={cls} onClick={onClick}>
            {children}
        </button>
    );
};

export { Button };
