import { CategoryType, TimeframeType } from 'src/models';
import { EmissionsListItemType } from 'src/libs';
import { EnergyRegion, EnergyType } from 'src/models/energy';
import { CalculationType, TransportType } from 'src/models/transport';
import { getTransportDistanceAverage, getTransportFuelConsumptionAverage } from '../transport';
import { Conversion } from '../conversion';
import { getGroceriesAverage } from '../groceries';
import { getWaterAverage } from '../water';
import { WaterRegion } from 'src/models/water';
import { getEnergyAverage } from '../energy';

function formatNumber(value: number, round: boolean): string {
    return value.toLocaleString('en-AU', { maximumFractionDigits: round ? 0 : 2 });
}

function formatPercentageText(value: number): string {
    const THRESHOLD = 5;
    const percentage = Math.round((value - 1) * 100);
    if (Math.abs(percentage) < THRESHOLD) {
        return value > 1 ? 'slightly more than' : 'slightly less than';
    }
    if (value > 1) {
        return `${percentage.toLocaleString('en-AU', { maximumFractionDigits: 1 })}% more than`;
    }
    return `${Math.abs(percentage).toLocaleString('en-AU', { maximumFractionDigits: 1 })}% less than`;
}

function getAverageComparisonSubtitle(item: EmissionsListItemType, timeframe: TimeframeType): string | undefined {
    switch (item.category) {
        case CategoryType.Transport:
            switch (item.type) {
                case TransportType.Car:
                case TransportType.SUV:
                case TransportType.Van:
                    if (item.isAverage === false) {
                        switch (item.calculation) {
                            case CalculationType.Distance:
                                const distanceAverage = getTransportDistanceAverage({
                                    type: item.type,
                                    timeframe,
                                });
                                const distance = item.distance * Conversion.weekly[timeframe];
                                const distanceComparison = distance / distanceAverage;
                                return `This is ${formatPercentageText(
                                    distanceComparison,
                                )} the Australian average of ${formatNumber(distanceAverage, true)} km`;
                            case CalculationType.Fuel:
                                const fuelAverage = getTransportFuelConsumptionAverage({
                                    type: item.type,
                                    fuel: item.fuel,
                                    timeframe,
                                });
                                const fuel = item.consumption * Conversion.weekly[timeframe];
                                const fuelComparison = fuel / fuelAverage;
                                return `This is ${formatPercentageText(
                                    fuelComparison,
                                )} the Australian average of ${formatNumber(fuelAverage, true)} L`;
                        }
                    }
                    break;
                case TransportType.Motorcycle:
                    if (item.isAverage === false) {
                        switch (item.calculation) {
                            case CalculationType.Distance:
                                const distanceAverage = getTransportDistanceAverage({
                                    type: item.type,
                                    timeframe,
                                });
                                const distance = item.distance * Conversion.weekly[timeframe];
                                const distanceComparison = distance / distanceAverage;
                                return `This is ${formatPercentageText(
                                    distanceComparison,
                                )} the Australian average of ${formatNumber(distanceAverage, true)} km`;
                            case CalculationType.Fuel:
                                const fuelAverage = getTransportFuelConsumptionAverage({
                                    type: item.type,
                                    timeframe,
                                });
                                const fuel = item.consumption * Conversion.weekly[timeframe];
                                const fuelComparison = fuel / fuelAverage;
                                return `This is ${formatPercentageText(
                                    fuelComparison,
                                )} the Australian average of ${formatNumber(fuelAverage, true)} L`;
                        }
                    }
                    break;
            }
            break;
        case CategoryType.Groceries:
            if (item.type !== undefined) {
                if (item.isAverage === false) {
                    const average = getGroceriesAverage(item.type, timeframe);
                    const comparison =
                        (item.amount * Conversion.weekly[timeframe]) / getGroceriesAverage(item.type, timeframe);
                    return `This is ${formatPercentageText(comparison)} the Australian average of $${formatNumber(
                        average,
                        true,
                    )}`;
                }
            }
            break;
        case CategoryType.Energy:
            switch (item.type) {
                case EnergyType.Electricity:
                    if (item.isAverage === false) {
                        const average = getEnergyAverage(item.type, item.region!, timeframe);
                        const comparison = item.consumption / average;
                        if (item.region === EnergyRegion.Australia) {
                            return `This is ${formatPercentageText(
                                comparison,
                            )} than the average Australian household (${formatNumber(average, true)} kWh)`;
                        }
                        return `This is ${formatPercentageText(
                            comparison,
                        )} the average household in your state (${formatNumber(average, true)} kWh)`;
                    }
                    break;
                case EnergyType.NaturalGas:
                    if (item.isAverage === false) {
                        const average = getEnergyAverage(item.type, item.region!, timeframe);
                        const comparison = item.consumption / average;
                        const units = item.region === EnergyRegion.WA ? 'kwh' : 'MJ';
                        if (item.region === EnergyRegion.Australia) {
                            return `This is ${formatPercentageText(
                                comparison,
                            )} the average Australian household (${formatNumber(average, true)} ${units})`;
                        }
                        return `This is ${formatPercentageText(
                            comparison,
                        )} the average household in your state (${formatNumber(average, true)} ${units})`;
                    }
                    break;
            }
            break;
        case CategoryType.Water:
            if (item.isAverage === false) {
                const average = getWaterAverage(item.region, timeframe);
                const comparison = item.consumption / average;
                if (item.region === WaterRegion.Australia) {
                    return `This is ${formatPercentageText(
                        comparison,
                    )} the average Australian household (${formatNumber(average, true)} kL).`;
                }
                return `This is ${formatPercentageText(comparison)} the average household in your state (${formatNumber(
                    average,
                    true,
                )} kL)`;
            }
            return '';
    }
    return undefined;
}

export { getAverageComparisonSubtitle };
