import { Actions, ActionType } from '../actions/water';
import { WaterEmissions } from 'src/models/water';

export type WaterState = WaterEmissions;

const initialState = {
    region: undefined,
    consumption: 0,
    isAverage: false,
    emissions: 0,
};

const waterReducer = (state: WaterState = initialState, action: Actions) => {
    switch (action.type) {
        case ActionType.RESET:
            return initialState;
        case ActionType.UPDATE_REGION:
            return {
                ...state,
                region: action.payload.region,
            };
        case ActionType.UPDATE_CONSUMPTION:
            return {
                ...state,
                consumption: action.payload.consumption,
                isAverage: action.payload.isAverage,
            };
        case ActionType.UPDATE_EMISSIONS:
            return {
                ...state,
                emissions: action.payload.emissions,
            };
    }
    return state;
};

export { waterReducer };
