import { CategoryType } from 'src/models';

export const CategoryLabels = {
    [CategoryType.Transport]: 'Transport',
    [CategoryType.Energy]: 'Energy',
    [CategoryType.Water]: 'Water',
    [CategoryType.Travel]: 'Travel',
    [CategoryType.Groceries]: 'Food & Drink',
    [CategoryType.Waste]: 'Waste',
};
