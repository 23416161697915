import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Text, TextVariant } from 'src/components/shared/Text';
import { TextNew } from 'src/components/shared/TextNew';

import { ReactComponent as Question } from 'src/components/svg/Question.svg';
import { Tooltip } from '../Tooltip';

import styles from './FormControl.module.sass';

type FormControlProps = {
    className?: string;
    label: string | React.ReactNode;
    labelTextVariant?: TextVariant;
    tooltipTitle?: string;
    tooltip?: string;
    children: ReactNode;
    footer?: ReactNode;
};

const FormControl: React.FC<FormControlProps> = (props: FormControlProps) => {
    const { className, label, tooltipTitle, tooltip, children, footer } = props;

    const tooltipContent = (
        <div>
            <strong>{tooltipTitle}</strong>
            <p>{tooltip}</p>
        </div>
    );

    return (
        <div className={classNames(className, styles.root)} data-has-tooltip={Boolean(tooltip)}>
            <TextNew className={classNames(styles.formLabel)} textStyle={'smallCaps'} textSize={'S'} textWeight={700}>
                {label}
            </TextNew>
            {tooltip && (
                <Tooltip text={tooltipContent} iconElement={<Question />}>
                    <Question className={styles.tooltip} tabIndex={-1} />
                </Tooltip>
            )}
            <div className={styles.formInput}>{children}</div>
            {footer && <div className={styles.formFooter}>{footer}</div>}
        </div>
    );
};

export { FormControl };
