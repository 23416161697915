import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { NavigationRoot } from 'src/components/shared/Navigation';
import { TransportType } from 'src/models/transport';
import { Transport } from './Transport';
import { VehicleCount } from './VehicleCount';
import { Vehicles } from './Vehicles';
import { PublicTransport } from './PublicTransport';
import getVehicleCount from 'src/store/selectors/getVehicleCount';
import { flushTransportState } from 'src/store/storage/flushTransportState';
import { NavigationKey } from 'src/store/reducers/navigationReducer';

const TransportRoot: React.FC = () => {
    const selected = useSelector<RootState, Array<TransportType>>(state => state.calculation.transport.selected);
    const vehicleCount = useSelector(getVehicleCount);
    const hasVehicles =
        selected.includes(TransportType.Car) ||
        selected.includes(TransportType.Motorcycle) ||
        selected.includes(TransportType.Van) ||
        selected.includes(TransportType.SUV);
    return (
        <NavigationRoot navigationKey={NavigationKey.Transport} onNavigate={flushTransportState}>
            <Transport />
            {hasVehicles && <VehicleCount />}
            {hasVehicles && vehicleCount > 0 && <Vehicles />}
            {selected.includes(TransportType.PublicTransport) && <PublicTransport />}
        </NavigationRoot>
    );
};

export { TransportRoot };
