import { CalculationState, UIState } from 'src/store';

export enum ActionType {
    NEW = 'ROOT:NEW',
    SAVE = 'ROOT:SAVE',
    LOAD = 'ROOT:LOAD',
}

export type NewAction = {
    type: ActionType.NEW;
};

export type SaveAction = {
    type: ActionType.SAVE;
    // TODO
};

export type LoadAction = {
    type: ActionType.LOAD;
    payload: {
        ui: UIState;
        calculation: CalculationState;
    };
};

export type Actions = NewAction | SaveAction | LoadAction;
