import { ActionType, Actions, UpdateActionPayload } from '.';
import { ReduxThunkAction, TravelState } from 'src/store';
import { TravelType } from 'src/models/travel';
import { ConfigurationType } from 'src/configuration';
import strip from 'src/utils/strip';

type UpdateTravelProps = UpdateActionPayload;

function calculateEmissions(props: UpdateTravelProps, state: TravelState, configuration: ConfigurationType): number {
    const {
        emissions: { travel: factors },
    } = configuration;
    switch (props.type) {
        case TravelType.Coach:
            return factors[TravelType.Coach][props.subtype] * state[TravelType.Coach][props.subtype].duration;
        case TravelType.DomesticAir:
            return (
                factors[TravelType.DomesticAir][props.subtype] * state[TravelType.DomesticAir][props.subtype].duration
            );
        case TravelType.InternationalAir:
            return (
                factors[TravelType.InternationalAir][props.subtype] *
                state[TravelType.InternationalAir][props.subtype].duration
            );
        case TravelType.CruiseShip:
            return factors[TravelType.CruiseShip][props.subtype] * state[TravelType.CruiseShip][props.subtype].duration;
        case TravelType.Train:
            return factors[TravelType.Train][props.subtype] * state[TravelType.Train][props.subtype].duration;
    }
}

const updateTravel = (props: UpdateTravelProps): ReduxThunkAction<Actions> => {
    return (dispatch, getState, configuration) => {
        dispatch({
            type: ActionType.UPDATE,
            payload: {
                ...props,
            },
        });
        const {
            calculation: { travel },
        } = getState();
        dispatch({
            type: ActionType.UPDATE_EMISSIONS,
            payload: {
                ...strip(props, ['duration']),
                emissions: calculateEmissions(props, travel, configuration),
            },
        });
    };
};

export { updateTravel };
