import { store } from 'src/store';
import { serializeTravelState } from 'src/store/serializers/travelSerializers';

const flushTravelState = () => {
    const {
        calculation: { travel },
    } = store.getState();
    try {
        localStorage.setItem('cpos:travel', serializeTravelState(travel));
    } catch (err) {
        // TODO: update state with error
    }
};

export { flushTravelState };
