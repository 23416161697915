import React, { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { NavigationPage } from '../NavigationPage';
import { Carousel } from 'src/components/shared/Carousel';
import { useNavigation, useNavigationState } from 'src/components/shared/Navigation';
import { NavigationContext, NavigationContextType } from 'src/components/shared/Navigation/Navigation';
import { flushTravelState } from 'src/store/storage/flushTravelState';
import { ActionType } from 'src/store/actions/navigation';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { TravelType, RoadTripTransportType } from 'src/models/travel';
import { RoadTripTravelState } from 'src/store/reducers/travelReducer';
import { TransportType } from 'src/models/transport';
import { RoadTripVehicle } from './RoadTripVehicle';
import { RoadTripMotorcycle } from './RoadTripMotorcycle';
import { useTimeframe } from 'src/store/utils/useTimeframe';

const useVehicleFacts = (index: number) => {
    const { vehicleCount } = useSelector<RootState, RoadTripTravelState>(
        state => state.calculation.travel[TravelType.RoadTrip],
    );
    return useMemo<Array<string>>(() => {
        function getNormalizedType() {
            const array = Object.entries(vehicleCount).flatMap(([key, value]) => {
                const type = key as RoadTripTransportType;
                return Array.from({ length: value }, () => type);
            });
            return array[array.length - 1];
        }
        switch (getNormalizedType()) {
            case TransportType.Car:
                return [
                    'Hybrid vehicles can save you money AND lower your carbon footprint. They combine the power of a petrol engine with the efficiency of an electric motor - charging the battery while you drive.',
                    "Cars are responsible for roughly half of Australia's transport emissions, emitting roughly the same as Queensland's entire electricity supply!",
                    'Electric vehicles represent less than 1% of new car sales in Australia. Compare this to Norway where almost half of all new car sales are electric!',
                ];
            case TransportType.SUV:
                return [
                    'The average fuel economy of an Australian passenger vehicle is 11.1 litres per 100 kilometres (l/100km). The average for a medium-sized hybrid is less than a third of this at just 3.4 l/100km!',
                    'Per passenger, train travel produces up to 98% less emissions per kilometre than the average Australian car.',
                    'Sydney has the lowest proportion of commuters driving to work of all Australian capitals, and the highest proportion of commuters using public transport.',
                ];
            case TransportType.Van:
                return [
                    "Transport is Australia's second largest source of greenhouse gas emissions, with the highest rate of growth.",
                    'If you filled a balloon with the equivalent of 1 tonne of carbon dioxide, it would be roughly the same size as a two-storey house!',
                    'Less than 10% of Australian households get by without a car while most have at least two cars.',
                ];
            case TransportType.Motorcycle:
                return [
                    'Brisbane has 2 million fewer people than Melbourne, but almost 1,700 more motorcycle commuters!',
                    "The average fuel economy of an Australian motorbike is 6.1 litres per 100 kilometres (l/100km). That's almost half the fuel economy of the average car!",
                    'Motorcycles and scooters are popular for commuting as they require less fuel and are cheaper to run than cars.  There are almost 900,000 motorcycles in Australia, accounting for around 6% of all passenger vehicle registrations.',
                ];
        }
    }, [index, vehicleCount]);
};

const useVehicleComponents = () => {
    const { vehicleCount } = useSelector<RootState, RoadTripTravelState>(
        state => state.calculation.travel[TravelType.RoadTrip],
    );
    return useMemo<Array<ReactNode>>(() => {
        return Object.entries(vehicleCount).flatMap(([key, value]) => {
            const type = key as RoadTripTransportType;
            return Array.from({ length: value }, (_, index) => {
                switch (type) {
                    case TransportType.Car:
                    case TransportType.SUV:
                    case TransportType.Van:
                        return <RoadTripVehicle key={`${key}_${index}`} type={type} index={index} />;
                    case TransportType.Motorcycle:
                        return <RoadTripMotorcycle key={`${key}_${index}`} index={index} />;
                }
            });
        });
    }, [vehicleCount]);
};

const RoadTripVehicles = () => {
    const { index, dispatch } = useNavigationState(NavigationKey.TravelRoadTripVehicles);
    const facts = useVehicleFacts(index);
    const children = useVehicleComponents();
    const navigation = useNavigation();
    const { navigate } = useNavigationState(NavigationKey.TravelRoadTripVehicles);
    const actions = useMemo<NavigationContextType>(() => {
        return {
            back: () => {
                if (index === 0) {
                    navigation.back();
                    flushTravelState();
                    return;
                }
                dispatch({
                    type: ActionType.NAVIGATE_TO,
                    payload: {
                        key: NavigationKey.TravelRoadTripVehicles,
                        index: index - 1,
                    },
                });
                flushTravelState();
            },
            next: () => {
                if (index === children.length - 1) {
                    navigation.next();
                    flushTravelState();
                    return;
                }
                dispatch({
                    type: ActionType.NAVIGATE_TO,
                    payload: {
                        key: NavigationKey.TravelRoadTripVehicles,
                        index: index + 1,
                    },
                });
                flushTravelState();
            },
        };
    }, [dispatch, navigation, index]);
    const { timeframeText } = useTimeframe();
    return (
        <>
            <NavigationContext.Provider value={actions}>
                <NavigationPage
                    title="Travel - Road Trip"
                    description={`Tell us more about your vehicle's road trip activity over the past ${timeframeText}.`}
                    tooltip='This section relates to long-distance or "out of the ordinary" travel only. You can account for typical commuting around your local area in the Transport section.'>
                    <Carousel index={index} onClick={navigate}>
                        {children}
                    </Carousel>
                </NavigationPage>
            </NavigationContext.Provider>
            <DidYouKnow text={facts} />
        </>
    );
};

export { RoadTripVehicles };
