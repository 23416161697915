import { TransportType, CalculationType, MotorcycleEmissions, FuelType } from 'src/models/transport';
import { ActionType, Actions } from '.';
import { ReduxThunkAction } from 'src/store';
import { TimeframeType } from 'src/models';
import { ConfigurationType } from 'src/configuration';
import { Conversion } from 'src/libs/conversion';
import strip from 'src/utils/strip';

function calculateEmissions(
    motorcycle: MotorcycleEmissions,
    timeframe: TimeframeType,
    configuration: ConfigurationType,
): number {
    const factor = configuration.emissions.transport.vehicles[FuelType.Petrol];
    switch (motorcycle.calculation) {
        case CalculationType.Fuel:
            return factor * motorcycle.consumption * Conversion.weekly[timeframe];
        case CalculationType.Distance:
            const fuelEconomy = configuration.averages.transport.economy[TransportType.Motorcycle];
            return (motorcycle.distance / 100) * fuelEconomy * factor * Conversion.weekly[timeframe];
    }
}

type UpdateMotorcycleProps = {
    index: number;
    calculation?: CalculationType;
    consumption?: number;
    distance?: number;
    isAverage?: boolean;
};

const updateMotorcycle = (props: UpdateMotorcycleProps): ReduxThunkAction<Actions> => {
    const { index } = props;
    return (dispatch, getState, configuration) => {
        dispatch({
            type: ActionType.UPDATE_MOTORCYCLE,
            payload: strip(props),
        });

        const {
            calculation: {
                metadata: { timeframe },
                transport: {
                    [TransportType.Motorcycle]: { [index]: motorcycle },
                },
            },
        } = getState();

        dispatch({
            type: ActionType.UPDATE_MOTORCYCLE,
            payload: {
                index,
                emissions: calculateEmissions(motorcycle, timeframe, configuration),
            },
        });
    };
};

export { updateMotorcycle };
