import React, { ReactNode, useEffect, useState } from 'react';

export type wpApiSettings = {
    root: string;
    nonce: string;
};

declare global {
    interface Window {
        wpApiSettings: wpApiSettings;
    }
}

export type ConfigurationContextType = {
    wpApiSettings: {
        root: string;
        nonce: string;
    };
    logoutUrl: string | null | undefined;
    unitPrice: number;
};

const ConfigurationContext = React.createContext<ConfigurationContextType | null>(null);

function useConfiguration(): ConfigurationContextType {
    const context = React.useContext(ConfigurationContext);
    if (context === null) {
        throw Error('The ConfigurationContext has not yet been initialized.');
    }
    return context;
}

type ConfigurationProviderProps = {
    children: ReactNode;
};

function ConfigurationProvider(props: ConfigurationProviderProps) {
    const { children } = props;
    const cposRootElement = document.getElementById('cpos-root');
    const logoutUrl = cposRootElement?.getAttribute('data-logout-url');

    const [unitPrice, setUnitPrice] = useState(18);

    const { root, nonce } = window.wpApiSettings ?? { root: '', nonce: '' };

    useEffect(() => {
        const load = async () => {
            try {
                const response = await fetch(`${root}wp/v2/offset-product-price`, {
                    headers: {
                        'X-WP-Nonce': nonce,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                if (data.data.unit_price) {
                    setUnitPrice(data.data.unit_price);
                }
            } catch (e) {
                console.log(e);
            }
        };
        load();
    }, []);

    return (
        <ConfigurationContext.Provider value={{ wpApiSettings: { root, nonce }, logoutUrl, unitPrice }}>
            {children}
        </ConfigurationContext.Provider>
    );
}

export { ConfigurationProvider, useConfiguration };
