import { DietryEmissions, DietType } from 'src/models/groceries';
import { Actions, ActionType } from '.';
import { ReduxThunkAction } from 'src/store';
import strip from 'src/utils/strip';
import { TimeframeType } from 'src/models';
import { ConfigurationType } from 'src/configuration';
import { Conversion } from 'src/libs/conversion';

function calculateEmissions(diet: DietryEmissions, timeframe: TimeframeType, configuration: ConfigurationType): number {
    const factor = configuration.emissions.groceries.diet[diet.diet];
    return factor * diet.amount * Conversion.weekly[timeframe];
}

type UpdateDietProps = {
    diet?: DietType;
    amount?: number;
};

const updateDiet = (props: UpdateDietProps): ReduxThunkAction<Actions> => {
    return (dispatch, getState, configuration) => {
        dispatch({
            type: ActionType.UPDATE,
            payload: strip(props),
        });

        const {
            calculation: {
                metadata: { timeframe },
                groceries: { overall: diet },
            },
        } = getState();

        dispatch({
            type: ActionType.UPDATE_EMISSIONS,
            payload: {
                emissions: calculateEmissions(diet, timeframe, configuration),
            },
        });
    };
};

export { updateDiet };
