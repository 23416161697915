import React from 'react';
import classNames from 'classnames';
import { Action } from 'src/types';
import { UIElementProps } from '..';
import styles from './ButtonIcon.module.sass';

type ButtonIconProps = UIElementProps & {
    onClick: Action,
    label: string;
    icon: React.ReactNode;
};

const ButtonIcon: React.FC<ButtonIconProps> = (props: ButtonIconProps) => {
    const { className } = props;
    return (
        <button className={classNames(className, styles.root)} onClick={props.onClick}>
            <span className={styles.label}>{props.label}</span>
            {props.icon}
        </button>
    );
};

export { ButtonIcon };
