import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { UIElementProps } from 'src/components';
import { ButtonText } from 'src/components/shared/ButtonText';
import { Throbber } from 'src/components/shared/Throbber';
import { ReactComponent as RightArrow } from 'src/components/svg/RightArrow.svg';
import { ModalType, useUI } from 'src/components/UI';
import { saveCalculation } from 'src/store/actions/root/saveCalculation';
import { ReduxDispatch } from 'src/store';
import { useAuthentication } from 'src/AuthenticationProvider';
import { useConfiguration } from 'src/ConfigurationProvider';
import styles from './OffsetButton.module.sass';

type OffsetButtonProps = UIElementProps & {
    total: number;
};

const OffsetButton = (props: OffsetButtonProps) => {
    const { className, total } = props;
    const dispatch = useDispatch<ReduxDispatch>();
    const { showModal, offset, login } = useUI();
    const [isSaving, setSaving] = useState(false);
    const { isAuthenticated } = useAuthentication();
    const { wpApiSettings } = useConfiguration();

    const save = async () => {
        setSaving(true);
        const calculationId = await dispatch(saveCalculation(wpApiSettings));
        setSaving(false);
        return calculationId;
    };

    const clickHandler = async () => {
        // if the user is already authenticated then we
        // force the save and proceed to the offset page
        if (isAuthenticated === true) {
            const calculationId = await save();
            if (calculationId) {
                offset(total, calculationId);
            }
            return;
        }

        // the user isnt authenticated so we need to give them the
        // choice to offset as a guest or login/create an account
        showModal({
            type: ModalType.OFFSET_AS_GUEST,
            onGuest: () => offset(total, undefined),
            onLogin: async () => {
                const calculationId = await save();
                if (calculationId) {
                    login(total, calculationId);
                }
            },
        });
    };

    return (
        <ButtonText
            className={classNames(className, styles.root)}
            variant="primary"
            text={isSaving ? <Throbber /> : 'Proceed to offset'}
            icon={!isSaving && <RightArrow className={styles.buttonArrowRight} />}
            iconAlignment="right"
            onClick={clickHandler}
        />
    );
};

export { OffsetButton };
