import React from 'react';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';
import { CalculationMethod } from 'src/models/groceries';
import { Groceries } from './Groceries';
import { Categories } from './Categories';
import { Diet } from './Diet';
import { NavigationRoot } from 'src/components/shared/Navigation';
import { flushGroceriesState } from 'src/store/storage/flushGroceriesState';
import { NavigationKey } from 'src/store/reducers/navigationReducer';

const GroceriesRoot: React.FC = () => {
    const calculationMethod = useSelector<RootState, CalculationMethod | undefined>(
        state => state.calculation.groceries.calculationMethod,
    );
    return (
        <NavigationRoot navigationKey={NavigationKey.Groceries} onNavigate={flushGroceriesState}>
            <Groceries />
            {calculationMethod === CalculationMethod.Category && <Categories />}
            {calculationMethod === CalculationMethod.Spend && <Diet />}
        </NavigationRoot>
    );
};

export { GroceriesRoot };
