import React, { ReactNode } from 'react';
import { NavigationPage } from '../NavigationPage';
import { Grid } from 'src/components/shared/Grid';
import { NumericInputForm } from 'src/components/shared/NumericInputForm';
import { ReactComponent as Economy } from './Economy.svg';
import { ReactComponent as PremiumEconomy } from './PremiumEconomy.svg';
import { ReactComponent as Business } from './BusinessClass.svg';
import { ReactComponent as FirstClass } from './FirstClass.svg';
import { TravelType, InternationalAirTravelType } from 'src/models/travel';
import { useDispatch, useSelector } from 'react-redux';
import { updateTravel } from 'src/store/actions/travel/updateTravel';
import { RootState } from 'src/store';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { useTimeframe } from 'src/store/utils/useTimeframe';
import styles from './InternationalAir.module.sass';

type InternationalAirInputProps = {
    title: string;
    subtype: InternationalAirTravelType;
    icon: ReactNode;
};

const InternationalAirInput: React.FC<InternationalAirInputProps> = (props: InternationalAirInputProps) => {
    const { title, subtype, icon } = props;
    const dispatch = useDispatch();
    const duration = useSelector<RootState, number>(
        state => state.calculation.travel[TravelType.InternationalAir][subtype].duration,
    );
    return (
        <NumericInputForm
            title={title}
            icon={icon}
            value={duration}
            units="hours"
            onValueChange={value =>
                dispatch(
                    updateTravel({
                        type: TravelType.InternationalAir,
                        subtype,
                        duration: value,
                    }),
                )
            }
        />
    );
};

const InternationalAir: React.FC = () => {
    const { timeframeText } = useTimeframe();
    return (
        <>
            <NavigationPage
                title="Travel - Air, International"
                description={`Over the past ${timeframeText}, how many hours has your household travelled internationally by air?`}
                tooltip="To account for your whole household, make sure you multiply the flight time by the number of household members on each flight.">
                <div className={styles.root}>
                    <Grid columns={{ xs: 2, sm: 2, md: 4 }}>
                        <InternationalAirInput
                            title="Economy"
                            subtype={InternationalAirTravelType.Economy}
                            icon={<Economy className={styles.icon} />}
                        />
                        <InternationalAirInput
                            title="Premium Economy"
                            subtype={InternationalAirTravelType.PremiumEconomy}
                            icon={<PremiumEconomy className={styles.icon} />}
                        />
                        <InternationalAirInput
                            title="Business"
                            subtype={InternationalAirTravelType.Business}
                            icon={<Business className={styles.icon} />}
                        />
                        <InternationalAirInput
                            title="First Class"
                            subtype={InternationalAirTravelType.First}
                            icon={<FirstClass className={styles.icon} />}
                        />
                    </Grid>
                </div>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'Flying first class more than triples your per-passenger carbon footprint!',
                    'Aviation accounts for around 2% of global greenhouse gas emissions. To put that in perspective, if it were a country, it would be in the top 10 emitters!',
                    "The carbon footprint of a passenger flying economy class from Sydney to London <strong>3,300kg CO₂e</strong>! That's equivalent to driving <strong>25km</strong> to and from work everyday for <strong>a year</strong>!",
                ]}
            />
        </>
    );
};

export { InternationalAir };
