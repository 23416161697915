import React from 'react';
import { ToastProvider } from 'src/components/shared/ToastProvider';
import { Main } from './Main';
import { flushUIState } from 'src/store/storage/flushUIState';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { NavigationRoot } from './shared/Navigation';
import { Layout } from './layout/Layout';
import { History } from './pages/history';
import { Information } from './pages/information';
import { UIProvider } from './UI';

const Root = () => {
    return (
        <UIProvider>
            <ToastProvider>
                <Layout>
                    <NavigationRoot navigationKey={NavigationKey.Root} onNavigate={flushUIState}>
                        <Main />
                        <History />
                        <Information />
                    </NavigationRoot>
                </Layout>
            </ToastProvider>
        </UIProvider>
    );
};

export { Root };
