import React, { useState } from 'react';
import { useAsync } from 'react-use';
import { ButtonText } from '../../shared/ButtonText';
import { Page } from '../Page';
import { List } from './List';
import { Calculation } from 'src/api';
import { getAll } from 'src/api/calculation';
import { useNavigationState } from 'src/components/shared/Navigation';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { Throbber } from 'src/components/shared/Throbber';
import { useConfiguration } from 'src/ConfigurationProvider';
import styles from './index.module.sass';

const History = () => {
    const { wpApiSettings } = useConfiguration();
    const state = useAsync(() => getAll(10, wpApiSettings), []);
    const [calculation, setCalculation] = useState<Calculation | undefined>(undefined);
    const { navigate } = useNavigationState(NavigationKey.Root);
    return (
        <Page
            title="Calculation History"
            description="Browse both your previous saved and completed calculations. Open them in the calculator to pick up where you left off.">
            <div className={styles.root}>
                <ButtonText text="Go back to calculator" variant="outlined" onClick={() => navigate(0)} />
                {state.loading && <Throbber className={styles.loading} variant="primary" />}
                {!state.loading && !state.error && state.value && (
                    <div className={styles.container}>
                        <List
                            className={styles.list}
                            calculations={state.value}
                            selected={calculation}
                            onChange={setCalculation}
                        />
                    </div>
                )}
            </div>
        </Page>
    );
};

export { History };
