import React from 'react';
import { NavigationPage } from '../NavigationPage';
import { RegionSelector } from './RegionSelector';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store';
import { ActionType } from 'src/store/actions/energy';
import { EnergyType, EnergyRegion } from 'src/models/energy';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';

const NaturalGasRegion: React.FC = () => {
    const dispatch = useDispatch();
    const region = useSelector<RootState, EnergyRegion | undefined>(
        state => state.calculation.energy[EnergyType.NaturalGas].region,
    );
    return (
        <>
            <NavigationPage
                title="Energy - Natural Gas"
                description="Which region governs your natural gas supply?"
                enableBack={true}
                enableNext={region !== undefined}>
                <RegionSelector
                    region={region}
                    onRegionChange={value =>
                        dispatch({
                            type: ActionType.UPDATE_REGION,
                            payload: {
                                type: EnergyType.NaturalGas,
                                region: value,
                            },
                        })
                    }
                />
            </NavigationPage>
            <DidYouKnow
                text={[
                    'One carbon offset represents the prevention, reduction, or removal of one tonne of carbon dioxide equivalent (tCO₂e) from the atmosphere.',
                    'Trees remove carbon dioxide from the atmosphere and convert it into organic carbon as they grow, storing it in their trunks, stems, leaves and roots.',
                    "The South West region of Western Australia has been identified as one of only <strong>35</strong> globally significant biodiversity 'hotspots'. Combined, these hotspots account for over <strong>90%</strong> of all species on earth. ",
                ]}
            />
        </>
    );
};

export { NaturalGasRegion };
