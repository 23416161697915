import React from 'react';
import styles from './Copyright.module.sass';
import { TextNew } from '../shared/TextNew';
import { useNavigationState } from '../shared/Navigation';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { ShareFooter } from './ShareFooter';

type copyrightContext = 'sidebar' | 'default';
type copyrightProps = {
    context?: copyrightContext;
};

const Copyright = (props: copyrightProps) => {
    const { context = 'default' } = props;
    const { navigate } = useNavigationState(NavigationKey.Root);
    return (
        <section className={styles.root} data-context={context}>
            <div className={styles.wrapper}>
                <TextNew className={styles.copyrightLabel} textSize={'XS'}>
                    © 2024 — Carbon Positive Australia
                </TextNew>
                <TextNew
                    className={styles.copyrightLink}
                    tagName={'button'}
                    textWeight={700}
                    textSize={'XXS'}
                    onClick={() => {
                        document.body.removeAttribute('data-scroll-lock');
                        navigate(2);
                    }}>
                    How did we calculate this?
                </TextNew>
                <ShareFooter className={styles.copyrightSharing} />
                <TextNew
                    className={styles.copyrightColophon}
                    tagName={'p'}
                    textSize={'XXS'}
                    onClick={() => navigate(2)}>
                    This web app was designed by{' '}
                    <strong>
                        <a rel="noopener noreferrer" target="_blank" href="https://www.weareanthologie.com/">
                            Anthologie
                        </a>
                    </strong>{' '}
                    and developed in collaboration with{' '}
                    <strong>
                        <a rel="noopener noreferrer" target="_blank" href="https://carbonpositiveaustralia.org.au/">
                            Carbon Positive Australia
                        </a>
                    </strong>
                </TextNew>
            </div>
        </section>
    );
};

export { Copyright };
