import { createSelector } from 'reselect';
import { RootState } from 'src/store';
import { CategoryType } from 'src/models';
import getTransportTotal from './getTransportTotal';
import getEnergyTotal from './getEnergyTotal';
import getWaterTotal from './getWaterTotal';
import getWasteTotal from './getWasteTotal';
import getTravelTotal from './getTravelTotal';
import getGroceriesTotal from './getGroceriesTotal';

export default createSelector(
    (state: RootState) => state.calculation.categories.selected,
    getTransportTotal,
    getEnergyTotal,
    getWaterTotal,
    getTravelTotal,
    getGroceriesTotal,
    getWasteTotal,
    (categories, transport, energy, water, travel, groceries, waste) => {
        let emissions = 0;
        if (categories.includes(CategoryType.Transport)) {
            emissions += transport;
        }
        if (categories.includes(CategoryType.Energy)) {
            emissions += energy;
        }
        if (categories.includes(CategoryType.Travel)) {
            emissions += travel;
        }
        if (categories.includes(CategoryType.Groceries)) {
            emissions += groceries;
        }
        if (categories.includes(CategoryType.Waste)) {
            emissions += waste;
        }
        if (categories.includes(CategoryType.Water)) {
            emissions += water;
        }
        return emissions;
    },
);
