import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './SocialSharingLinks.module.sass';
import getTotal from 'src/store/selectors/getTotal';
import { useTimeframe } from 'src/store/utils/useTimeframe';

import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
} from 'react-share';
import { UIElementProps } from 'src/components';

const URL = window.location.href;
const useQuote = () => {
    const { timeframeText } = useTimeframe();
    const total = useSelector(getTotal);
    return `I know my number, do you? My personal carbon footprint for the past ${timeframeText} is ${Number(
        total / 1000,
    ).toFixed(
        2,
    )} tonnes CO2e. Use Carbon Positive Australia's free Carbon Footprint Calculator to see how you compare.`;
};

const SocialSharingLinks = (props: UIElementProps) => {
    const { className } = props;
    const quote = useQuote();
    return (
        <section className={classNames(className, styles.root)}>
            <FacebookShareButton className={styles.button} url={URL} quote={quote}>
                <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton className={styles.button} url={URL} title={quote}>
                <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton className={styles.button} url={URL}>
                <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
        </section>
    );
};

export { SocialSharingLinks };
