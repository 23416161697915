import React, { ReactNode } from 'react';
import { TransportType } from 'src/models/transport';
import { NavigationPage } from '../NavigationPage';
import { Grid } from 'src/components/shared/Grid';
import { ReactComponent as Car } from './Car.svg';
import { ReactComponent as SUV } from './SUV.svg';
import { ReactComponent as Van } from './Van.svg';
import { ReactComponent as Motorcycle } from './Motorcycle.svg';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store';
import { NumericInputForm } from 'src/components/shared/NumericInputForm';
import { ActionType } from 'src/store/actions/transport';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';

type VehicleCountInputProps = {
    title: string;
    tooltip: string;
    type: TransportType.Car | TransportType.SUV | TransportType.Van | TransportType.Motorcycle;
    icon: ReactNode;
};

const VehicleCountInput: React.FC<VehicleCountInputProps> = (props: VehicleCountInputProps) => {
    const { title, tooltip, type, icon } = props;
    const dispatch = useDispatch();
    const count = useSelector<RootState, number>(state => state.calculation.transport.vehicleCount[type] || 0);
    return (
        <NumericInputForm
            title={title}
            tooltip={tooltip}
            icon={icon}
            minValue={0}
            maxValue={9}
            decimalScale={0}
            value={count}
            onValueChange={value =>
                dispatch({
                    type: ActionType.SET_VEHICLE_COUNT,
                    payload: {
                        type: type,
                        count: value,
                    },
                })
            }
        />
    );
};

const VehicleCount: React.FC = () => {
    const selected = useSelector<RootState, Array<TransportType>>(state => state.calculation.transport.selected);
    const columnMax = selected.filter(el => el !== TransportType.PublicTransport).length;
    return (
        <>
            <NavigationPage title="Transport - Vehicles" description="How many vehicles does your household drive?">
                <Grid columns={{ xs: Math.min(2, columnMax), sm: Math.min(2, columnMax), md: Math.min(4, columnMax) }}>
                    {selected.includes(TransportType.Car) && (
                        <VehicleCountInput
                            title="Cars"
                            type={TransportType.Car}
                            tooltip="This category includes hatchbacks, sedans and station wagons. For example, a Toyota Corolla, Mazda 6 or Holden Commodore."
                            icon={<Car />}
                        />
                    )}
                    {selected.includes(TransportType.SUV) && (
                        <VehicleCountInput
                            title="SUVs"
                            type={TransportType.SUV}
                            tooltip="This category includes sports utility vehicles (SUVs) and 4WDs with higher ground clearance than a car. For example, a Subaru XV, Toyota Rav 4, Toyota Hilux, or Nissan Patrol."
                            icon={<SUV />}
                        />
                    )}
                    {selected.includes(TransportType.Van) && (
                        <VehicleCountInput
                            title="People Movers"
                            type={TransportType.Van}
                            tooltip="This category includes family wagons and vans that carry 6 or more passengers. For example, a Kia Carnival or Toyota Tarago."
                            icon={<Van />}
                        />
                    )}
                    {selected.includes(TransportType.Motorcycle) && (
                        <VehicleCountInput
                            title="Motorcycles"
                            type={TransportType.Motorcycle}
                            tooltip="This category includes motorcycles and scooters. For example, a Kawasaki NINJA 400, Honda NSC110 or Vespa GTS 300."
                            icon={<Motorcycle />}
                        />
                    )}
                </Grid>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'In Australia, transport represents over <strong>18%</strong> of total greenhouse gas emissions.',
                    'The average Australian passenger vehicle travels over <strong>11,000</strong> kilometres each year, releasing around <strong>2.9</strong> tonnes of CO₂e into the atmosphere. ',
                ]}
            />
        </>
    );
};

export { VehicleCount };
