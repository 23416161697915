import React from 'react';
import { NavigationRoot } from 'src/components/shared/Navigation';
import { Welcome } from './pages/welcome';
import { GettingStartedRoot } from './pages/getting-started/GettingStartedRoot';
import { Summary } from './pages/summary';
import { CategoryRoot } from './pages/CategoryRoot';
import { flushUIState } from 'src/store/storage/flushUIState';
import { NavigationKey } from 'src/store/reducers/navigationReducer';

const Main: React.FC = () => {
    return (
        <NavigationRoot navigationKey={NavigationKey.Main} onNavigate={flushUIState}>
            <Welcome />
            <GettingStartedRoot />
            <CategoryRoot />
            <Summary />
        </NavigationRoot>
    );
};

export { Main };
