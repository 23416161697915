import { TimeframeType } from 'src/models';

export default function getTimeframeText(timeframe: TimeframeType): string {
    switch (timeframe) {
        case TimeframeType.Month:
            return 'month';
        case TimeframeType.Quarter:
            return '3 months';
        case TimeframeType.Year:
            return 'year';
    }
}
