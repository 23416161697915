import React, { ReactNode } from 'react';
import styles from './ModalControls.module.sass';

type ModalControlsProps = {
    children: any
};

const ModalControls: React.FC<ModalControlsProps> = (props: ModalControlsProps) => {
    return (
        <div className={styles.root}>
            {props.children}
        </div>
    );
};

export { ModalControls };