import React from 'react';
import classNames from 'classnames';
import { ReactComponent as LeftArrow } from '../svg/LeftArrow.svg';
import { ReactComponent as RightArrow } from '../svg/RightArrow.svg';
import { ButtonText } from 'src/components/shared/ButtonText';
import styles from './NavigationButtons.module.sass';

type NavigationButtonsProps = {
    className: string;
    enableBack: boolean;
    enableNext: boolean;
    onBackClick?: () => void;
    onNextClick?: () => void;
};

const NavigationButtons: React.FC<NavigationButtonsProps> = (props: NavigationButtonsProps) => {
    const { className, enableBack, enableNext, onBackClick, onNextClick } = props;
    return (
        <div className={classNames(className, styles.root)}>
            <ButtonText
                className={styles.button}
                variant="outlined"
                text="Back"
                icon={<LeftArrow className={styles.arrow} />}
                disabled={!enableBack}
                onClick={onBackClick}
            />
            <ButtonText
                className={styles.button}
                variant="primary"
                text="Next"
                icon={<RightArrow className={styles.arrow} />}
                iconAlignment="right"
                disabled={!enableNext}
                onClick={onNextClick}
            />
        </div>
    );
};

export { NavigationButtons };
