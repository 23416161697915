import { GroceryType, CalculationMethod, DietType } from 'src/models/groceries';
import { CategoryType, TimeframeType } from 'src/models';
import { GroceriesState } from 'src/store';
import { GroceriesListItemType } from '.';
import { Conversion } from './conversion';
import { configuration } from 'src/configuration';
import capitalize from 'src/utils/capitalize';
import round from 'src/utils/round';

function getGroceriesAverage(type: GroceryType | undefined, timeframe: TimeframeType): number {
    const averages = configuration.averages[CategoryType.Groceries];
    if (type !== undefined) {
        return round(averages.category[type] * Conversion.weekly[timeframe]);
    }
    // we dont differentiate in the app
    //return round(averages.diet[DietType.Omnivore] * Conversion.weekly[timeframe]);
    return round(273.75 * Conversion.weekly[timeframe]);
}

function getGroceriesSubtitle(item: GroceriesListItemType): string {
    return `$${item.amount.toLocaleString('en-AU', { maximumFractionDigits: 2 })}/week`;
}

function getGroceriesTitle(item: GroceriesListItemType): string {
    switch (item.type) {
        case GroceryType.AlcoholicDrinks:
            return 'Alcoholic Drinks';
        case GroceryType.BreadAndCereals:
            return 'Bread & Cereals';
        case GroceryType.EggsAndDairy:
            return 'Eggs & Dairy';
        case GroceryType.FruitAndNuts:
            return 'Fruit & Nuts';
        case GroceryType.Meat:
            return 'Meat';
        case GroceryType.NonAlcoholicDrinks:
            return 'Non-Alcoholic Drinks';
        case GroceryType.ProcessedFood:
            return 'Processed Food';
        case GroceryType.DiningOutOrTakeaway:
            return 'Dining Out & Takeaway';
        case GroceryType.Seafood:
            return 'Seafood';
        case GroceryType.Vegetables:
            return 'Vegetables';
    }
    return `Total Spend (${capitalize(item.diet)})`;
}

function getGroceriesTotal(state: GroceriesState) {
    if (state.calculationMethod === CalculationMethod.Category) {
        return Object.values(GroceryType).reduce((a, b) => a + state.categories[b].emissions, 0);
    }
    return state.overall.emissions;
}

function getGroceriesList(state: GroceriesState): Array<GroceriesListItemType> {
    if (state.calculationMethod === CalculationMethod.Spend) {
        return [
            {
                ...state.overall,
                key: `groceries:${CalculationMethod.Spend}`,
                category: CategoryType.Groceries,
                type: undefined,
            },
        ];
    }
    return Object.entries(state.categories).map<GroceriesListItemType>(([key, value]) => {
        return {
            ...value,
            key: `groceries:${CalculationMethod.Category}:${key}`,
            category: CategoryType.Groceries,
            type: key as GroceryType,
        };
    });
}

export { getGroceriesList, getGroceriesTotal, getGroceriesTitle, getGroceriesSubtitle, getGroceriesAverage };
