import { PublicTransportEmissions, PublicTransportType, TransportType } from 'src/models/transport';
import { CategoryType, TimeframeType } from 'src/models';
import { ActionType, Actions } from '.';
import { ReduxThunkAction } from 'src/store';
import { Conversion } from 'src/libs/conversion';
import { ConfigurationType } from 'src/configuration';

function calculateEmissions(
    type: PublicTransportType,
    state: PublicTransportEmissions,
    timeframe: TimeframeType,
    configuration: ConfigurationType,
): number {
    const factor = configuration.emissions[CategoryType.Transport][TransportType.PublicTransport][type];
    return state.distance * factor * Conversion.weekly[timeframe];
}

type UpdatePublicTransportProps = {
    type: PublicTransportType;
    distance: number;
};

const updatePublicTransport = (props: UpdatePublicTransportProps): ReduxThunkAction<Actions> => {
    const { distance, type } = props;
    return (dispatch, getState, configuration) => {
        dispatch({
            type: ActionType.UPDATE_PUBLIC_TRANSPORT,
            payload: {
                type,
                distance,
            },
        });

        const {
            calculation: {
                metadata: { timeframe },
                transport: {
                    [TransportType.PublicTransport]: { [type]: publicTransport },
                },
            },
        } = getState();

        dispatch({
            type: ActionType.UPDATE_PUBLIC_TRANSPORT_EMISSIONS,
            payload: {
                type,
                emissions: calculateEmissions(type, publicTransport, timeframe, configuration),
            },
        });
    };
};

export { updatePublicTransport };
