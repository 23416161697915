import React from 'react';
import { useDispatch } from 'react-redux';
import { ActionType as NavigationActionType } from 'src/store/actions/navigation';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { UIElementProps } from '..';
import { ButtonText } from '../shared/ButtonText';
import { ModalType, useUI } from '../UI';

type ResultsButtonVariant = 'default' | 'popup';

type ResultsButtonProps = UIElementProps & {
    completed: boolean;
    variant: ResultsButtonVariant;
};

const ResultsButton = (props: ResultsButtonProps) => {
    const { className, completed } = props;
    const dispatch = useDispatch();
    const { showModal } = useUI();
    const onClickHandler = () => {
        if (completed) {
            document.body.removeAttribute('data-scroll-lock');
            dispatch({
                type: NavigationActionType.NAVIGATE_TO,
                payload: {
                    key: NavigationKey.Main,
                    index: 3,
                },
            });
        } else {
            showModal({ type: ModalType.INCOMPLETE_RESULTS_WARNING });
        }
    };
    return <ButtonText variant={'primary'} className={className} text="Results" onClick={onClickHandler} />;
};

export { ResultsButton };
