import React, { useState, EventHandler, FocusEvent, useEffect } from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { TextNew } from './TextNew';
import styles from './NumericInput.module.sass';

type FocusEventHandler<T = Element> = EventHandler<FocusEvent<T>>;

export type UnitsAlignment = 'left' | 'right';

interface NumericInputProps {
    className?: string;
    disabled?: boolean;
    tabIndex?: number;
    units?: string;
    unitsAlignment?: UnitsAlignment;
    minValue?: number;
    maxValue?: number;
    decimalScale?: number;
    value?: number;
    onValueChange?: (value: number) => void;
    onBlur?: FocusEventHandler<Element>;
}

const NumericInput: React.FC<NumericInputProps> = (props: NumericInputProps) => {
    const {
        className,
        disabled = false,
        tabIndex = undefined,
        units,
        unitsAlignment = 'right',
        minValue = 0,
        maxValue = Number.MAX_SAFE_INTEGER,
        decimalScale = 2,
        value,
        onValueChange,
    } = props;
    const [val, setVal] = useState(value);
    useEffect(() => {
        if (val !== value) {
            setVal(value);
        }
    }, [value]);
    return (
        <div
            className={classNames(className, styles.root)}
            onBlur={() => {
                const v = val || 0;
                if (v !== value && onValueChange) {
                    onValueChange(v);
                }
            }}>
            {units && unitsAlignment === 'left' && (
                <TextNew className={classNames(styles.units, styles.left)} textSize={'S'} textWeight={400}>
                    {units}
                </TextNew>
            )}
            <NumberFormat
                className={styles.input}
                disabled={disabled}
                tabIndex={tabIndex}
                placeholder="0"
                thousandSeparator={true}
                decimalScale={decimalScale}
                allowLeadingZeros={false}
                allowNegative={false}
                isAllowed={values => {
                    const { floatValue = 0 } = values;
                    return floatValue >= minValue && floatValue <= maxValue;
                }}
                value={val === 0 ? undefined : val}
                onValueChange={values => {
                    setVal(parseFloat(values.value));
                }}
            />
            {units && unitsAlignment === 'right' && (
                <TextNew className={classNames(styles.units, styles.right)} textSize={'S'} textWeight={400}>
                    {units}
                </TextNew>
            )}
        </div>
    );
};

export { NumericInput };
