import React from 'react';
import classNames from 'classnames';
import { TextNew } from 'src/components/shared/TextNew';
import { EnergyRegion } from 'src/models/energy';
import styles from './UseRegionAveragesButton.module.sass';

type UseRegionAveragesButtonProps = {
    className?: string;
    region: EnergyRegion;
    onClick: () => void;
};

const UseRegionAveragesButton: React.FC<UseRegionAveragesButtonProps> = (props: UseRegionAveragesButtonProps) => {
    const { className, region, onClick } = props;
    return (
        <button className={classNames(className, styles.root)} onClick={onClick}>
            <TextNew textSize={'XS'} textWeight={700} textStyle={'smallCaps'}>
                {region ? `Use average for ${region}` : 'Use Australian averages'}
            </TextNew>
        </button>
    );
};

export { UseRegionAveragesButton };
