import { combineReducers } from 'redux';
import { categoriesReducer } from './categoriesReducer';
import { transportReducer } from './transportReducer';
import { groceriesReducer } from './groceriesReducer';
import { wasteReducer } from './wasteReducer';
import { waterReducer } from './waterReducer';
import { energyReducer } from './energyReducer';
import { travelReducer } from './travelReducer';
import { metadataReducer } from './metadataReducer';

const calculationReducer = combineReducers({
    metadata: metadataReducer,
    categories: categoriesReducer,
    transport: transportReducer,
    groceries: groceriesReducer,
    waste: wasteReducer,
    water: waterReducer,
    energy: energyReducer,
    travel: travelReducer,
});

export type CalculationState = ReturnType<typeof calculationReducer>;

export { calculationReducer };
