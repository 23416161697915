import { WaterRegion } from 'src/models/water';

export enum ActionType {
    RESET = 'WATER:RESET',
    UPDATE_REGION = 'WATER:UPDATE_REGION',
    UPDATE_CONSUMPTION = 'WATER:UPDATE_CONSUMPTION',
    UPDATE_EMISSIONS = 'WATER:UPDATE_EMISSIONS',
}

export type ResetAction = {
    type: ActionType.RESET;
};

export type UpdateRegionAction = {
    type: ActionType.UPDATE_REGION;
    payload: {
        region: WaterRegion | undefined;
    };
};

export type UpdateConsumptionAction = {
    type: ActionType.UPDATE_CONSUMPTION;
    payload: {
        consumption: number;
        isAverage: boolean;
    };
};

export type UpdateEmissionsAction = {
    type: ActionType.UPDATE_EMISSIONS;
    payload: {
        emissions: number;
    };
};

export type Actions = ResetAction | UpdateRegionAction | UpdateConsumptionAction | UpdateEmissionsAction;
