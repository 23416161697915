import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { TextNew } from '../shared/TextNew';
import getTotal from 'src/store/selectors/getTotal';
import styles from './Total.module.sass';
import formatNumber from 'src/utils/formatNumber';

type TotalProps = {
    className: string;
};

const Total: React.FC<TotalProps> = (props: TotalProps) => {
    const { className } = props;
    const total = useSelector(getTotal);
    return (
        <div className={classNames(className, styles.root)}>
            <TextNew className={styles.label} textWeight={700} tagName={'strong'}>
                Footprint so far
            </TextNew>
            <div className={styles.total}>
                <TextNew className={styles.totalValue} textWeight={700} textSize={'XXL'} tagName={'span'}>
                    {formatNumber(total / 1000, { fixedWidth: true, minimum: total !== 0 ? 0.01 : 0 })}
                </TextNew>
                <TextNew className={styles.totalUnits} textWeight={400} textSize={'XL'} tagName={'span'}>
                    {' '}
                    tCO<sub>2</sub>e
                </TextNew>
            </div>
        </div>
    );
};

export { Total };
