import { GroceriesState } from 'src/store';
import stringify from './stringify';

const deserializeGroceriesState = (json: string): GroceriesState => {
    return JSON.parse(json, (key, value) => {
        switch (key) {
            case 'isUpdating':
                return false;
        }
        return value;
    });
};

const serializeGroceriesState = (state: GroceriesState): string => {
    return stringify(state);
};

export { serializeGroceriesState, deserializeGroceriesState };
