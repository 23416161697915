import React from 'react';
import { NavigationPage } from '../NavigationPage';
import { Form, FormBadge, FormControl } from 'src/components/shared/Forms';
import { Grid } from 'src/components/shared/Grid';
import { ReactComponent as LPG } from './LPG.svg';
import { NumericInput } from 'src/components/shared/NumericInput';
import { RootState } from 'src/store';
import { EnergyType } from 'src/models/energy';
import { useDispatch, useSelector } from 'react-redux';
import { updateConsumption } from 'src/store/actions/energy/updateConsumption';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { useTimeframe } from 'src/store/utils/useTimeframe';

const LPGConsumption: React.FC = () => {
    const { timeframeText } = useTimeframe();
    const consumption = useSelector<RootState, number>(state => state.calculation.energy[EnergyType.LPG].consumption);
    const dispatch = useDispatch();
    return (
        <>
            <NavigationPage
                title="Energy - LPG"
                description={`Over the past ${timeframeText}, how much LPG has your household consumed?`}
                tooltip="This includes both plumbed LPG systems and LPG bottles used for outdoor cooking.">
                <Form size={'large'} colorVariant={3} badge={<FormBadge colorVariant={3} title="LPG" icon={<LPG />} />}>
                    <Grid columns={{ default: 1, sm: 1, md: 1 }}>
                        <FormControl
                            label="Consumed"
                            tooltipTitle="How do I calculate this?"
                            tooltip="LPG consumption is measured in kilograms (kg). For plumbed LPG you can find your consumption on your bill or via your online account. For outdoor cooking, the size (in kg) is printed on the side of the LPG bottle. In Australia, bottles are generally 4kg or 9kg. By determining the size of the bottle and how often it needs replacing you can estimate your LPG consumption for the period.">
                            <NumericInput
                                value={consumption}
                                units="kg"
                                onValueChange={value =>
                                    dispatch(updateConsumption({ type: EnergyType.LPG, consumption: value }))
                                }
                            />
                        </FormControl>
                    </Grid>
                </Form>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'Almost <strong>two-thirds</strong> of Australian households own a barbeque!',
                    'Compared to charcoal, using LPG to power your barbeque can reduce your outdoor cooking footprint by almost <strong>two-thirds</strong>!',
                ]}
            />
        </>
    );
};

export { LPGConsumption };
