import React from 'react';
import { ReactComponent as SidebarClose } from 'src/components/svg/SidebarClose.svg';
import { useUI } from '../UI';
import { TextNew } from '../shared/TextNew';
import styles from './Footer.module.sass';
import { AtLeastXS } from 'src/utils/breakpoints';


const Footer: React.FC = () => {
    const { toggleSidebar } = useUI();

    const closeHandler = () => {
        toggleSidebar();
        document.body.removeAttribute('data-scroll-lock');
    };

    return (
        <button className={styles.root} onClick={closeHandler}>
            <TextNew className={styles.button} textSize={'XS'} textWeight={700} textStyle={'smallCaps'}>
                Collapse Panel
            </TextNew>
            <AtLeastXS>
                <SidebarClose className={styles.icon} />
            </AtLeastXS>
        </button>
    );
};

export { Footer };
