import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTransportDistanceAverage, getTransportFuelConsumptionAverage } from 'src/libs/transport';
import { TimeframeType } from 'src/models';
import { TransportType, MotorcycleEmissions, DefaultMotorcycleEmissions, FuelType } from 'src/models/transport';
import { RootState } from 'src/store';
import { updateMotorcycle } from 'src/store/actions/transport/updateMotorcycle';
import round from 'src/utils/round';
import { MotorcycleForm } from '../shared/MotorcycleForm';

type MotorcycleProps = {
    index: number;
};

const Motorcycle = (props: MotorcycleProps) => {
    const { index } = props;
    const dispatch = useDispatch();
    const motorcycle = useSelector<RootState, MotorcycleEmissions>(
        state => state.calculation.transport[TransportType.Motorcycle][index] || DefaultMotorcycleEmissions,
    );
    return (
        <MotorcycleForm
            title={`Motorcycle ${index + 1}`}
            colorVariant={4}
            motorcycle={motorcycle}
            weekly={true}
            disabled={false}
            onCalculationUpdate={calculation => dispatch(updateMotorcycle({ index, calculation }))}
            onConsumptionUpdate={consumption => dispatch(updateMotorcycle({ index, consumption, isAverage: false }))}
            onDistanceUpdate={distance => dispatch(updateMotorcycle({ index, distance, isAverage: false }))}
            onFuelAverageClick={() => {
                const average = getTransportFuelConsumptionAverage({
                    type: TransportType.Motorcycle,
                    timeframe: TimeframeType.Year,
                });
                dispatch(
                    updateMotorcycle({
                        index,
                        consumption: round(average / 52),
                        isAverage: true,
                    }),
                );
            }}
            onDistanceAverageClick={() => {
                const average = getTransportDistanceAverage({
                    type: TransportType.Motorcycle,
                    timeframe: TimeframeType.Year,
                });
                dispatch(updateMotorcycle({ index, distance: round(average / 52), isAverage: true }));
            }}
        />
    );
};

export { Motorcycle };
