export type EmissionsElement = {
    emissions: number;
};

export enum CategoryType {
    Transport = 'transport',
    Energy = 'energy',
    Water = 'water',
    Travel = 'travel',
    Groceries = 'groceries',
    Waste = 'waste',
}

export enum TimeframeType {
    Month = 'month',
    Quarter = 'quarter',
    Year = 'year',
}
