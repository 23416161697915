import React from 'react';
import classNames from 'classnames';
import { TextNew } from '../shared/TextNew';
import { ReactComponent as Check } from 'src/components/svg/Check.svg';
import { CategoryType } from 'src/models';
import { ActionType } from 'src/store/actions/navigation';
import { useDispatch } from 'react-redux';
import { getCorrespondingNavigationKeys } from './getCorrespondingNavigationKeys';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import styles from './CategoryButton.module.sass';

type CategoryButtonVariant = 'default' | 'popup';

type CategoryButtonProps = {
    className?: string;
    variant: CategoryButtonVariant;
    type: CategoryType;
    navigationIndex: number;
    label: string;
    selected: boolean;
    completed: boolean;
    onClick?: () => void;
};

const CategoryButton: React.FC<CategoryButtonProps> = (props: CategoryButtonProps) => {
    const dispatch = useDispatch();
    const { className, variant = 'default', type, navigationIndex, label, selected, completed, onClick } = props;
    const onClicked = () => {
        onClick && onClick();
        dispatch({
            type: ActionType.RESET,
            payload: {
                keys: getCorrespondingNavigationKeys(type),
            },
        });
        dispatch({
            type: ActionType.NAVIGATE_TO,
            payload: {
                key: NavigationKey.Categories,
                index: navigationIndex,
            },
        });
    };
    return (
        <div
            className={classNames(styles.root, styles[variant], { [styles.selected]: selected })}
            onClick={onClicked}
            data-category={type}>
            <TextNew className={classNames(className, styles.button)} tagName={'button'} textSize={'S'}>
                {label}
            </TextNew>
            {completed && <Check className={styles.check} />}
        </div>
    );
};

export { CategoryButton };
