import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Scrollable.module.sass';

type ScrollableProps = {
    className?: string;
    children: ReactNode;
};

const Scrollable: React.FC<ScrollableProps> = (props: ScrollableProps) => {
    const { className, children } = props;
    return <div className={classNames(className, styles.root)}>{children}</div>;
};

export { Scrollable };
