import React from 'react';
import { NavigationPage } from '../NavigationPage';
import { useNavigationState } from 'src/components/shared/Navigation';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { Form, FormBadge, FormControl } from 'src/components/shared/Forms';
import { Grid } from 'src/components/shared/Grid';
import { ReactComponent as Electricity } from './Electricity.svg';
import { UseRegionAveragesButton } from './UseRegionAveragesButton';
import { NumericInput } from 'src/components/shared/NumericInput';
import { RootState } from 'src/store';
import { EnergyType, RegionalEnergyEmissions, EnergyRegion } from 'src/models/energy';
import { useDispatch, useSelector } from 'react-redux';
import { updateConsumption } from 'src/store/actions/energy/updateConsumption';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { TextNew } from 'src/components/shared/TextNew';
import { getEnergyAverage } from 'src/libs/energy';
import { useTimeframe } from 'src/store/utils/useTimeframe';
import styles from './ElectricityConsumption.module.sass';

const ElectricityConsumption: React.FC = () => {
    const { navigate } = useNavigationState(NavigationKey.Root);
    const { timeframe, timeframeText } = useTimeframe();
    const { region = EnergyRegion.Australia, consumption } = useSelector<RootState, RegionalEnergyEmissions>(
        state => state.calculation.energy.electricity,
    );
    const dispatch = useDispatch();
    return (
        <>
            <NavigationPage
                title="Energy - Electricity"
                description={`Over the past ${timeframeText}, how much electricity has your household consumed?`}>
                <Form
                    size={'large'}
                    colorVariant={1}
                    badge={
                        <FormBadge
                            colorVariant={1}
                            title="Electricity"
                            subtitle={region ?? 'Australian Average'}
                            icon={<Electricity />}
                        />
                    }>
                    <Grid columns={{ default: 1, sm: 1, md: 1 }} limit={true}>
                        <FormControl
                            label="Consumed"
                            tooltipTitle="Where can I find this?"
                            tooltip="Electricity consumption is measured in kilowatt-hours (kWh). You can find this figure on your electricity bill or via your online account. Alternatively, you can select to use the average household electricity consumption for your state."
                            footer={
                                <UseRegionAveragesButton
                                    region={region}
                                    onClick={() => {
                                        const average = getEnergyAverage(EnergyType.Electricity, region, timeframe);
                                        dispatch(
                                            updateConsumption({
                                                type: EnergyType.Electricity,
                                                consumption: average,
                                                isAverage: true,
                                            }),
                                        );
                                    }}
                                />
                            }>
                            <NumericInput
                                value={consumption}
                                units="kWh"
                                onValueChange={value =>
                                    dispatch(updateConsumption({ type: EnergyType.Electricity, consumption: value }))
                                }
                            />
                        </FormControl>
                        <div>
                            <TextNew className={styles.label} tagName={'div'} textStyle={'smallCaps'} textSize={'S'} textWeight={700}>
                                Solar Panel Owners
                            </TextNew>
                            <TextNew tagName={'p'} textSize={'XS'} textWeight={400}>If your residence has solar panels installed please enter your Total Imported kWh for the period. This figure represents the electricity you have consumed from the grid and does not include the clean energy consumed from your home solar system. Learn more about&nbsp;
                            <TextNew
                                tagName={'a'}
                                textStyle={'link'}
                                textWeight={400}
                                textSize={'XS'}
                                onClick={() => {
                                    document.body.removeAttribute('data-scroll-lock');
                                    navigate(2);
                                }}>
                                how we calculate this
                            </TextNew>
                            .</TextNew>
                        </div>
                    </Grid>
                </Form>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'Leaving your appliances on standby could cost you hundreds of dollars a year. Try a power board with a master switch to turn off multiple items at once!',
                    'More than <strong>20%</strong> of Australian households now have rooftop solar panels installed.',
                    'Electric ovens are great for roasting or baking but they use a lot of power. The fan-forced setting helps the oven operate at a lower temperature, using less energy and saving you money! ',
                ]}
            />
        </>
    );
};

export { ElectricityConsumption };
