import React from 'react';
import classNames from 'classnames';
import styles from './Checkbox.module.sass';
import { ReactComponent as Checkmark } from '../svg/Checkmark.svg';

type CheckboxProps = {
    className: string;
    checked: boolean;
}

const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
    const {
        className,
        checked,
    } = props;
    return (
        <div className={classNames(className, styles.root, checked && styles.checked)}>
            <Checkmark className={classNames(styles.checkmark)} />
            {/* <div className={classNames(styles.checkmark, checked && styles.checked)} /> */}
        </div>
    );
}

export { Checkbox };