import React, { ReactNode } from 'react';
import { TextNew } from 'src/components/shared/TextNew';
import styles from './Category.module.sass';

type CategoryProps = {
    id: string;
    title: string;
    children: ReactNode;
};

const Category = (props: CategoryProps) => {
    const { id, title, children } = props;
    return (
        <>
            <TextNew id={id} className={styles.title} tagName={'h2'} textSize={'M'} textWeight={700}>
                {title}
            </TextNew>
            {children}
        </>
    );
};

export { Category };
