const clearState = () => {
    try {
        localStorage.removeItem('cpos:ui');
        localStorage.removeItem('cpos:metadata');
        localStorage.removeItem('cpos:categories');
        localStorage.removeItem('cpos:timeframe');
        localStorage.removeItem('cpos:transport');
        localStorage.removeItem('cpos:energy');
        localStorage.removeItem('cpos:groceries');
        localStorage.removeItem('cpos:travel');
        localStorage.removeItem('cpos:water');
        localStorage.removeItem('cpos:waste');
    } catch (err) {
        // TODO: update state with error
    }
};

export { clearState };
