import React, { ReactNode } from 'react';
import { NavigationPage } from '../NavigationPage';
import { Grid } from 'src/components/shared/Grid';
import { ReactComponent as Taxi } from './Taxi.svg';
import { ReactComponent as Bus } from './Bus.svg';
import { ReactComponent as Train } from './Train.svg';
import { ReactComponent as Tram } from './Tram.svg';
import { ReactComponent as Ferry } from './Ferry.svg';
import { PublicTransportEmissions, PublicTransportType, TransportType } from 'src/models/transport';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { NumericInputForm } from 'src/components/shared/NumericInputForm';
import { updatePublicTransport } from 'src/store/actions/transport/updatePublicTransport';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import styles from './PublicTransport.module.sass';

type DistanceInputProps = {
    className?: string;
    title: string | React.ReactNode;
    tooltip: string;
    type: PublicTransportType;
    icon: ReactNode;
};

const DistanceInput: React.FC<DistanceInputProps> = (props: DistanceInputProps) => {
    const { className, title, tooltip, type, icon } = props;
    const dispatch = useDispatch();
    const emissions = useSelector<RootState, PublicTransportEmissions>(
        state => state.calculation.transport[TransportType.PublicTransport][type],
    );
    return (
        <NumericInputForm
            className={className}
            title={title}
            tooltip={tooltip}
            icon={icon}
            value={emissions.distance}
            units="km/week"
            onValueChange={distance => dispatch(updatePublicTransport({ type, distance }))}
        />
    );
};

const PublicTransport: React.FC = () => {
    return (
        <>
            <NavigationPage
                title="Transport - Public Transport"
                description="For each of the following modes of transport, how far did your household typically travel each week?"
                tooltip='This section relates to typical commuting around your local area only. You can account for long-distance or "out of the ordinary" trips in the Travel section.'>
                <Grid columns={{ xs: 2, sm: 3, md: 3, xl: 3 }}>
                    <DistanceInput
                        title={
                            <>
                                Taxi/<wbr></wbr>Rideshare
                            </>
                        }
                        type={PublicTransportType.Taxi}
                        tooltip="Includes travel by taxi, through rideshare platforms, and as a passenger in a car with friends, family or colleagues."
                        icon={<Taxi className={styles.icon} />}
                    />
                    <DistanceInput
                        title="Bus"
                        type={PublicTransportType.Bus}
                        tooltip="Includes metropolitan bus travel. International and/or long distance coach travel is accounted for in the Travel section."
                        icon={<Bus className={styles.icon} />}
                    />
                    <DistanceInput
                        title="Tram"
                        type={PublicTransportType.Tram}
                        tooltip="Includes metropolitan travel by public tram."
                        icon={<Tram className={styles.icon} />}
                    />
                    <DistanceInput
                        title="Train"
                        type={PublicTransportType.Train}
                        tooltip="Includes metropolitan travel by train. International and/or long distance train travel is accounted for in the Travel section."
                        icon={<Train className={styles.icon} />}
                    />
                    <DistanceInput
                        title="Ferry"
                        type={PublicTransportType.Ferry}
                        tooltip="Includes metropolitan and regional travel by ferry. Travel by cruise ship is accounted for in the Travel section."
                        icon={<Ferry className={styles.icon} />}
                    />
                </Grid>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'Sydney has as many people who get to work by train (almost <strong>188,000</strong>) as the rest of Australia combined!',
                    'The Northern Territory is Australia\'s "walk to work" capital, with <strong>11%</strong> of all workers getting to work by foot!',
                    'Catching the train instead of driving could reduce your carbon emissions from transport by up to <strong>80%</strong>.',
                ]}
            />
        </>
    );
};

export { PublicTransport };
