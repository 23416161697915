import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { TextNew } from 'src/components/shared/TextNew';

import styles from './OptionButton.module.sass';

type OptionButtonProps = {
    title: string;
    description: string;
    icon: ReactNode;
    onClick: () => void;
}

const OptionButton: React.FC<OptionButtonProps> = (props: OptionButtonProps) => {
    const {
        title,
        description,
        icon,
        onClick
    } = props;
    return (
        <div className={classNames(styles.root)}>
            <div className={classNames(styles.content)}>
                <button aria-label={title} className={classNames(styles.option)} onClick={onClick}>
                    {icon}
                </button>
                <div className={styles.border} />
            </div>
            <TextNew className={styles.title} textSize={'S'} textWeight={700}>
                {title}
            </TextNew>
            <TextNew className={styles.subtitle} textSize={'S'} textWeight={300}>
                {description}
            </TextNew>
        </div>
    );
};

export { OptionButton };