import React, { ReactNode } from 'react';
import { TextNew } from '../shared/TextNew';
import { Container } from '../shared/Container';
import { Tooltip } from '../shared/Tooltip';
import { ReactComponent as Information } from 'src/components/svg/Information.svg';
import styles from './Page.module.sass';

type PageProps = {
    title: string;
    description: string;
    subtitle?: string;
    tooltip?: string;
    children: ReactNode;
};

const Page: React.FC<PageProps> = (props: PageProps) => {
    const { title, description, subtitle, tooltip, children } = props;
    return (
        <Container className={styles.root}>
            <TextNew
                className={styles.pageTitle}
                tagName={'h2'}
                textStyle={'smallCaps'}
                textWeight={700}
                textSize={'M'}>
                {title}
            </TextNew>
            <TextNew
                className={styles.pageDescription}
                tagName={'h1'}
                textStyle={'title'}
                textWeight={700}
                textSize={'XXL'}>
                {description}
                {tooltip && (
                    <Tooltip text={tooltip}>
                        <Information className={styles.tooltip} tabIndex={-1} />
                    </Tooltip>
                )}
            </TextNew>
            {subtitle && (
                <TextNew className={styles.pageSubtitle} textStyle={'subtitle'} textWeight={400} textSize={'S'}>
                    {subtitle}
                </TextNew>
            )}
            <div className={styles.content}>{children}</div>
        </Container>
    );
};

export { Page };
