import React from 'react';
import styles from './ListItem.module.sass';
import { TextNew } from 'src/components/shared/TextNew';
import formatNumber from 'src/utils/formatNumber';

type ListItemProps = {
    title: string;
    subtitle?: string;
    total: number;
    insight: number;
};

const ListItem: React.FC<ListItemProps> = (props: ListItemProps) => {
    const { title, subtitle, total, insight } = props;
    return (
        <>
            <dt className={styles.rowHeader} data-marker={insight > -1 ? insight + 1 : null}>
                <TextNew tagName={'strong'} className={styles.title} textSize={'XS'}>
                    {title}
                </TextNew>
                <TextNew className={styles.subtitle} textSize={'XXS'}>
                    {subtitle}
                </TextNew>
            </dt>
            <TextNew tagName={'dd'} className={styles.rowValue} textSize={'XS'}>
                {formatNumber(total / 1000, { minimum: total !== 0 ? 0.01 : 0, fixedWidth: true })}
            </TextNew>
        </>
    );
};

export { ListItem };
