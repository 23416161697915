import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'src/store';
import { ActionType as MetadataActionType } from 'src/store/actions/metadata';
import { Calculation } from 'src/api';
import { create, update } from 'src/api/calculation';
import { flushMetadataState } from 'src/store/storage/flushMetadataState';
import getTotal from 'src/store/selectors/getTotal';
import { getTransportTotal } from 'src/libs/transport';
import { getEnergyTotal } from 'src/libs/energy';
import { getWaterTotal } from 'src/libs/water';
import { getWasteTotal } from 'src/libs/waste';
import { getTravelTotal } from 'src/libs/travel';
import { getGroceriesTotal } from 'src/libs/groceries';
import { wpApiSettings } from 'src/ConfigurationProvider';

function serialize(state: RootState): Calculation {
    const { ui, calculation } = state;
    return {
        id: calculation.metadata.id,
        user_id: undefined,
        label: calculation.metadata.label,
        timeframe: calculation.metadata.timeframe,
        complete: calculation.categories.completed.length === calculation.categories.selected.length,
        started_at: calculation.metadata.startedAt,
        emission_transport: getTransportTotal(state.calculation.transport),
        emission_energy: getEnergyTotal(state.calculation.energy),
        emission_water: getWaterTotal(state.calculation.water),
        emission_travel: getTravelTotal(state.calculation.travel),
        emission_groceries: getGroceriesTotal(state.calculation.groceries),
        emission_waste: getWasteTotal(state.calculation.waste),
        emission_total: getTotal(state),
        state: {
            ui,
            calculation,
        },
    };
}

const saveCalculation = (
    wpApiSettings: wpApiSettings,
): ThunkAction<Promise<string | undefined>, RootState, any, AnyAction> => {
    return async (dispatch, getState) => {
        const calculation = serialize(getState());
        try {
            if (!calculation.id) {
                calculation.id = await create(calculation, wpApiSettings);
                dispatch({
                    type: MetadataActionType.UPDATE,
                    payload: {
                        id: calculation.id,
                        savedAt: new Date(),
                    },
                });
            } else {
                await update(calculation, wpApiSettings);
                dispatch({
                    type: MetadataActionType.UPDATE,
                    payload: {
                        savedAt: new Date(),
                    },
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            flushMetadataState();
        }
        return calculation.id;
    };
};

export { saveCalculation };
