import { EnergyState } from 'src/store';
import { EnergyType, EnergyRegion } from 'src/models/energy';
import { CategoryType, TimeframeType } from 'src/models';
import { EnergyListItemType } from '.';
import { Conversion } from './conversion';
import { configuration } from 'src/configuration';
import round from 'src/utils/round';

function getEnergyAverage(
    type: EnergyType.Electricity | EnergyType.NaturalGas,
    region: EnergyRegion,
    timeframe: TimeframeType,
): number {
    const averages = configuration.averages[CategoryType.Energy];
    switch (type) {
        case EnergyType.Electricity:
            return round(averages[EnergyType.Electricity][region] * Conversion.yearly[timeframe]);
        case EnergyType.NaturalGas:
            return round(averages[EnergyType.NaturalGas][region] * Conversion.yearly[timeframe]);
    }
}

function getEnergyTitle(item: EnergyListItemType): string {
    switch (item.type) {
        case EnergyType.Electricity:
            return `Electricity (${item.region === EnergyRegion.Australia ? 'Aust Avg' : item.region?.toUpperCase()})`;
        case EnergyType.NaturalGas:
            return `Natural Gas (${item.region === EnergyRegion.Australia ? 'Aust Avg' : item.region?.toUpperCase()})`;
        case EnergyType.LPG:
            return 'LPG';
        case EnergyType.Firewood:
            return 'Firewood';
    }
}

function getEnergySubtitle(item: EnergyListItemType): string {
    switch (item.type) {
        case EnergyType.Electricity:
            return `${item.consumption.toLocaleString('en-AU', { maximumFractionDigits: 2 })} kWh`;
        case EnergyType.NaturalGas:
            return `${item.consumption.toLocaleString('en-AU', { maximumFractionDigits: 2 })} kWh`;
        case EnergyType.LPG:
            return `${item.consumption.toLocaleString('en-AU', { maximumFractionDigits: 2 })} kg`;
        case EnergyType.Firewood:
            return `${item.consumption.toLocaleString('en-AU', { maximumFractionDigits: 2 })} kg`;
    }
}

function getEnergyTotal(state: EnergyState) {
    return Array.from(state.selected).reduce((a, b) => {
        return a + state[b].emissions;
    }, 0);
}

function getEnergyList(state: EnergyState): Array<EnergyListItemType> {
    return Array.from(state.selected).map<EnergyListItemType>(type => {
        switch (type) {
            case EnergyType.Electricity:
                return {
                    ...state[EnergyType.Electricity],
                    category: CategoryType.Energy,
                    key: `energy:${type}`,
                    type,
                };
            case EnergyType.NaturalGas:
                return {
                    ...state[EnergyType.NaturalGas],
                    key: `energy:${type}`,
                    category: CategoryType.Energy,
                    type,
                };
            case EnergyType.LPG:
                return {
                    ...state[EnergyType.LPG],
                    key: `energy:${type}`,
                    category: CategoryType.Energy,
                    type,
                };
            case EnergyType.Firewood:
                return {
                    ...state[EnergyType.Firewood],
                    key: `energy:${type}`,
                    category: CategoryType.Energy,
                    type,
                };
        }
    });
}

export { getEnergyList, getEnergyTotal, getEnergyTitle, getEnergySubtitle, getEnergyAverage };
