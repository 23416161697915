import React, { ChangeEventHandler } from 'react';
import classNames from 'classnames';
import { TextNew } from 'src/components/shared/TextNew';

import styles from './RadioButton.module.sass';

type RadioButtonProps = {
    className: string;
    checked: boolean;
    label: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

const RadioButton: React.FC<RadioButtonProps> = (props: RadioButtonProps) => {
    const {
        className,
        checked,
        label,
        onChange
    } = props;
    return (
        <label className={classNames(className, styles.root)}>
            <div
                className={classNames(styles.checkmark, {
                    [styles.checked]: checked,
                })}
            />
            <TextNew className={styles.label} tagName={'span'} textSize={'M'}>
                {label}
            </TextNew>
            <input type="checkbox" checked={checked} onChange={onChange} />
        </label>
    );
}

export { RadioButton };