import { Actions, ActionType } from 'src/store/actions/navigation';

export enum NavigationKey {
    Root = 'root',
    Main = 'root:main',
    GettingStarted = 'root:main:getting-started',
    Categories = 'root:main:categories',
    Transport = 'root:main:categories:transport',
    TransportVehicles = 'root:main:categories:transport:vehicles',
    Energy = 'root:main:categories:energy',
    EnergyElectricity = 'root:main:categories:energy:electricity',
    EnergyNaturalGas = 'root:main:categories:energy:naturalgas',
    Water = 'root:main:categories:water',
    Travel = 'root:main:categories:travel',
    TravelRoadTrip = 'root:main:categories:travel:roadtrip',
    TravelRoadTripVehicles = 'root:main:categories:travel:roadtrip:vehicles',
    Groceries = 'root:main:categories:groceries',
    Waste = 'root:main:categories:waste',
}

export type NavigationState = {
    [NavigationKey.Root]: number;
    [NavigationKey.Main]: number;
    [NavigationKey.GettingStarted]: number;
    [NavigationKey.Categories]: number;
    [NavigationKey.Transport]: number;
    [NavigationKey.TransportVehicles]: number;
    [NavigationKey.Energy]: number;
    [NavigationKey.EnergyElectricity]: number;
    [NavigationKey.EnergyNaturalGas]: number;
    [NavigationKey.Water]: number;
    [NavigationKey.Travel]: number;
    [NavigationKey.TravelRoadTrip]: number;
    [NavigationKey.TravelRoadTripVehicles]: number;
    [NavigationKey.Groceries]: number;
    [NavigationKey.Waste]: number;
    completed: boolean;
};

const initialState = {
    [NavigationKey.Root]: 0,
    [NavigationKey.Main]: 0,
    [NavigationKey.GettingStarted]: 0,
    [NavigationKey.Categories]: 0,
    [NavigationKey.Transport]: 0,
    [NavigationKey.TransportVehicles]: 0,
    [NavigationKey.Energy]: 0,
    [NavigationKey.EnergyElectricity]: 0,
    [NavigationKey.EnergyNaturalGas]: 0,
    [NavigationKey.Water]: 0,
    [NavigationKey.Travel]: 0,
    [NavigationKey.TravelRoadTrip]: 0,
    [NavigationKey.TravelRoadTripVehicles]: 0,
    [NavigationKey.Groceries]: 0,
    [NavigationKey.Waste]: 0,
    completed: false,
};

const navigationReducer = (state: NavigationState = initialState, action: Actions) => {
    switch (action.type) {
        case ActionType.NAVIGATE_TO:
            return {
                ...state,
                [action.payload.key]: action.payload.index,
            };
        case ActionType.RESET:
            for (let i = 0; i < action.payload.keys.length; i++) {
                state = {
                    ...state,
                    [action.payload.keys[i]]: 0,
                };
            }
            return state;
        case ActionType.SET_COMPLETED:
            return {
                ...state,
                completed: true,
            };
    }
    return state;
};

export { navigationReducer };
