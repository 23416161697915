import React from 'react';
import { TextNew } from 'src/components/shared/TextNew';
import { ButtonText } from 'src/components/shared/ButtonText';
import { Modal } from '../shared/Modal';
import { ModalControls } from '../shared/ModalControls';
import { ActionType } from 'src/store/actions/metadata';
import { ActionType as TransportActionType } from 'src/store/actions/transport';
import { ActionType as GroceriesActionType } from 'src/store/actions/groceries';
import { ActionType as WaterActionType } from 'src/store/actions/water';
import { ActionType as TravelActionType } from 'src/store/actions/travel';
import { ActionType as EnergyActionType } from 'src/store/actions/energy';
import { ActionType as WasteActionType } from 'src/store/actions/waste';
import { ActionType as NavigationActionType } from 'src/store/actions/navigation';
import { ActionType as CategoriesActionType } from 'src/store/actions/categories';
import { TimeframeType } from 'src/models';
import { useDispatch } from 'react-redux';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import styles from './modal.module.sass';

type Action = () => void;

type ChangeTimeframeModalProps = {
    timeframe: TimeframeType;
    onClose: Action;
};

const ChangeTimeframeModal = (props: ChangeTimeframeModalProps) => {
    const { timeframe, onClose } = props;
    const dispatch = useDispatch();
    return (
        <Modal onClose={onClose} title="Warning">
            {props => (
                <div className={styles.root}>
                    <TextNew className={styles.modalContent} tagName={'p'} textSize={'M'} textWeight={400}>
                        Changing your timeframe will clear any data you've entered into the calculation. Are you sure
                        you want to make this change?
                    </TextNew>
                    <ModalControls>
                        <ButtonText
                            className={styles.button}
                            variant="outlined"
                            onClick={props.close}
                            text="No, cancel"
                        />
                        <ButtonText
                            className={styles.button}
                            variant="primary"
                            text="Yes, change"
                            onClick={() => {
                                props.close();
                                dispatch({ type: TransportActionType.RESET });
                                dispatch({ type: TravelActionType.RESET });
                                dispatch({ type: WaterActionType.RESET });
                                dispatch({ type: GroceriesActionType.RESET });
                                dispatch({ type: WasteActionType.RESET });
                                dispatch({ type: EnergyActionType.RESET });
                                dispatch({
                                    type: ActionType.UPDATE,
                                    payload: {
                                        timeframe,
                                    },
                                });
                                dispatch({
                                    type: CategoriesActionType.RESET_COMPLETED,
                                });
                                dispatch({
                                    type: NavigationActionType.RESET,
                                    payload: {
                                        keys: [
                                            NavigationKey.Transport,
                                            NavigationKey.TransportVehicles,
                                            NavigationKey.Energy,
                                            NavigationKey.EnergyElectricity,
                                            NavigationKey.EnergyNaturalGas,
                                            NavigationKey.Water,
                                            NavigationKey.Travel,
                                            NavigationKey.TravelRoadTrip,
                                            NavigationKey.TravelRoadTripVehicles,
                                            NavigationKey.Groceries,
                                            NavigationKey.Waste,
                                        ],
                                    },
                                });
                            }}
                        />
                    </ModalControls>
                </div>
            )}
        </Modal>
    );
};

export { ChangeTimeframeModal };
