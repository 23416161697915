import { ActionType, Actions } from '.';
import { ReduxThunkAction } from 'src/store';

type UpdateConsumptionProps = {
    consumption: number;
    isAverage: boolean;
};

const updateConsumption = (props: UpdateConsumptionProps): ReduxThunkAction<Actions> => {
    const { consumption, isAverage = false } = props;
    return (dispatch, getState, { emissions: { water: factors } }) => {
        dispatch({
            type: ActionType.UPDATE_CONSUMPTION,
            payload: {
                consumption,
                isAverage,
            },
        });
        const {
            calculation: {
                water: { region },
            },
        } = getState();
        dispatch({
            type: ActionType.UPDATE_EMISSIONS,
            payload: {
                emissions: factors[region!] * consumption,
            },
        });
    };
};

export { updateConsumption };
