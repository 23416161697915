import React from 'react';
import { NavigationPage } from '../NavigationPage';
import { Form, FormBadge, FormControl } from 'src/components/shared/Forms';
import { Grid } from 'src/components/shared/Grid';
import { Dropdown } from 'src/components/shared/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { ReactComponent as Badge } from './Waste.svg';
import { updateWaste } from 'src/store/actions/waste/updateWaste';
import { BinSize, CollectionFrequency, PercentageFull, WasteEmissions } from 'src/models/waste';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import styles from './Waste.module.sass';

const Waste: React.FC = () => {
    const dispatch = useDispatch();
    const waste = useSelector<RootState, WasteEmissions>(state => state.calculation.waste);
    return (
        <>
            <NavigationPage
                title="Waste"
                description="How much waste does your household produce?"
                onNextClick={() => {
                    dispatch(updateWaste({}));
                    return true;
                }}>
                <Form
                    size={'large'}
                    colorVariant={3}
                    badge={<FormBadge colorVariant={3} title="General Waste" icon={<Badge />} />}>
                    <Grid columns={{ default: 1, sm: 2, md: 2 }} limit={false}>
                        <FormControl
                            label="Bin Size"
                            tooltipTitle="What size is this?"
                            tooltip="In Australia, most general waste bins are 240L. If you're unsure, check your bin lid or the 'waste' section of your local council's website.">
                            <Dropdown
                                className={styles.input}
                                options={[
                                    { label: 'Small (120L-140L)', value: BinSize.Small },
                                    { label: 'Medium (240L)', value: BinSize.Medium },
                                    { label: 'Large (360L)', value: BinSize.Large },
                                ]}
                                defaultValue={waste.binSize}
                                onValueChange={value =>
                                    dispatch(
                                        updateWaste({
                                            binSize: value as BinSize,
                                        }),
                                    )
                                }
                            />
                        </FormControl>
                        <FormControl label="Collection frequency">
                            <Dropdown
                                className={styles.input}
                                options={[
                                    {
                                        label: 'Weekly',
                                        value: CollectionFrequency.Weekly,
                                    },
                                    {
                                        label: 'Fortnightly',
                                        value: CollectionFrequency.Fortnightly,
                                    },
                                    {
                                        label: 'Monthly',
                                        value: CollectionFrequency.Monthly,
                                    },
                                ]}
                                defaultValue={waste.collectionFrequency}
                                onValueChange={value =>
                                    dispatch(
                                        updateWaste({
                                            collectionFrequency: value as CollectionFrequency,
                                        }),
                                    )
                                }
                            />
                        </FormControl>
                        <FormControl label="Fullness at collection">
                            <Dropdown
                                className={styles.input}
                                options={[
                                    { label: '0%', value: PercentageFull.Empty },
                                    { label: '25%', value: PercentageFull.Quarter },
                                    { label: '50%', value: PercentageFull.Half },
                                    {
                                        label: '75%',
                                        value: PercentageFull.ThreeQuarter,
                                    },
                                    { label: '100%', value: PercentageFull.Full },
                                ]}
                                defaultValue={waste.percentageFull}
                                onValueChange={value =>
                                    dispatch(
                                        updateWaste({
                                            percentageFull: value as PercentageFull,
                                        }),
                                    )
                                }
                            />
                        </FormControl>
                    </Grid>
                </Form>
            </NavigationPage>
            <DidYouKnow
                text={[
                    "The average Australian household wastes more than <strong>340kg</strong> of food each year. You can lower your household's carbon footprint (and your shopping bill!) by doing smaller, more frequent food shops.",
                    'If food waste was a country, it would be the <strong>third largest</strong> emitter of greenhouse gases after USA and China.',
                    'Methane released by food decomposing in landfill has a global warming potential <strong>28 times</strong> higher than carbon dioxide!',
                ]}
            />
        </>
    );
};

export { Waste };
