import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavigationPage } from '../NavigationPage';
import { Grid } from 'src/components/shared/Grid';
import { CheckboxControl } from 'src/components/shared/CheckboxControl';
import { ReactComponent as Electricity } from './Electricity.svg';
import { ReactComponent as NaturalGas } from './NaturalGas.svg';
import { ReactComponent as LPG } from './LPG.svg';
import { ReactComponent as Firewood } from './Firewood.svg';
import { EnergyType } from 'src/models/energy';
import { RootState } from 'src/store';
import { ActionType } from 'src/store/actions/energy';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { useTimeframe } from 'src/store/utils/useTimeframe';
import styles from './Energy.module.sass';

const Energy: React.FC = () => {
    const dispatch = useDispatch();
    const { timeframeText } = useTimeframe();
    const selected = useSelector<RootState, Array<EnergyType>>(state => state.calculation.energy.selected);
    return (
        <>
            <NavigationPage
                title="Energy"
                description={`Over the past ${timeframeText}, which forms of energy has your household used?`}
                subtitle="Select all that apply"
                enableNext={selected.length > 0}
                enableBack={true}>
                <div className={styles.root}>
                    <Grid columns={{ default: 2, xs: 2, sm: 3, md: 4 }}>
                        <CheckboxControl
                            className={styles.checkbox}
                            colorVariant={1}
                            title="Electricity"
                            icon={<Electricity className={styles.icon} />}
                            checked={selected.includes(EnergyType.Electricity)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: EnergyType.Electricity,
                                        selected: value,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            className={styles.checkbox}
                            colorVariant={2}
                            title="Natural Gas"
                            icon={<NaturalGas className={styles.icon} />}
                            checked={selected.includes(EnergyType.NaturalGas)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: EnergyType.NaturalGas,
                                        selected: value,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            className={styles.checkbox}
                            colorVariant={3}
                            title="LPG"
                            icon={<LPG className={styles.icon} />}
                            checked={selected.includes(EnergyType.LPG)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: EnergyType.LPG,
                                        selected: value,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            className={styles.checkbox}
                            colorVariant={4}
                            title="Firewood"
                            icon={<Firewood className={styles.icon} />}
                            checked={selected.includes(EnergyType.Firewood)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: EnergyType.Firewood,
                                        selected: value,
                                    },
                                })
                            }
                        />
                    </Grid>
                </div>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'Australia has the highest emissions per capita of all developed countries at <strong>22.2</strong> tonnes of CO₂e per person.',
                    'Energy use in residential buildings accounts for around <strong>11%</strong> of global greenhouse gas emissions.',
                    "<strong>30%</strong> of the world's greenhouse gas emissions come from burning coal.",
                ]}
            />
        </>
    );
};

export { Energy };
