import { RefObject, DependencyList, useEffect, useState, useCallback } from 'react';

type UseScrollbarVisibilityReturnType = {
    x: boolean;
    y: boolean;
};

const useScrollbarVisibility = (
    ref: RefObject<HTMLElement>,
    deps: DependencyList = [],
): UseScrollbarVisibilityReturnType => {
    const getVisibility = useCallback(() => {
        return {
            x: false,
            y: ref.current ? ref.current.scrollHeight > ref.current.clientHeight : false,
        };
    }, [ref]);

    const [visibility, setVisiblity] = useState<UseScrollbarVisibilityReturnType>(getVisibility());

    const update = useCallback(() => {
        setVisiblity(getVisibility());
    }, [getVisibility]);

    useEffect(() => {
        window.addEventListener('resize', update);
        return () => window.removeEventListener('resize', update);
    }, [update]);

    useEffect(update, deps);

    return visibility;
};

export { useScrollbarVisibility };
