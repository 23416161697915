import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { AuthenticationProvider } from './AuthenticationProvider';
import registerServiceWorker from './registerServiceWorker';
import './index.css';
import { ConfigurationProvider } from './ConfigurationProvider';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('cpos-root');

ReactDOM.render(
    <ConfigurationProvider>
        <AuthenticationProvider>
            <BrowserRouter basename={baseUrl}>
                <App />
            </BrowserRouter>
        </AuthenticationProvider>
    </ConfigurationProvider>,
    rootElement,
);

registerServiceWorker();
