import React from 'react';
import { NavigationPage } from '../NavigationPage';
import { Grid } from 'src/components/shared/Grid';
import { CheckboxControl } from 'src/components/shared/CheckboxControl';
import { ReactComponent as NSW } from 'src/components/svg/Regions/NSW.svg';
import { ReactComponent as VIC } from 'src/components/svg/Regions/VIC.svg';
import { ReactComponent as QLD } from 'src/components/svg/Regions/QLD.svg';
import { ReactComponent as SA } from 'src/components/svg/Regions/SA.svg';
import { ReactComponent as TAS } from 'src/components/svg/Regions/TAS.svg';
import { ReactComponent as NT } from 'src/components/svg/Regions/NT.svg';
import { ReactComponent as WA } from 'src/components/svg/Regions/WA.svg';
import { ReactComponent as AusAvg } from 'src/components/svg/Regions/AusAvg.svg';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store';
import { ActionType } from 'src/store/actions/water';
import { WaterRegion } from 'src/models/water';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import styles from './Regions.module.sass';

const Regions: React.FC = () => {
    const dispatch = useDispatch();
    const region = useSelector<RootState, WaterRegion | undefined>(state => state.calculation.water.region);
    return (
        <>
            <NavigationPage
                title="Water"
                description="Which region governs your water supply?"
                enableBack={true}
                enableNext={region !== undefined}>
                <Grid columns={{ default: 3, xs: 3, sm: 3, md: 4 }}>
                    <CheckboxControl
                        colorVariant={1}
                        title="NSW"
                        icon={<NSW className={styles.icon} />}
                        checked={region === WaterRegion.NSW}
                        onChecked={value =>
                            dispatch({
                                type: ActionType.UPDATE_REGION,
                                payload: {
                                    region: WaterRegion.NSW,
                                },
                            })
                        }
                    />
                    <CheckboxControl
                        colorVariant={2}
                        title="ACT"
                        icon={<NSW className={styles.icon} />}
                        checked={region === WaterRegion.ACT}
                        onChecked={value =>
                            dispatch({
                                type: ActionType.UPDATE_REGION,
                                payload: {
                                    region: WaterRegion.ACT,
                                },
                            })
                        }
                    />
                    <CheckboxControl
                        colorVariant={3}
                        title="VIC"
                        icon={<VIC className={styles.icon} />}
                        checked={region === WaterRegion.VIC}
                        onChecked={value =>
                            dispatch({
                                type: ActionType.UPDATE_REGION,
                                payload: {
                                    region: WaterRegion.VIC,
                                },
                            })
                        }
                    />
                    <CheckboxControl
                        colorVariant={4}
                        title="QLD"
                        icon={<QLD className={styles.icon} />}
                        checked={region === WaterRegion.QLD}
                        onChecked={value =>
                            dispatch({
                                type: ActionType.UPDATE_REGION,
                                payload: {
                                    region: WaterRegion.QLD,
                                },
                            })
                        }
                    />
                    <CheckboxControl
                        colorVariant={5}
                        title="SA"
                        icon={<SA className={styles.icon} />}
                        checked={region === WaterRegion.SA}
                        onChecked={value =>
                            dispatch({
                                type: ActionType.UPDATE_REGION,
                                payload: {
                                    region: WaterRegion.SA,
                                },
                            })
                        }
                    />
                    <CheckboxControl
                        colorVariant={6}
                        title="TAS"
                        icon={<TAS className={styles.icon} />}
                        checked={region === WaterRegion.TAS}
                        onChecked={value =>
                            dispatch({
                                type: ActionType.UPDATE_REGION,
                                payload: {
                                    region: WaterRegion.TAS,
                                },
                            })
                        }
                    />
                    <CheckboxControl
                        colorVariant={7}
                        title="WA"
                        icon={<WA className={styles.icon} />}
                        checked={region === WaterRegion.WA}
                        onChecked={value =>
                            dispatch({
                                type: ActionType.UPDATE_REGION,
                                payload: {
                                    region: WaterRegion.WA,
                                },
                            })
                        }
                    />
                    <CheckboxControl
                        colorVariant={1}
                        title="NT"
                        icon={<NT className={styles.icon} />}
                        checked={region === WaterRegion.NT}
                        onChecked={value =>
                            dispatch({
                                type: ActionType.UPDATE_REGION,
                                payload: {
                                    region: WaterRegion.NT,
                                },
                            })
                        }
                    />
                    <CheckboxControl
                        colorVariant={2}
                        title="Use Aus Average"
                        icon={<AusAvg className={styles.icon} />}
                        checked={region === WaterRegion.Australia}
                        onChecked={value =>
                            dispatch({
                                type: ActionType.UPDATE_REGION,
                                payload: {
                                    region: WaterRegion.Australia,
                                },
                            })
                        }
                    />
                </Grid>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'Australia is the driest inhabited continent in the world, making us uniquely vulnerable to the impacts of climate change.',
                    'Australians are the greatest per capita consumers of water in the world, using an average of <strong>100,000L</strong> of fresh water per person each year.',
                    "2021 marks the beginning of the UN's Decade on Ecosystem Restoration - a rallying call for the protection and revival of ecosystems all around the world, for the benefit of people and nature.",
                ]}
            />
        </>
    );
};

export { Regions };
