import React, { useState } from 'react';
import { TextNew } from 'src/components/shared/TextNew';
import { ButtonText } from 'src/components/shared/ButtonText';
import { Modal } from '../shared/Modal';
import { ModalControls } from '../shared/ModalControls';
import { TextInput } from '../shared/TextInput';
import { Throbber } from 'src/components/shared/Throbber';
import { useDispatch, useSelector } from 'react-redux';
import { saveCalculation } from 'src/store/actions/root/saveCalculation';
import { ReduxDispatch, RootState } from 'src/store';
import { Button } from '../shared/Button';
import { toast } from 'react-toastify';
import { ActionType } from 'src/store/actions/metadata';
import { useConfiguration } from 'src/ConfigurationProvider';
import styles from './modal.module.sass';

type SaveCalculationProps = {
    onClose: () => void;
};

const SaveCalculation = (props: SaveCalculationProps) => {
    const { onClose } = props;
    const dispatch = useDispatch<ReduxDispatch>();
    const [isSaving, setSaving] = useState(false);
    const [label, setLabel] = useState<string>(
        useSelector<RootState, string>(state => state.calculation.metadata.label) ?? '',
    );
    const { wpApiSettings } = useConfiguration();
    return (
        <Modal onClose={onClose} title="Save">
            {props => (
                <div className={styles.root}>
                    <TextNew className={styles.modalContent} tagName={'p'} textSize={'M'} textWeight={400}>
                        Enter a name for this calculation so you can identify it later.
                    </TextNew>
                    <TextInput
                        className={styles.label}
                        disabled={isSaving}
                        value={label}
                        onValueChange={value => setLabel(value)}
                    />
                    <ModalControls>
                        <ButtonText
                            className={styles.button}
                            variant="outlined"
                            disabled={isSaving}
                            onClick={props.close}
                            text="Cancel"
                        />
                        <Button
                            className={styles.button}
                            variant="primary"
                            disabled={isSaving}
                            onClick={async () => {
                                dispatch({
                                    type: ActionType.UPDATE,
                                    payload: {
                                        label,
                                    },
                                });
                                setSaving(true);
                                try {
                                    await dispatch(saveCalculation(wpApiSettings));
                                    toast('The calculation was saved!');
                                } catch (error) {
                                    toast.error(error);
                                } finally {
                                    setTimeout(() => {
                                        setSaving(false);
                                        props.close();
                                    }, 200);
                                }
                            }}>
                            {isSaving ? <Throbber /> : <TextNew>OK</TextNew>}
                        </Button>
                    </ModalControls>
                </div>
            )}
        </Modal>
    );
};

export { SaveCalculation };
