import { createSelector } from 'reselect';
import getTransportList from 'src/store/selectors/getTransportList';
import getEnergyList from 'src/store/selectors/getEnergyList';
import getWaterList from 'src/store/selectors/getWaterList';
import getTravelList from 'src/store/selectors/getTravelList';
import getGroceriesList from 'src/store/selectors/getGroceriesList';
import getWasteList from 'src/store/selectors/getWasteList';
import { RootState } from '..';
import { CategoryType } from 'src/models';

const selectedSelector = createSelector(
    (state: RootState) => state.calculation.categories,
    categories => categories.selected,
);

export default createSelector(
    selectedSelector,
    getTransportList,
    getEnergyList,
    getWaterList,
    getTravelList,
    getGroceriesList,
    getWasteList,
    (selected, transport, energy, water, travel, groceries, waste) => {
        let array = Array<any>();
        if (selected.includes(CategoryType.Transport)) {
            array = array.concat(transport);
        }
        if (selected.includes(CategoryType.Energy)) {
            array = array.concat(energy);
        }
        if (selected.includes(CategoryType.Water)) {
            array = array.concat(water);
        }
        if (selected.includes(CategoryType.Travel)) {
            array = array.concat(travel);
        }
        if (selected.includes(CategoryType.Groceries)) {
            array = array.concat(groceries);
        }
        if (selected.includes(CategoryType.Waste)) {
            array = array.concat(waste);
        }
        return array;
    },
);
