import { CategoryType } from 'src/models';
import { FuelType, PublicTransportType, TransportType, VehicleSize } from 'src/models/transport';
import { EnergyRegion, EnergyType } from './models/energy';
import { WaterRegion } from './models/water';
import emissions from 'src/data/emissions.json';
import averages from 'src/data/averages.json';
import {
    TravelType,
    CoachTravelType,
    CruiseShipTravelType,
    DomesticAirTravelType,
    InternationalAirTravelType,
    TrainTravelType,
} from './models/travel';
import { DietType, GroceryType } from './models/groceries';
import { BinSize } from './models/waste';

type FuelAverageType = {
    [FuelType.Petrol]: number;
    [FuelType.Diesel]: number;
    [FuelType.LPG]: number;
    [FuelType.E10]: number;
    [FuelType.Biodiesel]: number;
    [FuelType.Electric]: number;
};

type TransportFuelEconomyAveragesType = {
    [TransportType.Car]: {
        [VehicleSize.Small]: FuelAverageType;
        [VehicleSize.SmallHybrid]: FuelAverageType;
        [VehicleSize.Medium]: FuelAverageType;
        [VehicleSize.MediumHybrid]: FuelAverageType;
        [VehicleSize.Large]: FuelAverageType;
        [VehicleSize.LargeHybrid]: FuelAverageType;
    };
    [TransportType.SUV]: {
        [VehicleSize.Small]: FuelAverageType;
        [VehicleSize.SmallHybrid]: FuelAverageType;
        [VehicleSize.Medium]: FuelAverageType;
        [VehicleSize.MediumHybrid]: FuelAverageType;
        [VehicleSize.Large]: FuelAverageType;
        [VehicleSize.LargeHybrid]: FuelAverageType;
        [VehicleSize.ExtraLarge]: FuelAverageType;
        [VehicleSize.ExtraLargeHybrid]: FuelAverageType;
    };
    [TransportType.Van]: {
        [VehicleSize.PeopleMover]: FuelAverageType;
        [VehicleSize.PeopleMoverHybrid]: FuelAverageType;
    };
    [TransportType.Motorcycle]: number;
};

type TransportConsumptionAvergagesType = {
    [TransportType.Car]: FuelAverageType;
    [TransportType.SUV]: FuelAverageType;
    [TransportType.Van]: FuelAverageType;
    [TransportType.Motorcycle]: number;
};

type TransportAveragesType = {
    economy: TransportFuelEconomyAveragesType;
    distance: {
        [TransportType.Car]: number;
        [TransportType.SUV]: number;
        [TransportType.Van]: number;
        [TransportType.Motorcycle]: number;
    };
    consumption: TransportConsumptionAvergagesType;
};

type EnergyAveragesType = {
    [EnergyType.Electricity]: {
        [EnergyRegion.NSW_ACT]: number;
        [EnergyRegion.VIC]: number;
        [EnergyRegion.QLD]: number;
        [EnergyRegion.SA]: number;
        [EnergyRegion.WA]: number;
        [EnergyRegion.TAS]: number;
        [EnergyRegion.NT]: number;
        [EnergyRegion.Australia]: number;
    };
    [EnergyType.NaturalGas]: {
        [EnergyRegion.NSW_ACT]: number;
        [EnergyRegion.VIC]: number;
        [EnergyRegion.QLD]: number;
        [EnergyRegion.SA]: number;
        [EnergyRegion.WA]: number;
        [EnergyRegion.TAS]: number;
        [EnergyRegion.NT]: number;
        [EnergyRegion.Australia]: number;
    };
};

type WaterAveragesType = {
    [WaterRegion.ACT]: number;
    [WaterRegion.NSW]: number;
    [WaterRegion.VIC]: number;
    [WaterRegion.QLD]: number;
    [WaterRegion.SA]: number;
    [WaterRegion.TAS]: number;
    [WaterRegion.WA]: number;
    [WaterRegion.NT]: number;
    [WaterRegion.Australia]: number;
};

type GroceriesAveragesType = {
    category: {
        [GroceryType.Meat]: number;
        [GroceryType.EggsAndDairy]: number;
        [GroceryType.Seafood]: number;
        [GroceryType.FruitAndNuts]: number;
        [GroceryType.BreadAndCereals]: number;
        [GroceryType.Vegetables]: number;
        [GroceryType.ProcessedFood]: number;
        [GroceryType.NonAlcoholicDrinks]: number;
        [GroceryType.AlcoholicDrinks]: number;
        [GroceryType.DiningOutOrTakeaway]: number;
    };
    // diet: {
    //     [DietType.Omnivore]: number;
    //     [DietType.Pescatarian]: number;
    //     [DietType.Vegan]: number;
    //     [DietType.Vegetarian]: number;
    // };
};

type AveragesType = {
    [CategoryType.Transport]: TransportAveragesType;
    [CategoryType.Energy]: EnergyAveragesType;
    [CategoryType.Water]: WaterAveragesType;
    [CategoryType.Groceries]: GroceriesAveragesType;
};

type TransportEmissionFactorsTypes = {
    vehicles: FuelAverageType;
    [TransportType.PublicTransport]: {
        [PublicTransportType.Taxi]: number;
        [PublicTransportType.Bus]: number;
        [PublicTransportType.Train]: number;
        [PublicTransportType.Tram]: number;
        [PublicTransportType.Ferry]: number;
    };
};

type EnergyEmissionFactorsType = {
    [EnergyType.Electricity]: {
        [EnergyRegion.NSW_ACT]: number;
        [EnergyRegion.VIC]: number;
        [EnergyRegion.QLD]: number;
        [EnergyRegion.SA]: number;
        [EnergyRegion.TAS]: number;
        [EnergyRegion.WA]: number;
        [EnergyRegion.NT]: number;
        [EnergyRegion.Australia]: number;
    };
    [EnergyType.NaturalGas]: {
        [EnergyRegion.NSW_ACT]: number;
        [EnergyRegion.VIC]: number;
        [EnergyRegion.QLD]: number;
        [EnergyRegion.SA]: number;
        [EnergyRegion.TAS]: number;
        [EnergyRegion.WA]: number;
        [EnergyRegion.NT]: number;
        [EnergyRegion.Australia]: number;
    };
    [EnergyType.LPG]: number;
    [EnergyType.Firewood]: number;
};

type WaterEmissionFactorsType = {
    [WaterRegion.ACT]: number;
    [WaterRegion.NSW]: number;
    [WaterRegion.VIC]: number;
    [WaterRegion.QLD]: number;
    [WaterRegion.SA]: number;
    [WaterRegion.TAS]: number;
    [WaterRegion.WA]: number;
    [WaterRegion.NT]: number;
    [WaterRegion.Australia]: number;
};

type TravelEmissionFactorsType = {
    [TravelType.Coach]: {
        [CoachTravelType.Coach]: number;
    };
    [TravelType.DomesticAir]: {
        [DomesticAirTravelType.Economy]: number;
        [DomesticAirTravelType.Business]: number;
    };
    [TravelType.InternationalAir]: {
        [InternationalAirTravelType.Economy]: number;
        [InternationalAirTravelType.PremiumEconomy]: number;
        [InternationalAirTravelType.Business]: number;
        [InternationalAirTravelType.First]: number;
    };
    [TravelType.Train]: {
        [TrainTravelType.Economy]: number;
        [TrainTravelType.PremiumEconomy]: number;
        [TrainTravelType.Business]: number;
        [TrainTravelType.Sleeper]: number;
    };
    [TravelType.CruiseShip]: {
        [CruiseShipTravelType.Cabin]: number;
    };
};

type GroceriesEmissionFactorsType = {
    category: {
        [GroceryType.Meat]: number;
        [GroceryType.EggsAndDairy]: number;
        [GroceryType.Seafood]: number;
        [GroceryType.FruitAndNuts]: number;
        [GroceryType.BreadAndCereals]: number;
        [GroceryType.Vegetables]: number;
        [GroceryType.ProcessedFood]: number;
        [GroceryType.NonAlcoholicDrinks]: number;
        [GroceryType.AlcoholicDrinks]: number;
        [GroceryType.DiningOutOrTakeaway]: number;
    };
    diet: {
        [DietType.Omnivore]: number;
        [DietType.Pescatarian]: number;
        [DietType.Vegan]: number;
        [DietType.Vegetarian]: number;
    };
};

type WasteEmissionFactorsType = {
    [BinSize.Small]: number;
    [BinSize.Medium]: number;
    [BinSize.Large]: number;
};

type EmissionFactorsType = {
    [CategoryType.Transport]: TransportEmissionFactorsTypes;
    [CategoryType.Energy]: EnergyEmissionFactorsType;
    [CategoryType.Water]: WaterEmissionFactorsType;
    [CategoryType.Travel]: TravelEmissionFactorsType;
    [CategoryType.Groceries]: GroceriesEmissionFactorsType;
    [CategoryType.Waste]: WasteEmissionFactorsType;
};

export type ConfigurationType = {
    emissions: EmissionFactorsType;
    averages: AveragesType;
};

const configuration = {
    emissions,
    averages,
} as ConfigurationType;

export { configuration };
