import React, { ReactNode} from 'react';
import classNames from 'classnames';
import { AtMostLG, AtLeastXL } from '../../utils/breakpoints';
import { Copyright } from './Copyright';
import { Backdrop } from '../shared/Backdrop';
import styles from './Navigation.module.sass';
import { motion, AnimatePresence } from 'framer-motion';

type NavigationPropContext = 'sidebar' | 'default';
type NavigationProps = {
    children?: ReactNode;
    context?: NavigationPropContext;
};

type NavigationSidebarToggleProps = {
    setOpen: any
    isOpen: boolean;
};


const NavigationSidebarToggle: React.FC<NavigationSidebarToggleProps> = (props: NavigationSidebarToggleProps) => {

    const toggleHandler = () => {
        if (props.isOpen) {
            props.setOpen(false);
            document.body.removeAttribute('data-scroll-lock');
        } else {
            props.setOpen(true);
        }
    };

    return (
        <button
            role="switch"
            aria-label="Click to toggle the mobile menu"
            className={styles.trigger}
            aria-checked={props.isOpen}
            onClick={toggleHandler}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 8 8"
                stroke="currentColor"
                strokeWidth="0.5"
                fill="none">
                <g className={classNames(styles.triggerSlice, styles.triggerSliceStart)}>
                    <svg>
                        <rect width="8" height="8" fill="none" stroke="none" />
                        <use href="#slice" y="0" />
                    </svg>
                </g>
                <g className={classNames(styles.triggerSlice, styles.triggerSliceMid)}>
                    <svg>
                        <rect width="8" height="8" fill="none" stroke="none" />
                        <polyline className={styles.triggerSlice} id="slice" points="1 4, 7 4" />
                    </svg>
                </g>
                <g className={classNames(styles.triggerSlice, styles.triggerSliceEnd)}>
                    <svg>
                        <rect width="8" height="8" fill="none" stroke="none" />
                        <use href="#slice" y="0" />
                    </svg>
                </g>
            </svg>
        </button>
    );
}

const Navigation: React.FC<NavigationProps> = (props: NavigationProps) => {
    const { children, context = 'default' } = props;
    const [isOpen, setOpen] = React.useState(false);

    let handleClick = (evt: Event) => {
        let target = evt.target as HTMLElement;
        if (target && target.closest(`.${styles.wrapper}`)) return;
        window.removeEventListener('click', handleClick);
        document.body.removeAttribute('data-scroll-lock');
        setOpen(false);
    };

    React.useEffect(() => {
        if (!isOpen) return;
        window.addEventListener('click', handleClick);
    }, [isOpen]);

    return (
        <>
            <AtMostLG forceWrap={false}>
                <NavigationSidebarToggle {...{ isOpen, setOpen }} />
            </AtMostLG>
            <AtMostLG forceWrap={false}>
                <>
                    <nav data-context={context} className={styles.root} data-visible={isOpen}>
                        <ul>{children}</ul>
                        <div className={styles.footer}>
                            <Copyright context={context} />
                        </div>
                    </nav>
                    <AnimatePresence>
                        {isOpen && <Backdrop onClick={() => setOpen(false)} />}
                    </AnimatePresence>
                </>
            </AtMostLG>
            <AtLeastXL>
                <nav data-context={context} className={styles.root}>
                    <ul>{children}</ul>
                </nav>
            </AtLeastXL>
        </>
    );
};

export { Navigation };
