import React from 'react';
import classNames from 'classnames';
import { UIElementProps } from 'src/components';
import styles from './Throbber.module.sass';

type ThrobberVariant = 'primary' | 'outlined' | 'error';

type ThrobberProps = UIElementProps & {
    variant?: ThrobberVariant;
};

const Throbber = (props: ThrobberProps) => {
    const { className, variant = 'outlined' } = props;
    return (
        <div className={classNames(className, styles.root)}>
            <div className={classNames(styles.dot, styles[variant])} />
            <div className={classNames(styles.dot, styles[variant])} />
            <div className={classNames(styles.dot, styles[variant])} />
        </div>
    );
};

export { Throbber };
