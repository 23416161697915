import React from 'react';
import classNames from 'classnames';
import { TextNew } from 'src/components/shared/TextNew';
import { ReactComponent as Question } from 'src/components/svg/Question.svg';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import getTotal from 'src/store/selectors/getTotal';
import { Tooltip } from 'src/components/shared/Tooltip';
import { useTimeframe } from 'src/store/utils/useTimeframe';
import { SocialSharingLinks } from './SocialSharingLinks';
import round from 'src/utils/round';
import { OffsetButton } from './OffsetButton';
import styles from './Overview.module.sass';
import formatNumber from 'src/utils/formatNumber';

type OverviewProps = {
    className: string;
};

const Overview: React.FC<OverviewProps> = (props: OverviewProps) => {
    const { className } = props;
    const total = useSelector(getTotal);
    const { timeframeText } = useTimeframe();
    return (
        <div className={classNames(className, styles.root)}>
            <div className={styles.header}>
                <TextNew tagName={'h2'} textSize={'M'} textStyle={'smallCaps'} textWeight={700}>
                    Calculator Results
                </TextNew>
                <TextNew tagName={'p'} textSize={'XXL'} textWeight={700}>
                    Your household's carbon footprint for the past {timeframeText} is
                </TextNew>
            </div>
            <TextNew tagName={'p'} className={styles.total} textSize={'XXXL'} textWeight={700}>
                <span className={styles.totalValue}>{formatNumber(total / 1000, { fixedWidth: true })}</span>{' '}
                <span className={styles.totalUnits}>{Math.trunc(total / 1000) != 1 ? 'tonnes' : 'tonne'}</span>
            </TextNew>
            <TextNew tagName={'span'} className={styles.totalFootnote} textSize={'S'} textWeight={700}>
                of carbon dioxide equivalent (tCO<sub>2</sub>e).{' '}
                <Tooltip text="Your carbon footprint is made up of more than just carbon dioxide and includes other greenhouse gases such as methane and nitrous oxide. Carbon dioxide equivalent (CO₂e) is a metric measure used to convert emissions from other greenhouse gases to the amount of carbon dioxide with the equivalent global warming potential. For example, the global warming potential of methane is 28. This means that emitting 1 metric tonne of methane is equivalent to emitting 28 tonnes of carbon dioxide.">
                    <Question className={styles.tooltipIcon} tabIndex={-1} />
                </Tooltip>
            </TextNew>

            <div className={styles.content}>
                <TextNew tagName={'p'} className={styles.text} textSize={'S'} textWeight={400}>
                    The average Australian household has an annual carbon footprint of approximately 15-20 tCO
                    <sub>2</sub>e.
                </TextNew>
                <TextNew tagName={'p'} className={styles.text} textSize={'S'} textWeight={400}>
                    According to the UN, to limit global warming to 1.5 degrees by 2050 we must limit our per-person
                    emissions to 2 tCO<sub>2</sub>e per year.
                </TextNew>
                <TextNew tagName={'p'} className={styles.text} textSize={'S'} textWeight={400}>
                    Now that you know your number, you can reduce, remove & restore:
                </TextNew>
                <ul>
                    <li>
                        <TextNew tagName={'p'} className={styles.text} textSize={'S'} textWeight={400}>
                            <strong>Reduce</strong> your emissions using our tips below.
                        </TextNew>
                    </li>
                    <li>
                        <TextNew tagName={'p'} className={styles.text} textSize={'S'} textWeight={400}>
                            Support our reforestation projects which <strong>remove</strong> carbon dioxide from the
                            atmosphere.
                        </TextNew>
                    </li>
                    <li>
                        <TextNew tagName={'p'} className={styles.text} textSize={'S'} textWeight={400}>
                            Follow us as we use your donation to <strong>restore</strong> Australia.
                        </TextNew>
                    </li>
                </ul>
            </div>
            <OffsetButton className={styles.button} total={round(total)} />
            <div className={styles.sharing}>
                <TextNew className={styles.sharingLabel} textSize={'S'} textStyle={'smallCaps'} textWeight={700}>
                    Share your results
                </TextNew>
                <SocialSharingLinks className={styles.sharingLinks} />
            </div>
        </div>
    );
};

export { Overview };
