import { UIState } from 'src/store';
import stringify from './stringify';

const deserializeUIState = (json: string): UIState => {
    return JSON.parse(json, (key, value) => {
        return value;
    });
};

const serializeUIState = (state: UIState): string => {
    return stringify(state);
};

export { serializeUIState, deserializeUIState };
