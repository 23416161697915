import React, { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './ToastProvider.module.sass';

type ToastProviderProps = {
    children: ReactNode;
};

const ToastProvider = (props: ToastProviderProps) => {
    const { children } = props;
    return (
        <>
            {children}
            <ToastContainer
                className={styles.root}
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                closeButton={false}
            />
        </>
    );
};

export { ToastProvider };
