import React, { ReactNode } from 'react';
import { TransportType } from 'src/models/transport';
import { NavigationPage } from '../NavigationPage';
import { Grid } from 'src/components/shared/Grid';
import { ReactComponent as Car } from '../transport/Car.svg';
import { ReactComponent as SUV } from '../transport/SUV.svg';
import { ReactComponent as Van } from '../transport/Van.svg';
import { ReactComponent as Motorcycle } from '../transport/Motorcycle.svg';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store';
import { NumericInputForm } from 'src/components/shared/NumericInputForm';
import { ActionType } from 'src/store/actions/travel';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { TravelType } from 'src/models/travel';
import { useTimeframe } from 'src/store/utils/useTimeframe';

type CountProps = {
    title: string;
    tooltip: string;
    type: TransportType.Car | TransportType.SUV | TransportType.Van | TransportType.Motorcycle;
    icon: ReactNode;
};

const Count = (props: CountProps) => {
    const { title, tooltip, type, icon } = props;
    const dispatch = useDispatch();
    const count = useSelector<RootState, number>(
        state => state.calculation.travel[TravelType.RoadTrip].vehicleCount[type] || 0,
    );
    return (
        <NumericInputForm
            title={title}
            tooltip={tooltip}
            icon={icon}
            minValue={0}
            maxValue={9}
            decimalScale={0}
            value={count}
            onValueChange={value =>
                dispatch({
                    type: ActionType.UPDATE_ROADTRIP_VEHICLE_COUNT,
                    payload: {
                        type: type,
                        count: value,
                    },
                })
            }
        />
    );
};

const RoadTripVehicleCount = () => {
    const { timeframeText } = useTimeframe();
    return (
        <>
            <NavigationPage
                title="Travel - Road Trip"
                description={`Over the past ${timeframeText}, which vehicles has your household used for long-distance road trips?`}>
                <Grid
                    columns={{
                        xs: Math.min(2, 4),
                        sm: Math.min(2, 4),
                        md: Math.min(4, 4),
                    }}>
                    <Count
                        title="Cars"
                        type={TransportType.Car}
                        tooltip="This category includes hatchbacks, sedans and station wagons. For example, a Toyota Corolla, Mazda 6 or Holden Commodore."
                        icon={<Car />}
                    />
                    <Count
                        title="SUVs"
                        type={TransportType.SUV}
                        tooltip="This category includes sports utility vehicles (SUVs) and 4WDs with higher ground clearance than a car. For example, a Subaru XV, Toyota Rav 4, Toyota Hilux, or Nissan Patrol."
                        icon={<SUV />}
                    />
                    <Count
                        title="People Movers"
                        type={TransportType.Van}
                        tooltip="This category includes family wagons and vans that carry 6 or more passengers. For example, a Kia Carnival or Toyota Tarago."
                        icon={<Van />}
                    />
                    <Count
                        title="Motorcycles"
                        type={TransportType.Motorcycle}
                        tooltip="This category includes motorcycles and scooters. For example, a Kawasaki NINJA 400, Honda NSC110 or Vespa GTS 300."
                        icon={<Motorcycle />}
                    />
                </Grid>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'The average Australian passenger vehicle travels over 11,000 kilometres each year, releasing around 2.9 tonnes of CO₂e into the atmosphere.',
                    'More than 65% of the Australian workforce use a private car for their daily commute. Only 10% of commuters rely solely on public transport.',
                    'Keeping your tyres inflated to the right pressure can save up to 10% on fuel!',
                ]}
            />
        </>
    );
};

export { RoadTripVehicleCount };
