import { store } from 'src/store';
import { serializeUIState } from 'src/store/serializers/uiSerializers';

const flushUIState = () => {
    const { ui } = store.getState();
    try {
        localStorage.setItem('cpos:ui', serializeUIState(ui));
    } catch (err) {
        // TODO: update state with error
    }
};

export { flushUIState };
