import React from 'react';
import { useSelector } from 'react-redux';
import { CategoriesState, RootState } from 'src/store';
import styles from './PopupContainer.module.sass';
import { CategoryButton } from './CategoryButton';
import { CategoryLabels } from './CategoryLabels';
import { motion, AnimatePresence } from 'framer-motion';

type PopupContainerProps = {
    onClose: () => void;
    buttonStart: React.ReactElement;
    buttonResults: React.ReactElement;
};

const spring = {
    type: 'spring',
    damping: 17.5,
    stiffness: 300,
};

const PopupContainer = (props: PopupContainerProps) => {
    const { onClose } = props;
    const { selected, completed } = useSelector<RootState, CategoriesState>(state => state.calculation.categories);
    return (
        <motion.div
            className={styles.root}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={spring}>
            {selected.map((category, index) => {
                return (
                    <>
                        <CategoryButton
                            key={category}
                            variant="popup"
                            type={category}
                            navigationIndex={index}
                            label={CategoryLabels[category]}
                            selected={false}
                            completed={completed.includes(category)}
                            onClick={onClose}
                        />
                    </>
                );
            })}
            <div className={styles.popupFooter}>
                {props.buttonStart}
                {props.buttonResults}
            </div>
        </motion.div>
    );
};

export { PopupContainer };
