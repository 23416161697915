import React, { ReactNode } from 'react';
import formatNumber from 'src/utils/formatNumber';
import styles from './Category.module.sass';
import { TextNew } from '../../shared/TextNew';

type CategoryProps = {
    title: string;
    showCategoryLegend: boolean;
    total: number;
    children: ReactNode;
    categoryIndex: number;
};

const Category = (props: CategoryProps) => {
    const { title, showCategoryLegend, total, categoryIndex, children } = props;
    return (
        <>
            <div className={styles.root} data-marker={showCategoryLegend ? categoryIndex : null}>
                <TextNew tagName={'h3'} className={styles.rowName} textSize={'S'} textWeight={700}>
                    {title}
                </TextNew>
                <TextNew className={styles.rowValue} textSize={'S'} textWeight={700}>
                    {formatNumber(total / 1000, { minimum: total !== 0 ? 0.01 : 0, fixedWidth: true })}
                </TextNew>
            </div>
            {children}
        </>
    );
};

export { Category };
