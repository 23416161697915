import { EnergyState } from 'src/store';
import stringify from './stringify';

const deserializeEnergyState = (json: string): EnergyState => {
    return JSON.parse(json, (key, value) => {
        switch (key) {
            case 'isUpdating':
                return false;
        }
        return value;
    });
};

const serializeEnergyState = (state: EnergyState): string => {
    return stringify(state);
};

export { serializeEnergyState, deserializeEnergyState };
