import { createSelector } from 'reselect';
import { RootState } from 'src/store';
import { CategoryType } from 'src/models';
import getTransportTotal from './getTransportTotal';
import getEnergyTotal from './getEnergyTotal';
import getWaterTotal from './getWaterTotal';
import getWasteTotal from './getWasteTotal';
import getTravelTotal from './getTravelTotal';
import getGroceriesTotal from './getGroceriesTotal';

export default createSelector(
    (state: RootState) => state.calculation.categories.selected,
    getTransportTotal,
    getEnergyTotal,
    getWaterTotal,
    getTravelTotal,
    getGroceriesTotal,
    getWasteTotal,
    (categories, transport, energy, water, travel, groceries, waste) => {
        return [
            categories.includes(CategoryType.Transport) ? transport : 0,
            categories.includes(CategoryType.Energy) ? energy : 0,
            categories.includes(CategoryType.Water) ? water : 0,
            categories.includes(CategoryType.Travel) ? travel : 0,
            categories.includes(CategoryType.Groceries) ? groceries : 0,
            categories.includes(CategoryType.Waste) ? waste : 0,
        ];
    },
);
