import React, { ReactNode } from 'react';
import { TextNew } from 'src/components/shared/TextNew';

import styles from './SubCategory.module.sass';

type SubCategoryProps = {
    title?: string;
    children: ReactNode;
};

const SubCategory = (props: SubCategoryProps) => {
    const { title, children } = props;
    return (
        <>
            {title && (
                <TextNew className={styles.title} tagName={'h3'} textSize={'S'} textWeight={700} >
                    {title}
                </TextNew>
            )}
            {children}
        </>
    );
};

export { SubCategory };
