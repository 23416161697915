import React from 'react';
import { Grid } from 'src/components/shared/Grid';
import { NavigationPage } from '../NavigationPage';
import { CheckboxControl } from 'src/components/shared/CheckboxControl';
import { ReactComponent as FoodByCategory } from './FoodByCategory.svg';
import { ReactComponent as FoodByOverallSpend } from './FoodByOverallSpend.svg';
import { RootState } from 'src/store';
import { useSelector, useDispatch } from 'react-redux';
import { CalculationMethod } from 'src/models/groceries';
import { ActionType } from 'src/store/actions/groceries';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import styles from './Groceries.module.sass';

const Groceries: React.FC = () => {
    const dispatch = useDispatch();
    const calculationMethod = useSelector<RootState, CalculationMethod | undefined>(
        state => state.calculation.groceries.calculationMethod,
    );
    return (
        <>
            <NavigationPage
                title="Food &amp; Drink"
                description="How would you like to calculate your food &amp; drink footprint?"
                enableBack={true}
                enableNext={calculationMethod !== undefined}>
                <Grid columns={{ default: 2 }}>
                    <CheckboxControl
                        title="By category"
                        subtitle="Allocate your total weekly spend across food &amp; drink categories"
                        icon={<FoodByCategory className={styles.icon} />}
                        checked={calculationMethod === CalculationMethod.Category}
                        onChecked={value =>
                            dispatch({
                                type: ActionType.SET_CALCULATION_METHOD,
                                payload: {
                                    method: CalculationMethod.Category,
                                },
                            })
                        }
                    />
                    <CheckboxControl
                        title="By total spend"
                        subtitle="Select diet type and enter total weekly spend"
                        icon={<FoodByOverallSpend className={styles.icon} />}
                        checked={calculationMethod === CalculationMethod.Spend}
                        onChecked={value =>
                            dispatch({
                                type: ActionType.SET_CALCULATION_METHOD,
                                payload: {
                                    method: CalculationMethod.Spend,
                                },
                            })
                        }
                    />
                </Grid>
            </NavigationPage>
            <DidYouKnow
                text={[
                    "Agriculture is responsible for <strong>14%</strong> of Australia's greenhouse gas emissions, with livestock and agricultural soils the largest sources of methane and nitrous oxide.",
                    'One of the easiest ways to reduce the carbon footprint of your diet is to buy local to avoid unnecessary emissions from transportation.',
                    'Due to the resources required to farm and process meat, animal-based foods tend to have a higher carbon footprint than plant-based foods.',
                ]}
            />
        </>
    );
};

export { Groceries };
