import { store } from 'src/store';
import { serializeWasteState } from 'src/store/serializers/wasteSerializers';

const flushWasteState = () => {
    const {
        calculation: { waste },
    } = store.getState();
    try {
        localStorage.setItem('cpos:waste', serializeWasteState(waste));
    } catch (err) {
        // TODO: update state with error
    }
};

export { flushWasteState };
