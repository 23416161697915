import React from 'react';
import { List } from './List';
import { Footnote } from './Footnote';
import { Grid } from '../../shared/Grid';
import { BreakdownChart } from './BreakdownChart';
import styles from './ImpactBreakdown.module.sass';

const ImpactBreakdown: React.FC = () => {
    return (
        <div className={styles.root}>
            <Grid className={styles.grid} columns={{ default: 1, sm: 1, md: 2 }}>
                <List className={styles.col1} showCategoryLegend={true} />
                <div className={styles.col2}>
                    <BreakdownChart className={styles.chart}></BreakdownChart>
                    <Footnote className={styles.footnote} />
                </div>
            </Grid>
        </div>
    );
};

export { ImpactBreakdown };
