import { EnergyRegion, EnergyType } from 'src/models/energy';

export enum ActionType {
    RESET = 'ENERGY:RESET',
    SET_SELECTED = 'ENERGY:SET_SELECTED',
    UPDATE_REGION = 'ENERGY:UPDATE_REGION',
    UPDATE_CONSUMPTION = 'ENERGY:UPDATE_CONSUMPTION',
    UPDATE_EMISSIONS = 'ENERGY:UPDATE_EMISSIONS',
}

export type ResetAction = {
    type: ActionType.RESET;
};

export type SetSelectedAction = {
    type: ActionType.SET_SELECTED;
    payload: {
        type: EnergyType;
        selected: boolean;
    };
};

export type UpdateRegionAction = {
    type: ActionType.UPDATE_REGION;
    payload: {
        type: EnergyType;
        region: EnergyRegion | undefined;
    };
};

export type UpdateConsumptionAction = {
    type: ActionType.UPDATE_CONSUMPTION;
    payload: {
        type: EnergyType;
        consumption: number;
        isAverage: boolean;
    };
};

export type UpdateEmissionsAction = {
    type: ActionType.UPDATE_EMISSIONS;
    payload: {
        type: EnergyType;
        emissions: number;
    };
};

export type Actions =
    | ResetAction
    | SetSelectedAction
    | UpdateRegionAction
    | UpdateConsumptionAction
    | UpdateEmissionsAction;
