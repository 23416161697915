import React from 'react';
import classNames from 'classnames';
import styles from './Backdrop.module.sass';
import { motion, AnimatePresence } from 'framer-motion';

type BackdropProps = {
    className?: string;
    onClick: any;
};

const clickHandler = (onClick: Function, toggleActive: Function) => {
    toggleActive();
    onClick();
};

const Backdrop: React.FC<BackdropProps> = (props: BackdropProps) => {
    const [isActive, setActive] = React.useState(false);
    const { className, onClick } = props;
    const element = React.useRef() as React.MutableRefObject<HTMLDivElement>;

    React.useEffect(() => {
        !isActive
            ? document.body.setAttribute('data-scroll-lock', '')
            : document.body.removeAttribute('data-scroll-lock')
    }, [isActive]);
    return (
        <motion.div
            ref={element}
            role="presentation"
            data-active={isActive}
            className={classNames(className, styles.root)}
            onClick={() => clickHandler(onClick, () => setActive(!isActive))}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.3 }}
            transition={{duration: 0.2, ease: 'easeOut'}}
            exit={{ opacity: 0 }}
        />
    );
};

export { Backdrop };
