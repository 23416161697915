import { CalculationType, FuelType, MeasurementType, TransportType, VehicleSize } from 'src/models/transport';
import {
    CoachTravelType,
    CruiseShipTravelType,
    DomesticAirTravelType,
    InternationalAirTravelType,
    RoadTripTransportType,
    TrainTravelType,
    TravelType,
} from 'src/models/travel';

export enum ActionType {
    RESET = 'TRAVEL:RESET',
    SET_SELECTED = 'TRAVEL:SET_SELECTED',
    UPDATE = 'TRAVEL:UPDATE',
    UPDATE_EMISSIONS = 'TRAVEL:UPDATE_EMISSIONS',
    UPDATE_ROADTRIP_VEHICLE_COUNT = 'TRAVEL:UPDATE_ROADTRIP_VEHICLE_COUNT',
    UPDATE_ROADTRIP = 'TRAVEL:UPDATE_ROADTRIP',
    UPDATE_ROADTRIP_EMISSIONS = 'TRAVEL:UPDATE_ROADTRIP_EMISSIONS',
}

export type ResetAction = {
    type: ActionType.RESET;
};

export type SetSelectedAction = {
    type: ActionType.SET_SELECTED;
    payload: {
        type: TravelType;
        selected: boolean;
    };
};

export type UpdateActionPayload =
    | { type: TravelType.DomesticAir; subtype: DomesticAirTravelType; duration: number }
    | { type: TravelType.InternationalAir; subtype: InternationalAirTravelType; duration: number }
    | { type: TravelType.Train; subtype: TrainTravelType; duration: number }
    | { type: TravelType.CruiseShip; subtype: CruiseShipTravelType; duration: number }
    | { type: TravelType.Coach; subtype: CoachTravelType; duration: number };

export type UpdateAction = {
    type: ActionType.UPDATE;
    payload: UpdateActionPayload;
};

export type UpdateEmissionsActionPayload =
    | { type: TravelType.DomesticAir; subtype: DomesticAirTravelType; emissions: number }
    | { type: TravelType.InternationalAir; subtype: InternationalAirTravelType; emissions: number }
    | { type: TravelType.Train; subtype: TrainTravelType; emissions: number }
    | { type: TravelType.CruiseShip; subtype: CruiseShipTravelType; emissions: number }
    | { type: TravelType.Coach; subtype: CoachTravelType; emissions: number };

export type UpdateEmissionsAction = {
    type: ActionType.UPDATE_EMISSIONS;
    payload: UpdateEmissionsActionPayload;
};

export type UpdateRoadTripVehicleCountAction = {
    type: ActionType.UPDATE_ROADTRIP_VEHICLE_COUNT;
    payload: {
        type: RoadTripTransportType;
        count: number;
    };
};

type UpdateRoadTripVehiclePayload = {
    type: TransportType.Car | TransportType.SUV | TransportType.Van;
    index: number;
    fuel?: FuelType;
    measurement?: MeasurementType;
    size?: VehicleSize;
    fuelEconomy?: number;
    calculation?: CalculationType;
    consumption?: number;
    distance?: number;
};

type UpdateRoadTripMotorcyclePayload = {
    type: TransportType.Motorcycle;
    index: number;
    calculation?: CalculationType;
    consumption?: number;
    distance?: number;
};

export type UpdateRoadTripAction = {
    type: ActionType.UPDATE_ROADTRIP;
    payload: UpdateRoadTripVehiclePayload | UpdateRoadTripMotorcyclePayload;
};

export type UpdateRoadTripEmissionsAction = {
    type: ActionType.UPDATE_ROADTRIP_EMISSIONS;
    payload: {
        type: RoadTripTransportType;
        index: number;
        emissions: number;
    };
};

export type Actions =
    | ResetAction
    | SetSelectedAction
    | UpdateAction
    | UpdateEmissionsAction
    | UpdateRoadTripVehicleCountAction
    | UpdateRoadTripAction
    | UpdateRoadTripEmissionsAction;
