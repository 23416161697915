import React from 'react';
import { NavigationPage } from '../NavigationPage';
import { Form, FormBadge, FormControl } from 'src/components/shared/Forms';
import { Grid } from 'src/components/shared/Grid';
import { ReactComponent as NaturalGas } from './NaturalGas.svg';
import { UseRegionAveragesButton } from './UseRegionAveragesButton';
import { NumericInput } from 'src/components/shared/NumericInput';
import { RootState } from 'src/store';
import { EnergyType, RegionalEnergyEmissions, EnergyRegion } from 'src/models/energy';
import { useDispatch, useSelector } from 'react-redux';
import { updateConsumption } from 'src/store/actions/energy/updateConsumption';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { getEnergyAverage } from 'src/libs/energy';
import { useTimeframe } from 'src/store/utils/useTimeframe';
import styles from './NaturalGasConsumption.module.sass';

const NaturalGasConsumption: React.FC = () => {
    const { timeframe, timeframeText } = useTimeframe();
    const { region = EnergyRegion.Australia, consumption } = useSelector<RootState, RegionalEnergyEmissions>(
        state => state.calculation.energy[EnergyType.NaturalGas],
    );
    const dispatch = useDispatch();
    return (
        <>
            <NavigationPage
                title="Energy - Natural Gas"
                description={`Over the past ${timeframeText}, how much natural gas has your household consumed?`}>
                <Form
                    size={'large'}
                    colorVariant={2}
                    badge={
                        <FormBadge
                            colorVariant={2}
                            title="Natural Gas"
                            subtitle={region ?? 'Australian Average'}
                            icon={<NaturalGas />}
                        />
                    }>
                    <Grid columns={{ default: 1, sm: 1, md: 1 }}>
                        <FormControl
                            label="Consumed"
                            tooltipTitle="Where can I find this?"
                            tooltip={
                                region === EnergyRegion.WA
                                    ? "In Western Australia, natural gas is measured in 'units'. A unit of gas is equivalent to 1 kilowatt-hour of energy. You can find this figure on your gas bill or via your online account. Alternatively, you can select to use the average household gas consumption for your state."
                                    : 'Natural gas is measured in megajoules (MJ). You can find this figure on your gas bill or via your online account. Alternatively, you can select to use the average household gas consumption for your state.'
                            }
                            footer={
                                <UseRegionAveragesButton
                                    region={region}
                                    onClick={() => {
                                        const averge = getEnergyAverage(EnergyType.NaturalGas, region, timeframe);
                                        dispatch(
                                            updateConsumption({
                                                type: EnergyType.NaturalGas,
                                                consumption: averge,
                                                isAverage: true,
                                            }),
                                        );
                                    }}
                                />
                            }>
                            <NumericInput
                                className={styles.input}
                                value={consumption}
                                units={region === EnergyRegion.WA ? 'units' : 'MJ'}
                                onValueChange={value =>
                                    dispatch(updateConsumption({ type: EnergyType.NaturalGas, consumption: value }))
                                }
                            />
                        </FormControl>
                    </Grid>
                </Form>
            </NavigationPage>
            <DidYouKnow
                text={[
                    'Tasmania and the Northern Territory use virtually no household natural gas. There are only <strong>12,000</strong> homes connected to the network in Tasmania!',
                    'Victorian households use more than <strong>twice</strong> as much natural gas as households in all other states and territories combined!',
                ]}
            />
        </>
    );
};

export { NaturalGasConsumption };
