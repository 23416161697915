import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { NavigationRoot } from 'src/components/shared/Navigation';
import { TravelType } from 'src/models/travel';
import { Travel } from './Travel';
import { Coach } from './Coach';
import { DomesticAir } from './DomesticAir';
import { InternationalAir } from './InternationalAir';
import { Train } from './Train';
import { CruiseShip } from './CruiseShip';
import { RoadTrip } from './RoadTrip';
import { flushTravelState } from 'src/store/storage/flushTravelState';
import { NavigationKey } from 'src/store/reducers/navigationReducer';

const TravelRoot: React.FC = () => {
    const selected = useSelector<RootState, Array<TravelType>>(state => state.calculation.travel.selected);
    return (
        <NavigationRoot navigationKey={NavigationKey.Travel} onNavigate={flushTravelState}>
            <Travel />
            {selected.includes(TravelType.RoadTrip) && <RoadTrip />}
            {selected.includes(TravelType.Coach) && <Coach />}
            {selected.includes(TravelType.Train) && <Train />}
            {selected.includes(TravelType.DomesticAir) && <DomesticAir />}
            {selected.includes(TravelType.InternationalAir) && <InternationalAir />}
            {selected.includes(TravelType.CruiseShip) && <CruiseShip />}
        </NavigationRoot>
    );
};

export { TravelRoot };
