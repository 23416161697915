import { EmissionsElement } from '.';

export enum EnergyType {
    Electricity = 'electricity',
    NaturalGas = 'natural-gas',
    LPG = 'lpg',
    Firewood = 'firewood',
}

export enum EnergyRegion {
    NSW_ACT = 'nsw-act',
    VIC = 'vic',
    QLD = 'qld',
    SA = 'sa',
    TAS = 'tas',
    WA = 'wa',
    NT = 'nt',
    Australia = 'australia',
}

export type EnergyEmissions = EmissionsElement & {
    consumption: number;
};

export type RegionalEnergyEmissions = EnergyEmissions & {
    region?: EnergyRegion;
    isAverage: boolean;
};
