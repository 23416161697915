import { combineReducers } from 'redux';
import { NavigationState } from '..';
import { navigationReducer } from './navigationReducer';

export type UIState = {
    navigation: NavigationState;
};

const uiReducer = combineReducers({
    navigation: navigationReducer,
});

export { uiReducer };
