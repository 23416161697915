import React, { ReactNode } from 'react';
import { TextNew } from 'src/components/shared/TextNew';
import { CategoryType } from 'src/models';
import formatNumber from 'src/utils/formatNumber';
import styles from './Category.module.sass';

type CategoryTitleProps = {
    title: string;
    total: number;
};

const CategoryTitle: React.FC<CategoryTitleProps> = (props: CategoryTitleProps) => {
    const { title, total } = props;
    return (
        <div className={styles.row}>
            <TextNew tagName="h3" className={styles.rowName} textSize={'M'} textWeight={700}>
                {title}
            </TextNew>
            <TextNew className={styles.rowValue} textSize={'M'} textWeight={700}>
                {formatNumber(total / 1000, { minimum: total > 0 ? 0.01 : 0, fixedWidth: true })}
            </TextNew>
        </div>
    );
};


type CategoryProps = CategoryTitleProps & {
    type: CategoryType;
    children?: ReactNode;
};

const Category = (props: CategoryProps) => {
    const { type, title, total, children } = props;
    if (total === 0) {
        return null;
    }
    return (
        <li className={styles.root} data-category={type}>
            <div className={styles.border}></div>
            <div className={styles.content}>
                <CategoryTitle title={title} total={total} />
                <ul className={styles.list}>{children}</ul>
            </div>
        </li>
    );
};

export { Category };
