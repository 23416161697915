import { Actions, ActionType } from '../actions/groceries';
import { GroceryType, GroceryEmissions, DietryEmissions, CalculationMethod, DietType } from 'src/models/groceries';

export type GroceryCategoriesState = {
    [GroceryType.Meat]: GroceryEmissions;
    [GroceryType.EggsAndDairy]: GroceryEmissions;
    [GroceryType.Seafood]: GroceryEmissions;
    [GroceryType.BreadAndCereals]: GroceryEmissions;
    [GroceryType.FruitAndNuts]: GroceryEmissions;
    [GroceryType.Vegetables]: GroceryEmissions;
    [GroceryType.ProcessedFood]: GroceryEmissions;
    [GroceryType.NonAlcoholicDrinks]: GroceryEmissions;
    [GroceryType.AlcoholicDrinks]: GroceryEmissions;
    [GroceryType.DiningOutOrTakeaway]: GroceryEmissions;
};

export type GroceriesState = {
    calculationMethod: CalculationMethod | undefined;
    categories: GroceryCategoriesState;
    overall: DietryEmissions;
};

const emptyEmissions = {
    amount: 0,
    isAverage: false,
    emissions: 0,
};

const initialState = {
    calculationMethod: undefined,
    categories: {
        [GroceryType.Meat]: emptyEmissions,
        [GroceryType.EggsAndDairy]: emptyEmissions,
        [GroceryType.Seafood]: emptyEmissions,
        [GroceryType.BreadAndCereals]: emptyEmissions,
        [GroceryType.FruitAndNuts]: emptyEmissions,
        [GroceryType.Vegetables]: emptyEmissions,
        [GroceryType.ProcessedFood]: emptyEmissions,
        [GroceryType.NonAlcoholicDrinks]: emptyEmissions,
        [GroceryType.AlcoholicDrinks]: emptyEmissions,
        [GroceryType.DiningOutOrTakeaway]: emptyEmissions,
    },
    overall: {
        diet: DietType.Omnivore,
        amount: 0,
        emissions: 0,
    },
};

const groceriesReducer = (state: GroceriesState = initialState, action: Actions) => {
    switch (action.type) {
        case ActionType.RESET:
            return initialState;
        case ActionType.SET_CALCULATION_METHOD:
            return {
                ...state,
                calculationMethod: action.payload.method,
            };
        case ActionType.UPDATE:
            return {
                ...state,
                overall: {
                    ...state.overall,
                    ...action.payload,
                },
            };
        case ActionType.UPDATE_EMISSIONS:
            return {
                ...state,
                overall: {
                    ...state.overall,
                    emissions: action.payload.emissions,
                },
            };
        case ActionType.UPDATE_CATEGORY:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    [action.payload.type]: {
                        ...state.categories[action.payload.type],
                        amount: action.payload.amount,
                        isAverage: action.payload.isAverage,
                    },
                },
            };
        case ActionType.UPDATE_CATEGORY_EMISSIONS:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    [action.payload.type]: {
                        ...state.categories[action.payload.type],
                        emissions: action.payload.emissions,
                    },
                },
            };
    }
    return state;
};

export { groceriesReducer };
