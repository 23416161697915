import { GroceryEmissions, GroceryType } from 'src/models/groceries';
import { ActionType, Actions } from '.';
import { ReduxThunkAction } from 'src/store';
import { TimeframeType } from 'src/models';
import { ConfigurationType } from 'src/configuration';
import { Conversion } from 'src/libs/conversion';

function calculateEmissions(
    type: GroceryType,
    category: GroceryEmissions,
    timeframe: TimeframeType,
    configuration: ConfigurationType,
): number {
    const factor = configuration.emissions.groceries.category[type];
    return factor * category.amount * Conversion.weekly[timeframe];
}

type UpdateCategoryProps = {
    type: GroceryType;
    amount: number;
    isAverage: boolean;
};

const updateCategory = (props: UpdateCategoryProps): ReduxThunkAction<Actions> => {
    return (dispatch, getState, configuration) => {
        dispatch({
            type: ActionType.UPDATE_CATEGORY,
            payload: props,
        });

        const { type } = props;

        const {
            calculation: {
                metadata: { timeframe },
                groceries: {
                    categories: { [type]: category },
                },
            },
        } = getState();

        dispatch({
            type: ActionType.UPDATE_CATEGORY_EMISSIONS,
            payload: {
                type,
                emissions: calculateEmissions(type, category, timeframe, configuration),
            },
        });
    };
};

export { updateCategory };
