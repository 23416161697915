import { EmissionsElement } from '.';

export enum CalculationMethod {
    Category = 'category',
    Spend = 'spend',
}

export enum DietType {
    Omnivore = 'omnivore',
    Pescatarian = 'pescatarian',
    Vegetarian = 'vegetarian',
    Vegan = 'vegan',
}

export enum GroceryType {
    Meat = 'meat',
    EggsAndDairy = 'eggs-diary',
    Seafood = 'seafood',
    FruitAndNuts = 'fruit-nuts',
    BreadAndCereals = 'bread-cereals',
    Vegetables = 'vegetables',
    ProcessedFood = 'processed-food',
    NonAlcoholicDrinks = 'non-alcoholic-drinks',
    AlcoholicDrinks = 'alcoholic-drinks',
    DiningOutOrTakeaway = 'dining-out',
}

export type GroceryEmissions = EmissionsElement & {
    amount: number;
    isAverage: boolean;
};

export type DietryEmissions = EmissionsElement & {
    amount: number;
    diet: DietType;
};
