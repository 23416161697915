import {
    EnergyState,
    UIState,
    TransportState,
    GroceriesState,
    TravelState,
    WaterState,
    WasteState,
    CategoriesState,
    MetadataState,
} from 'src/store';
import { deserializeUIState } from 'src/store/serializers/uiSerializers';
import { deserializeCategoriesState } from 'src/store/serializers/categorySerializers';
import { deserializeMetadataState } from 'src/store/serializers/metadataSerializers';
import { deserializeEnergyState } from 'src/store/serializers/energySerializers';
import { deserializeTransportState } from 'src/store/serializers/transportSerializers';
import { deserializeGroceriesState } from 'src/store/serializers/groceriesSerializers';
import { deserializeTravelState } from 'src/store/serializers/travelSerializers';
import { deserializeWaterState } from 'src/store/serializers/waterSerializers';
import { deserializeWasteState } from 'src/store/serializers/wasteSerializers';
import strip from 'src/utils/strip';

type Deserializer<TState> = (json: string) => TState | undefined;

function load<TState>(key: string, deserializer: Deserializer<TState>): TState | undefined {
    const json = localStorage.getItem(key);
    if (json) {
        return deserializer(json);
    }
    return undefined;
}

function loadState() {
    return strip({
        ui: load<UIState>('cpos:ui', deserializeUIState),
        calculation: {
            metadata: load<MetadataState>('cpos:metadata', deserializeMetadataState),
            categories: load<CategoriesState>('cpos:categories', deserializeCategoriesState),
            energy: load<EnergyState>('cpos:energy', deserializeEnergyState),
            transport: load<TransportState>('cpos:transport', deserializeTransportState),
            groceries: load<GroceriesState>('cpos:groceries', deserializeGroceriesState),
            travel: load<TravelState>('cpos:travel', deserializeTravelState),
            water: load<WaterState>('cpos:water', deserializeWaterState),
            waste: load<WasteState>('cpos:waste', deserializeWasteState),
        },
    });
}

export { loadState };
