import { store } from 'src/store';
import { serializeWaterState } from 'src/store/serializers/waterSerializers';

const flushWaterState = () => {
    const {
        calculation: { water },
    } = store.getState();
    try {
        localStorage.setItem('cpos:water', serializeWaterState(water));
    } catch (err) {
        // TODO: update state with error
    }
};

export { flushWaterState };
