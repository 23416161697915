import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { TextNew } from 'src/components/shared/TextNew';

import { Checkbox } from 'src/components/shared/Checkbox';
import { ColorVariant } from 'src/styles/ColorVariant';
import styles from './CheckboxControl.module.sass';

type Size = 'small' | 'large';

type CheckboxControlProps = {
    className?: string;
    colorVariant?: ColorVariant;
    title: string;
    subtitle?: string;
    icon: ReactNode;
    size?: Size;
    checked: boolean;
    onChecked: (checked: boolean) => void;
};

const CheckboxControl: React.FC<CheckboxControlProps> = (props: CheckboxControlProps) => {
    const { className, colorVariant = undefined, title, subtitle, icon, size = 'small', checked, onChecked } = props;
    return (
        <div className={classNames(className, styles.root)} data-size={size} data-color-variant={colorVariant}>
            <div className={classNames(styles.content, { [styles.checked]: checked })}>
                <Checkbox className={styles.checkmark} checked={checked} />
                <button
                    aria-label={title}
                    className={classNames(styles.checkbox, { [styles.checked]: checked })}
                    onClick={() => onChecked(!checked)}>
                    {icon}
                </button>
                <div className={styles.border} />
            </div>
            <TextNew className={styles.title} textSize={'S'} textWeight={700}>
                {title}
            </TextNew>
            {subtitle && (
                <TextNew className={styles.subtitle} textSize={'S'} textWeight={300}>
                    {subtitle}
                </TextNew>
            )}
        </div>
    );
};

export { CheckboxControl };
