import React from 'react';
import { NavigationRoot } from 'src/components/shared/Navigation';
import { flushTravelState } from 'src/store/storage/flushTravelState';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { RoadTripVehicleCount } from './RoadTripVehicleCount';
import { RoadTripVehicles } from './RoadTripVehicles';
import { RoadTripTravelState } from 'src/store/reducers/travelReducer';
import { TravelType } from 'src/models/travel';
import { TransportType } from 'src/models/transport';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

const RoadTrip = () => {
    const { vehicleCount } = useSelector<RootState, RoadTripTravelState>(
        state => state.calculation.travel[TravelType.RoadTrip],
    );
    const hasVechiles =
        vehicleCount[TransportType.Car] +
        vehicleCount[TransportType.SUV] +
        vehicleCount[TransportType.Van] +
        vehicleCount[TransportType.Motorcycle];
    return (
        <NavigationRoot navigationKey={NavigationKey.TravelRoadTrip} onNavigate={flushTravelState}>
            <RoadTripVehicleCount />
            {hasVechiles && <RoadTripVehicles />}
        </NavigationRoot>
    );
};

export { RoadTrip };
