const getCookie = (name: string): string | null => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; ++i) {
        const pair = cookies[i].trim().split('=');
        if (pair[0] == name) {
            return pair[1];
        }
    }
    return null;
};

const setCookie = (name: string, value: string) => {
    document.cookie = `${name}=${value}; Path=/;`;
};

const deleteCookie = (name: string) => {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export { getCookie, setCookie, deleteCookie };
