import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransportType, MotorcycleEmissions, DefaultMotorcycleEmissions } from 'src/models/transport';
import { TravelType } from 'src/models/travel';
import { RootState } from 'src/store';
import { updateRoadTripMotorcycle } from 'src/store/actions/travel/updateRoadTripMotorcycle';
import { MotorcycleForm } from '../shared/MotorcycleForm';

type RoadTripMotorcycleProps = {
    index: number;
};

const RoadTripMotorcycle = (props: RoadTripMotorcycleProps) => {
    const { index } = props;
    const dispatch = useDispatch();
    const motorcycle = useSelector<RootState, MotorcycleEmissions>(
        state =>
            state.calculation.travel[TravelType.RoadTrip][TransportType.Motorcycle][index] ||
            DefaultMotorcycleEmissions,
    );
    return (
        <MotorcycleForm
            title={`Motorcycle ${index + 1}`}
            colorVariant={4}
            motorcycle={motorcycle}
            disabled={false}
            weekly={false}
            onCalculationUpdate={calculation => dispatch(updateRoadTripMotorcycle({ index, calculation }))}
            onConsumptionUpdate={consumption =>
                dispatch(updateRoadTripMotorcycle({ index, consumption, isAverage: false }))
            }
            onDistanceUpdate={distance => dispatch(updateRoadTripMotorcycle({ index, distance, isAverage: false }))}
        />
    );
};

export { RoadTripMotorcycle };
