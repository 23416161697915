import React, { useState } from 'react';
import { TextNew } from './TextNew';
import { ReactComponent as Question } from '../svg/Question.svg';
import { useRandom } from 'src/utils/useRandom';
import styles from './DidYouKnow.module.sass';
import { ButtonIcon } from './ButtonIcon';
import { useSpring, motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';

type DidYouKnowProps = {
    text: Array<string>;
};
const DidYouKnow: React.FC<DidYouKnowProps> = (props: DidYouKnowProps) => {
    const { text } = props;
    const [expanded, setExpanded] = useState<Boolean>(false);

    const toggle = () => {
        setExpanded(!expanded);
    };
    
    const animationRoot:any = React.useRef(null);
    const spring = {
        type: 'spring',
        stiffness: 500,
        damping: 30,
    };

    const random = useRandom();
    const t = text[Math.floor(random * text.length)];

    return (
        <AnimateSharedLayout>
            <motion.div
                onClick={toggle}
                ref={animationRoot}
                layout
                initial={{ borderRadius: 8 }}
                className={styles.root}
                transition={spring}
                onLayoutAnimationComplete={() => {
                    animationRoot.current.style.borderRadius = null;
                }}>
                <div className={styles.toggleRoot}>
                    <motion.button layout className={styles.trigger} onClick={toggle}>
                        <Question className={styles.icon}/>
                        <TextNew tagName={'h3'} textSize={'S'} className={styles.title} textWeight={700}>
                            Did you know?
                        </TextNew>
                    </motion.button>
                </div>
                {expanded && (
                    <TextNew tagName={'p'} textSize={'S'} className={styles.content} textWeight={400}>
                        <span dangerouslySetInnerHTML={{ __html: t }} />
                    </TextNew>
                )}
            </motion.div>
        </AnimateSharedLayout>
    );
};

export { DidYouKnow };
