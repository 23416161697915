import { AnyAction, createStore, applyMiddleware } from 'redux';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { rootReducer, RootState } from './reducers/rootReducer';
import { UIState } from './reducers/uiReducer';
import { CalculationState } from './reducers/calculationReducer';
import { NavigationState } from './reducers/navigationReducer';
import { MetadataState } from './reducers/metadataReducer';
import { CategoriesState } from './reducers/categoriesReducer';
import { TransportState, PublicTransportState } from './reducers/transportReducer';
import { GroceriesState } from './reducers/groceriesReducer';
import { WasteState } from './reducers/wasteReducer';
import { WaterState } from './reducers/waterReducer';
import { EnergyState } from './reducers/energyReducer';
import { TravelState } from './reducers/travelReducer';
import { loadState } from 'src/store/storage/loadState';
import { rootMiddleware } from 'src/store/middleware/rootMiddleware';
import { navigationMiddleware } from 'src/store/middleware/navigationMiddleware';
import { configuration, ConfigurationType } from 'src/configuration';

type ReduxDispatch = ThunkDispatch<RootState, any, AnyAction>;

type ReduxThunkAction<A extends AnyAction> = ThunkAction<void, RootState, ConfigurationType, A>;

type ReduxAsyncThunkAction<A extends AnyAction> = ThunkAction<Promise<void>, RootState, ConfigurationType, A>;

export type {
    RootState,
    UIState,
    CalculationState,
    ReduxDispatch,
    ReduxThunkAction,
    ReduxAsyncThunkAction,
    NavigationState,
    MetadataState,
    CategoriesState,
    TransportState,
    PublicTransportState,
    GroceriesState,
    WasteState,
    WaterState,
    EnergyState,
    TravelState,
};

const initialState = loadState();

const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunk.withExtraArgument(configuration), rootMiddleware, navigationMiddleware),
);

export { store };
