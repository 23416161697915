import { Calculation } from 'src/api';

const deserialize = (payload: any): Calculation => {
    const state = JSON.parse(payload.state);
    return {
        ...payload,
        started_at: new Date(String(payload.started_at).replace(/ /g,"T")),
        state: {
            ui: state.ui,
            calculation: {
                ...state.calculation,
                metadata: {
                    ...state.calculation.metadata,
                    id: payload.id,
                },
            },
        },
    };
};

function serializeDate(date: Date): string {
    function pad(value: number): string {
        return value.toString().padStart(2, '0');
    }
    return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(date.getUTCDate())} ${pad(
        date.getUTCHours(),
    )}:${pad(date.getUTCMinutes())}:${pad(date.getUTCSeconds())}`;
}

const serialize = (calculation: Calculation): string => {
    function replacer(key: string, value: any) {
        switch (key) {
            case 'calculation':
                return value;
            case 'complete':
                return value ? 1 : 0;
            case 'started_at':
                return serializeDate(new Date(value));
        }
        return value;
    }
    return JSON.stringify(calculation, replacer);
};

export { serialize, deserialize };
