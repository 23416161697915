import { CategoryType } from 'src/models';
import { Actions, ActionType } from 'src/store/actions/categories';

export type CategoriesState = {
    selected: Array<CategoryType>;
    completed: Array<CategoryType>;
};

const initialState = {
    selected: [],
    completed: [],
};

const ordinals = [
    CategoryType.Travel,
    CategoryType.Energy,
    CategoryType.Water,
    CategoryType.Transport,
    CategoryType.Groceries,
    CategoryType.Waste,
];

const categorySorter = (a: CategoryType, b: CategoryType) => ordinals.indexOf(a) - ordinals.indexOf(b);

const categoriesReducer = (state: CategoriesState = initialState, action: Actions) => {
    switch (action.type) {
        case ActionType.SET_SELECTED:
            // this action needs to maintain the categories in their navigational order
            if (action.payload.selected) {
                return {
                    ...state,
                    selected: [...state.selected, action.payload.type].sort(categorySorter),
                };
            }
            return {
                ...state,
                selected: state.selected.filter(type => type !== action.payload.type),
            };
        case ActionType.APPEND_SELECTED:
            // this action just appends the category type to the end of the list
            return {
                ...state,
                selected: [...state.selected, action.payload.type],
            };
        case ActionType.SET_COMPLETED:
            if (state.completed.includes(action.payload.type) === false) {
                return {
                    ...state,
                    completed: [...state.completed, action.payload.type],
                };
            }
            break;
        case ActionType.RESET_COMPLETED:
            return {
                ...state,
                completed: [],
            };
    }
    return state;
};

export { categoriesReducer };
