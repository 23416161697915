import { TransportState } from 'src/store';
import {
    TransportType,
    PublicTransportType,
    CalculationType,
    VehicleCalculation,
    FuelType,
    VehicleSize,
} from 'src/models/transport';
import { CategoryType, TimeframeType } from 'src/models';
import { TransportListItemType } from '.';
import { Conversion } from './conversion';
import { configuration } from 'src/configuration';
import round from 'src/utils/round';
import capitalize from 'src/utils/capitalize';

export type VehicleSubtype = TransportType.Car | TransportType.SUV | TransportType.Van;

type GetDistanceAverageProps = {
    type: VehicleSubtype | TransportType.Motorcycle;
    timeframe: TimeframeType;
};

function getTransportDistanceAverage(props: GetDistanceAverageProps): number {
    const averages = configuration.averages[CategoryType.Transport];
    return round(averages.distance[props.type] * Conversion.yearly[props.timeframe]);
}

type GetTransportVehicleFuelConsumptionAverage = {
    type: TransportType.Car | TransportType.SUV | TransportType.Van;
    fuel: FuelType;
    timeframe: TimeframeType;
};

type GetTransportMptorcycleFuelConsumptionAverage = {
    type: TransportType.Motorcycle;
    timeframe: TimeframeType;
};

type GetTransportFuelConsumptionAverage =
    | GetTransportVehicleFuelConsumptionAverage
    | GetTransportMptorcycleFuelConsumptionAverage;

function getTransportFuelConsumptionAverage(props: GetTransportFuelConsumptionAverage): number {
    const averages = configuration.averages[CategoryType.Transport];
    switch (props.type) {
        case TransportType.Car:
        case TransportType.SUV:
        case TransportType.Van:
            return round(averages.consumption[props.type][props.fuel] * Conversion.yearly[props.timeframe]);
        case TransportType.Motorcycle:
            return round(averages.consumption[TransportType.Motorcycle] * Conversion.yearly[props.timeframe]);
    }
}

const VehicleSizes = {
    [TransportType.Car]: [
        { label: 'Small', value: VehicleSize.Small },
        { label: 'Small (Hybrid)', value: VehicleSize.SmallHybrid },
        { label: 'Medium', value: VehicleSize.Medium },
        { label: 'Medium (Hybrid)', value: VehicleSize.MediumHybrid },
        { label: 'Large', value: VehicleSize.Large },
        { label: 'Large (Hybrid)', value: VehicleSize.LargeHybrid },
    ],
    [TransportType.SUV]: [
        { label: 'Small', value: VehicleSize.Small },
        { label: 'Small (Hybrid)', value: VehicleSize.SmallHybrid },
        { label: 'Medium', value: VehicleSize.Medium },
        { label: 'Medium (Hybrid)', value: VehicleSize.MediumHybrid },
        { label: 'Large', value: VehicleSize.Large },
        { label: 'Large (Hybrid)', value: VehicleSize.LargeHybrid },
        { label: 'Extra Large', value: VehicleSize.ExtraLarge },
        { label: 'Extra Large (Hybrid)', value: VehicleSize.ExtraLargeHybrid },
    ],
    [TransportType.Van]: [
        { label: 'Non-Hybrid', value: VehicleSize.PeopleMover },
        { label: 'Hybrid', value: VehicleSize.PeopleMoverHybrid },
    ],
};

function getVehicleSubtitle(vehicle: VehicleCalculation): string {
    switch (vehicle.calculation) {
        case CalculationType.Distance:
            return `${vehicle.distance.toLocaleString('en-AU', { maximumFractionDigits: 2 })} km/week`;
        case CalculationType.Fuel:
            return `${vehicle.consumption.toLocaleString('en-AU', { maximumFractionDigits: 2 })} L/week`;
    }
}

function getTransportSubtitle(item: TransportListItemType): string {
    switch (item.type) {
        case TransportType.PublicTransport:
            return `${item.distance.toLocaleString('en-AU', { maximumFractionDigits: 2 })} km/week`;
        case TransportType.Car:
            if (item.fuel === FuelType.Electric) {
                return 'Electric';
            }
            return getVehicleSubtitle(item);
        default:
            return getVehicleSubtitle(item);
    }
}

function getTransportTitle(item: TransportListItemType): string {
    switch (item.type) {
        case TransportType.PublicTransport:
            return `Public - ${capitalize(item.subtype)}`;
        case TransportType.Car:
            return `Car ${item.index + 1}`;
        case TransportType.SUV:
            return `SUV ${item.index + 1}`;
        case TransportType.Van:
            return `People Mover ${item.index + 1}`;
        case TransportType.Motorcycle:
            return `Motorcycle ${item.index + 1}`;
    }
}

function getVehicleTotal(state: TransportState, type: TransportType.Car | TransportType.SUV | TransportType.Van) {
    if (state.selected.includes(type) === false) {
        return 0;
    }
    const count = state.vehicleCount[type] || 0;
    return state[type].slice(0, count).reduce((a, b) => a + b.emissions, 0);
}

function getMotorcycleTotal(state: TransportState) {
    if (state.selected.includes(TransportType.Motorcycle) === false) {
        return 0;
    }
    const count = state.vehicleCount[TransportType.Motorcycle] || 0;
    return state[TransportType.Motorcycle].slice(0, count).reduce((a, b) => a + b.emissions, 0);
}

function getPublicTransportTotal(state: TransportState) {
    if (state.selected.includes(TransportType.PublicTransport) === false) {
        return 0;
    }
    return (
        state[TransportType.PublicTransport][PublicTransportType.Bus].emissions +
        state[TransportType.PublicTransport][PublicTransportType.Ferry].emissions +
        state[TransportType.PublicTransport][PublicTransportType.Taxi].emissions +
        state[TransportType.PublicTransport][PublicTransportType.Train].emissions +
        state[TransportType.PublicTransport][PublicTransportType.Tram].emissions
    );
}

function getTransportTotal(state: TransportState) {
    return (
        getVehicleTotal(state, TransportType.Car) +
        getVehicleTotal(state, TransportType.SUV) +
        getVehicleTotal(state, TransportType.Van) +
        getMotorcycleTotal(state) +
        getPublicTransportTotal(state)
    );
}

function getVehicleEnumerable(state: TransportState, type: TransportType.Car | TransportType.SUV | TransportType.Van) {
    const count = state.vehicleCount[type] || 0;
    return state[type].slice(0, count);
}

function getMotorcycleEnumerable(state: TransportState) {
    const count = state.vehicleCount[TransportType.Motorcycle] || 0;
    return state[TransportType.Motorcycle].slice(0, count);
}

function getTransportList(state: TransportState): Array<TransportListItemType> {
    return Array.from(state.selected).flatMap<TransportListItemType>(type => {
        switch (type) {
            case TransportType.Car:
                return getVehicleEnumerable(state, TransportType.Car).map((vehicle, index) => {
                    return {
                        ...vehicle,
                        key: `transport:car:${index}`,
                        category: CategoryType.Transport,
                        type: TransportType.Car,
                        index,
                    };
                });
            case TransportType.SUV:
                return getVehicleEnumerable(state, TransportType.SUV).map((vehicle, index) => {
                    return {
                        ...vehicle,
                        key: `transport:suv:${index}`,
                        category: CategoryType.Transport,
                        type: TransportType.SUV,
                        index,
                    };
                });
            case TransportType.Van:
                return getVehicleEnumerable(state, TransportType.Van).map((vehicle, index) => {
                    return {
                        ...vehicle,
                        key: `transport:van:${index}`,
                        category: CategoryType.Transport,
                        type: TransportType.Van,
                        index,
                    };
                });
            case TransportType.Motorcycle:
                return getMotorcycleEnumerable(state).map((motorcycle, index) => {
                    return {
                        ...motorcycle,
                        key: `transport:motorcycle:${index}`,
                        category: CategoryType.Transport,
                        type: TransportType.Motorcycle,
                        index,
                    };
                });
            case TransportType.PublicTransport:
                return Object.entries(PublicTransportType).map(([key, value]) => {
                    return {
                        ...state[TransportType.PublicTransport][value],
                        key: `transport:public:${key}`,
                        category: CategoryType.Transport,
                        type: TransportType.PublicTransport,
                        subtype: value,
                    };
                });
        }
    });
}

export {
    getTransportList,
    getTransportTotal,
    getTransportTitle,
    getTransportSubtitle,
    getTransportDistanceAverage,
    getTransportFuelConsumptionAverage,
    VehicleSizes,
};
