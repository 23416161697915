import { Calculation } from 'src/api';
import { ReduxThunkAction } from 'src/store';
import { flushCategoriesState } from 'src/store/storage/flushCategoriesState';
import { flushEnergyState } from 'src/store/storage/flushEnergyState';
import { flushGroceriesState } from 'src/store/storage/flushGroceriesState';
import { flushMetadataState } from 'src/store/storage/flushMetadataState';
import { flushTransportState } from 'src/store/storage/flushTransportState';
import { flushTravelState } from 'src/store/storage/flushTravelState';
import { flushUIState } from 'src/store/storage/flushUIState';
import { flushWasteState } from 'src/store/storage/flushWasteState';
import { flushWaterState } from 'src/store/storage/flushWaterState';
import { Actions, ActionType } from '.';

type LoadCalculationProps = {
    calculation: Calculation;
};

const loadCalculation = (props: LoadCalculationProps): ReduxThunkAction<Actions> => {
    const { calculation } = props;
    return dispatch => {
        dispatch({
            type: ActionType.LOAD,
            payload: {
                ui: calculation.state.ui,
                calculation: {
                    ...calculation.state.calculation,
                    metadata: {
                        ...calculation.state.calculation.metadata,
                        unitPrice: calculation.unit_price,
                    },
                },
            },
        });
        flushUIState();
        flushMetadataState();
        flushCategoriesState();
        flushTransportState();
        flushEnergyState();
        flushGroceriesState();
        flushTravelState();
        flushWasteState();
        flushWaterState();
    };
};

export { loadCalculation };
