import React from 'react';
import { Grid } from 'src/components/shared/Grid';
import { NavigationPage } from '../NavigationPage';
import { ReactComponent as Transport } from './Transport.svg';
import { ReactComponent as Energy } from './Energy.svg';
import { ReactComponent as Travel } from './Travel.svg';
import { ReactComponent as Waste } from './Waste.svg';
import { ReactComponent as Groceries } from './Groceries.svg';
import { ReactComponent as Water } from './Water.svg';
import { RootState } from 'src/store';
import { useSelector, useDispatch } from 'react-redux';
import { ActionType } from 'src/store/actions/categories';
import { CategoryType } from 'src/models';
import { CheckboxControl } from 'src/components/shared/CheckboxControl';
import styles from './Categories.module.sass';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';

const Categories: React.FC = () => {
    const dispatch = useDispatch();
    const selected = useSelector<RootState, Array<CategoryType>>(state => state.calculation.categories.selected);
    return (
        <>
            <NavigationPage
                title="Getting Started - Categories"
                description="Before we start, which categories would you like to include in your household's carbon footprint calculation?"
                subtitle="Select all that apply"
                tooltip="The more categories you choose, the more accurate your carbon footprint calculation will be. You can add or remove categories later using the progress bar at the bottom of the calculator."
                enableNext={selected.length > 0}>
                <div className={styles.root}>
                    <Grid columns={{ default: 2, xs: 2, sm: 3, md: 3 }}>
                        <CheckboxControl
                            title="Travel"
                            subtitle="Road, air, rail & ocean"
                            icon={<Travel className={styles.icon} />}
                            size="large"
                            checked={selected.includes(CategoryType.Travel)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: CategoryType.Travel,
                                        selected: value,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            title="Energy"
                            subtitle="Electricity and other utilities"
                            icon={<Energy className={styles.icon} />}
                            size="large"
                            checked={selected.includes(CategoryType.Energy)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: CategoryType.Energy,
                                        selected: value,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            title="Water"
                            subtitle="State water supply"
                            icon={<Water className={styles.icon} />}
                            size="large"
                            checked={selected.includes(CategoryType.Water)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: CategoryType.Water,
                                        selected: value,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            title="Transport"
                            subtitle="Vehicles &amp; public transport"
                            icon={<Transport className={styles.icon} />}
                            size="large"
                            checked={selected.includes(CategoryType.Transport)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: CategoryType.Transport,
                                        selected: value,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            title="Food &amp; Drink"
                            subtitle="Household grocery &amp; dining"
                            icon={<Groceries className={styles.icon} />}
                            size="large"
                            checked={selected.includes(CategoryType.Groceries)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: CategoryType.Groceries,
                                        selected: value,
                                    },
                                })
                            }
                        />
                        <CheckboxControl
                            title="Waste"
                            subtitle="General waste disposal"
                            icon={<Waste className={styles.icon} />}
                            size="large"
                            checked={selected.includes(CategoryType.Waste)}
                            onChecked={value =>
                                dispatch({
                                    type: ActionType.SET_SELECTED,
                                    payload: {
                                        type: CategoryType.Waste,
                                        selected: value,
                                    },
                                })
                            }
                        />
                    </Grid>
                </div>
            </NavigationPage>
            <DidYouKnow
                text={[
                    "Australian households generate more than <strong>20%</strong> of Australia's total greenhouse gas emissions. It might surprise you to learn that small behaviour changes can significantly reduce your carbon footprint. Together, we can make a difference.",
                    'The average Australian household generates more than <strong>15 tonnes</strong> of CO₂e each year. It might surprise you to learn that small behaviour changes can significantly reduce your carbon footprint. Together, we can make a difference.',
                ]}
            />
        </>
    );
};

export { Categories };
