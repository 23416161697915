import React from 'react';
import { CheckboxControl } from 'src/components/shared/CheckboxControl';
import { Grid } from 'src/components/shared/Grid';
import { ReactComponent as NSW } from 'src/components/svg/Regions/NSW.svg';
import { ReactComponent as VIC } from 'src/components/svg/Regions/VIC.svg';
import { ReactComponent as QLD } from 'src/components/svg/Regions/QLD.svg';
import { ReactComponent as SA } from 'src/components/svg/Regions/SA.svg';
import { ReactComponent as TAS } from 'src/components/svg/Regions/TAS.svg';
import { ReactComponent as NT } from 'src/components/svg/Regions/NT.svg';
import { ReactComponent as WA } from 'src/components/svg/Regions/WA.svg';
import { ReactComponent as AusAvg } from 'src/components/svg/Regions/AusAvg.svg';
import { EnergyRegion } from 'src/models/energy';
import styles from './RegionSelector.module.sass';

type RegionSelectorProps = {
    region: EnergyRegion | undefined;
    onRegionChange: (region: EnergyRegion | undefined) => void;
};

const RegionSelector: React.FC<RegionSelectorProps> = (props: RegionSelectorProps) => {
    const { region, onRegionChange } = props;
    return (
        <Grid columns={{ default: 3, xs: 3, sm: 3, md: 4 }}>
            <CheckboxControl
                title="NSW/ACT"
                icon={<NSW className={styles.icon} />}
                checked={region === EnergyRegion.NSW_ACT}
                onChecked={value => onRegionChange(EnergyRegion.NSW_ACT)}
            />
            <CheckboxControl
                title="VIC"
                icon={<VIC className={styles.icon} />}
                checked={region === EnergyRegion.VIC}
                onChecked={value => onRegionChange(EnergyRegion.VIC)}
            />
            <CheckboxControl
                title="QLD"
                icon={<QLD className={styles.icon} />}
                checked={region === EnergyRegion.QLD}
                onChecked={value => onRegionChange(EnergyRegion.QLD)}
            />
            <CheckboxControl
                title="SA"
                icon={<SA className={styles.icon} />}
                checked={region === EnergyRegion.SA}
                onChecked={value => onRegionChange(EnergyRegion.SA)}
            />
            <CheckboxControl
                title="TAS"
                icon={<TAS className={styles.icon} />}
                checked={region === EnergyRegion.TAS}
                onChecked={value => onRegionChange(EnergyRegion.TAS)}
            />
            <CheckboxControl
                title="WA"
                icon={<WA className={styles.icon} />}
                checked={region === EnergyRegion.WA}
                onChecked={value => onRegionChange(EnergyRegion.WA)}
            />
            <CheckboxControl
                title="NT"
                icon={<NT className={styles.icon} />}
                checked={region === EnergyRegion.NT}
                onChecked={value => onRegionChange(EnergyRegion.NT)}
            />
            <CheckboxControl
                title="Use Aus Average"
                icon={<AusAvg className={styles.icon} />}
                checked={region === EnergyRegion.Australia}
                onChecked={value => onRegionChange(EnergyRegion.Australia)}
            />
        </Grid>
    );
};

export { RegionSelector };
