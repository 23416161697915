import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { PopupContainer } from './PopupContainer';
import { useNavigationState } from '../shared/Navigation';
import { NavigationKey } from 'src/store/reducers/navigationReducer';
import { useSelector } from 'react-redux';
import { CategoriesState, RootState } from 'src/store';
import { CategoryLabels } from './CategoryLabels';
import { TextNew } from '../shared/TextNew';
import styles from './Popup.module.sass';
import { ReactComponent as Chevron } from 'src/components/svg/ChevronDown.svg';
import { motion, AnimatePresence } from 'framer-motion';
import { Backdrop } from '../shared/Backdrop';


type PopupProps = {
    buttonStart: React.ReactElement;
    buttonResults: React.ReactElement;
};

const Popup = (props: PopupProps) => {
    const { index } = useNavigationState(NavigationKey.Categories);
    const { selected } = useSelector<RootState, CategoriesState>(state => state.calculation.categories);
    const [isOpen, setOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>();
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
    const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'top-start',
        modifiers: [{ name: 'arrow' }],
    });

    const closeHandler = () => {
        setOpen(false);
        document.body.removeAttribute('data-scroll-lock');
    };
    const toggleHandler = () => {
        if (isOpen) {
            setOpen(false);
            document.body.removeAttribute('data-scroll-lock');
        }
        else {
            setOpen(true);
        }
    };

    return (
        <>
            <AnimatePresence>
                {isOpen && <Backdrop className={styles.backdrop} onClick={closeHandler} />}
            </AnimatePresence>
            <button
                className={styles.buttonTrigger}
                aria-expanded={isOpen}
                data-category={CategoryLabels[selected[index]]}
                ref={setReferenceElement}
                onClick={toggleHandler}>
                <TextNew textSize={'S'} className={styles.buttonTriggerLabel}>
                    {CategoryLabels[selected[index]]} <Chevron className={styles.buttonTriggerIcon} />
                </TextNew>
            </button>
            <div ref={setPopperElement} className={styles.popup} style={popperStyles.popper} {...attributes.popper}>
                <AnimatePresence>
                    {isOpen && (
                        <PopupContainer
                            buttonStart={props.buttonStart}
                            buttonResults={props.buttonResults}
                            onClose={closeHandler}></PopupContainer>
                    )}
                </AnimatePresence>
            </div>
        </>
    );
};

export { Popup };
