import React, { MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import { TextNew } from './TextNew';
import styles from './ButtonText.module.sass';
import { Button, ButtonVariant } from './Button';

type IconAlignment = 'left' | 'right';

export type ButtonTextProps = {
    className?: string;
    text: string | React.FC | JSX.Element;
    icon?: ReactNode;
    iconAlignment?: IconAlignment;
    variant?: ButtonVariant;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
};

const ButtonText: React.FC<ButtonTextProps> = (props: ButtonTextProps) => {
    const { className, text, variant = 'primary', icon, iconAlignment = 'left', disabled, onClick } = props;
    return (
        <Button className={classNames(className, styles.root)} variant={variant} disabled={disabled} onClick={onClick}>
            {iconAlignment === 'left' && icon}
            <TextNew
                textSize={'S'}
                className={classNames({
                    [styles.padLeft]: icon && iconAlignment === 'left',
                    [styles.padRight]: icon && iconAlignment === 'right',
                })}
                >
                {text}
            </TextNew>
            {iconAlignment === 'right' && icon}
        </Button>
    );
};

export { ButtonText };
