import React from 'react';
import { NavigationPage } from '../NavigationPage';
import { Form, FormBadge, FormControl } from 'src/components/shared/Forms';
import { Grid } from 'src/components/shared/Grid';
import { ReactComponent as Firewood } from './Firewood.svg';
import { NumericInput } from 'src/components/shared/NumericInput';
import { RootState } from 'src/store';
import { EnergyType } from 'src/models/energy';
import { useDispatch, useSelector } from 'react-redux';
import { updateConsumption } from 'src/store/actions/energy/updateConsumption';
import { DidYouKnow } from 'src/components/shared/DidYouKnow';
import { useTimeframe } from 'src/store/utils/useTimeframe';

const FirewoodConsumption = () => {
    const { timeframeText } = useTimeframe();
    const consumption = useSelector<RootState, number>(
        state => state.calculation.energy[EnergyType.Firewood].consumption,
    );
    const dispatch = useDispatch();
    return (
        <>
            <NavigationPage
                title="Energy - Firewood"
                description={`Over the past ${timeframeText}, how much firewood has your household consumed?`}>
                <Form
                    size={'large'}
                    colorVariant={4}
                    badge={<FormBadge colorVariant={4} title="Firewood" icon={<Firewood />} />}>
                    <Grid columns={{ default: 1, sm: 1, md: 1 }}>
                        <FormControl label="Consumed">
                            <NumericInput
                                value={consumption}
                                units="kg"
                                onValueChange={value =>
                                    dispatch(updateConsumption({ type: EnergyType.Firewood, consumption: value }))
                                }
                            />
                        </FormControl>
                    </Grid>
                </Form>
            </NavigationPage>
            <DidYouKnow
                text={[
                    "In Australia, around <strong>5,000</strong> square kilometres of virgin bushland and advanced regrowth are destroyed annually. In addition to wiping out native wildlife, it is the major cause of salinity and represents around <strong>14%</strong> of Australia's total greenhouse gas emissions.",
                    "Australia is the only developed nation to make the World Wildlife Fund's (WWF) global list of deforestation hotspots.",
                ]}
            />
        </>
    );
};

export { FirewoodConsumption };
